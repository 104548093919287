export const STEP_STATE_DEFAULT = 'default'
export const STEP_STATE_SUCCESS = 'success'
export const STEP_STATE_ERROR = 'error'
export const STEP_STATE_VALIDATED = 'validated'

export type StepState =
  | typeof STEP_STATE_DEFAULT
  | typeof STEP_STATE_SUCCESS
  | typeof STEP_STATE_ERROR
  | typeof STEP_STATE_VALIDATED

export type Step<T extends string> = {
  id: T
  title: string
  state: StepState
  isClickable?: boolean
  isDisabled: boolean
  isActive?: boolean
  child?: React.ReactElement
  callback?: () => void
}
