import { parseDateTime } from 'chronos/utils/parseDateTime'

/**
 * @deprecated - to be migrated to js-joda intervals
 */
export type PeriodWithBoundaries = {
  startDate: Date
  endDate: Date
}

/**
 * @deprecated - to be migrated to js-joda intervals
 */
export class DeprecatedPeriod {
  startDate: Date | null
  endDate: Date | null

  constructor(startDate?: Date, endDate?: Date) {
    if (startDate && !(startDate instanceof Date)) {
      throw new Error('startDate must be of type Date')
    }

    if (endDate && !(endDate instanceof Date)) {
      throw new Error('endDate must be of type Date')
    }

    this.startDate = startDate || null
    this.endDate = endDate || null
  }

  toString() {
    return [this.startDate ? this.startDate.toISOString() : '', this.endDate ? this.endDate.toISOString() : ''].join(
      '/'
    )
  }

  toJSON() {
    return this.toString()
  }

  static fromString = (period: string) => {
    if (typeof period !== 'string' || !period.includes('/')) {
      throw new Error('period must be a string and needs to contain /')
    }

    const [start, end] = period.split('/')

    return new DeprecatedPeriod(start ? parseDateTime(start) : undefined, end ? parseDateTime(end) : undefined)
  }
}
