import { useIntl } from 'core/hooks'
import { firstUpper } from 'core/i18n/utils'
import lodash from 'lodash'
import PropTypes from 'prop-types'

type Props = {
  keys: any
  children: any
  capitalize: boolean
}

/**
 * @deprecated please use `t` function from `core/i18n` instead
 */
export const Translate = ({ keys, children, capitalize }: Props) => {
  const [, intl] = useIntl()
  const translations = lodash.reduce(
    keys,
    (all: any, key: any) => {
      let normalizedId = ''
      let normalizedValues = {}

      if (typeof key === 'string') {
        normalizedId = key
      } else {
        const [id, values] = key
        normalizedId = id
        normalizedValues = typeof values === 'function' ? values(...all) : values
      }

      let translated = intl.formatMessage({ id: normalizedId }, normalizedValues, capitalize)

      if (capitalize) translated = firstUpper(translated)

      return [...all, translated]
    },
    []
  )

  return children(...translations)
}

Translate.propTypes = {
  children: PropTypes.func.isRequired,
  capitalize: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])).isRequired,
  html: PropTypes.bool,
}

Translate.defaultProps = {
  capitalize: true,
  html: false,
}
