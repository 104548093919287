import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getActiveUser } from 'authentication/state/selectors'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { TranslateElement } from 'core/components/i18n'
import { getConfigKey, getFormat } from 'core/state/selectors'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { StyleSheet, css } from 'theme'

export class UnconnectedTimecheckMessage extends Component<any> {
  renderGeolocation = () => {
    return <FontAwesomeIcon className={css(styles.icon)} icon={faMapMarkerAlt} />
  }

  renderAdjustmentMessage = (adjustment: any, hourFormat: any) => {
    const adjustmentSource = adjustment.sourceOut || adjustment.sourceIn
    const adjustmentHour = format(adjustment.out || adjustment.in, hourFormat)

    return adjustmentSource.split(',').includes('breaktime') ? (
      <span>
        &nbsp;
        <TranslateElement
          id="timeclock.breaktime_adjustment_hour_details"
          values={{ hour: adjustmentHour }}
          capitalize={false}
        />
      </span>
    ) : null
  }

  render() {
    const { activeUserFirstName, activeUserGender, isGeolocation, period, hourFormat, adjustment } = this.props
    const { startDate: start, endDate: end } = period
    const title = end ? 'tipee.goodbye' : 'tipee.hello'
    const hour = end ? format(end, hourFormat) : format(start, hourFormat)

    let message = end ? 'timeclock.you_left_at' : 'timeclock.you_came_in'
    message += activeUserGender === '1' ? '_f' : ''

    return (
      <div>
        <div>
          <TranslateElement id={title} /> {activeUserFirstName}
        </div>
        <TranslateElement id={message} values={{ hour }} />
        {adjustment && this.renderAdjustmentMessage(adjustment, hourFormat)}
        {isGeolocation && this.renderGeolocation()}
      </div>
    )
  }
}

// @ts-expect-error (nocheck)
UnconnectedTimecheckMessage.propTypes = {
  activeUserFirstName: PropTypes.string.isRequired,
  isGeolocation: PropTypes.bool.isRequired,
  period: PropTypes.instanceOf(DeprecatedPeriod),
  adjustment: PropTypes.shape({
    in: PropTypes.instanceOf(Date),
    out: PropTypes.instanceOf(Date),
    sourceIn: PropTypes.string,
    sourceOut: PropTypes.string,
  }),
  activeUserGender: PropTypes.string,
  hourFormat: PropTypes.string,
}

// @ts-expect-error (nocheck)
UnconnectedTimecheckMessage.defaultProps = {
  activeUserGender: '',
  isGeolocation: false,
  hourFormat: 'HH:mm',
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: '5px',
  },
})

const mapStateToProps = (state: any) => {
  return {
    activeUserFirstName: getActiveUser(state).first_name,
    // @ts-expect-error (nocheck)
    activeUserGender: getActiveUser(state).gender,
    isGeolocation: getConfigKey(state, 'timeclock.geolocation'),
    hourFormat: getFormat(state, 'hour'),
  }
}

export const TimecheckMessage = connect(mapStateToProps)(UnconnectedTimecheckMessage)
