export const REQUEST_TASKS = 'REQUEST_TASKS'
export function requestTasks() {
  return {
    type: REQUEST_TASKS,
  }
}

export const RECEIVE_TASKS = 'RECEIVE_TASKS'
export function receiveTasks(tasks: any) {
  return {
    type: RECEIVE_TASKS,
    payload: {
      tasks,
    },
  }
}

export const REQUEST_TASK_COMPLETED = 'REQUEST_TASK_COMPLETED'
export function requestTaskCompleted(taskId: any) {
  return {
    type: REQUEST_TASK_COMPLETED,
    payload: {
      taskId,
    },
  }
}

export const MARK_TASK_COMPLETED = 'MARK_TASK_COMPLETED'
export function markTaskCompleted(taskId: any, taskAssigneeId: any) {
  return {
    type: MARK_TASK_COMPLETED,
    payload: {
      taskId,
      taskAssigneeId,
    },
  }
}

export const TASK_COMPLETED = 'TASK_COMPLETED'
export function taskCompleted(taskId: any, taskAssigneeId: any, dateHandled: any) {
  return {
    type: TASK_COMPLETED,
    payload: {
      taskId,
      taskAssigneeId,
      dateHandled,
    },
  }
}

export const ADD_TASK = 'ADD_TASK'
export function addTask(task: any) {
  return {
    type: ADD_TASK,
    payload: {
      task,
    },
  }
}

export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
export function addTaskSuccess(task: any) {
  return {
    type: ADD_TASK_SUCCESS,
    payload: {
      task,
    },
  }
}
