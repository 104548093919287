import { QualityTypes, RECEIVE_BOOKMARKS, REQUEST_BOOKMARKS } from 'quality/state/types'
import { BookmarkType } from 'quality/types'

export function requestBookmarks(): QualityTypes {
  return {
    type: REQUEST_BOOKMARKS,
  }
}

export function receiveBookmarks(bookmarks: BookmarkType[]): QualityTypes {
  return {
    type: RECEIVE_BOOKMARKS,
    payload: {
      bookmarks,
    },
  }
}
