import { parseDateTime } from 'chronos'
import { publicApi } from 'core/services/api'
import { SnowflakeType } from 'core/types'
import { RequestExpenseResponseType, SaveExpenseType } from 'expenses/state/types'
import { AttachmentType, ExpenseType } from 'expenses/types'

const deserialize = (expense: any): ExpenseType => ({ ...expense, date: parseDateTime(expense.date) })

export const expenseService = {
  delete: async (id: SnowflakeType): Promise<void> => {
    await publicApi.post('/expenses/expenses.delete', { id })
  },

  save: async (expense: SaveExpenseType): Promise<SnowflakeType> => {
    let id
    if (expense.id) {
      await publicApi.post('/expenses/expenses.update', expense)
      id = expense.id
    } else {
      const response = await publicApi.post('/expenses/expenses.create', expense)
      id = response.data.id
    }

    return id
  },

  getExpenses: async (expenseReportId: SnowflakeType): Promise<ExpenseType[]> => {
    const expenses = await publicApi.get<Array<RequestExpenseResponseType>>('/expenses/expenses.list', {
      params: {
        expense_report_id: [expenseReportId],
      },
    })
    return expenses.data.map(deserialize)
  },

  getExpense: async (expenseId: SnowflakeType): Promise<ExpenseType> => {
    const expenses = await publicApi.get<RequestExpenseResponseType>('/expenses/expenses.show', {
      params: {
        id: expenseId,
      },
    })
    return deserialize(expenses.data)
  },

  createAttachment: async (expenseId: SnowflakeType, attachment: { file: Blob; fileName: string }) => {
    const formData = new FormData()
    formData.append('attachment', attachment.file, attachment.fileName)
    formData.append('expense_id', expenseId)

    await publicApi.post('/expenses/attachment.create', formData)
  },

  removeAttachment: async (expenseId: SnowflakeType, attachment: AttachmentType) => {
    await publicApi.post('/expenses/attachment.delete', {
      expense_id: expenseId,
      attachment_id: attachment.id,
    })
  },
}
