import { AxiosResponse } from 'axios'
import { MessageType } from 'communication/types'
import { brain } from 'core/services/api'

export interface GetMessagesResponse extends AxiosResponse {
  messages: MessageType[]
  nb_of_messages: number
}

export const communicationService = {
  getMessages: async (personId: number): Promise<GetMessagesResponse> => {
    try {
      const config = await brain.get<GetMessagesResponse>('/message/person/' + personId)
      return config.data
    } catch (e: any) {
      throw e
    }
  },
}
