import { getAllowedSectors } from 'authentication/state/selectors'
import { SnowflakeType } from 'core/types'
import { subState } from 'directory/state/selectors/subState'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { StoreStateType } from 'store/types'

export const isFetchingSitesList = (state: StoreStateType) => subState(state).ui.sitesList.isFetching

export const getSites = (state: StoreStateType) => subState(state).sites
export const getSectors = (state: StoreStateType) => subState(state).sectors
export const getSectorById = (state: StoreStateType, teamId: number) => subState(state).sectors[teamId]
export const getSiteById = (state: StoreStateType, teamId: number) => subState(state).sites[teamId]

type GetSectorsAsOptions = (
  state: StoreStateType,
  resource?: string,
  action?: string,
  onlyEnabled?: boolean
) => { id: number; snowflake: SnowflakeType; label: string; site: string; siteId: number; siteSnowflake: string }[]

export const getSectorsAsOptions: GetSectorsAsOptions = createCachedSelector(
  (state: StoreStateType, resource?: string, action?: string) =>
    resource && action ? getAllowedSectors(state, resource, action) : '*',
  (_0: StoreStateType, _1?: string, _2?: string, onlyEnabled?: boolean) => onlyEnabled,
  getSectors,
  getSites,
  (allowedSectorIds, onlyEnabled, sectors, sites) => {
    const allowedSectors =
      allowedSectorIds === '*' ? sectors : lodash.filter(sectors, sector => allowedSectorIds.includes(sector.id))
    const onlyEnabledSectors = onlyEnabled ? lodash.pickBy(allowedSectors, sector => sector.enabled) : allowedSectors
    return lodash(onlyEnabledSectors)
      .map((sector: any) => ({
        id: sector.id,
        snowflake: sector.snowflake,
        label: sector.name,
        site: sites[sector.siteId].name || '',
        siteId: sector.siteId,
        siteSnowflake: sites[sector.siteId].snowflake || '',
      }))
      .value()
  }
)((state: StoreStateType, resource: string, action: string) => `sectors_by_acl_${resource}_${action}`)
