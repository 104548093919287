import { pal } from 'design/colors'

export const fg = {
  greyStrong: {
    default: pal.grey600,
    hover: pal.grey600,
    disable: pal.grey400,
  },
  greyMedium: {
    default: pal.grey500,
    hover: pal.grey600,
    disable: pal.grey300,
  },
  greyLight: {
    default: pal.grey400,
    hover: pal.grey500,
    disable: pal.grey200,
  },
  blue: { default: pal.blue600, hover: pal.blue700, disable: pal.blue300 },
  pink: { default: pal.pink700, hover: pal.pink800, disable: pal.pink300 },
  success: {
    default: pal.green600,
    hover: pal.green700,
    disable: pal.green300,
  },
  warning: { default: pal.orange600, hover: pal.orange700, disable: pal.orange300 },
  error: {
    default: pal.red600,
    hover: pal.red700,
    disable: pal.red300,
  },
  white: { default: pal.white, hover: pal.white, disable: pal.white },
} as const
