import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { bookmarksService } from 'quality/services/bookmarks'
import { receiveBookmarks } from 'quality/state/actions'
import { REQUEST_BOOKMARKS } from 'quality/state/types'
import { BookmarkType } from 'quality/types'
import { put, takeLatest } from 'typed-redux-saga'

function* fetchBookmarks() {
  try {
    const bookmarks: BookmarkType[] = yield* callApi(bookmarksService.getBookmarks)
    yield* put(receiveBookmarks(bookmarks))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export const qualitySaga = function* qualitySaga() {
  yield* takeLatest(REQUEST_BOOKMARKS, fetchBookmarks)
}
