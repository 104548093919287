// reference: https://design.tipee.ch/7e40187d1/p/544200--tailles/
export enum Size {
  v16 = '16px',
  v20 = '20px',
  v24 = '24px', // S
  v32 = '32px', // M
  v40 = '40px', // L
  v48 = '48px',
  v56 = '56px',
}

export type PickSize<Keys extends keyof typeof Size> = Keys

export enum OverlaySize {
  v220 = '220px',
  v320 = '320px',
  v420 = '420px', // default
  v520 = '520px',
  v620 = '620px',
  v720 = '720px',
}

export type PickOverlaySize<Keys extends keyof typeof OverlaySize> = Keys
