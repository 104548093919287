import {
  ActivityTypes,
  CLOSE_ACTIVITIES,
  RECEIVE_ACTIVITIES,
  RECEIVE_ACTIVITY_RECENT_TAGS,
  REQUEST_ACTIVITY_RECENT_TAGS,
  REQUEST_OPENED_ACTIVITY,
  START_ACTIVITY,
  STOP_ACTIVITY,
} from 'activity/state/types'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { SnowflakeType } from 'core/types'
import { TagType } from 'tag/types'

export function closeActivities(end: Date): ActivityTypes {
  return {
    type: CLOSE_ACTIVITIES,
    payload: {
      end,
    },
  }
}

export function requestOpenedActivity(personId: number): ActivityTypes {
  return {
    type: REQUEST_OPENED_ACTIVITY,
    payload: {
      personId,
    },
  }
}

export function requestActivityRecentTags(
  personId: number | null,
  contextDate?: Date,
  forcedTags?: SnowflakeType[]
): ActivityTypes {
  return {
    type: REQUEST_ACTIVITY_RECENT_TAGS,
    payload: {
      personId,
      contextDate,
      forcedTags,
    },
  }
}

export function receiveActivityRecentTags(tags: SnowflakeType[]): ActivityTypes {
  return {
    type: RECEIVE_ACTIVITY_RECENT_TAGS,
    payload: {
      tags,
    },
  }
}

export function startActivity(tags: TagType[], period: DeprecatedPeriod, remark?: string): ActivityTypes {
  return {
    type: START_ACTIVITY,
    payload: {
      tags,
      period,
      remark,
    },
  }
}

export function stopActivity(id: number, tags: any, period: DeprecatedPeriod, remark?: string): ActivityTypes {
  return {
    type: STOP_ACTIVITY,
    payload: {
      id,
      tags,
      period,
      remark,
    },
  }
}

export function receiveActivities(activities: any) {
  return {
    type: RECEIVE_ACTIVITIES,
    payload: {
      activities,
    },
  }
}
