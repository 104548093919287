import { ResourceModesAdapter } from 'balance/adapter/ResourceModesAdapter'
import { BalanceModes, ReportsPermissions, TimecheckModes, WorkDisplayModes } from 'balance/types'
import { publicApi } from 'core/services/api'
import { SnowflakeType } from 'core/types'

export const balanceService = {
  getResourceModes: async (resourceSnowflake: SnowflakeType) => {
    try {
      const resourceModes = await publicApi.get(`/balances/modes.show?resource=${resourceSnowflake}`)

      return ResourceModesAdapter.fromApi(resourceModes.data)
    } catch (e) {
      throw e
    }
  },
  saveResourceTimecheckMode: async (resourceSnowflake: SnowflakeType, mode: TimecheckModes) => {
    try {
      await publicApi.post(`/balances/modes.change-timecheck-mode`, {
        resources: [resourceSnowflake],
        mode,
      })
    } catch (e) {
      throw e
    }
  },
  saveResourceBalancekMode: async (resourceSnowflake: SnowflakeType, mode: BalanceModes) => {
    try {
      await publicApi.post(`/balances/modes.change-balance-mode`, {
        resources: [resourceSnowflake],
        mode,
      })
    } catch (e) {
      throw e
    }
  },
  saveResourceWorkDisplayMode: async (resourceSnowflake: SnowflakeType, mode: WorkDisplayModes) => {
    try {
      await publicApi.post(`/balances/work-display-mode.change`, {
        resources: [resourceSnowflake],
        mode,
      })
    } catch (e) {
      throw e
    }
  },
  showReportsPermissions: async () => {
    try {
      return (await publicApi.get<ReportsPermissions>(`/balances/reports.show-permission`)).data
    } catch (e) {
      throw e
    }
  },
}
