import lodash from 'lodash'

export class PeriodAdapter {
  static adapt = (periods: any) => {
    return lodash.reduce(
      periods,
      (all: any[], period) => {
        const property = period.hasOwnProperty('planif_id') ? 'planif_id' : 'absence_id'
        const index = lodash.findIndex(all, formattedPeriod => formattedPeriod[property] === period[property])

        if (index !== -1) {
          all[index] = {
            ...all[index],
            periods: [...all[index].periods, { start: period.start, end: period.end }],
          }
        } else {
          all.push({
            ...period,
            periods: [{ start: period.start, end: period.end }],
          })
        }

        return all
      },
      []
    )
  }

  static revert = (periods: any) => {
    return lodash.reduce(
      periods,
      (all, current) => {
        return [
          ...all,
          ...lodash.map(current.periods, period => {
            const reverted = {
              ...current,
              start: period.start,
              end: period.end,
            }

            delete reverted.periods

            return reverted
          }),
        ]
      },
      [] as any[]
    )
  }
}
