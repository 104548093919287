import {
  Entity,
  PageReceivedAction,
  PAGE_RECEIVED,
  PerformOperationSuccessAction,
  PERFORM_OPERATION_SUCCESS,
} from 'crud/state/types'
import lodash from 'lodash'

export const withCrudReducer =
  <T, V extends { type: string }>(wantedName: string) =>
  (wrappedReducer: (state: T, action: V) => T) =>
  (currentState: T, action: PageReceivedAction | PerformOperationSuccessAction | V) => {
    switch (action.type) {
      case PAGE_RECEIVED: {
        const {
          meta: { entityName },
          payload: { entities },
        } = action as PageReceivedAction

        // remove first portion of the entityName, which is the state slice
        const normalizedEntityName = entityName.split('.').slice(1).join('.')

        if (wantedName !== normalizedEntityName) {
          return wrappedReducer(currentState, action as V)
        }

        // map entities to the appropriate subtree
        const statePart = lodash.set({}, normalizedEntityName, {})

        lodash.forEach(entities, entity => {
          lodash.set(statePart, normalizedEntityName, {
            ...lodash.get(statePart, normalizedEntityName),
            [entity.id]: entity,
          })
        })

        return lodash.mergeWith({}, currentState, statePart, (objValue, srcValue) => {
          /* istanbul ignore if */
          if (lodash.isArray(objValue)) {
            return srcValue
          }
        })
      }
      case PERFORM_OPERATION_SUCCESS: {
        const {
          meta: { entityName },
          payload: { operation, result },
        } = action as PerformOperationSuccessAction

        // remove first portion of the entityName, which is the state slice
        const normalizedEntityName = entityName.split('.').slice(1).join('.')

        if (wantedName !== normalizedEntityName || operation !== 'view') {
          return wrappedReducer(currentState, action as V)
        }

        return lodash.mergeWith(
          {},
          currentState,
          {
            [normalizedEntityName]: {
              [((result as any).data as Entity).id]: (result as any).data,
            },
          },
          (objValue, srcValue) => {
            /* istanbul ignore if */
            if (lodash.isArray(objValue)) {
              return srcValue
            }
          }
        )
      }
      default:
        return wrappedReducer(currentState, action as V)
    }
  }
