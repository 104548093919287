import { Translate } from 'core/components/i18n/Translate'

export type TranslateElementProps<C extends React.ElementType> = {
  id: string
  values?: object
  html?: boolean
  capitalize?: boolean
  component?: C
  prefix?: string
  suffix?: string
  [x: string]: any
}

/**
 * @deprecated please use the correct typo from `ui/dataDisplay/Typo`
 */
export const TranslateElement: <T extends React.ElementType>(
  props: TranslateElementProps<T>
) => React.ReactElement<TranslateElementProps<T>> = ({
  id,
  values = {},
  html = false,
  capitalize = true,
  component: Component = 'span',
  prefix = '',
  suffix = '',
  ...componentProps
}) => (
  <Translate keys={[[id, values]]} capitalize={capitalize}>
    {translation =>
      html ? (
        <Component {...componentProps} dangerouslySetInnerHTML={{ __html: prefix + translation + suffix }}></Component>
      ) : (
        <Component {...componentProps}>{prefix + translation + suffix}</Component>
      )
    }
  </Translate>
)
