import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { SnowflakeType } from 'core/types'
import { TagShallow } from 'tag/types'

export const CLOCK_IN = 'CLOCK_IN'
export function clockIn(tags?: TagShallow[], period?: DeprecatedPeriod, remark?: string) {
  return {
    type: CLOCK_IN,
    payload: {
      tags,
      period,
      remark,
    },
  }
}

export const CLOCK_OUT = 'CLOCK_OUT'
export function clockOut(tags?: TagShallow[], period?: DeprecatedPeriod, activityId?: string, remark?: string) {
  return {
    type: CLOCK_OUT,
    payload: {
      tags: tags || [],
      period,
      activityId,
      remark,
    },
  }
}

export const REQUEST_CHANGE_ACTIVE_TIMECHECK_TAG = 'REQUEST_CHANGE_ACTIVE_TIMECHECK_TAG'
export function requestChangeActiveTimecheckTag() {
  return {
    type: REQUEST_CHANGE_ACTIVE_TIMECHECK_TAG,
  }
}

export const RECEIVE_CHANGE_ACTIVE_TIMECHECK_TAG = 'RECEIVE_CHANGE_ACTIVE_TIMECHECK_TAG'
export function receiveChangeActiveTimecheckTag() {
  return {
    type: RECEIVE_CHANGE_ACTIVE_TIMECHECK_TAG,
  }
}

export const REQUEST_TIMECHECK_TAG_VALIDATION = 'REQUEST_TIMECHECK_TAG_VALIDATION'
export function requestTimecheckTagValidation() {
  return {
    type: REQUEST_TIMECHECK_TAG_VALIDATION,
  }
}

export const RECEIVE_TIMECHECK_TAG_VALIDATION = 'RECEIVE_TIMECHECK_TAG_VALIDATION'
export function receiveTimecheckTagValidation() {
  return {
    type: RECEIVE_TIMECHECK_TAG_VALIDATION,
  }
}

// todo: ajouter remark ?
export const REQUEST_ASSIGN_LAST_TIMECHECK_TAG = 'REQUEST_ASSIGN_LAST_TIMECHECK_TAG'
export function requestAssignLastTimecheckTag(tags: SnowflakeType[], remark: string) {
  return {
    type: REQUEST_ASSIGN_LAST_TIMECHECK_TAG,
    payload: {
      tags,
      remark,
    },
  }
}

export const RECEIVE_ASSIGN_LAST_TIMECHECK_TAG = 'RECEIVE_ASSIGN_LAST_TIMECHECK_TAG'
export function receiveAssignLastTimecheckTag(tags: TagShallow[]) {
  return {
    type: RECEIVE_ASSIGN_LAST_TIMECHECK_TAG,
    payload: {
      tags,
    },
  }
}
export const REQUEST_ASSIGN_TAG_TO_TIMECHECK = 'REQUEST_ASSIGN_TAG_TO_TIMECHECK'
export function requestAssignTagToTimecheck(timecheck: SnowflakeType, tags: SnowflakeType[], remark: string) {
  return {
    type: REQUEST_ASSIGN_TAG_TO_TIMECHECK,
    payload: {
      tags,
      timecheck,
      remark,
    },
  }
}

export const RECEIVE_ASSIGN_TAG_TO_TIMECHECK = 'RECEIVE_ASSIGN_TAG_TO_TIMECHECK'
export function receiveAssignTagToTimecheck() {
  return {
    type: RECEIVE_ASSIGN_TAG_TO_TIMECHECK,
    payload: {},
  }
}

export const FILTER_PRESENCE = 'FILTER_PRESENCE'
export function filterPresence(text: string) {
  return {
    type: FILTER_PRESENCE,
    payload: {
      text,
    },
  }
}

export const REQUEST_PRESENCE = 'REQUEST_PRESENCE'
export function requestPresence() {
  return {
    type: REQUEST_PRESENCE,
  }
}

export const RECEIVE_PRESENCE = 'RECEIVE_PRESENCE'
export function receivePresence(presences: any) {
  return {
    type: RECEIVE_PRESENCE,
    payload: {
      presences,
    },
  }
}

export const REQUEST_LAST_TIMECHECK = 'REQUEST_LAST_TIMECHECK'
export function requestLastTimecheck() {
  return {
    type: REQUEST_LAST_TIMECHECK,
  }
}

export const REQUEST_LIST_TIMECHECK_FOR_RANGE = 'REQUEST_LIST_TIMECHECK_FOR_RANGE'
export function requestListTimecheckForRange(payload: any) {
  return {
    type: REQUEST_LIST_TIMECHECK_FOR_RANGE,
    payload,
  }
}

export const RECEIVE_LAST_TIMECHECK = 'RECEIVE_LAST_TIMECHECK'
export function receiveLastTimecheck(lastTimecheck: any) {
  lastTimecheck.time_in = lastTimecheck.time_in ? lastTimecheck.time_in.replace(/-/g, '/') : null
  lastTimecheck.time_out = lastTimecheck.time_out ? lastTimecheck.time_out.replace(/-/g, '/') : null

  return {
    type: RECEIVE_LAST_TIMECHECK,
    payload: {
      lastTimecheck,
    },
  }
}

export const RECEIVE_LIST_TIMECHECK_FOR_RANGE = 'RECEIVE_LIST_TIMECHECK_FOR_RANGE'
export function receiveListTimecheckForRange(payload: any) {
  return {
    type: RECEIVE_LIST_TIMECHECK_FOR_RANGE,
    payload,
  }
}

export const RECEIVE_LIST_TIMECHECK_FOR_RANGE_FORBIDDEN = 'RECEIVE_LIST_TIMECHECK_FOR_RANGE_FORBIDDEN'
export function receiveListTimecheckForRangeForbidden() {
  return {
    type: RECEIVE_LIST_TIMECHECK_FOR_RANGE_FORBIDDEN,
  }
}

export const REQUEST_LIST_TIMECHECK_FOR_DATE = 'REQUEST_LIST_TIMECHECK_FOR_DATE'
export function requestListTimecheckForDate(payload: any) {
  return {
    type: REQUEST_LIST_TIMECHECK_FOR_DATE,
    payload,
  }
}

export const RECEIVE_LIST_TIMECHECK_FOR_DATE = 'RECEIVE_LIST_TIMECHECK_FOR_DATE'
export function receiveListTimecheckForDate(payload: any) {
  return {
    type: RECEIVE_LIST_TIMECHECK_FOR_DATE,
    payload,
  }
}

export const RECEIVE_LIST_TIMECHECK_FOR_DATE_FORBIDDEN = 'RECEIVE_LIST_TIMECHECK_FOR_DATE_FORBIDDEN'
export function receiveListTimecheckForDateForbidden() {
  return {
    type: RECEIVE_LIST_TIMECHECK_FOR_DATE_FORBIDDEN,
  }
}

export const SAVE_TIMECHECK = 'SAVE_TIMECHECK'
export function saveTimecheck() {
  return {
    type: SAVE_TIMECHECK,
  }
}

export const RECEIVE_TIMECHECKS = 'RECEIVE_TIMECHECKS'
export function receiveTimechecks(timechecks: any) {
  return {
    type: RECEIVE_TIMECHECKS,
    payload: {
      timechecks,
    },
  }
}

export const REQUEST_PUT_TIMECHECK_VALIDATION = 'REQUEST_PUT_TIMECHECK_VALIDATION'
export function requestPutTimecheckValidation(payload: any) {
  return {
    type: REQUEST_PUT_TIMECHECK_VALIDATION,
    payload,
  }
}

export const RECEIVE_PUT_TIMECHECK_VALIDATION = 'RECEIVE_PUT_TIMECHECK_VALIDATION'
export function receivePutTimecheckValidation(payload: any) {
  return {
    type: RECEIVE_PUT_TIMECHECK_VALIDATION,
    payload,
  }
}

export const REQUEST_PUT_TIMECHECK_RANGE_VALIDATION = 'REQUEST_PUT_TIMECHECK_RANGE_VALIDATION'
export function requestPutTimecheckRangeValidation(payload: any) {
  return {
    type: REQUEST_PUT_TIMECHECK_RANGE_VALIDATION,
    payload,
  }
}

export const RECEIVE_PUT_TIMECHECK_RANGE_VALIDATION = 'RECEIVE_PUT_TIMECHECK_RANGE_VALIDATION'
export function receivePutTimecheckRangeValidation(payload: any) {
  return {
    type: RECEIVE_PUT_TIMECHECK_RANGE_VALIDATION,
    payload,
  }
}

export const REQUEST_PUT_TIMECHECK_DATE_VALIDATION = 'REQUEST_PUT_TIMECHECK_DATE_VALIDATION'
export function requestPutTimecheckDateValidation(payload: any) {
  return {
    type: REQUEST_PUT_TIMECHECK_DATE_VALIDATION,
    payload,
  }
}

export const RECEIVE_PUT_TIMECHECK_DATE_VALIDATION = 'RECEIVE_PUT_TIMECHECK_DATE_VALIDATION'
export function receivePutTimecheckDateValidation(payload: any) {
  return {
    type: RECEIVE_PUT_TIMECHECK_DATE_VALIDATION,
    payload,
  }
}
