import { SnowflakeType } from 'core/types'
import {
  CONFIRM_DELETE_EXPENSE_REPORT,
  DELETE_ATTACHMENT,
  DELETE_EXPENSE,
  DELETE_EXPENSE_REPORT,
  ExpenseReportResponseType,
  ExpensesTypes,
  PREVALIDATE_EXPENSE_REPORT,
  RECEIVED_EXPENSES_EXPORT,
  RECEIVE_EXPENSE,
  RECEIVE_EXPENSES,
  RECEIVE_EXPENSE_REPORT_DETAILS,
  RECEIVE_EXPENSE_REPORT_SUMMARY,
  RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT,
  RECEIVE_EXPENSE_TYPES_ENABLED,
  RECEIVE_RECENT_EXPENSES_TAGS,
  REFUSE_EXPENSE_REPORT,
  RENAME_EXPENSE_REPORT,
  REQUEST_EXPENSE,
  REQUEST_EXPENSES,
  REQUEST_EXPENSES_EXPORT,
  REQUEST_EXPENSE_REPORT_DETAILS,
  REQUEST_EXPENSE_REPORT_SUMMARY,
  REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT,
  REQUEST_EXPENSE_TYPES_ENABLED,
  REQUEST_RECENT_EXPENSES_TAGS,
  RESET_EXPENSE_MODAL,
  SaveExpenseType,
  SAVE_ATTACHMENT,
  SAVE_EXPENSE,
  SAVE_EXPENSE_SUCCEEDED,
  SEND_EXPENSE_REPORT_TO_VALIDATION,
  VALIDATE_EXPENSE_REPORT,
} from 'expenses/state/types'
import {
  ExpenseType,
  ExpenseTypeType,
  EXPENSE_REPORT_GROUPS,
  EXPENSE_REPORT_GROUP_ARCHIVED,
  EXPENSE_REPORT_STATUS_VALIDATED,
  EXPENSE_REPORT_TYPES,
} from 'expenses/types'

export const requestExpensesByExpenseReportId = (expenseReportId: SnowflakeType): ExpensesTypes => ({
  type: REQUEST_EXPENSES,
  payload: {
    expenseReportId,
  },
})

export const receiveExpenses = (expenses: ExpenseType[]): ExpensesTypes => ({
  type: RECEIVE_EXPENSES,
  payload: {
    expenses,
  },
})

export const requestExpenseReportSummary = (
  type: EXPENSE_REPORT_TYPES,
  group: EXPENSE_REPORT_GROUPS,
  lastSnowflake?: SnowflakeType,
  date?: Date,
  authorId?: SnowflakeType,
  validatorId?: SnowflakeType
): ExpensesTypes => ({
  type: REQUEST_EXPENSE_REPORT_SUMMARY,
  payload: {
    type,
    group,
    ...(group === EXPENSE_REPORT_GROUP_ARCHIVED && lastSnowflake && date
      ? {
          pagination: {
            lastSnowflake,
            date,
          },
        }
      : {}),
    ...(authorId ? { authorId } : {}),
    ...(validatorId ? { validatorId } : {}),
  },
})

export const receiveExpenseReportSummary = (
  type: EXPENSE_REPORT_TYPES,
  group: EXPENSE_REPORT_GROUPS,
  expenseReports: ExpenseReportResponseType[]
): ExpensesTypes => ({
  type: RECEIVE_EXPENSE_REPORT_SUMMARY,
  payload: {
    type,
    group,
    expenseReports,
  },
})

export const requestExpenseReportDetails = (id: SnowflakeType): ExpensesTypes => ({
  type: REQUEST_EXPENSE_REPORT_DETAILS,
  payload: {
    id,
  },
})

export const receiveExpenseReportDetails = (details: ExpenseReportResponseType): ExpensesTypes => ({
  type: RECEIVE_EXPENSE_REPORT_DETAILS,
  payload: {
    details,
  },
})

export const renameExpenseReport = (id: SnowflakeType, label: string): ExpensesTypes => ({
  type: RENAME_EXPENSE_REPORT,
  payload: {
    id,
    label,
  },
})

export const requestExpenseTypesEnabled = (): ExpensesTypes => ({
  type: REQUEST_EXPENSE_TYPES_ENABLED,
})

export const receiveExpenseTypesEnabled = (expenseTypes: Array<ExpenseTypeType>): ExpensesTypes => ({
  type: RECEIVE_EXPENSE_TYPES_ENABLED,
  payload: {
    expenseTypes,
  },
})

export const requestExpenseAction = (expenseId: SnowflakeType): ExpensesTypes => ({
  type: REQUEST_EXPENSE,
  payload: {
    expenseId,
  },
})

export const receiveExpense = (expense: ExpenseType): ExpensesTypes => ({
  type: RECEIVE_EXPENSE,
  payload: {
    expense,
  },
})

export const saveExpense = (expense: SaveExpenseType): ExpensesTypes => ({
  type: SAVE_EXPENSE,
  payload: {
    expense,
  },
})

export const saveExpenseSucceeded = (id: SnowflakeType): ExpensesTypes => ({
  type: SAVE_EXPENSE_SUCCEEDED,
  payload: {
    id,
  },
})

export const deleteExpenseReport = (id: SnowflakeType): ExpensesTypes => ({
  type: DELETE_EXPENSE_REPORT,
  payload: {
    id,
  },
})

export const confirmDeleteExpenseReport = (id: SnowflakeType): ExpensesTypes => ({
  type: CONFIRM_DELETE_EXPENSE_REPORT,
  payload: {
    id,
  },
})

export const deleteExpense = (expenseId: SnowflakeType, expenseReportId: SnowflakeType): ExpensesTypes => ({
  type: DELETE_EXPENSE,
  payload: {
    expenseId,
    expenseReportId,
  },
})

export const resetExpenseModal = () => ({
  type: RESET_EXPENSE_MODAL,
})

export const sendToValidation = (
  expenseReportId: SnowflakeType,
  validatorId: SnowflakeType,
  remark: string | null
): ExpensesTypes => ({
  type: SEND_EXPENSE_REPORT_TO_VALIDATION,
  payload: {
    expenseReportId,
    validatorId,
    remark,
  },
})

export const validate = (expenseReportId: SnowflakeType, remark: string | null): ExpensesTypes => ({
  type: VALIDATE_EXPENSE_REPORT,
  payload: {
    expenseReportId,
    remark,
  },
})

export const prevalidate = (
  expenseReportId: SnowflakeType,
  validatorId: SnowflakeType,
  remark: string | null
): ExpensesTypes => ({
  type: PREVALIDATE_EXPENSE_REPORT,
  payload: {
    expenseReportId,
    validatorId,
    remark,
  },
})

export const refuse = (expenseReportId: SnowflakeType, remark: string | null): ExpensesTypes => ({
  type: REFUSE_EXPENSE_REPORT,
  payload: {
    expenseReportId,
    remark,
  },
})

export const requestExpenseReportToValidateCount = () => ({
  type: REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT,
})

export const receiveExpenseReportToValidateCount = (count: number): ExpensesTypes => ({
  type: RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT,
  payload: {
    count,
  },
})

export const saveAttachment = (attachment: string, fileName: string): ExpensesTypes => ({
  type: SAVE_ATTACHMENT,
  payload: {
    data: attachment,
    fileName,
  },
})

export const deleteAttachment = (): ExpensesTypes => ({
  type: DELETE_ATTACHMENT,
})

export const requestExpensesExport = (
  alreadyExported: boolean,
  exportedAt: boolean,
  date: Date,
  status: typeof EXPENSE_REPORT_STATUS_VALIDATED
): ExpensesTypes => ({
  type: REQUEST_EXPENSES_EXPORT,
  payload: {
    exportedAt,
    date,
    alreadyExported,
    status,
  },
})

export const receivedExpensesExport = (): ExpensesTypes => ({
  type: RECEIVED_EXPENSES_EXPORT,
})

export const requestRecentExpensesTags = (): ExpensesTypes => {
  return {
    type: REQUEST_RECENT_EXPENSES_TAGS,
  }
}

export const receiveRecentExpensesTags = (tags: SnowflakeType[]): ExpensesTypes => {
  return {
    type: RECEIVE_RECENT_EXPENSES_TAGS,
    payload: {
      tags: tags,
    },
  }
}
