/**
 * Port of PHP implementation of the function floor() with the flag PHP_ROUND_HALF_EVEN
 * https://github.com/php/php-src/blob/dfae7f9835c931bf94cf02f10003e6acacf93ab6/ext/standard/math.c#L96
 */
export const roundFloat = (value: number, precision = 2) => {
  const factor = Math.pow(10, precision)
  const scaledValue = value * factor

  let tmpValue

  if (scaledValue >= 0) {
    tmpValue = Math.floor(scaledValue + 0.5)
    if (scaledValue === 0.5 + 2 * Math.floor(tmpValue / 2)) tmpValue = tmpValue - 1
  } else {
    tmpValue = Math.ceil(scaledValue - 0.5)
    if (scaledValue === -0.5 + 2 * Math.ceil(tmpValue / 2)) tmpValue = tmpValue + 1
  }

  return tmpValue / factor
}

/**
 *
 * @param opt.round default `true`
 * @param opt.precision default `2`
 * @param opt.cute default `true` &mdash; removes trailing zeros
 * @param opt.alwaysNumber default `false` &mdash; if `true` and value is "not a number" or "infinite", returns `0`
 */
export const formatFloat = (
  value: number,
  opt: { round?: boolean; precision?: number; cute?: boolean; alwaysNumber?: boolean } = {}
) => {
  // default options
  const options = { round: true, precision: 2, cute: true, alwaysNumber: false, ...opt }

  let res = value
  if (options.round) res = roundFloat(res, options.precision)

  if (options.alwaysNumber && (isNaN(res) || !isFinite(res))) res = 0

  let resString = res.toFixed(options.precision)
  if (options.cute) {
    // remove trailing zeros. Also remove the dot if no decimal or all zeros
    resString = resString.replace(/(\.0*|0+)$/, '')
  }

  return resString
}

export const formatCurrency = (value: number) => {
  return (
    value
      // de-CH ensures the apostrophe as thousands separator
      .toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF',
        currencyDisplay: 'code',
      })
      // put CHF at the end
      .replace(/\s*CHF\s*/, '')
      // remove decimal if xxx.00
      .replace(/\.00$/, '') + ' CHF'
  )
}

export const between = (incl: '()' | '[]' | '(]' | '[)', x: number) => (a: number, b: number) => {
  const [aIncl, bIncl] = incl.split('')
  const aComp = aIncl === '(' ? x > a : x >= a
  const bComp = bIncl === ')' ? x < b : x <= b
  return aComp && bComp
}
