import { CrudService } from 'crud/services/CrudService'
import {
  ADD_SORT_FIELD,
  CHANGE_PER_PAGE,
  CrudActionTypes,
  GO_TO_PAGE,
  INIT_CRUD,
  PAGE_RECEIVED,
  PERFORM_OPERATION,
  PERFORM_OPERATION_FAILED,
  PERFORM_OPERATION_SUCCESS,
  REFRESH_PAGE,
  ServiceEnabledAction,
  UPDATE_FILTER,
} from 'crud/state/types'

const serviceEnabledAction =
  (actionType: string) =>
  (entityName: string, payload: object, service: CrudService): ServiceEnabledAction<typeof actionType> => {
    if (typeof service !== 'object' || !(service.isPrototypeOf(CrudService) || service instanceof CrudService)) {
      throw new Error('Service must be specified, and should be a subclass of CrudService')
    }

    return {
      type: actionType,
      payload,
      meta: {
        service,
        entityName,
      },
    }
  }

export const initCrud = (entityName: string): CrudActionTypes => {
  return {
    type: INIT_CRUD,
    payload: {
      entityName,
    },
  }
}

export const updateFilter = serviceEnabledAction(UPDATE_FILTER)
export const goToPage = serviceEnabledAction(GO_TO_PAGE)
export const refreshPage = serviceEnabledAction(REFRESH_PAGE)
export const changePerPage = serviceEnabledAction(CHANGE_PER_PAGE)
export const addSortField = serviceEnabledAction(ADD_SORT_FIELD)
export const pageReceived = serviceEnabledAction(PAGE_RECEIVED)
export const performOperation = serviceEnabledAction(PERFORM_OPERATION)
export const performOperationSuccess = serviceEnabledAction(PERFORM_OPERATION_SUCCESS)
export const performOperationFailed = serviceEnabledAction(PERFORM_OPERATION_FAILED)
