import { getActiveUser } from 'authentication/state/selectors'
import { parseDateTime } from 'chronos'
import { isBefore, isToday, isTomorrow, isYesterday, startOfYesterday } from 'date-fns'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

const subState = (state: any) => state.task

export const getTasks = (state: any) => subState(state).tasks
export const getTasksByPeriod = createSelector(getTasks, tasks => {
  const periods = {
    overdue: {},
    today: {},
    tomorrow: {},
    later: {},
  }

  lodash.forIn(tasks, task => {
    const dateDue = parseDateTime(task.date_due.slice(0, 22) + 'Z')
    if (isYesterday(dateDue) || isBefore(dateDue, startOfYesterday())) {
      // @ts-expect-error
      periods.overdue[task.id] = task
    } else if (isToday(dateDue)) {
      // @ts-expect-error
      periods.today[task.id] = task
    } else if (isTomorrow(dateDue)) {
      // @ts-expect-error
      periods.tomorrow[task.id] = task
    } else {
      // @ts-expect-error
      periods.later[task.id] = task
    }
  })

  return periods
})

export const getTasksForToday = createCachedSelector(
  getTasksByPeriod,
  (state: any, personId: any) => personId,
  (periods, personId) =>
    lodash.pickBy(periods.today, (task: any) =>
      lodash.find(
        task.assignees,
        assignee => assignee.person_id === personId && typeof assignee.date_handled === 'undefined'
      )
    )
)((state, personId) => 'today_tasks_' + personId.toString())

export const getTasksForTodayAmount = createCachedSelector(
  getTasksForToday,
  tasks => Object.keys(tasks).length
)((state, personId) => 'today_tasks_amount_' + personId.toString())

export const isPendingCompletion = createCachedSelector(
  getTasks,
  getActiveUser,
  state => subState(state).ui.pendingCompletion,
  (state: any, taskId: any) => taskId,
  (tasks, { id: activeUserId }, pendingCompletion, taskId) => {
    const assigneeId = lodash.find(tasks[taskId].assignees, {
      person_id: activeUserId,
    }).id

    return lodash.findIndex(pendingCompletion, { assigneeId }) !== -1
  }
)((state, taskId) => 'pending_completion' + taskId.toString())

export const getTask = (state: any, id: any) => getTasks(state)[id]
export const isFormSubmitting = (state: any) => subState(state).ui.form.isSubmitting

export const getLastUpdated = (state: any): null | number => subState(state).lastUpdated
