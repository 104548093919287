import { ActivityType } from 'activity/types'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { brain } from 'core/services/api'
import { FilterType } from 'core/types'
import { TagReferenceType, TagType } from 'tag/types'

export type SearchParamType = {
  query: FilterType[]
  order_by: { [x: string]: 'ASC' | 'DESC' }
}

export const activityService = {
  addActivity: async (period: DeprecatedPeriod, tags: TagType[], remark?: string): Promise<ActivityType> => {
    const activity = await brain.post('/activities', { period, tags, remark })
    return activity.data
  },
  editActivity: async (
    id: number,
    period: DeprecatedPeriod,
    tags: TagType[],
    remark?: string
  ): Promise<ActivityType> => {
    const activity = await brain.put('/activities/' + id, { period, tags, remark })
    return activity.data
  },
  searchActivity: async (params: SearchParamType): Promise<ActivityType[]> => {
    const activity = await brain.post('/activities/search', params)
    return activity.data.data
  },
  getRecentTags: async (personId: number | null): Promise<TagReferenceType[]> => {
    const tags = await brain.get('/activities/tags/recent', {
      params: {
        limit: 30,
        ...(personId && {
          person_id: personId,
        }),
      },
    })
    return tags.data
  },
}
