import { parseDateTime } from 'chronos'
import { brain } from 'core/services/api'
import { format, parseISO } from 'date-fns'

export const adjustmentService = {
  getBalances: async (personId: any, date: any) => {
    const balances = await brain.get(
      '/plannings/adjustments/' + personId + '/' + format(parseDateTime(date), 'yyyy-MM-dd')
    )
    return {
      work: {
        balance: balances.data.work.balance * -1,
        amount: balances.data.work.value ? balances.data.work.value * -1 : null,
        date: parseDateTime(balances.data.work.date),
        remark: balances.data.work.remark || '',
      },
      holidays: {
        balance: balances.data.holidays.balance,
        amount: balances.data.holidays.value || null,
        date: parseDateTime(balances.data.holidays.date),
        remark: balances.data.holidays.remark || '',
      },
    }
  },
  adjustmentPersonsBatch: async (personsAdjustementBalances: any) => {
    return await brain.post('/plannings/adjustments-summary/batch', {
      ...personsAdjustementBalances,
      date: format(parseISO(personsAdjustementBalances.date), 'yyyy-MM-dd'),
    })
  },
}
