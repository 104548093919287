import { ActivityMessage } from 'activity/components/ActivityMessage'
import React from 'react'
import { TimecheckMessage } from 'timeclock/components/TimecheckMessage'

type Props = {
  isStamping: boolean
  tags: []
  period: any
  adjustment: any
}

export const TimecheckNotification = ({ isStamping, tags = [], period, adjustment }: Props) => {
  return (
    <React.Fragment>
      {isStamping && <TimecheckMessage period={period} adjustment={adjustment} />}
      {tags.length > 0 && <ActivityMessage tags={tags} period={period} />}
    </React.Fragment>
  )
}

TimecheckNotification.defaultProps = {
  tags: [],
}
