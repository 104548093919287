import { TagType } from 'tag/types'

export class TagAdapter {
  static fromApi(tag: any) {
    return {
      id: tag.id,
      snowflake: tag.snowflake,
      name: tag.name,
      enabled: tag.enabled,
      externalId: tag.external_id,
      sectors: tag.sectors,
    } as TagType
  }

  static arrayFromApi(tags: []) {
    return tags.map((tag: any) => TagAdapter.fromApi(tag)) as TagType[]
  }
}
