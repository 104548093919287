export const bp = {
  largeDesktop: '1920px',
  tablet: '991px',
  mobileLandscape: '767px',
  mobilePortrait: '478px',
} as const

/**
 * @deprecated Use the object `bp` instead in design/breakpoints.ts
 */
export const breakpoints = {
  xxs: '@media (max-width: 360px)',
  sm: '@media (min-width: 576px)',
  smMax: '@media (max-width: 576px)',
  md: '@media (min-width: 768px)',
  mdMax: '@media (max-width: 768px)',
  lg: '@media (min-width: 992px)',
  xl: '@media (min-width: 1200px)',
  xxl: '@media (min-width: 1900px)',
  ultra: '@media (min-width: 2200px)',
  sidebar: {
    hidden: {
      left: '-100%',
    },
    collapsed: {
      width: '90px',
    },
    full: {
      width: '260px',
    },
  },
} as const
