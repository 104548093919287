import * as actions from 'communication/task/state/actions'
import produce from 'immer'
import lodash from 'lodash'

const initialState = {
  ui: {
    isFetching: false,
    pendingCompletion: [],
    form: {
      isSubmitting: false,
    },
  },
  tasks: {},
  lastUpdated: null,
}

export const taskReducer = (currentState: any = initialState, action: any) =>
  produce(currentState, (draft: any) => {
    switch (action.type) {
      case actions.REQUEST_TASKS:
        draft.ui.isFetching = true
        break
      case actions.RECEIVE_TASKS: {
        const { tasks } = action.payload
        tasks.forEach((task: any) => (draft.tasks[task.id] = task))
        draft.ui.isFetching = false
        draft.lastUpdated = Date.now()
        break
      }
      case actions.MARK_TASK_COMPLETED:
        draft.ui.isFetching = true
        draft.ui.pendingCompletion.push({
          id: action.payload.taskId,
          assigneeId: action.payload.taskAssigneeId,
        })
        break
      case actions.TASK_COMPLETED:
        const pendingCompletion = lodash.filter(
          currentState.ui.pendingCompletion,
          ({ assigneeId }) => assigneeId !== action.payload.taskAssigneeId
        )

        const tasks = {}
        for (const task in currentState.tasks) {
          const taskToAdd = { ...currentState.tasks[task] } // shallow copy
          if (taskToAdd.id === action.payload.taskId) {
            taskToAdd.assignees = taskToAdd.assignees.map((assignee: any) => {
              if (assignee.id === action.payload.taskAssigneeId) {
                return { ...assignee, date_handled: action.payload.dateHandled }
              }
              return assignee
            })
          }
          // @ts-expect-error (... to add insult to injury)
          tasks[task] = taskToAdd
        }

        draft.tasks = tasks
        draft.ui.isFetching = false
        draft.ui.pendingCompletion = pendingCompletion
        break
      case actions.ADD_TASK:
        draft.ui.form.isSubmitting = true
        break
      case actions.ADD_TASK_SUCCESS:
        draft.ui.form.isSubmitting = false
        draft.tasks[action.payload.task.id] = action.payload.task
        break
      default:
        break
    }
  })
