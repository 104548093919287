import { SnowflakeType } from 'core/types'
import {
  RECEIVE_EXTRA_TAG_SELECT_TAGS,
  RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME,
  RECEIVE_TAGS,
  RECEIVE_TAG_LIST,
  RECEIVE_TAG_SELECT_HAS_TAGS,
  RECEIVE_TAG_SELECT_TAGS,
  REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME,
  REQUEST_TAGS,
  REQUEST_TAG_LIST,
  REQUEST_TAG_SELECT_HAS_TAGS,
  REQUEST_TAG_SELECT_TAGS,
  TagTypes,
} from 'tag/state/types'
import { RequestTagFilter, TagType } from 'tag/types'

export function requestTags(id: number, filter: RequestTagFilter, contextDate?: Date): TagTypes {
  return {
    type: REQUEST_TAGS,
    payload: {
      id,
      filter,
      contextDate,
    },
  }
}

export function receiveTags(tags: TagType[]): TagTypes {
  return {
    type: RECEIVE_TAGS,
    payload: {
      tags,
    },
  }
}

export function requestTagsList(
  tags: SnowflakeType[],
  id?: number,
  filter?: RequestTagFilter,
  contextDate?: Date
): TagTypes {
  return {
    type: REQUEST_TAG_LIST,
    payload: {
      tags,
      id,
      filter,
      contextDate,
    },
  }
}

export function receiveTagList(tags: TagType[]): TagTypes {
  return {
    type: RECEIVE_TAG_LIST,
    payload: {
      tags,
    },
  }
}

export function requestTagSelectTags(id: number, filter: RequestTagFilter, contextDate?: Date): TagTypes {
  return {
    type: REQUEST_TAG_SELECT_TAGS,
    payload: {
      id,
      filter,
      contextDate,
    },
  }
}

export function receiveTagSelectTags(tags: SnowflakeType[]): TagTypes {
  return {
    type: RECEIVE_TAG_SELECT_TAGS,
    payload: {
      tags,
    },
  }
}

export function receiveExtraTagSelectTags(tags: SnowflakeType[]): TagTypes {
  return {
    type: RECEIVE_EXTRA_TAG_SELECT_TAGS,
    payload: {
      tags,
    },
  }
}

export function requestTagSelectHasTags(id: number, filter: RequestTagFilter, contextDate?: Date): TagTypes {
  return {
    type: REQUEST_TAG_SELECT_HAS_TAGS,
    payload: {
      id,
      filter,
      contextDate,
    },
  }
}

export function receiveTagSelectHasTags(hasTags: boolean): TagTypes {
  return {
    type: RECEIVE_TAG_SELECT_HAS_TAGS,
    payload: {
      hasTags,
    },
  }
}

export function requestSearchTagSelectTagsByName(
  name: string,
  id: number,
  filter: RequestTagFilter,
  contextDate?: Date
) {
  return {
    type: REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME,
    payload: {
      id,
      filter,
      contextDate,
      name,
    },
  }
}

export function receiveSearchTagSelectTagsByName(tags: SnowflakeType[]): TagTypes {
  return {
    type: RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME,
    payload: {
      tags,
    },
  }
}
