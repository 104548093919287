import { brain } from 'core/services/api'

export const sessionService = {
  fetchSession: async (key: string): Promise<string> => {
    const session = await brain.get(`/usersessions/${key}`)
    return session.data
  },
  saveSession: async (key: string, value: string): Promise<boolean> => {
    await brain.put(`/usersessions/${key}`, value)
    return true
  },
}
