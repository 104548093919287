/**
 * @deprecated Use `/design/colors` instead
 */
export const colors = {
  dropdownGrey: '#76838f',
  dropdownGreyHover: '#f3f7f9',
  lightGrey: '#f4f4f4',
  lightGreyBorder: '#E6ECF5',
  lightGreyHover: '#efefef',
  greyHover: '#dcdcdc',
  mediumGrey: '#a9a9a9',
  darkGrey: '#37474f',

  greySecondary: '#676A84',
  lightPink: '#f7e1ef',
  lightGreen: '#cbeec9',
  lightRed: '#fa7a7a',
  darkGreen: '#00874b',
  green: '#3cbe32',
  skyblue: '#00a5e3',

  default: '#e4eaec',
  primary: '#0C6ECB',
  primaryLight: '#E6EEFC',
  primaryLightHover: '#CEE0FE',
  secondary: '#bf077f',
  success: '#46BE8A',
  success10: '#EEF9F4',
  success40: '#ADE1C9',
  warning: '#f2a654',
  warningBackground: '#FDF3E8',
  info: '#57c7d4',
  danger: '#f96868',
  dangerBackground: '#FFEAEA',

  events: {
    orange: '#f2a859',
    pink: '#4f54CD',
  },

  //Status pills
  pill: {
    warningDark: '#633603',
    lightGreen: '#C4E9D9',
    green: '#44BC88',
    darkGreen: '#1B4B36',
    textOrange: '#F88D11',
    backgroundOrange: '#FEE7CD',
    greyBorder: '#788697',
    greyBackground: '#EEEEEE',
    blackText: '#314154',
    lightBlue: '#D2EEFF',
    darkBlue: '#37474F',
  },

  alert: {
    success: {
      background: '#E9FAF2',
      border: '#46BE8A',
    },
    error: {
      background: '#FFEAEA',
      border: '#FF4C52',
    },
    warning: {
      background: '#FFF3E6',
      border: '#F2A654',
    },
    info: {
      background: '#E8F6FB',
      border: '#0C6ECB',
    },
  },

  blue: '#00a5e3',
  red: '#ff4c52',
  white: '#fff',

  placeholder: '#f9f9f9',
  placeholderPrimary: '#f3f3f3',
  placeholderSecondary: '#ecebeb',

  input: {
    disabledColor: '#999999',
    disabledBackground: '#f2f2f2',
    placeholder: '#aab4bb',
  },

  roundButton: {
    default: {
      hoverBackground: '#e5e5e5',
      color: '#76838f',
      background: 'transparent',
    },
  },
  // timecheck
  timecheck: {
    manualEntryText: '#0C6ECB',
    proposalText: '#DE6A18',
    validationText: '#17845B',
  },

  absence: {
    color: '#dddddd',
  },

  // timeclock
  lightSuccess: '#e7faf2',
  lightWarning: '#f9eec1',
  lightError: '#fad3d3',

  // tables
  table: {
    border: '#E5E5E5',
    line: 'white',
    lineHover: '#F9F9F9',
    lineOdd: '#FCFCFC',
    lineOddHover: '#F4F4F4',
    highlight: '#EFEFEF',
    highlightHover: '#EAEAEA',
    textColor: '#37474f',
    textEmptyColor: '#A9A9A9',
  },

  // tipee - offical colors
  tipee: {
    lightGreen: '#7ebd0b',
    green: '#3cbe32',
    darkGreen: '#00874b',
    lightBlue: '#00a5df',
    blue: '#0C6ECB',
    darkBlue: '#036fad',
    lightPink: '#e5005a',
    pink: '#bf077f',
    darkPink: '#70196f',
  },

  rgb: {
    darkGrey: '55, 71, 79',
    black: '0, 0, 0',
    default: '161, 170, 176',
    dropdownGrey: '118, 131, 143',
    info: '87, 199, 212',
    mediumGrey: '169, 169, 169',
    pink: '242, 65, 153',
    secondary: '191, 7, 127',
    skyblue: '0, 165, 227',
    success: '70, 190, 138',
    warning: '242, 166, 84',
    white: '255, 255, 255',
    timecheck: {
      manualEntryText: '217, 83, 79',
      proposalText: '240, 173, 78',
      validationText: '92, 184, 92',
    },
  },
} as const
