import { pal } from 'design/colors'

export const br = {
  grey: {
    light: pal.grey100,
    medium: pal.grey300,
    strong: pal.grey500,
  },
  blue: {
    light: pal.blue200,
    medium: pal.blue300,
    strong: pal.blue600,
  },
  pink: {
    light: pal.pink100,
    medium: pal.pink300,
    strong: pal.pink700,
  },
  success: {
    light: pal.green100,
    medium: pal.green300,
    strong: pal.green600,
  },
  warning: {
    light: pal.orange100,
    medium: pal.orange300,
    strong: pal.orange600,
  },
  error: {
    light: pal.red100,
    medium: pal.red300,
    strong: pal.red600,
  },
} as const
