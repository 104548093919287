import { brain, publicApi } from 'core/services/api'
import { TeamType } from 'core/types'
import { TagAdapter } from 'tag/adapter/TagAdapter'

export const timeclockService = {
  getPresences: async () => {
    try {
      const presences = await publicApi.get('/employees/inthehouse')
      return presences.data || []
    } catch (e) {
      throw e
    }
  },
  getRawTimecheck: async (id: any) => {
    try {
      const { data: timecheck } = await publicApi.get(`/timeclock/timechecks/${id}`)
      return timecheck
    } catch (e) {
      throw e
    }
  },
  deleteTimecheck: async (id: any) => {
    try {
      await publicApi.delete(`/timeclock/timechecks/${id}`)
    } catch (e) {
      throw e
    }
  },
  deleteProposal: async (id: any) => {
    try {
      await brain.delete(`/timeclock/validations/timechecks/proposal/${id}`)
    } catch (e) {
      throw e
    }
  },
  deleteValidation: async (id: any) => {
    try {
      await brain.delete(`/timeclock/validations/timechecks/validation/${id}`)
    } catch (e) {
      throw e
    }
  },
  getLastTimecheck: async () => {
    try {
      const timecheck = await brain.get('/timeclock/validations/timechecks/last')
      //if properties.TimechecTags exist in the return object from api we format it and add every tags information in the lasttimecheck object
      if (
        timecheck.data &&
        timecheck.data.relations &&
        timecheck.data.relations.TimecheckTags &&
        timecheck.data.relations.Tags
      ) {
        const [formattedTags] = extractTagsInfo(timecheck.data.relations.TimecheckTags, timecheck.data.relations.Tags)
        timecheck.data.properties = { ...timecheck.data.properties, timecheckTags: formattedTags }
      }
      return timecheck.data ? timecheck.data.properties : {}
    } catch (e) {
      throw e
    }
  },
  getWorkday: async (data: any) => {
    try {
      const timecheck = await publicApi.get(`/employees/${data.personId}/workday?date=${data.date}`)
      return timecheck.data
    } catch (e) {
      throw e
    }
  },
  getListTimecheckForRange: async (data: any) => {
    try {
      const timecheck = await publicApi.get(
        `/timeclock/timechecks?date_start=${data.startDate}&date_end=${data.endDate}&person=${data.personId}`
      )
      return timecheck.data
    } catch (e) {
      throw e
    }
  },
  getTimecheckListForDate: async ({ date, sectorId }: any) => {
    try {
      const timecheckList = await publicApi.get(`/timeclock/timechecks?date=${date}&sector=${sectorId}`)
      /**
       * Bugfix: if a team has only members that do not have the "employee" role in this sector,
       * the API returns a 204 status code instead of a response with the whole object when there is no timecheck for the given date
       * --> In this case, we return an empty array to avoid breaking the application
       * */
      if (timecheckList.status === 204) {
        return []
      }
      return timecheckList.data
    } catch (e) {
      throw e
    }
  },
  getTimecheckListForRange: async ({ startDate, endDate, team }: any) => {
    const teamTypeString = team.type === TeamType.Sector ? 'sector' : 'site'
    try {
      const timecheck = await publicApi.get(
        `/timeclock/timechecks?date_start=${startDate}&date_end=${endDate}&${teamTypeString}=${team.id}`
      )
      return timecheck.data
    } catch (e: any) {
      throw e.response
    }
  },
  enumerateValidatableTeams: async () => {
    try {
      const response = await publicApi.get('/timeclock/validation.enumerate-teams')
      return response.data // : string[]
    } catch (e) {
      throw e
    }
  },
  enumerateValidatableResources: async ({ dateStart = '', dateEnd = '' } = {}) => {
    try {
      const dateToSend = (dateStart || '-') + '/' + (dateEnd || '-')
      const response = await publicApi.get('/timeclock/validation.enumerate-resources', {
        params: { date_range: dateToSend },
      })
      return response.data // : string[]
    } catch (e) {
      throw e
    }
  },
  putTimecheckValidation: async (data: any) => {
    try {
      const timecheck = await brain.put(`/timeclock/validations/days/${data.day}/employees/${data.personId}`)
      return timecheck.data
    } catch (e: any) {
      throw e.response
    }
  },
  putTimecheckRangeValidation: async (data: any) => {
    try {
      const timecheckRange = await brain.put(
        `/timeclock/validations/days/${data.startDate}/${data.endDate}/employee/${data.personId}`
      )
      return timecheckRange.data
    } catch (e: any) {
      throw e.response
    }
  },
  putTimecheckDateValidation: async (data: any) => {
    try {
      const timecheckDate = await brain.put(`/timeclock/validations/days/${data.date}/sectors/${data.sectorId}`)
      return timecheckDate.data
    } catch (e: any) {
      throw e.response
    }
  },
  putValidation: async (id: any, datas: any) => {
    try {
      await brain.put(`/timeclock/validations/timechecks/${id}`, datas)
    } catch (e: any) {
      throw e.response
    }
  },
  saveSingleProposal: async (data: any) => {
    try {
      const response = await brain.post('/timeclock/validations/timechecks/proposal', data)
      return response.data
    } catch (e: any) {
      throw e.response
    }
  },
  saveValidation: async (data: any) => {
    try {
      return await brain.post('/timeclock/validations/timechecks', data)
    } catch (e: any) {
      throw e.response
    }
  },
  saveBulkValidation: async (data: any) => {
    try {
      // data is an object such as {id: ["123", "456", "789"]}
      return await brain.post('/timeclock/validations/timechecks/ids', data)
    } catch (e: any) {
      throw e.response
    }
  },
  saveTimecheck: async (data: any) => {
    try {
      const timecheck = await brain.post('/timeclock/timechecks', data)
      if (timecheck.data && timecheck.data.relations && 'TimecheckTags' in timecheck.data.relations) {
        const [timecheckTags, tags] = extractTagsInfo(
          timecheck.data.relations.TimecheckTags,
          timecheck.data.relations.Tags
        )
        timecheck.data.properties = { ...timecheck.data.properties, timecheckTags, tags }
      }
      return timecheck.data ? timecheck.data.properties : {}
    } catch (e) {
      throw e
    }
  },
  putProposal: async (id: any, datas: any) => {
    try {
      await brain.put(`/timeclock/validations/timechecks/proposal/${id}`, datas)
    } catch (e: any) {
      throw e.response
    }
  },
  getCurrentLocation: async () => {
    try {
      const geolocData = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        })
      })

      return await geolocData
    } catch (e) {
      return false
    }
  },
  getPersonList: async () => {
    try {
      const params = {
        action: 'manage',
        mapping: JSON.stringify([
          'id',
          'first_name',
          'last_name',
          'full_name',
          'picture',
          'company',
          'is_company',
          'mainsectorname',
        ]),
        order: 'ASC',
        resource: 'timecheck',
        role: 'employee',
      }
      const persons = await brain.get('/persons', { params })
      return persons.data
    } catch (e) {
      return []
    }
  },
  assignTagToTimecheck: async (timecheck: any, tags: any, remark: any) => {
    const params = {
      timecheck,
      tags,
      tags_comment: remark,
    }
    try {
      await publicApi.post('/timeclock/timecheck.tag', params)
    } catch (e: any) {
      throw e.response
    }
  },
  getTimecheckMatching: async (resourceSnowflake: any, timepointDate: any) => {
    try {
      const params = {
        resource: resourceSnowflake,
        timepoint: timepointDate,
      }

      const { data } = await publicApi.get('/timeclock/timecheck.match?', { params })
      return data
    } catch (e: any) {
      throw e.response
    }
  },
}

const extractTagsInfo = (timecheckTags: any, tags: any) => {
  const relatedTags = [] as any[]
  const formattedTags = timecheckTags.map((timecheckTag: any) => {
    const tag = tags.find((tag: any) => tag.properties.snowflake === timecheckTag.properties.tag_id)
    relatedTags.push({ ...tag.properties })
    return {
      ...timecheckTag.properties,
      tag: TagAdapter.fromApi({
        ...tag.properties,
        sectors: [],
        enabled: tag.properties.enabled === '1',
      }),
    }
  })
  return [formattedTags, relatedTags]
}
