/* eslint-disable no-restricted-imports */
import { LocalDate, LocalDateTime, LocalTime } from '@js-joda/core'
/* eslint-enable no-restricted-imports */
import { Interval } from 'chronos/interval/Interval'
import { parseInterval } from 'chronos/interval/parseInterval'
import { format } from 'chronos/utils'
import { t } from 'core/i18n'

export type FiniteLocalDateInterval = LocalDateTimeInterval & {
  start: LocalDateTime
  end: LocalDateTime
}
export class LocalDateTimeInterval extends Interval<LocalDateTime> {
  static parse(value: string) {
    return parseInterval(LocalDateTime, LocalDateTimeInterval, value)
  }

  static of(start: LocalDateTime | null, end: LocalDateTime | null) {
    if (start && end) {
      if (start.isAfter(end)) {
        throw new Error('start must be before end')
      }
    }
    return new LocalDateTimeInterval(start, end)
  }
  static ofFinite(start: LocalDateTime, end: LocalDateTime) {
    return new LocalDateTimeInterval(start, end) as FiniteLocalDateInterval
  }

  /**
   * Creates a new LocalDateTimeInterval of the given date from 00:00 to 23:59.
   */
  static ofDay(date: LocalDate) {
    return new LocalDateTimeInterval(LocalDateTime.of(date, LocalTime.MIN), LocalDateTime.of(date, LocalTime.MAX))
  }

  static since(start: LocalDateTime) {
    return new LocalDateTimeInterval(start, null)
  }

  static until(end: LocalDateTime) {
    return new LocalDateTimeInterval(null, end)
  }

  static containerOf(items: LocalDateTime[]) {
    if (items.length === 0) return new LocalDateTimeInterval(null, null)
    if (items.length === 1) return new LocalDateTimeInterval(items[0], items[0])

    const min = items.reduce<LocalDateTime>((acc, item) => (item.isBefore(acc) ? item : acc), items[0])
    const max = items.reduce<LocalDateTime>((acc, item) => (item.isAfter(acc) ? item : acc), items[0])

    return new LocalDateTimeInterval(min, max)
  }

  *iterate() {
    if (!this.isFinite()) throw new Error('Iterate is not supported for infinite interval.')

    for (let i = this.start.toLocalDate(); i.isBefore(this.end.toLocalDate().plusDays(1)); i = i.plusDays(1)) {
      yield i
    }
  }

  days() {
    return Array.from(this.iterate())
  }

  countDays() {
    if (!this.isFinite()) return Infinity

    return this.days().length
  }

  // TODO:
  // For now we dont manage the case where the interval is open.
  // Should we move this method to a helper file ? (more business-related than technical)
  /**
   * Splits an overnight interval into two intervals, each covering one day.
   * @throws {Error} if the interval is infinite, spans more than 2 days or is on the same day.
   */
  splitOvernightInterval() {
    if (!this.isFinite()) {
      throw new Error('Cannot split infinite interval')
    }

    if (this.countDays() > 2 || this.start.toLocalDate().equals(this.end.toLocalDate())) {
      throw new Error('Cannot split interval spanning more than 2 days or on the same day')
    }

    const midnight = LocalDateTime.of(this.end.toLocalDate(), LocalTime.MIN)

    return [new LocalDateTimeInterval(this.start, midnight), new LocalDateTimeInterval(midnight, this.end)] as const
  }

  // TODO move it elsewhere because it's only for the Time part ?
  format(options?: { verboseFiniteRange?: boolean; capitalize?: boolean }) {
    const pattern = 'like HH:mm'

    if (this.isFinite()) {
      return options?.verboseFiniteRange
        ? t(
            'tipee.from_date_to_date',
            {
              startDate: format.local(this.start, pattern),
              endDate: format.local(this.end, pattern),
            },
            { capitalize: options?.capitalize ?? true }
          )
        : `${format.local(this.start, pattern)} - ${format.local(this.end, pattern)}`
    }
    if (this.hasStart()) {
      return t(
        'tipee.since_date',
        { date: format.local(this.start, pattern) },
        { capitalize: options?.capitalize ?? true }
      )
    }
    if (this.hasEnd()) {
      return t(
        'planning.until.date',
        { date: format.local(this.end, pattern) },
        { capitalize: options?.capitalize ?? true }
      )
    }
  }
}
