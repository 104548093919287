import { activityService, SearchParamType } from 'activity/services/activity'
import { receiveActivities, receiveActivityRecentTags } from 'activity/state/actions'
import {
  REQUEST_ACTIVITY_RECENT_TAGS,
  REQUEST_OPENED_ACTIVITY,
  RequestActivityRecentTagsAction,
  RequestOpenedActivityAction,
  START_ACTIVITY,
  StartActivityAction,
  STOP_ACTIVITY,
  StopActivityAction,
} from 'activity/state/types'
import { parseDateTime } from 'chronos'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { SnowflakeType } from 'core/types'
import { receiveExtraTagSelectTags, requestTagsList } from 'tag/state/actions'
import { RECEIVE_TAG_LIST, ReceiveTagListAction } from 'tag/state/types'
import { RequestTagFilter } from 'tag/types'
import { RECEIVE_LAST_TIMECHECK, saveTimecheck } from 'timeclock/state/actions'
import { ReceiveLastTimechecksAction } from 'timeclock/types'
import { all, put, take, takeLatest, takeLeading } from 'typed-redux-saga'
import { pushToast } from 'utils/pushToast'

function* fetchOpenedActivity(action: RequestOpenedActivityAction) {
  const {
    payload: { personId },
  } = action
  try {
    const params: SearchParamType = {
      query: [
        {
          field: 'activity.period.end.utc',
          op: '=',
          value: null,
        },
        {
          field: 'activity.person',
          op: '=',
          value: personId,
        },
      ],
      order_by: {
        'activity.period.start.utc': 'ASC',
      },
    }

    const activities = yield* callApi(activityService.searchActivity, params)
    yield* put(receiveActivities(activities))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export function* fetchRecentTags(action: RequestActivityRecentTagsAction) {
  try {
    const {
      payload: { personId, contextDate },
    } = action
    const recentTags = yield* callApi(activityService.getRecentTags, personId)

    const recentTagsSnowflakes: SnowflakeType[] = recentTags.map(tag => tag.snowflake)

    if (recentTagsSnowflakes.length > 0) {
      yield* put(requestTagsList(recentTagsSnowflakes, personId || undefined, RequestTagFilter.ByResource, contextDate))
      const result = yield* take<ReceiveTagListAction>(RECEIVE_TAG_LIST)
      yield* put(receiveExtraTagSelectTags(result.payload.tags.map(tag => tag.snowflake)))
    }

    yield* put(receiveActivityRecentTags(recentTagsSnowflakes))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* startActivity(action: StartActivityAction) {
  const {
    payload: { tags, remark },
  } = action
  let {
    payload: { period },
  } = action
  try {
    if (!period) {
      yield* put(saveTimecheck())
      const lastTimecheckAction = yield* take<ReceiveLastTimechecksAction>(RECEIVE_LAST_TIMECHECK)
      period = new DeprecatedPeriod(
        parseDateTime(
          lastTimecheckAction.payload.lastTimecheck.adjusted_in || lastTimecheckAction.payload.lastTimecheck.time_in
        )
      )
    }
    const activities = yield* callApi(activityService.addActivity, period, tags, remark)

    yield* put(receiveActivities([activities]))
  } catch (e: any) {
    pushToast('tipee.server_error', 'error')
  }
}

function* stopActivity(action: StopActivityAction) {
  const {
    payload: { tags, period, remark },
  } = action

  try {
    const activities = yield* callApi(activityService.editActivity, action.payload.id, period, tags, remark)

    yield* put(receiveActivities([activities]))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export const activitySaga = function* activitySaga() {
  all([
    yield* takeLeading(REQUEST_OPENED_ACTIVITY, fetchOpenedActivity),
    yield* takeLatest(REQUEST_ACTIVITY_RECENT_TAGS, fetchRecentTags),
    yield* takeLatest(START_ACTIVITY, startActivity),
    yield* takeLatest(STOP_ACTIVITY, stopActivity),
  ])
}
