import { TranslatedString } from 'api'
import { useBootstrap } from 'core/hooks/useBootstrap'
import { Locale } from 'core/i18n/types'
import { useCallback, useMemo } from 'react'

export type TranslatedStringUtils = {
  translate: (translatedString: TranslatedString) => string
  hasTranslation: (translatedString: TranslatedString) => boolean
  isMultilingual: boolean
  priorityList: Locale[]
  flatten: (translatedString: TranslatedString) => string[]
}

const hasTranslation = (translatedString: TranslatedString) => {
  const keys = Object.keys(translatedString)
  for (const key of keys) {
    if (translatedString[key as Locale] && translatedString[key as Locale] !== '') return true
  }
  return false
}

export const useTranslatedString = () => {
  const { config } = useBootstrap()
  const priorityList: Locale[] = config.multilingual_language
  const userLocale = config.active_language

  const isMultilingual = priorityList.length > 1

  //avoids that the user locale ovverides the priorityList if it's not in it
  const filteredPriorityList = useMemo((): Locale[] => {
    if (userLocale && priorityList.includes(userLocale)) {
      return [userLocale, ...priorityList.filter(locale => locale !== userLocale)]
    }
    return priorityList
  }, [priorityList, userLocale])

  //translates a TranslatedString based on the instance priority list and the user locale
  const translate = useCallback(
    (translatedString: TranslatedString): string => {
      const trKey = filteredPriorityList.find(locale => translatedString[locale] !== undefined)
      if (trKey !== undefined) return translatedString[trKey] ?? ''

      const availableKeys = Object.keys(translatedString) as Locale[]
      if (availableKeys.length > 0) return translatedString[availableKeys[0]] ?? ''

      return ''
    },
    [filteredPriorityList]
  )

  const flatten = (translatedString: TranslatedString): string[] => {
    return filteredPriorityList.map(locale => translatedString[locale] ?? '').filter(str => str !== '')
  }

  const translatedStringUtils: TranslatedStringUtils = {
    translate,
    hasTranslation,
    isMultilingual,
    priorityList: filteredPriorityList,
    flatten,
  }

  return translatedStringUtils
}
