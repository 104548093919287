/* eslint-disable you-dont-need-momentjs/no-moment-constructor */
/* eslint-disable you-dont-need-momentjs/no-import-moment */
import { parseDateTime } from 'chronos/utils/parseDateTime'
import { format } from 'date-fns'
import moment from 'moment'
import 'moment/min/locales'

/**
 * @deprecated use js-joda instead
 */
export const getMonthsNames = () => moment.months()

/**
 * @deprecated use js-joda instead
 */
export const setMomentLocale = (locale: any) => moment.locale(locale)

/**
 * @deprecated use js-joda instead
 */
export const getLocale = () => moment.locale()

/**
 * @deprecated use js-joda instead
 */
export const getYears = (start: Date, end: Date) => {
  const startYear = parseInt(format(start, 'yyyy'))
  const endYear = parseInt(format(end, 'yyyy'))

  return [...Array(endYear - startYear + 1).keys()].map(i => i + startYear)
}

// https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
/**
 * @deprecated use js-joda instead
 */
export const translateDateFnsFormatToMoment = (dateFnsFormat: string) =>
  dateFnsFormat.replace(/d/g, 'D').replace(/y/g, 'Y')

/**
 * @deprecated use js-joda instead
 */
export const formatYMD = (date: Date) => format(date, 'yyyy-MM-dd')

/**
 * @deprecated use js-joda instead
 */
export const formatCH = (date: Date) => format(date, 'dd.MM.yyyy')

/**
 * @deprecated use js-joda instead
 */
export const getLocaleDate = (date: Date) => {
  const weekday = date.toLocaleDateString(getLocale(), { weekday: 'long' }).toLocaleLowerCase()
  const dateCH = formatCH(date)
  return { weekday, date: dateCH, longDate: `${weekday} ${dateCH}` }
}

/**
 * @deprecated use js-joda instead
 */
export const todayYMD = () => formatYMD(parseDateTime())

export const formatHoursMinutes = (hoursDecimal: number) => {
  const hours = Math.floor(hoursDecimal)
  const minutes = Math.round((hoursDecimal - hours) * 60)
  return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`
}
