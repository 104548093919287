import { TwoFactorAdapter } from 'authentication/adapter/TwoFactorAdapter'
import { AclRecursionType, PreferencesRequestType } from 'authentication/state/types'
import { ActiveUserType, PreferencesType } from 'authentication/types'
import { brain, publicApi } from 'core/services/api'
import { SnowflakeType } from 'core/types'
import lodash from 'lodash'

type PreferencesQueueType = {
  preference: string
  value: string
  deferred: any
}

type DeferredType = {
  promise?: Promise<void>
  resolve?: () => void
}

const preferencesQueue: PreferencesQueueType[] = []
let preferencesPending = false

const savePreferencesLoop = async (allSettled?: () => void) => {
  preferencesPending = true
  try {
    while (preferencesQueue.length) {
      const { preference, value, deferred } = preferencesQueue.shift() as PreferencesQueueType
      deferred.resolve(brain.put('/preferences/' + preference, value))
      await deferred.promise
    }
    preferencesPending = false
    if (allSettled) allSettled()
  } catch (e: any) {
    return e
  }
}

export const authenticationService = {
  getActiveUser: async (): Promise<ActiveUserType> => {
    const user = await brain.get<ActiveUserType>('/users/me')
    return user.data
  },
  getAuthMatrix: async (): Promise<AclRecursionType> => {
    const acls = await brain.get<AclRecursionType>('/users/me/auth-matrix')
    return acls.data
  },
  getPreferences: async (): Promise<PreferencesType> => {
    const preferences = await brain.get<PreferencesRequestType>('/preferences')
    return preferences.data.properties.preferences
  },
  savePreference: async (preference: string, value: string, allSettled?: () => void) => {
    const deferred: DeferredType = {}
    deferred.promise = new Promise<void>(resolve => (deferred.resolve = resolve))
    preferencesQueue.push({ preference, value, deferred })
    if (!preferencesPending) savePreferencesLoop(allSettled)
    return deferred.promise
  },
  fetchTwoFactorMethod: async (userId: SnowflakeType) => {
    try {
      const twoFactor = await publicApi.get('/security/resource-2fa.list', {
        params: {
          resources: userId,
        },
      })
      const orderedMethods = lodash.orderBy(
        twoFactor.data,
        [
          item => {
            return item.available
          },
          item => {
            return item.since
          },
        ],
        ['desc', 'desc']
      )
      return orderedMethods.map(TwoFactorAdapter.fromApi)
    } catch (e) {
      throw e
    }
  },
  removeTwoFactorMethod: async (methodId: SnowflakeType) => {
    try {
      await publicApi.post('/security/resource-2fa.delete', {
        id: methodId,
      })
    } catch (e: any) {
      throw e
    }
  },
}
