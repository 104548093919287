import { TranslatedString } from 'api'
import { SnowflakeType } from 'core/types'
import { SectorType } from 'directory/types'

export enum AssociatedModules {
  Expenses = 'expenses',
  Activity = 'activity',
  Schedule = 'schedule',
}

export type TagOptionType = {
  value: string | undefined
  label: string | undefined
  snowflake: SnowflakeType
  isLoading?: boolean
}

export type TagShallow = {
  id: number
  snowflake: SnowflakeType
  name: string
  translatedNames?: TranslatedString
}

export type TagType = TagShallow & {
  enabled: boolean
  externalId: string
  sectors: SectorType[]
}

export type TagReferenceType = {
  id: number
  snowflake: SnowflakeType
}

export type TagFormType = {
  name: string | TranslatedString
  sectors: {
    value: number
    name?: string
    head?: boolean
  }[]
  translatedNames: TranslatedString
}

export type AddTagFormType = Omit<TagFormType, 'sectors'> & {
  sectors: {
    id: number
  }[]
}

export type TagViewType = {
  id: SnowflakeType
  name: string
  enabled: true
}

export enum RequestTagFilter {
  ByResource = 'byResource',
  BySector = 'bySector',
}
