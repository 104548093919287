import { TranslateElement } from 'core/components/i18n'
import { TranslationKey } from 'i18nKeys'
import { toast } from 'react-toastify'
import { css, StyleSheet } from 'theme'

type PushToastType = 'success' | 'warning' | 'error'

export const pushToast = (text: TranslationKey | Array<TranslationKey>, type: PushToastType) =>
  // dynamic call to toast.success, toast.warning or toast.error
  toast[type](
    () =>
      typeof text === 'string' ? (
        <TranslateElement id={text} className={css(styles.text)} />
      ) : (
        text.map((message, i) => (
          <TranslateElement key={(i + message).toString()} id={message} component="p" className={css(styles.text)} />
        ))
      ),
    {
      className: css(styles.container),
      progressClassName: css(styles.progress),
    }
  )

const styles = StyleSheet.create({
  text: {
    margin: '0px',
  },
  container: {
    padding: '10px 20px',
  },
  message: {
    color: 'rgba(0, 0, 0, 0.55)',
  },
  progress: {
    background: 'rgba(255, 255, 255, 0.7)',
  },
})
