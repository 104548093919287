import { IconName } from '@fortawesome/pro-light-svg-icons'
import { SnowflakeType } from 'core/types'

export const EXPENSE_REPORT_ACTION_VALIDATE = 'approve'
export const EXPENSE_REPORT_ACTION_PREVALIDATE = 'forward'
export const EXPENSE_REPORT_ACTION_REFUSE = 'refuse'
export type EXPENSE_REPORT_ACTIONS =
  | typeof EXPENSE_REPORT_ACTION_VALIDATE
  | typeof EXPENSE_REPORT_ACTION_PREVALIDATE
  | typeof EXPENSE_REPORT_ACTION_REFUSE

export const EXPENSE_REPORT_STATUS_DRAFT = 'draft'
export const EXPENSE_REPORT_STATUS_READY = 'ready'
export const EXPENSE_REPORT_STATUS_PENDING_APPROVAL = 'pending-approval'
export const EXPENSE_REPORT_STATUS_VALIDATED = 'approved'
export const EXPENSE_REPORT_STATUS_REFUSED = 'refused'
export const EXPENSE_REPORT_STATUS_ARCHIVED = 'archived'

export const EXPENSE_REPORT_TYPE_MINE = 'mine'
export const EXPENSE_REPORT_TYPE_TO_VALIDATE = 'to-validate'
export type EXPENSE_REPORT_TYPES = typeof EXPENSE_REPORT_TYPE_MINE | typeof EXPENSE_REPORT_TYPE_TO_VALIDATE

export const EXPENSE_REPORT_GROUP_CURRENT = 'current'
export const EXPENSE_REPORT_GROUP_ARCHIVED = 'archived'
export type EXPENSE_REPORT_GROUPS = typeof EXPENSE_REPORT_GROUP_CURRENT | typeof EXPENSE_REPORT_GROUP_ARCHIVED

export const ExpenseReportStatusTypes = [
  EXPENSE_REPORT_STATUS_DRAFT,
  EXPENSE_REPORT_STATUS_READY,
  EXPENSE_REPORT_STATUS_PENDING_APPROVAL,
  EXPENSE_REPORT_STATUS_VALIDATED,
  EXPENSE_REPORT_STATUS_REFUSED,
  EXPENSE_REPORT_STATUS_ARCHIVED,
] as const

export const EXPENSE_REPORT_TRANSITION_TYPE_SEND = 'Send'
export const EXPENSE_REPORT_TRANSITION_TYPE_APPROVE = 'Approve'
export const EXPENSE_REPORT_TRANSITION_TYPE_FORWARD = 'Forward'
export const EXPENSE_REPORT_TRANSITION_TYPE_REFUSE = 'Refuse'

export const ExpenseReportTransitionTypeTypes = [
  EXPENSE_REPORT_TRANSITION_TYPE_SEND,
  EXPENSE_REPORT_TRANSITION_TYPE_APPROVE,
  EXPENSE_REPORT_TRANSITION_TYPE_FORWARD,
  EXPENSE_REPORT_TRANSITION_TYPE_REFUSE,
] as const

export type ExpenseReportStatusType = {
  current: (typeof ExpenseReportStatusTypes)[number]
  since: Date
}

export type ExpenseType = {
  id: SnowflakeType
  expense_report_id: SnowflakeType
  summary: string
  date: Date
  attachments: AttachmentType[]
  amount?: number
  value: number | null
  type_id: SnowflakeType | null
  tag_ids: SnowflakeType[]
  deletable: boolean
  updatable: boolean
}

export type AttachmentType = {
  id: SnowflakeType
  url: string
  thumbnail_url: string
  filename: string
  mime_type: string
}

export type ExpenseTypeType = {
  id: SnowflakeType
  label: string
  icon: IconName
  unit: string
  rate: number
  pinned?: boolean
  enabled: boolean
}

export type ExpenseTypeOption = Omit<ExpenseTypeType, 'id' | 'pinned'> & {
  value: SnowflakeType
}

export type ExpenseFormType = {
  summary: string
  date: Date
  amount: string | null
  type: ExpenseTypeOption | null
  expenseReport: ExpenseReportOptionType
}

export type ExpenseReportType = {
  id: SnowflakeType
  deletable: boolean
  updatable: boolean
  person_id: SnowflakeType
  label: string
  expense_ids: SnowflakeType[]
  total: number
  status: ExpenseReportStatusType
  transitions: ExpenseReportTransitionType[]
  current_transition: ExpenseReportTransitionType | null
}

export type ExpenseReportTransitionType = {
  id: SnowflakeType
  remark: string | null
  type: (typeof ExpenseReportTransitionTypeTypes)[number]
  validator_id: SnowflakeType
  person_id: SnowflakeType
  date: Date
}

export type ExpenseReportOptionType = {
  value: SnowflakeType
  label: string
  type?: EXPENSE_REPORT_TYPES
}
