/**
 * @deprecated only import these variables directly inside the design folder, or if you are working on a legacy page.
 * By default, use the design tokens from the `design` module (fg, bg, pk, ly.. etc).
 */
export const pal = {
  white: '#FFFFFF', // rgba(255, 255, 255, 1)
  black: '#000000', // rgba(0, 0, 0, 1)

  grey50: '#F8FAFB', // rgba(248, 250, 251, 1)
  grey100: '#F0F3F8', // rgba(240, 243, 248, 1)
  grey200: '#E0E5F2', // rgba(224, 229, 242, 1)
  grey300: '#D0D6ED', // rgba(208, 214, 237, 1)
  grey400: '#A4AEC9', // rgba(164, 174, 201, 1)
  grey500: '#676D84', // rgba(103, 109, 132, 1)
  grey600: '#383A49', // rgba(56, 58, 73, 1)

  blue50: '#F1F7FE', // rgba(241, 247, 254, 1)
  blue100: '#E1EEFD', // rgba(225, 238, 253, 1)
  blue200: '#BDDCFA', // rgba(189, 220, 250, 1)
  blue300: '#83C0F6', // rgba(131, 192, 246, 1)
  blue400: '#41A0EF', // rgba(65, 160, 239, 1)
  blue500: '#1884DF', // rgba(24, 132, 223, 1)
  blue600: '#0C6ECB', // rgba(12, 110, 203, 1)
  blue700: '#0A529A', // rgba(10, 82, 154, 1)
  blue800: '#0D467F', // rgba(13, 70, 127, 1)
  blue900: '#113C69', // rgba(17, 60, 105, 1)
  blue950: '#0B2546', // rgba(11, 37, 70, 1)

  pink50: '#FEF1FB', // rgba(254, 241, 251, 1)
  pink100: '#FEE5F9', // rgba(254, 229, 249, 1)
  pink200: '#FFCBF5', // rgba(255, 203, 245, 1)
  pink300: '#FFA0EB', // rgba(255, 160, 235, 1)
  pink400: '#FF65DB', // rgba(255, 101, 219, 1)
  pink500: '#FC38C7', // rgba(252, 56, 199, 1)
  pink600: '#ED15A8', // rgba(237, 21, 168, 1)
  pink700: '#BF077F', // rgba(191, 7, 127, 1)
  pink800: '#AA0A70', // rgba(170, 10, 112, 1)
  pink900: '#8E0D60', // rgba(142, 13, 96, 1)
  pink950: '#570038', // rgba(87, 0, 56, 1)

  green50: '#EEFBF4', // rgba(238, 251, 244, 1)
  green100: '#D6F5E3', // rgba(214, 245, 227, 1)
  green200: '#B1E9CB', // rgba(177, 233, 203, 1)
  green300: '#7DD8AE', // rgba(125, 216, 174, 1)
  green400: '#46BE8A', // rgba(70, 190, 138, 1)
  green500: '#25A471', // rgba(37, 164, 113, 1)
  green600: '#17845B', // rgba(23, 132, 91, 1)
  green700: '#13694B', // rgba(19, 105, 75, 1)
  green800: '#11543C', // rgba(17, 84, 60, 1)
  green900: '#0F4533', // rgba(15, 69, 51, 1)
  green950: '#07271D', // rgba(7, 39, 29, 1)

  orange50: '#FEF8EE', // rgba(254, 248, 238, 1)
  orange100: '#FDF0D7', // rgba(253, 240, 215, 1)
  orange200: '#FADCAE', // rgba(250, 220, 174, 1)
  orange300: '#F6C27B', // rgba(246, 194, 123, 1)
  orange400: '#F2A654', // rgba(242, 166, 84, 1)
  orange500: '#ED8322', // rgba(237, 131, 34, 1)
  orange600: '#DE6A18', // rgba(222, 106, 24, 1)
  orange700: '#B85016', // rgba(184, 80, 22, 1)
  orange800: '#934019', // rgba(147, 64, 25, 1)
  orange900: '#763618', // rgba(118, 54, 24, 1)
  orange950: '#401A0A', // rgba(64, 26, 10, 1)

  red50: '#FEF2F3', // rgba(254, 242, 243, 1)
  red100: '#FFE1E2', // rgba(255, 225, 226, 1)
  red200: '#FFB7BA', // rgba(255, 183, 186, 1)
  red300: '#FEA3A7', // rgba(254, 163, 167, 1)
  red400: '#FB6E74', // rgba(251, 110, 116, 1)
  red500: '#F24148', // rgba(242, 65, 72, 1)
  red600: '#E0222A', // rgba(224, 34, 42, 1)
  red700: '#BC1920', // rgba(188, 25, 32, 1)
  red800: '#9B191E', // rgba(155, 25, 30, 1)
  red900: '#811B1F', // rgba(129, 27, 31, 1)
  red950: '#46090C', // rgba(70, 9, 12, 1)
}
