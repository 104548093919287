import { TwoFactorType } from 'authentication/types'
import { parseDateTime } from 'chronos'

export class TwoFactorAdapter {
  static fromApi(method: any) {
    const data = method.type === 'totp' ? null : { phoneNumber: method.data.phone_number }
    return {
      active: method.available,
      canDelete: method.can_delete,
      snowflake: method.id,
      data,
      type: method.type,
      since: parseDateTime(method.since),
    } as TwoFactorType
  }
}
