export const pk = {
  blue: {
    light: '#e5f1fb',
    strong1: '#4683b8',
    strong2: '#386994',
    strong3: '#2a4f6f',
    strong4: '#1c354a',
  },
  teal: {
    light: '#d1f1e9',
    strong1: '#4eb197',
    strong2: '#3f8e79',
    strong3: '#306a5a',
    strong4: '#1f473d',
  },
  green: {
    light: '#dff2d1',
    strong1: '#75b44b',
    strong2: '#5e903c',
    strong3: '#456c2d',
    strong4: '#2f481e',
  },
  amber: {
    light: '#fbe3b4',
    strong1: '#d99c27',
    strong2: '#ad7e1e',
    strong3: '#835d16',
    strong4: '#573f0f',
  },
  orange: {
    light: '#f5d9cb',
    strong1: '#d96226',
    strong2: '#ad4d1f',
    strong3: '#823b16',
    strong4: '#57270f',
  },
  red: {
    light: '#f4d6d6',
    strong1: '#cc3233',
    strong2: '#a32829',
    strong3: '#7a1f1f',
    strong4: '#521415',
  },
  purple: {
    light: '#f2e4f9',
    strong1: '#8a609f',
    strong2: '#6f4d80',
    strong3: '#533960',
    strong4: '#362640',
  },
  pink: {
    light: '#fce3f0',
    strong1: '#f765b1',
    strong2: '#ae749f',
    strong3: '#914c76',
    strong4: '#6e2c5a',
  },
} as const
