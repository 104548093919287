import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

export type ValidationError = {
  field: string
  message: string
}

const setupRetry = (axiosInstance: AxiosInstance) => {
  axiosRetry(axiosInstance, {
    retries: 3,
    retryCondition: error => {
      if (error.response?.headers && error.response.headers['retry-after']) {
        return true
      }
      return false
    },
    retryDelay: (count, error) => {
      if (error.response?.headers && error.response.headers['retry-after']) {
        return parseInt(error.response?.headers['retry-after']) * 1000
      }
      return 1000
    },
  })
}

const brain = axios.create({
  baseURL: '/brain/',
  withCredentials: true,
})
setupRetry(brain)

const publicApi = axios.create({
  baseURL: '/api/',
  withCredentials: true,
})
setupRetry(publicApi)

const scripts = axios.create({
  baseURL: '/scripts/',
  withCredentials: true,
})
setupRetry(scripts)

const admin = axios.create({
  baseURL: '/admin',
  withCredentials: true,
})
setupRetry(admin)

const pluginsForumComponent = axios.create({
  baseURL: '/plugins/Forum/Component/',
  withCredentials: true,
})
setupRetry(pluginsForumComponent)

export { admin, brain, publicApi, scripts, pluginsForumComponent }
