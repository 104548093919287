import { getActiveUser } from 'authentication/state/selectors'
import { taskService } from 'communication/task/services/task'
import {
  addTaskSuccess,
  ADD_TASK,
  markTaskCompleted,
  receiveTasks,
  REQUEST_TASKS,
  REQUEST_TASK_COMPLETED,
  taskCompleted,
} from 'communication/task/state/actions'
import { getTask, isPendingCompletion } from 'communication/task/state/selectors'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import lodash from 'lodash'
import { put, select, takeEvery, takeLatest } from 'typed-redux-saga'
import { pushToast } from 'utils/pushToast'

function* getTasks() {
  const { id } = yield select(getActiveUser)

  try {
    const tasks = yield* callApi(taskService.getTasks, id)
    yield put(receiveTasks(tasks))
  } catch (e) {
    yield put(throwError(e))
  }
}

function* closeTask({ payload: { taskId } }: any) {
  const { id } = yield select(getActiveUser)
  const { assignees } = yield select(getTask, taskId)
  const pendingCompletion = yield* select(isPendingCompletion, taskId)

  if (pendingCompletion) return
  const taskAssigneeId = lodash.find(assignees, { person_id: id }).id
  yield put(markTaskCompleted(id, taskAssigneeId))

  try {
    const { date_handled } = yield callApi(taskService.close, taskAssigneeId)
    yield put(taskCompleted(taskId, taskAssigneeId, date_handled))
  } catch (e) {
    yield put(throwError(e))
  }
}

function* saveTask({ payload: { task } }: any) {
  try {
    const response = yield* callApi(taskService.save, task)
    yield put(addTaskSuccess(response))
    pushToast('tipee.save_success', 'success')
  } catch (e) {
    yield put(throwError(e))
  }
}

export const taskSaga = function* taskSaga() {
  yield* takeLatest(REQUEST_TASKS, getTasks)
  yield* takeEvery(REQUEST_TASK_COMPLETED, closeTask)
  yield* takeEvery(ADD_TASK, saveTask)
}
