import { withCrudReducer } from 'crud/state/withCrudReducer'
import produce from 'immer'
import lodash from 'lodash'
import { compose } from 'redux'
import * as scheduleActions from 'schedule/state/actions'

export const initialState = {
  scheduleTemplateTypes: {},
  schedules: {},
  schedulesBySnowflake: {},
  scheduleTemplates: {},
  workRegimes: {},
  absenceRequest: {
    count: 0,
  },
  absences: {},
  partialAbsences: {},
  alldayAbsences: {},
  absenceTypes: {},
  balanceCompensations: {},
  weeklyHours: {},
  proposalMatchSchedule: {},
  extraHours: {
    error: {
      hasError: false,
      message: '',
    },
    tableData: {
      columns: [],
      rows: [],
    },
  },
  ui: {
    balances: {
      isFetching: false,
      holidays: {
        total: 0.0,
        planned: 0.0,
        remaining: 0.0,
      },
      work: {
        total: 0,
        done: 0,
        remaining: 0,
      },
    },
    personsAdjustmentBalances: {
      success: false,
    },
    scheduleTemplateTypes: {
      isFetching: false,
    },
    workRegimes: {
      isFetching: false,
    },
    schedules: {
      isFetching: false,
    },
    weeklyHours: {
      isFetching: false,
    },
    workdayBalances: {
      isFetching: false,
    },
    extraHours: {
      isFetching: false,
    },
  },
}

export const scheduleReducer = compose(
  withCrudReducer('scheduleTemplateTypes'),
  withCrudReducer('workRegimes'),
  withCrudReducer('absenceTypes'),
  withCrudReducer('balanceCompensations')
)((currentState = initialState, action: any) =>
  produce(currentState, (draft: any) => {
    switch (action.type) {
      case scheduleActions.REQUEST_BALANCES:
        draft.ui.balances.isFetching = true
        break
      case scheduleActions.RECEIVE_BALANCES:
        draft.ui.balances.isFetching = false
        draft.ui.balances.holidays = action.payload.holidays
        draft.ui.balances.work = {
          ...draft.ui.balances.work,
          ...action.payload.work,
        }
        break
      case scheduleActions.RECEIVE_SCHEDULE_TEMPLATE:
        draft.scheduleTemplates = action.payload.scheduleTemplate = {
          ...draft.scheduleTemplates,
          [action.payload.scheduleTemplate.id]: action.payload.scheduleTemplate,
        }
        break
      case scheduleActions.REQUEST_EMPLOYEE_BALANCES:
        draft.ui.workdayBalances.isFetching = true
        break
      case scheduleActions.RECEIVE_PERIODS: {
        const { schedules, absences, partialAbsences, alldayAbsence } = action.payload
        /**
         * We intentionnaly overwrite the store on every fetch action,
         * to ensure that we have the latest data from the server.
         * This avoids a deleted schedule in legacy to still be present in the store.
         */
        draft.schedules = schedules ? lodash.keyBy(schedules, 'planif_id') : {}
        draft.absences = absences ? lodash.keyBy(absences, 'absence_id') : {}
        draft.partialAbsences = partialAbsences ? lodash.keyBy(partialAbsences, 'id') : {}
        if (alldayAbsence.id) {
          draft.alldayAbsences[alldayAbsence.id] = alldayAbsence
        }
        break
      }
      case scheduleActions.RECEIVE_SCHEDULE: {
        const { schedule } = action.payload
        draft.ui.schedules.isFetching = false
        draft.schedulesBySnowflake[schedule.snowflake] = schedule
        break
      }
      case scheduleActions.FETCHING_SCHEDULE: {
        draft.ui.schedules.isFetching = true
        break
      }
      case scheduleActions.FETCHING_SCHEDULE_DONE: {
        draft.ui.schedules.isFetching = false
        break
      }
      case scheduleActions.REQUEST_SCHEDULE_TEMPLATE_TYPES: {
        draft.ui.scheduleTemplateTypes.isFetching = true
        break
      }
      case scheduleActions.RECEIVE_SCHEDULE_TEMPLATE_TYPES: {
        draft.ui.scheduleTemplateTypes.isFetching = false
        draft.scheduleTemplateTypes = lodash.reduce(
          action.payload.scheduleTemplateTypes,
          (previous, current) => ({ ...previous, [current.id]: current }),
          {}
        )
        break
      }
      case scheduleActions.RECEIVE_ABSENCE_REQUEST_TO_VALIDATE_COUNT: {
        draft.absenceRequest.count = action.payload.count
        break
      }
      case scheduleActions.REQUEST_ADJUSTMENT_BALANCES: {
        delete draft.ui.personsAdjustmentBalances[action.payload.personId]
        break
      }
      case scheduleActions.RECEIVE_ADJUSTMENT_BALANCES: {
        draft.ui.personsAdjustmentBalances[action.payload.personId] = {
          balances: action.payload.adjustmentBalances,
        }
        break
      }
      case scheduleActions.SAVE_ADJUSTMENT_BALANCES: {
        draft.ui.personsAdjustmentBalances.success = false
        break
      }
      case scheduleActions.ADJUSTMENT_BALANCES_SAVED: {
        draft.ui.personsAdjustmentBalances.success = true
        delete draft.ui.personsAdjustmentBalances[action.payload.personId]
        break
      }
      case scheduleActions.REQUEST_WEEKLY_HOURS:
        draft.ui.weeklyHours.isFetching = true
        break
      case scheduleActions.REQUEST_EXTRA_HOURS:
        draft.ui.extraHours.isFetching = true
        draft.extraHours = {
          error: { hasError: false, message: '' },
          tableData: draft.extraHours.tableData,
        }
        break
      case scheduleActions.RECEIVE_EXTRA_HOURS:
        const { error, extraHours } = action.payload
        const tableData = extraHours.rows?.length > 0 ? extraHours : initialState.extraHours.tableData

        draft.ui.extraHours.isFetching = false
        draft.extraHours.tableData = tableData
        draft.extraHours.error = error ? { hasError: true, message: error } : initialState.extraHours.error

        break
      case scheduleActions.RECEIVE_WEEKLY_HOURS: {
        const {
          weeklyHours: { weekly_hours, limit },
          personId,
        } = action.payload
        const { weeklyHours: actualWeeklyHours } = draft
        draft.ui.weeklyHours.isFetching = false
        draft.weeklyHours = {
          ...actualWeeklyHours,
          limit,
          [personId]: {
            ...actualWeeklyHours[personId],
            ...weekly_hours,
          },
        }
        break
      }
      default:
        break
    }
  })
)
