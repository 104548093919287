import { faFile } from '@fortawesome/pro-light-svg-icons/faFile'
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv'
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel'
import { faFileImage } from '@fortawesome/pro-light-svg-icons/faFileImage'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf'
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint'
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'

export const DOCUMENT_TYPE_FOLDER = 'folder'
export const DOCUMENT_TYPE_FILE = 'file'

export type DOCUMENT_TYPES = typeof DOCUMENT_TYPE_FOLDER | typeof DOCUMENT_TYPE_FILE

export type Document = {
  blobURI: string | null
  createdAt: Date
  createdBy: number
  depth: number
  hasChildrenFolder: boolean
  fullPath: string
  hash: string | null
  id: number
  lft: number
  name: string
  parentFolderId: number | null
  personId: number | null
  rgt: number
  scopeId: number
  type: DOCUMENT_TYPES
  updatedAt: Date | null
  updatedBy: number | null
  visible: boolean
}

export type FileExtensions =
  | 'pdf'
  | 'doc'
  | 'docx'
  | 'pptx'
  | 'ppt'
  | 'jpg'
  | 'jpeg'
  | 'png'
  | 'svg'
  | 'gif'
  | 'giff'
  | 'tiff'
  | 'xls'
  | 'xlsx'
  | 'csv'

type FilesTypesRef = {
  [x: string]: {
    extensions: FileExtensions[]
    icon: IconDefinition
  }
}

export const FILE_TYPES: FilesTypesRef = {
  image: {
    extensions: ['jpg', 'jpeg', 'png', 'gif', 'giff', 'tiff', 'svg'],
    icon: faFileImage,
  },
  pdf: {
    extensions: ['pdf'],
    icon: faFilePdf,
  },
  word: {
    extensions: ['doc', 'docx'],
    icon: faFileWord,
  },
  excel: {
    extensions: ['xls', 'xlsx'],
    icon: faFileExcel,
  },
  powerpoint: {
    extensions: ['ppt', 'pptx'],
    icon: faFilePowerpoint,
  },
  csv: {
    extensions: ['csv'],
    icon: faFileCsv,
  },
  default: {
    extensions: [],
    icon: faFile,
  },
}

export type ImportFilesStates = 'initial' | 'matching' | 'matched' | 'uploading' | 'error' | 'success'
type FileStatesRef = {
  [K in ImportFilesStates]: ImportFilesStates
}

export const FILE_STATES: FileStatesRef = {
  initial: 'initial',
  matching: 'matching',
  matched: 'matched',
  uploading: 'uploading',
  error: 'error',
  success: 'success',
}

export type Error = {
  title?: string
  description?: string
  tooltip?: string
}

export type ImportFile = {
  id: string
  extension: string
  blobUrl: string
  size: number
  name: string
  type: string
  state: ImportFilesStates
  personSnowflake?: string
  error?: Error
}

export type ImportFileWithError = ImportFile & { error: Required<ImportFile>['error'] }
export type ImportFileWithMatch = ImportFile & { personSnowflake: Required<ImportFile>['personSnowflake'] }
