import { brain, publicApi } from 'core/services/api'
import lodash from 'lodash'
import qs from 'qs'
import Route from 'route-parser'

type Endpoint = {
  method: 'get' | 'post' | 'put' | 'delete'
  route?: string | Route
  data?: (...args: any) => object | null
  config?: (...args: any) => object | null
  placeHolders?: (...args: any) => object
  mapResult?: (result: any, ...args: any) => any
}

export type Endpoints = { [key: string]: Endpoint }

interface CrudServiceInterface {
  provider: typeof brain | typeof publicApi
  endpoints: Endpoints
}

type EndpointsDefinition = string | Endpoints

type Operation = (...args: any) => Promise<any>

export type OperationsDefinition = {
  [key: string]: Operation
}

const buildEndpointMethod = (endpoint: Endpoint, service: CrudService, errorHandler: any): Operation => {
  const dataMapper = endpoint.data || ((d?: any) => d)
  const configMapper = endpoint.config || (() => ({}))
  const placeholdersMapper = endpoint.placeHolders || (o => ({ id: (o && o.id) || null }))
  const resultMapper = endpoint.mapResult || (r => r)

  return async (...args: any) => {
    const { method, route } = endpoint
    const providerArgs = {
      method,
      url: (route! as Route).reverse(placeholdersMapper(...args)) as string,
      data: dataMapper(...args),
      paramsSerializer: qs.stringify,
      ...configMapper(...args),
    }
    try {
      const apiResult = await service.provider(providerArgs)
      return resultMapper(apiResult, ...args)
    } catch (e) {
      errorHandler(e)
    }
  }
}

/**
 *
 * Crappy |--------------------------| Clever
 *                                ^
 */
export class CrudService implements CrudServiceInterface {
  /**
   * Endpoint definition:
   *  - method
   *  - route
   *  - data         : (...args) => data
   *  - config       : (...args) => config
   *  - placeholders : (...args) => placeholders
   *  - mapResult    : (data, ...args) => result
   */
  static DEFAULT_ENDPOINTS: EndpointsDefinition = {
    list: {
      method: 'get',
      data: () => null,
      config: (page: number, perPage: number, sort: { [key: string]: 'asc' | 'desc' }) => ({
        params: { page, limit: perPage, orderBy: sort },
      }),
    },
    filter: { method: 'get' },
    create: { method: 'post' },
    update: { method: 'put' },
    delete: { method: 'delete' },
    view: { method: 'get' },
  }

  provider: typeof brain | typeof publicApi
  endpoints: any
  operations: OperationsDefinition = {}

  constructor(endpointsDefinition: EndpointsDefinition, isPublic = false, throwOnError = true) {
    this.provider = isPublic ? publicApi : brain

    this.endpoints = CrudService.createEndpointsFromDefinitions(
      typeof endpointsDefinition === 'string'
        ? CrudService.createEndpointsDefinitionFromString(endpointsDefinition)
        : endpointsDefinition
    )

    lodash.forOwn(lodash.pickBy(this.endpoints), (endpoint, op) => {
      // The operation handler might already be defined by a subclass...
      // Handlers are already heavily configurable, consider using available definition options before overloading!
      this.operations[op] =
        this.operations[op] ||
        buildEndpointMethod(endpoint, this, (e: any) => {
          if (throwOnError) throw e
        })
    })
  }

  static createEndpointsFromDefinitions = (endpoints: EndpointsDefinition) => {
    return lodash.mapValues(
      // Using pickBy to allow definition to remove one of the default endpoint
      // @ts-expect-error Wrong type string | EndpointsDefinition ??
      lodash.pickBy(lodash.merge({}, CrudService.DEFAULT_ENDPOINTS, endpoints)),
      endpoint => ({ ...(endpoint as object), route: new Route((endpoint as Endpoint).route as string) })
    )
  }

  static createEndpointsDefinitionFromString = (endpoint: EndpointsDefinition): EndpointsDefinition => {
    return lodash.merge({}, CrudService.DEFAULT_ENDPOINTS, {
      list: { route: endpoint },
      filter: { route: endpoint },
      create: { route: endpoint },
      update: { route: endpoint + '/:id' },
      delete: { route: endpoint + '/:id' },
      view: { route: endpoint + '/:id' },
    })
  }
}
