import { DIRECTORY_ROLES_WITH_DOCUMENTS } from 'directory/types'
import { Pattern } from 'document/components/fileNamePattern/types'
import { StepKey } from 'document/pages/documentImportPage/steps/types'
import {
  ADD_FILES_TO_IMPORT,
  DocumentActions,
  IMPORT_FILE,
  MatchFilesResponseData,
  PartialImportPageSteps,
  RECEIVE_CHILDREN_DOCUMENTS,
  RECEIVE_FILES_MATCH,
  RECEIVE_IMPORT_FILE,
  RECEIVE_PARENTS_DOCUMENTS,
  RECEIVE_ROOT_FOLDER,
  REMOVE_FILE,
  REMOVE_IMPORT_FILES_WITH_ERROR,
  REQUEST_CHILDREN_DOCUMENTS,
  REQUEST_FILES_MATCH,
  REQUEST_PARENTS_DOCUMENTS,
  REQUEST_ROOT_FOLDER,
  RESET_ALL_FILES,
  RESET_ERRORED_FILES_TO_MATCHED,
  RETRY_FAILED_IMPORTS,
  SET_ACTIVE_STEP,
  SET_FILES_ARE_UPLOADING,
  SET_FILES_STATE_TO_INITIAL,
  SET_FILE_IS_DUPLICATE,
  SET_IMPORT_CONFIG,
  SET_STEPS,
  START_FILES_MATCH,
  START_IMPORT,
} from 'document/state/types'
import { Document, ImportFile, ImportFilesStates } from 'document/types'

export function addFilesToImport(role: DIRECTORY_ROLES_WITH_DOCUMENTS, files: File[]): DocumentActions {
  return {
    type: ADD_FILES_TO_IMPORT,
    payload: {
      role,
      files,
    },
  }
}

export function receiveFilesMatch(
  role: DIRECTORY_ROLES_WITH_DOCUMENTS,
  response: MatchFilesResponseData
): DocumentActions {
  return {
    type: RECEIVE_FILES_MATCH,
    payload: {
      role,
      response,
    },
  }
}

export function removeImportFilesWithError(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: REMOVE_IMPORT_FILES_WITH_ERROR,
    payload: {
      role,
    },
  }
}

export function removeImportFile(role: DIRECTORY_ROLES_WITH_DOCUMENTS, fileId: string): DocumentActions {
  return {
    type: REMOVE_FILE,
    payload: {
      role,
      fileId,
    },
  }
}

export function startFilesMatch(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: START_FILES_MATCH,
    payload: {
      role,
    },
  }
}

export function requestFilesMatch(role: DIRECTORY_ROLES_WITH_DOCUMENTS, files: File[]): DocumentActions {
  return {
    type: REQUEST_FILES_MATCH,
    payload: {
      role,
      files,
    },
  }
}

export function requestChildrenDocuments(role: DIRECTORY_ROLES_WITH_DOCUMENTS, id: number): DocumentActions {
  return {
    type: REQUEST_CHILDREN_DOCUMENTS,
    payload: {
      role,
      id,
    },
  }
}

export function receiveChildrenDocuments(
  role: DIRECTORY_ROLES_WITH_DOCUMENTS,
  id: number,
  documents: Document[]
): DocumentActions {
  return {
    type: RECEIVE_CHILDREN_DOCUMENTS,
    payload: {
      role,
      id,
      documents,
    },
  }
}

export function requestParentsDocuments(role: DIRECTORY_ROLES_WITH_DOCUMENTS, id: number): DocumentActions {
  return {
    type: REQUEST_PARENTS_DOCUMENTS,
    payload: {
      role,
      id,
    },
  }
}

export function receiveParentsDocuments(role: DIRECTORY_ROLES_WITH_DOCUMENTS, documents: Document[]): DocumentActions {
  return {
    type: RECEIVE_PARENTS_DOCUMENTS,
    payload: {
      role,
      documents,
    },
  }
}

export function requestRootFolder(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: REQUEST_ROOT_FOLDER,
    payload: {
      role,
    },
  }
}

export function receiveRootFolder(role: DIRECTORY_ROLES_WITH_DOCUMENTS, document: Document): DocumentActions {
  return {
    type: RECEIVE_ROOT_FOLDER,
    payload: {
      role,
      document,
    },
  }
}

export function requestPostFile(
  role: DIRECTORY_ROLES_WITH_DOCUMENTS,
  file: ImportFile,
  formData: FormData
): DocumentActions {
  return {
    type: IMPORT_FILE,
    payload: {
      role,
      file,
      formData,
    },
  }
}

export function receivePostFile(
  role: DIRECTORY_ROLES_WITH_DOCUMENTS,
  fileId: string,
  state: ImportFilesStates
): DocumentActions {
  return {
    type: RECEIVE_IMPORT_FILE,
    payload: {
      role,
      fileId,
      state,
    },
  }
}

export function setActiveStep(stepKey: StepKey): DocumentActions {
  return {
    type: SET_ACTIVE_STEP,
    payload: {
      stepKey,
    },
  }
}

export function setSteps(steps: PartialImportPageSteps): DocumentActions {
  return {
    type: SET_STEPS,
    payload: {
      steps,
    },
  }
}

export function startImport(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: START_IMPORT,
    payload: {
      role,
    },
  }
}

export function resetErroredFilesToMatched(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: RESET_ERRORED_FILES_TO_MATCHED,
    payload: {
      role,
    },
  }
}

export function retryFailedImports(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: RETRY_FAILED_IMPORTS,
    payload: {
      role,
    },
  }
}

export function setImportConfig(
  fileNamePattern: Pattern,
  folderId: number,
  isVisible: boolean,
  sendNotification: boolean
): DocumentActions {
  return {
    type: SET_IMPORT_CONFIG,
    payload: {
      fileNamePattern,
      folderId,
      isVisible,
      sendNotification,
    },
  }
}

export function resetAllFiles(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: RESET_ALL_FILES,
    payload: {
      role,
    },
  }
}

export function setFilesStateToInitial(role: DIRECTORY_ROLES_WITH_DOCUMENTS): DocumentActions {
  return {
    type: SET_FILES_STATE_TO_INITIAL,
    payload: {
      role,
    },
  }
}

export function setFileIsDuplicate(role: DIRECTORY_ROLES_WITH_DOCUMENTS, fileId: string): DocumentActions {
  return {
    type: SET_FILE_IS_DUPLICATE,
    payload: {
      role,
      fileId,
    },
  }
}

export function setFilesAreUploading(role: DIRECTORY_ROLES_WITH_DOCUMENTS, files: ImportFile[]): DocumentActions {
  return {
    type: SET_FILES_ARE_UPLOADING,
    payload: {
      role,
      files,
    },
  }
}
