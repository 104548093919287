import { formatYMD } from 'chronos/deprecated/date'
import { parseDateTime } from 'chronos/utils/parseDateTime'
import { DateRange } from 'timeclock/types'

/**
 *  @deprecated - use js-joda based parsers
 */
export const DateRangeToISO = (start: Date, end: Date) => {
  return `${formatYMD(start)}/${formatYMD(end)}`
}

/**
 *  @deprecated - use js-joda based parsers
 */
export const parseISODateRange: (s: string) => DateRange = string => {
  const split = string.split('/')
  return {
    start: parseDateTime(split[0]),
    end: parseDateTime(split[1]),
  }
}

const isoWeekNumberToIntRegex = /^(\d+)-W(\d\d)$/
export const parseISOWeekNumber = (isoWeekNumber: string) => {
  const match = isoWeekNumber.match(isoWeekNumberToIntRegex)
  const weekNumber = parseInt(match ? match[2] : '')

  return weekNumber
}
