import { BootstrapData } from 'api'
import {
  CLOSE_LOADER,
  FLUSH_LOADER,
  NEW_UPDATE_AVAILABLE,
  OPEN_LOADER,
  RECEIVE_BOOTSTRAP,
  SELECT_SIDEBAR_ITEM,
  SELECT_SIDEBAR_SUBSECTION,
  THROW_ERROR,
  TOGGLE_MOBILE_MENU,
  TOGGLE_SEARCHBAR,
  TOGGLE_SIDEBAR,
  TOGGLE_TOPBAR_VISIBILITY,
  TOPBAR_HEIGHT_CHANGED,
} from 'core/state/types'

export function receiveBootstrap(bootstrap: BootstrapData, aclExpiration: Date) {
  return {
    type: RECEIVE_BOOTSTRAP as typeof RECEIVE_BOOTSTRAP,
    payload: {
      boot: { ...bootstrap },
      aclExpiration,
    },
  }
}

export function throwError(e: any) {
  return {
    type: THROW_ERROR,
    payload: {
      error: e,
    },
  }
}

export function toggleTopbarVisibility(visible: boolean) {
  return {
    type: TOGGLE_TOPBAR_VISIBILITY,
    payload: {
      visible,
    },
  }
}

export function topbarHeightChanged(height: number) {
  return {
    type: TOPBAR_HEIGHT_CHANGED,
    payload: {
      height,
    },
  }
}

export function toggleSearchbar() {
  return {
    type: TOGGLE_SEARCHBAR,
  }
}

export function openLoader(message = 'tipee.loader.message', timeout = 10000) {
  return {
    type: OPEN_LOADER,
    payload: {
      message,
      timeout,
    },
  }
}

export function closeLoader() {
  return {
    type: CLOSE_LOADER,
  }
}

export function flushLoader() {
  return {
    type: FLUSH_LOADER,
  }
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  }
}

export function selectSidebarItem(name: string, selected: boolean) {
  return {
    type: SELECT_SIDEBAR_ITEM,
    payload: {
      name,
      selected,
    },
  }
}

export function selectSidebarSubsection(name: string, selected: boolean) {
  return {
    type: SELECT_SIDEBAR_SUBSECTION,
    payload: {
      name,
      selected,
    },
  }
}

export function toggleMobileMenu() {
  return {
    type: TOGGLE_MOBILE_MENU,
  }
}

export function newUpdateAvailable() {
  return {
    type: NEW_UPDATE_AVAILABLE,
  }
}
