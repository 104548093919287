import { Query } from '@tanstack/react-query'
import { Redacted } from 'api'
import axios from 'axios'
import classNames from 'classnames'
import { t } from 'core/i18n'
import { ConfigType } from 'core/state/types'
import { SnowflakeType } from 'core/types'
import { format } from 'date-fns'
import _, { camelCase, isArray, isObject, transform } from 'lodash'
import { isValidElement } from 'react'
import { toast } from 'react-toastify'
import { DateRange } from 'timeclock/types'

export const convertMinutesToHours = (minutes: number) => {
  const hours = minutes / 60
  const roundeToTwoDecimal = Math.round(hours * 100) / 100
  return roundeToTwoDecimal
}

// temporary any type, until API bindings give better typing
// export const formatLunchbreakConfig = (config: BootstrapData['config']) => {
export const formatLunchbreakConfig = (config: any) => {
  if (config?.timeclock?.lunchbreak) {
    try {
      config.timeclock.lunchbreak = config.timeclock.lunchbreak.map((config: ConfigType) => ({
        hours: convertMinutesToHours(config.minutes_per_day),
        minutes: config.lunchbreak_time,
      }))
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error while formatting lunchbreak config', e)
      }
    }
  }
  return config
}

export const formatBytes = (bytes = 0, decimals = 2) => {
  if (bytes === 0) return '0 B'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

export const fetchBlobUrl = (blobUrl: string, fileName: string) =>
  fetch(blobUrl)
    .then(r => r.blob())
    .then(blobFile => new File([blobFile], fileName))

export const navigateOutsideReact = (url: string, openInNewTab = false) => {
  if (openInNewTab) window.open(url, '_blank')
  else window.location.assign(url)
}

export class LegacyRoutes {
  static scheduleSummary = (employeeId: SnowflakeType | number, dateRange?: DateRange) => {
    const baseLink = `/planning_monthly_summary.php?person=${employeeId}`

    if (dateRange) {
      const YYYYMMDD = 'yyyy-MM-dd'
      const dateFilter = `&day_start=${format(dateRange.start, YYYYMMDD)}&day_end=${format(dateRange.end, YYYYMMDD)}`
      return `${baseLink}${dateFilter}`
    }

    return baseLink
  }
}

export const camelize = (obj: Record<string, unknown>) =>
  transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key)
    result[camelKey] = isObject(value) ? camelize(value as Record<string, unknown>) : value
  })

export const urlMatch = (
  toMatch: string | RegExp | (string | RegExp)[],
  options: { ignoreSearch?: boolean } = {}
): boolean => {
  const url = options.ignoreSearch
    ? window.location.pathname + window.location.hash
    : window.location.pathname + window.location.search + window.location.hash

  // if is array, check if any of the urls match
  if (Array.isArray(toMatch)) return toMatch.some(url => urlMatch(url, options))

  if (typeof toMatch === 'string') {
    return url.startsWith(toMatch)
  } else {
    // use the regex to check match
    const match = url.match(toMatch)
    if (match) return true
  }

  return false
}

/**
 * Type guard to check if a value is redacted
 */
export function isRedacted<T>(value: T | Redacted | undefined): value is Redacted {
  if (value === undefined) return true

  return typeof value === 'object' && value !== null && 'redacted' in value && typeof value.redacted === 'string'
}

export const ifRedacted = <T, Df>(value: T | Redacted, defaultValue: Df) =>
  isRedacted(value) ? defaultValue : (value as Exclude<T, Redacted>)

/**
 *
 * ### classnames
 *
 * The `cx` function takes any number of arguments which can be a string or object.
 * The argument 'foo' is short for { foo: true }. If the value associated with a given key
 * is falsy, that key won't be included in the output.
 *
 * ```js
 * cx('foo', 'bar'); // => 'foo bar'
 * cx('foo', { bar: true }); // => 'foo bar'
 * cx({ 'foo-bar': true }); // => 'foo-bar'
 * cx({ 'foo-bar': false }); // => ''
 * cx({ foo: true }, { bar: true }); // => 'foo bar'
 * cx({ foo: true, bar: true }); // => 'foo bar'
 *
 * // lots of arguments of various types
 * cx('foo', { bar: true, duck: false }, 'baz', { quux: true }); // => 'foo bar baz quux'
 *
 * // other falsy values are just ignored
 * cx(null, false, 'bar', undefined, 0, 1, { baz: null }, ''); // => 'bar 1'
 *
 * // arrays will be recursively flattened as per the rules above:
 * const arr = ['b', { c: true, d: false }];
 * cx('a', arr); // => 'a b c'
 * ```
 */
export const cx = classNames

/** Makes a falsy value undefined, otherwise returns the value */
export function u<T>(v: T): T extends undefined | false | null | typeof NaN | 0 | '' ? undefined : T
export function u(v: any) {
  return v || undefined
}

/**
 * It return the always valid (s)css selector `&` if the condition is true,
 * otherwise it returns a selector that is always false.
 *
 * @example
 * ```
 *  styled.div<{active: boolean}>`
 *    color: red;
 *
 *    ${p => cssIf(p.active)}, &:focus {
 *      color: ${colors.white};
 *    }
 * `
 * ```
 */
export const cssIf = (condition?: any) => (condition ? '&' : '&.__noop__')

/**
 * Allows to scroll an element into view if it's not already visible.
 *
 * inspired by https://gist.github.com/hsablonniere/2581101#file-index-js
 */
export const scrollIntoViewIfNeeded = (
  target: HTMLElement | undefined,
  options: ScrollIntoViewOptions,
  actualParent?: HTMLElement | null
) => {
  if (!target || !target.parentNode) return

  const parent = actualParent ?? (target.parentNode as HTMLElement)
  const parentComputedStyle = window.getComputedStyle(parent, null)
  const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'))
  const parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'))
  const overTop = target.offsetTop - parent.offsetTop < parent.scrollTop
  const overBottom =
    target.offsetTop - parent.offsetTop + target.clientHeight - parentBorderTopWidth >
    parent.scrollTop + parent.clientHeight
  const overLeft = target.offsetLeft - parent.offsetLeft < parent.scrollLeft
  const overRight =
    target.offsetLeft - parent.offsetLeft + target.clientWidth - parentBorderLeftWidth >
    parent.scrollLeft + parent.clientWidth

  if (overTop || overBottom || overLeft || overRight) {
    target.scrollIntoView(options)
  }
}

/**
 * To be used in combination with the `useQueryClient` hook to invalidate queries that match the given string,
 * regardless of the string's position in the query key array.
 * @example
 * ```ts
 * const qc = useQueryClient()
 * qc.invalidateQueries(thatInclude('directory.showResourceSummary'))
 * ```
 */
export const thatInclude = (...str: string[]) => ({ predicate: (q: Query) => str.some(s => q.queryKey.includes(s)) })

/**
 * The tipee-way to handle errors when mutating data with react-query.
 */
export const toastMutationError = (e: any) => {
  if (typeof e === 'string') {
    toast.error(e)
    return
  }

  let message: string | undefined
  if (axios.isAxiosError(e)) {
    switch (e.response?.status) {
      case 422:
        message = e.response.data?.detail
        break
      default:
        message = e.response?.data?.body?.message || e.response?.data?.message
    }
  } else {
    message = e?.response?.data?.message
  }
  toast.error(message || t('tipee.server_error'))
}

/**
 * If the wrapped function throws an error, it will return `undefined` instead.
 * @example
 * ```ts
 * const result = hopeFor(() => JSON.parse('invalid json')) ?? {}
 * ```
 */
export const hopeFor = <T>(fn: () => T): T | undefined => {
  try {
    return fn()
  } catch (e) {
    return undefined
  }
}
/** Check for a condition, if the condition is false, outputs and display
 * an error message.
 *
 * from: https://doc.rust-lang.org/std/macro.debug_assert.html
 *
 * **Only in dev mode** */
export const debugAssert = <T extends boolean>(arg: T, context?: string) => {
  if (arg === false && process.env.NODE_ENV === 'development') {
    throw new Error(`DEBUG_ASSERT\n${context}`)
  }
}

/**
 * Performs a deep clone of an object, if it encounters a React component, it will not clone it, but it will
 * return the original reference.
 */
export const cloneDeepSafeReactComponents = <T>(value: T) =>
  _.cloneDeepWith(value, v =>
    // from lodash doc: If customizer returns undefined, cloning is handled by the method instead
    (Array.isArray(v) && v.every(isValidElement)) || isValidElement(v) ? v : undefined
  ) as T
