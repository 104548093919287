export const DEFAULT_ITEM = 'XXX'

export type Identifier = 'externalId' | 'avs'
type IdentifierRef = {
  [K in Identifier]: Identifier
}
export const IDENTIFIER: IdentifierRef = {
  externalId: 'externalId',
  avs: 'avs',
}

export type Separator = '_' | '.' | '-' | '~' | 'space'
export type SeparatorChar = '_' | '.' | '-' | '~' | ' '

type SeparatorRef = {
  [K in string]: Separator
}

export const SEPARATOR: SeparatorRef = {
  underscore: '_',
  hyphen: '-',
  tilde: '~',
  space: 'space',
}

export type SeparatorListItem = {
  value: Separator
  description: string
  char: SeparatorChar
}

export const SEPARATOR_LIST: SeparatorListItem[] = [
  { value: SEPARATOR.underscore, description: 'document.file.config.separator.underscore', char: '_' },
  { value: SEPARATOR.hyphen, description: 'document.file.config.separator.hyphen', char: '-' },
  { value: SEPARATOR.tilde, description: 'document.file.config.separator.tilde', char: '~' },
  { value: SEPARATOR.space, description: 'document.file.config.separator.space', char: ' ' },
]

export type PatternValues = {
  elementsNumber: number
  position: { value: number; label?: string }
  identifier: Identifier
  separator: { value: Separator; label?: string }
}

export type Pattern = {
  elementsNumber: number
  position: number
  identifier: Identifier
  separator: Separator
}
