import { SnowflakeType } from 'core/types'
import { emptyState } from 'crud/state/reducer'
import { CrudSetup, Entity, EntityCollection } from 'crud/state/types'
import { ErrorsType } from 'crud/types'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { StoreStateType } from 'store/types'

const subState = (state: StoreStateType, entityName: string): CrudSetup => state.crud[entityName] || emptyState

export const getCurrentPage = (state: StoreStateType, entityName: string) => subState(state, entityName).currentPage
export const getTotalPages = (state: StoreStateType, entityName: string) => subState(state, entityName).totalPages
export const getTotalEntities = (state: StoreStateType, entityName: string) => subState(state, entityName).total
export const getPerPage = (state: StoreStateType, entityName: string) => subState(state, entityName).perPage
export const isProcessing = (state: StoreStateType, entityName: string) => subState(state, entityName).isProcessing
export const isFetchingEntity = (state: StoreStateType, entityName: string) =>
  subState(state, entityName).isFetchingEntity
export const getFilter = (state: StoreStateType, entityName: string) => subState(state, entityName).filter

export const getSortField = createCachedSelector(
  (state: StoreStateType, entityName: string) => subState(state, entityName).sortField,
  (state: StoreStateType, entityName: string) => subState(state, entityName).sortOrder,
  (sortField, sortOrder) =>
    sortField && sortOrder
      ? {
          [sortField]: sortOrder,
        }
      : {}
)((state, entityName) => entityName)

export const getErrors = createCachedSelector(
  subState,
  (crudState: CrudSetup): ErrorsType =>
    lodash(crudState.errors)
      .map(error => {
        const upperChars = error.field.match(/([A-Z])/g)
        if (!upperChars) {
          return error
        }

        let str = error.field.toString()
        for (let i = 0, n = upperChars.length; i < n; i++) {
          str = str.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase())
        }

        return { field: str, message: error.message }
      })
      .reduce((all, { field, message }) => ({ ...all, [field]: message }), {})
)((state, entityName: string) => 'errors_' + entityName)

export const isPageFetching = createCachedSelector(
  getCurrentPage,
  subState,
  (currentPage, subState) => subState.pages[currentPage].isFetching
)((state, entityName) => entityName)

export const getCurrentPageIds = createCachedSelector(
  getCurrentPage,
  subState,
  (currentPage, subState) => subState.pages[currentPage].ids
)((state, entityName) => entityName)

export const getEntitiesForCurrentPage = createCachedSelector(
  getCurrentPageIds,
  (state: StoreStateType, entityName: string): EntityCollection => lodash.get(state, entityName),
  (ids, entities): Entity[] => ids.map(id => entities[id])
)((state, entityName) => entityName)

export const getEntity = createCachedSelector(
  (state: StoreStateType, entityName: string): EntityCollection => lodash.get(state, entityName),
  entities => (id: number | SnowflakeType) => entities[id]
)((state, entityName) => 'entity_' + entityName)
