import { ActiveUserType, PreferencesType, SessionType, TwoFactorType } from 'authentication/types'
import { receiveBootstrap } from 'core/state/actions'
import { BySnowflake, SnowflakeType } from 'core/types'

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const REQUEST_ACTIVE_USER = 'REQUEST_ACTIVE_USER'
export const RECEIVE_ACTIVE_USER = 'RECEIVE_ACTIVE_USER'
export const REQUEST_ACLS = 'REQUEST_ACLS'
export const RECEIVE_ACLS = 'RECEIVE_ACLS'
export const SAVE_ACTIVE_LANGUAGE = 'SAVE_ACTIVE_LANGUAGE'
export const SAVE_PREFERENCE = 'SAVE_PREFERENCE'
export const RECEIVE_SESSION = 'RECEIVE_SESSION'
export const SAVE_SESSION = 'SAVE_SESSION'
export const REQUEST_TWO_FACTOR_METHOD = 'REQUEST_TWO_FACTOR_METHOD'
export const RECEIVE_TWO_FACTOR_METHOD = 'RECEIVE_TWO_FACTOR_METHOD'
export const REQUEST_REMOVAL_TWO_FACTOR_METHOD = 'REQUEST_REMOVAL_TWO_FACTOR_METHOD'
export const RECEIVE_PERMISSIONS = 'RECEIVE_PERMISSIONS'

export type PreferencesRequestType = {
  properties: {
    preferences: PreferencesType
  }
}

type AclType = {
  allowed: boolean
  sectors?: number[] | '*'
} & AclRecursionType

export type Permissions = { [key: string]: boolean }

export interface AclRecursionType {
  [x: string]: AclType
}

interface UserLoggedOutAction {
  type: typeof USER_LOGGED_OUT
}

interface RequestActiveUserAction {
  type: typeof REQUEST_ACTIVE_USER
}

interface ReceiveActiveUserAction {
  type: typeof RECEIVE_ACTIVE_USER
  payload: {
    active_user: ActiveUserType
  }
}

export interface RequestAclsAction {
  type: typeof REQUEST_ACLS
  payload: {
    force: boolean
  }
}

interface ReceiveAclsAction {
  type: typeof RECEIVE_ACLS
  payload: {
    acls: AclRecursionType
    expiration: Date
  }
}

export interface SaveActiveLanguage {
  type: typeof SAVE_ACTIVE_LANGUAGE
  payload: {
    language: string
  }
}

export interface SavePreferenceAction {
  type: typeof SAVE_PREFERENCE
  payload: {
    preference: string
    value: any
  }
}

interface ReceiveSessionAction {
  type: typeof RECEIVE_SESSION
  payload: string
}

export interface SaveSessionAction {
  type: typeof SAVE_SESSION
  payload: {
    key: string
    value: string
  }
}

export interface RequestTwoFactorMethod {
  type: typeof REQUEST_TWO_FACTOR_METHOD
  payload: {
    userId: SnowflakeType
  }
}

export interface ReceiveTwoFactorMethod {
  type: typeof RECEIVE_TWO_FACTOR_METHOD
  payload: {
    userId: SnowflakeType
    data: TwoFactorType[]
  }
}

export interface requestRemovalTwoFactorMethod {
  type: typeof REQUEST_REMOVAL_TWO_FACTOR_METHOD
  payload: {
    resourceSnowflake: SnowflakeType
    methodId: SnowflakeType
  }
}

export interface receivePermissionsAction {
  type: typeof RECEIVE_PERMISSIONS
  payload: {
    snowflake: SnowflakeType
    permissions: Permissions
  }
}

export type AuthenticationActionTypes =
  | UserLoggedOutAction
  | RequestActiveUserAction
  | ReceiveActiveUserAction
  | RequestAclsAction
  | ReceiveAclsAction
  | SaveActiveLanguage
  | SavePreferenceAction
  | ReceiveSessionAction
  | SaveSessionAction
  | RequestTwoFactorMethod
  | ReceiveTwoFactorMethod
  | requestRemovalTwoFactorMethod
  | receivePermissionsAction
  | ReturnType<typeof receiveBootstrap>

export interface AuthenticationState {
  ui: {
    boot: {
      acl: boolean
      activeUser: boolean
      preferences: boolean
    }
    twoFactor: {
      isFetching: boolean
      isDeleting: boolean
      isPosting: boolean
    }
  }
  activeUser: ActiveUserType
  preferences: PreferencesType
  session: SessionType
  permissions: BySnowflake<Permissions>
  twoFactor: BySnowflake<TwoFactorType[]>
  acls: {
    values: AclRecursionType
    expiration: Date | null
  }
}
