import { withCrudReducer } from 'crud/state/withCrudReducer'
import produce from 'immer'
import lodash from 'lodash'
import { compose } from 'redux'
import {
  RECEIVE_EXTRA_TAG_SELECT_TAGS,
  RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME,
  RECEIVE_TAGS,
  RECEIVE_TAG_LIST,
  RECEIVE_TAG_SELECT_HAS_TAGS,
  RECEIVE_TAG_SELECT_TAGS,
  REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME,
  REQUEST_TAGS,
  REQUEST_TAG_LIST,
  REQUEST_TAG_SELECT_TAGS,
  TagState,
  TagTypes,
} from 'tag/state/types'

const initialState: TagState = {
  tags: {},
  tagSelect: {
    hasTags: false,
    tags: [],
  },
  ui: {
    tags: {
      tagsFetching: {},
      isFetching: false,
    },
    tagSelect: {
      isFetching: false,
    },
  },
}

export const tagReducer = compose(withCrudReducer<TagState, TagTypes>('tags'))(
  (currentState = initialState, action): TagState =>
    produce(currentState, draft => {
      switch (action.type) {
        case REQUEST_TAGS:
          draft.ui.tags.isFetching = true
          break
        case RECEIVE_TAGS:
          action.payload.tags.forEach(tag => {
            draft.tags[tag.snowflake] = tag
            draft.ui.tags.isFetching = false
            draft.ui.tags.tagsFetching[tag.snowflake] = false
          })
          draft.ui.tags.isFetching = false
          break
        case REQUEST_TAG_SELECT_TAGS:
          draft.ui.tagSelect.isFetching = true
          break
        case RECEIVE_TAG_SELECT_TAGS:
          draft.tagSelect.hasTags = action.payload.tags.length > 0
          const unfilteredTags = [...draft.tagSelect.tags, ...action.payload.tags]
          const uniqueTags = [...new Set(unfilteredTags)]
          draft.tagSelect.tags = uniqueTags
          draft.ui.tagSelect.isFetching = false
          break
        case RECEIVE_EXTRA_TAG_SELECT_TAGS:
          const tags = action.payload.tags
          const tempTags = [...draft.tagSelect.tags]
          tags.forEach(tag => {
            if (!tempTags.includes(tag)) tempTags.push(tag)
          })

          draft.tagSelect.tags = tempTags
          draft.ui.tagSelect.isFetching = false
          break
        case RECEIVE_TAG_SELECT_HAS_TAGS:
          draft.tagSelect.hasTags = action.payload.hasTags
          break
        case REQUEST_TAG_LIST:
          draft.ui.tags.isFetching = true
          action.payload.tags.forEach(tag => {
            draft.ui.tags.tagsFetching[tag] = true
          })
          break
        case RECEIVE_TAG_LIST:
          draft.ui.tags.isFetching = false
          action.payload.tags.forEach(tag => {
            draft.ui.tags.tagsFetching[tag.snowflake] = false
            draft.tags[tag.snowflake] = tag
          })
          break
        case REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME:
          draft.ui.tagSelect.isFetching = true
          break
        case RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME:
          draft.tagSelect.tags = lodash.union(draft.tagSelect.tags, action.payload.tags)
          draft.tagSelect.hasTags = draft.tagSelect.tags.length > 0
          draft.ui.tagSelect.isFetching = false
          break
        default:
          break
      }
    })
)
