import { balanceService } from 'balance/services/balance'
import { parseDateTime } from 'chronos'
import { TranslateElement } from 'core/components/i18n'
import { getIntl } from 'core/i18n'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { getConfig, getDirectoryKinds } from 'core/state/selectors'
import { TeamType } from 'core/types'
import { addMinutes } from 'date-fns'
import { directoryService } from 'directory/services/directory'
import { sitesService } from 'directory/services/sites'
import * as actions from 'directory/state/actions'
import {
  isFetchingResourceAccount,
  isFetchingResourceSummary,
  isPersonDataExpired,
  isResourceAccountDataExpired,
  isResourceSummaryDataExpired,
} from 'directory/state/selectors'
import { getRoleAttributions, isRemoveResourceBlocked } from 'directory/state/selectors/role'
import { getTeamsByType, getTeamsSnowflakesAsArray } from 'directory/state/selectors/team'
import lodash from 'lodash'
import { toast } from 'react-toastify'
import { actionChannel, all, fork, put, select, take, takeEvery, takeLatest } from 'typed-redux-saga'

function* fetchBirthdays() {
  const config = yield* select(getConfig)

  if (!config.show_birthdates) {
    return
  }

  try {
    const bookmarks = yield* callApi(directoryService.getBirthdays)
    yield* put(actions.receiveBirthdays(bookmarks))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* fetchPerson() {
  const chan = yield* actionChannel(actions.REQUEST_PERSON)
  while (true) {
    const action = yield* take(chan)
    const { id } =
      // @ts-expect-error (nocheck)
      action.payload

    if (!id) continue
    const isExpired = yield* select(isPersonDataExpired, id)

    if (!isExpired) {
      continue
    }

    yield* put(actions.fetchingPerson(id))

    try {
      const person = yield* callApi(directoryService.getPerson, id)
      yield* put(actions.receivePerson(person, addMinutes(parseDateTime(), 10)))
    } catch (e: any) {
      if (e?.response?.status === 403) {
        e?.response?.data?.message && toast.error(e.response.data.message)
      } else {
        yield* put(throwError(e))
      }
    }
  }
}

function* watchResourcesSummaries() {
  const chan = yield* actionChannel(actions.REQUEST_RESOURCES_SUMMARIES)
  while (true) {
    const action = yield* take(chan)
    const { snowflakes } =
      // @ts-expect-error (nocheck)
      action.payload
    const resourceSummariesToFetch = [] as any[]
    for (const snowflake of snowflakes) {
      const alreadyLoaded = yield* isResourceSummaryAlreadyLoaded(snowflake)

      if (!alreadyLoaded && !resourceSummariesToFetch.find(value => value === snowflake)) {
        resourceSummariesToFetch.push(snowflake)
      }
    }

    if (resourceSummariesToFetch && resourceSummariesToFetch.length > 0) {
      yield* put(actions.fetchingResourcesSummaries(resourceSummariesToFetch))

      try {
        const resources = yield* callApi(directoryService.getResourcesSummaries, resourceSummariesToFetch)
        yield* put(
          // @ts-expect-error (nocheck)
          actions.receiveResourcesSummaries(resources)
        )
      } catch (e) {
        yield* put(throwError(e))
      }
    }
  }
}

function* watchResourceSummary() {
  const chan = yield* actionChannel(actions.REQUEST_RESOURCE_SUMMARY)
  while (true) {
    const action = yield* take(chan)
    const { snowflake } =
      // @ts-expect-error (nocheck)
      action.payload
    const alreadyLoaded = yield* isResourceSummaryAlreadyLoaded(snowflake)
    if (alreadyLoaded) continue
    yield* put(actions.fetchingResourceSummary(snowflake))
    yield* fork(fetchResourceSummary, snowflake)
  }
}

function* isResourceSummaryAlreadyLoaded(snowflake: any) {
  const isExpired = yield* select(isResourceSummaryDataExpired, snowflake)
  const isAlreadyFetching = yield* select(isFetchingResourceSummary, snowflake)
  return !isExpired || isAlreadyFetching
}

function* watchResourceAccount() {
  const chan = yield* actionChannel(actions.REQUEST_RESOURCE_ACCOUNT)
  while (true) {
    const action = yield* take(chan)
    const { snowflake } =
      // @ts-expect-error (nocheck)
      action.payload
    const alreadyLoaded = yield* isResourceAccountAlreadyLoaded(snowflake)
    if (
      alreadyLoaded &&
      // @ts-expect-error (nocheck)
      !action.payload.forceRefetch
    )
      continue
    yield* getResourceAccount(snowflake)
  }
}

function* getResourceAccount(snowflake: any) {
  const account = yield* callApi(directoryService.getResourceAccount, [snowflake])
  yield* put(
    // @ts-expect-error (nocheck)
    actions.receiveResourceAccount(snowflake, account)
  )
}

function* isResourceAccountAlreadyLoaded(snowflake: any) {
  const isExpired = yield* select(isResourceAccountDataExpired, snowflake)
  const isAlreadyFetching = yield* select(isFetchingResourceAccount, snowflake)
  return !isExpired || isAlreadyFetching
}

function* fetchResourceSummary(snowflake: any) {
  try {
    const resources = yield* callApi(directoryService.getResourcesSummaries, [snowflake])
    yield* all(
      resources.map((resource: any) => {
        return put(
          // @ts-expect-error (nocheck)
          actions.receiveResourceSummary(resource)
        )
      })
    )
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* searchPersons(action: any) {
  const { text, resourceInfos } = action.payload
  try {
    const promises = lodash.map(resourceInfos, ({ role, resource, action }) =>
      callApi(directoryService.getList, text, role, resource, action)
    )
    const persons = yield* all(promises)
    yield* put(actions.receivePersons(lodash.flatten(persons)))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* fetchSitesAndSectors(action: any) {
  const {
    payload: { resource, action: aclAction },
  } = action
  try {
    const sites = yield* callApi(sitesService.getList, resource, aclAction)
    yield* put(actions.receiveSitesList(sites, resource, aclAction))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* fetchRolesList() {
  try {
    const roles =
      // @ts-expect-error (nocheck)
      yield callApi(directoryService.getRoles)
    yield put(actions.receiveRolesList(roles))
  } catch (e) {
    yield put(throwError(e))
  }
}

function* fetchSupportPersonList() {
  try {
    const { persons, sectors } = yield* callApi(directoryService.getSupportPersonList)
    yield* put(actions.receivePersons(persons))
    yield* put(actions.receiveSupportPersonList(sectors))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* findTeam(id: any, type: any) {
  const teamsByType = yield* select(s =>
    getTeamsByType(
      s,
      // @ts-expect-error (nocheck)
      type
    )
  )
  const teams = teamsByType[type]
  const team = teams?.find(team => parseInt(team.id) === parseInt(id))
  // @ts-expect-error (nocheck)
  return yield team
}

export function* requestTeam(action: any) {
  const { id, type } = action.payload
  try {
    const foundTeam = yield* findTeam(id, type)
    if (!foundTeam) {
      const serviceGetter = type === TeamType.Sector ? directoryService.getSector : directoryService.getSite
      const team = yield* callApi(serviceGetter, id)
      if (team) {
        yield* put(actions.receiveTeam(team, type))
      }
    }
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* fetchTeamList() {
  try {
    yield* put(actions.requestTeamList())
    const teamList = yield* callApi(directoryService.getTeamList)
    yield* put(actions.receiveTeamList(teamList))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* fetchRoleAttributions(action: any) {
  try {
    // get teams list
    yield* fork(fetchTeamList)

    // get active resources
    const kindsList = yield* select(getDirectoryKinds)
    const activResources = yield* callApi(directoryService.getActivResources, kindsList.employee)

    // get roles by resources
    const roleAttributions = yield* callApi(
      directoryService.getRoleAttributions,
      action.payload.roleSnowflake,
      activResources
    )

    // get resource summaries
    const resourceIdList = Object.keys(roleAttributions.resources).map(resourceId => resourceId)
    const resourcesSummaries = yield* callApi(directoryService.getResourcesSummaries, resourceIdList)
    yield* put(
      // @ts-expect-error (nocheck)
      actions.receiveResourcesSummaries(resourcesSummaries)
    )

    // WARNING : this must be called at the end
    // we want to get the resources summaries (with labels of resources (name-firstname))
    // in order to sort the table by this labels (firstname-name)
    yield* put(
      // @ts-expect-error (nocheck)
      actions.receiveRoleAttributions(roleAttributions)
    )
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* grantAllTeamsToResource(action: any) {
  try {
    const { resource, role } = action.payload
    yield* callApi(directoryService.grantAllTeamsToResource, resource, role)

    yield* put(actions.grantAllTeamsToResourceSuccess(resource))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* grantTeamToResource(action: any) {
  try {
    const { resource, role, team } = action.payload
    yield* callApi(directoryService.grantTeamToResource, resource, role, [team])
    yield* put(actions.grantTeamToResourceSuccess(resource, team))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* revokeAllTeamsToResource(action: any) {
  try {
    yield* callApi(directoryService.revokeAllTeamsToResource, action.payload.resource, action.payload.role)
    yield* put(actions.revokeAllTeamsToResourceSuccess(action.payload.resource))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* removeResourceFromRole(action: any) {
  try {
    const { resource, role } = action.payload
    const isBlocked = yield* select(isRemoveResourceBlocked, resource)
    if (!isBlocked) {
      yield* callApi(directoryService.removeResourceFromRole, resource, role)
      yield* put(actions.removeResourceFromRoleSuccess(resource))
    }
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* revokeTeamToResource(action: any) {
  try {
    const { resource, role, team } = action.payload

    const roleAttributions = yield* select(getRoleAttributions)
    const resourceAttribution = roleAttributions.resources[resource]
    if (resourceAttribution.allTeams) {
      const allRoleTeams = yield* select(getTeamsSnowflakesAsArray)
      yield* callApi(directoryService.revokeAllTeamsToResource, resource, role)
      const filteredTeams = allRoleTeams.filter((e: any) => e !== team)
      yield* callApi(directoryService.grantTeamToResource, resource, role, filteredTeams)
      yield* put(actions.setRoleTeamsToResource(resource, allRoleTeams))
    } else {
      yield* callApi(directoryService.revokeTeamToResource, resource, role, team)
    }

    yield* put(actions.revokeTeamToResourceSuccess(resource, team))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* grantRoleAttributions(action: any) {
  try {
    const { resources, role, teams } = action.payload
    yield* callApi(directoryService.grantRoleAttributions, resources, role, teams)
    yield* put(actions.grantRoleAttributionsSuccess())
    yield* put(actions.requestRoleAttributions(role))
  } catch (e) {
    yield* put(actions.grantRoleAttributionsFailure({ error: 'tipee.error.generic_retry' }))
  }
}

export function* grantAllTeamsRoleAttributions(action: any) {
  try {
    const { resources, role } = action.payload
    yield* callApi(directoryService.grantAllTeamsRoleAttributions, resources, role)
    yield* put(actions.grantRoleAttributionsSuccess())
    yield* put(actions.requestRoleAttributions(role))
  } catch (e) {
    yield* put(actions.grantRoleAttributionsFailure({ error: 'tipee.error.generic_retry' }))
  }
}

export function* saveResourceUsername(action: any) {
  try {
    const { resource, username } = action.payload
    yield* callApi(directoryService.saveResourceUsername, resource, username)
    yield* put(actions.saveResourceUsernameSuccess())
    yield* getResourceAccount(resource)
  } catch (e) {
    yield* put(
      actions.saveResourceUsernameFailure({
        error:
          // @ts-expect-error (nocheck)
          e.data?.message,
      })
    )
  }
}

export function* saveMustChangePassword(action: any) {
  try {
    const { resource, mustChangePassword } = action.payload
    yield* callApi(directoryService.saveMustChangePassword, resource, mustChangePassword)
    yield* put(actions.saveMustChangePasswordSuccess())
    yield* getResourceAccount(resource)
  } catch (e) {
    yield* put(actions.saveMustChangePasswordFailure({ error: 'tipee.error.generic_retry' }))
    yield* getResourceAccount(action.payload.resource)
  }
}

export function* sendCredentialsLink(action: any) {
  try {
    const { resource } = action.payload
    yield* callApi(directoryService.sendCredentialsLink, resource)
    yield* put(actions.sendCredentialsLinkSuccess())
    yield* getResourceAccount(resource)
    toast.success(<TranslateElement id="directory.resource.account_settings.sent_authentification_link_success" />)
  } catch (e) {
    yield* put(actions.sendCredentialsLinkFailure({ error: 'tipee.error.generic_retry' }))
  }
}

function* saveResourceBalanceMode(action: any) {
  const { resourceSnowflake, mode } = action.payload
  const { formatMessage } = getIntl()

  try {
    yield* callApi(balanceService.saveResourceBalancekMode, resourceSnowflake, mode)
    yield* put(actions.saveResourceBalanceModeSuccess())
    yield* put(actions.requestPerson(resourceSnowflake))
  } catch (e) {
    yield* put(
      actions.saveResourceBalanceModeFailure({
        error:
          // @ts-expect-error (nocheck)
          e.data?.message || formatMessage({ id: 'tipee.save_error' }),
      })
    )
    yield* put(actions.requestPerson(resourceSnowflake))
  }
}

function* saveResourceTimecheckMode(action: any) {
  const { resourceSnowflake, mode } = action.payload
  const { formatMessage } = getIntl()

  try {
    yield* callApi(balanceService.saveResourceTimecheckMode, resourceSnowflake, mode)
    yield* put(actions.saveResourceTimecheckModeSuccess())
    yield* put(actions.requestPerson(resourceSnowflake))
  } catch (e) {
    yield* put(
      actions.saveResourceTimecheckModeFailure({
        error:
          // @ts-expect-error (nocheck)
          e.data?.message || formatMessage({ id: 'tipee.save_error' }),
      })
    )
    yield* put(actions.requestPerson(resourceSnowflake))
  }
}

function* saveResourceWorkDisplayMode(action: any) {
  const { resourceSnowflake, mode } = action.payload
  const { formatMessage } = getIntl()

  try {
    yield* callApi(balanceService.saveResourceWorkDisplayMode, resourceSnowflake, mode)
    yield* put(actions.saveResourceWorkDisplayModeSuccess())
    yield* put(actions.requestPerson(resourceSnowflake))
  } catch (e) {
    yield* put(
      actions.saveResourceWorkDisplayModeFailure({
        error:
          // @ts-expect-error (nocheck)
          e.data?.message || formatMessage({ id: 'tipee.save_error' }),
      })
    )
    yield* put(actions.requestPerson(resourceSnowflake))
  }
}

export const directorySaga = function* directorySaga() {
  yield* all([
    takeLatest(actions.REQUEST_BIRTHDAYS, fetchBirthdays),
    takeLatest(actions.SEARCH_PERSONS, searchPersons),
    takeEvery(actions.REQUEST_SITES_LIST, fetchSitesAndSectors),
    takeEvery(actions.REQUEST_ROLES_LIST, fetchRolesList),
    takeLatest(actions.REQUEST_SUPPORT_PERSON_LIST, fetchSupportPersonList),
    takeEvery(actions.REQUEST_TEAM, requestTeam),
    takeLatest(actions.REQUEST_ROLE_ATTRIBUTIONS, fetchRoleAttributions),
    takeEvery(actions.GRANT_TEAM_TO_RESOURCE, grantTeamToResource),
    takeEvery(actions.GRANT_ALL_TEAMS_TO_RESOURCE, grantAllTeamsToResource),
    takeEvery(actions.REVOKE_ALL_TEAMS_TO_RESOURCE, revokeAllTeamsToResource),
    takeEvery(actions.REVOKE_TEAM_TO_RESOURCE, revokeTeamToResource),
    takeEvery(actions.GRANT_ROLE_ATTRIBUTIONS, grantRoleAttributions),
    takeEvery(actions.GRANT_ALL_TEAMS_ROLE_ATTRIBUTIONS, grantAllTeamsRoleAttributions),
    takeEvery(actions.REMOVE_RESOURCE_FROM_ROLE, removeResourceFromRole),
    takeLatest(actions.SAVE_RESOURCE_USERNAME, saveResourceUsername),
    takeLatest(actions.SAVE_MUST_CHANGE_PASSWORD, saveMustChangePassword),
    takeLatest(actions.SEND_CREDENTIALS_LINK, sendCredentialsLink),
    takeEvery(actions.SAVE_RESOURCE_BALANCE_MODE, saveResourceBalanceMode),
    takeEvery(actions.SAVE_RESOURCE_TIMECHECK_MODE, saveResourceTimecheckMode),
    takeEvery(actions.SAVE_RESOURCE_WORK_DISPLAY_MODE, saveResourceWorkDisplayMode),
    watchResourceSummary(),
    watchResourceAccount(),
    watchResourcesSummaries(),
    fetchPerson(),
  ])
}
