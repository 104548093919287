import { parseDateTime } from 'chronos'
import { AvatarShape, Resource, SnowflakeType } from 'core/types'
import { ResourceAccount } from 'directory/types'
export class ResourceAccountAdapter {
  static fromApi(resourceAccount: any) {
    return {
      username: resourceAccount.username || '',
      password: {
        hasPassword: resourceAccount.has_password,
        forceChange: resourceAccount.password_must_be_changed,
        lastChange: resourceAccount.password_last_changed_at
          ? parseDateTime(resourceAccount.password_last_changed_at)
          : null,
      },
      authenticationLink: {
        lastSentAt: resourceAccount.link_last_sent_at ? parseDateTime(resourceAccount.link_last_sent_at) : null,
        lastSentBy: resourceAccount.link_last_sent_by,
        lastSentTo: resourceAccount.link_last_sent_to,
      },
      twoFactorAllowed: resourceAccount.config_allows_two_factor,
    } as ResourceAccount
  }

  // adapt: id -> snowflake
  static fromApiSummary(resource: {
    id: SnowflakeType
    label: string
    picture: {
      shape: AvatarShape
      initial: string
      url: string | null
    }
  }): Resource {
    return {
      snowflake: resource.id,
      label: resource.label,
      picture: resource.picture,
    }
  }
}
