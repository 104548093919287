import { css, StyleSheet } from 'aphrodite/no-important'
import { breakpoints } from 'design/breakpoints'
import * as styledComponents from 'styled-components'
import { colors } from 'theme/colors'
import { fonts } from 'theme/fonts'
import { muiColors } from 'theme/muiColors'
import { muiFont } from 'theme/muiFonts'
import { defaultSwipeableOptions } from 'theme/swipeable'

const { default: styled, useTheme, css: styledCSS, keyframes } = styledComponents

export {
  breakpoints,
  colors,
  css,
  defaultSwipeableOptions,
  fonts,
  keyframes,
  muiColors,
  muiFont,
  styled,
  styledCSS,
  StyleSheet,
  useTheme,
}
