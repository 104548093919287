import { SnowflakeType } from 'core/types'
import { withCrudReducer } from 'crud/state/withCrudReducer'
import * as directoryActions from 'directory/state/actions'
import { DirectoryState, ResourcesFetchingState } from 'directory/state/types'
import produce from 'immer'
import lodash from 'lodash'
import { compose } from 'redux'

export const initialState: DirectoryState = {
  birthdays: {
    employees: [],
    residents: [],
  },
  categories: {},
  persons: {},
  personsBySnowflake: {},
  resourcesSummaries: {},
  resourcesAccount: {},
  sectors: {},
  sites: {},
  supportList: {},
  teams: {},
  roles: {},
  roleAttributions: {
    resources: {},
  },
  ui: {
    pages: {
      settings: {
        accountSettings: {
          changeUsername: {
            isPosting: false,
            error: {
              hasError: false,
              message: '',
            },
          },
          forceChangePassword: {
            isPosting: false,
            error: {
              hasError: false,
              message: '',
            },
          },
          sendCredentialsLink: {
            isPosting: false,
            error: {
              hasError: false,
              message: '',
            },
          },
        },
        balanceSettings: {
          balanceMode: {
            isPosting: false,
            error: {
              hasError: false,
              message: '',
            },
          },
          workDisplayMode: {
            isPosting: false,
            error: {
              hasError: false,
              message: '',
            },
          },
          timecheckMode: {
            isPosting: false,
            error: {
              hasError: false,
              message: '',
            },
          },
        },
      },
      roleManagement: {
        grantResourcesRolesPost: {
          isPosting: false,
          error: {
            hasError: false,
            message: '',
          },
        },
        roleAttributions: {
          isFetching: false,
          error: {
            hasError: false,
            message: '',
          },
          resources: {},
        },
      },
    },
    persons: {
      isFetching: false,
      personsFetching: {},
    },
    resourcesSummaries: {
      isFetching: false,
      resourcesSummariesFetching: {},
    },
    resourcesAccount: {
      isFetching: false,
      resourcesAccountFetching: {},
    },
    roles: {
      isFetching: false,
    },
    searchbar: {
      visible: true,
      searchText: '',
    },
    supportList: {
      isFetching: false,
    },
    sitesList: {
      isFetching: false,
    },
    teams: {
      isFetching: false,
    },
  },
}

export const defaultRoleAttributionFetchingState = {
  isFetchingAllTeam: false,
  isRemoving: false,
  teams: {},
}

export const directoryReducer = compose(withCrudReducer('categories'))((currentState = initialState, action: any) =>
  produce(currentState, (draft: DirectoryState) => {
    //@ts-expect-error
    const state: DirectoryState = currentState

    // declare accessors
    const roleManagementUi = draft.ui.pages.roleManagement
    const accountSettingsUi = draft.ui.pages.settings.accountSettings
    const settingsUi = draft.ui.pages.settings.balanceSettings

    switch (action.type) {
      case directoryActions.RECEIVE_BIRTHDAYS:
        draft.birthdays = action.payload.birthdays
        break

      case directoryActions.FETCHING_PERSON:
        draft.ui.persons.isFetching = true
        draft.ui.persons.personsFetching[action.payload.id] = true
        break
      case directoryActions.RECEIVE_PERSON: {
        const { person, expiration } = action.payload
        draft.ui.persons.isFetching = !!Object.values(draft.ui.persons.personsFetching).find(
          personIsFetching => personIsFetching
        )
        draft.ui.persons.personsFetching[person.id] = false
        draft.persons[person.id] = {
          value: person,
          expiration,
        }

        if (person.snowflake) {
          draft.ui.persons.personsFetching[person.snowflake] = false
          draft.personsBySnowflake[person.snowflake] = {
            value: person,
            expiration,
          }
        }

        break
      }
      case directoryActions.FETCHING_RESOURCES_SUMMARIES:
        draft.ui.resourcesSummaries.isFetching = true
        action.payload.snowflakes.forEach((snowflake: SnowflakeType) => {
          draft.ui.resourcesSummaries.resourcesSummariesFetching[snowflake] = true
        })
        break
      case directoryActions.RECEIVE_RESOURCES_SUMMARIES:
        draft.ui.resourcesSummaries.isFetching = false
        const { summaries, expiration } = action.payload
        summaries.forEach((summary: any) => {
          draft.ui.resourcesSummaries.resourcesSummariesFetching[summary.id] = false
          draft.resourcesSummaries[summary.id] = {
            value: summary,
            expiration,
          }
        })
        break
      case directoryActions.FETCHING_RESOURCE_SUMMARY:
        draft.ui.resourcesSummaries.isFetching = true
        draft.ui.resourcesSummaries.resourcesSummariesFetching[action.payload.snowflake] = true
        break
      case directoryActions.RECEIVE_RESOURCE_SUMMARY: {
        const { summary, expiration } = action.payload

        draft.ui.resourcesSummaries.resourcesSummariesFetching[summary.id] = false
        draft.resourcesSummaries[summary.id] = {
          value: summary,
          expiration,
        }
        break
      }
      case directoryActions.FETCHING_RESOURCE_ACCOUNT:
        draft.ui.resourcesAccount.isFetching = true
        draft.ui.resourcesAccount.resourcesAccountFetching[action.payload.snowflake] = true
        break
      case directoryActions.RECEIVE_RESOURCE_ACCOUNT: {
        const { snowflake, account, expiration } = action.payload
        draft.ui.resourcesAccount.resourcesAccountFetching[snowflake] = false
        draft.resourcesAccount[snowflake] = {
          value: account,
          expiration,
        }
        break
      }
      case directoryActions.RECEIVE_PERSONS: {
        const { persons } = action.payload

        lodash.forEach(persons, person => {
          const existing = !!state.persons[person.id] || !!state.personsBySnowflake[person.snowflake]
          draft.persons[person.id] = {
            value: person,
            //@ts-expect-error
            ...(!existing && { expiration: null }),
          }
          draft.ui.persons.personsFetching[person.id] = false

          if (person.snowflake) {
            draft.personsBySnowflake[person.snowflake] = {
              value: person,
              //@ts-expect-error
              ...(!existing && { expiration: null }),
            }
            draft.ui.persons.personsFetching[person.snowflake] = false
          }
        })
        draft.ui.persons.isFetching = !!Object.values(draft.ui.persons.personsFetching).find(
          personIsFetching => personIsFetching
        )

        break
      }
      case directoryActions.SEARCH_PERSONS:
        draft.ui.persons.isFetching = true
        draft.ui.searchbar.searchText = action.payload.text
        break
      case directoryActions.REQUEST_SITES_LIST:
        draft.ui.sitesList.isFetching = true
        break
      case directoryActions.RECEIVE_SITES_LIST:
        const { sites: sitesList } = action.payload

        draft.ui.sitesList.isFetching = false

        // first we add the sectors and sites to their respective collection
        draft.sectors = lodash.keyBy(
          lodash.flatMap(sitesList, site => site.sectors),
          sector => sector.id
        )

        draft.sites = lodash.keyBy(
          lodash.map(sitesList, site => ({
            ...site,
            sectors: lodash.map(site.sectors, sector => sector.id),
          })),
          site => site.id
        )
        break
      case directoryActions.REQUEST_ROLES_LIST:
        draft.ui.roles.isFetching = true
        break
      case directoryActions.RECEIVE_ROLES_LIST:
        const { roles } = action.payload

        roles.forEach((role: any) => (draft.roles[role.snowflake] = role))
        draft.ui.roles.isFetching = false
        break
      case directoryActions.RECEIVE_TEAM:
        const { team, teamType } = action.payload
        team.type = teamType
        draft.teams[team.id] = team
        break
      case directoryActions.REQUEST_TEAM_LIST:
        draft.ui.teams.isFetching = true
        break
      case directoryActions.RECEIVE_TEAM_LIST:
        const { teamList } = action.payload
        draft.ui.teams.isFetching = false
        draft.teams = teamList
        break
      case directoryActions.REQUEST_SUPPORT_PERSON_LIST:
        draft.ui.persons.isFetching = true
        draft.ui.supportList.isFetching = true
        break
      case directoryActions.RECEIVE_SUPPORT_PERSON_LIST:
        const { persons } = action.payload
        draft.ui.supportList.isFetching = false
        draft.supportList = persons
        break
      case directoryActions.REQUEST_ROLE_ATTRIBUTIONS:
        roleManagementUi.roleAttributions.isFetching = true
        roleManagementUi.roleAttributions.resources = {}
        roleManagementUi.roleAttributions.error = initialState.ui.pages.roleManagement.roleAttributions.error
        break
      case directoryActions.RECEIVE_ROLE_ATTRIBUTIONS:
        const { roleAttributions, error } = action.payload
        const resourcesFetchingState: ResourcesFetchingState = {}
        Object.keys(roleAttributions.resources).forEach((resourceSnowflake: SnowflakeType) => {
          resourcesFetchingState[resourceSnowflake] = defaultRoleAttributionFetchingState
        })

        roleManagementUi.roleAttributions.resources = resourcesFetchingState
        roleManagementUi.roleAttributions.isFetching = false
        roleManagementUi.roleAttributions.error = error
          ? { hasError: true, message: error }
          : initialState.ui.pages.roleManagement.roleAttributions.error
        draft.roleAttributions = roleAttributions
        break
      case directoryActions.GRANT_TEAM_TO_RESOURCE:
        roleManagementUi.roleAttributions.resources[action.payload.resource].teams[action.payload.team] = {
          isFetching: true,
        }
        break
      case directoryActions.GRANT_TEAM_TO_RESOURCE_SUCCESS:
        roleManagementUi.roleAttributions.resources[action.payload.resource].teams[action.payload.team] = {
          isFetching: false,
        }
        draft.roleAttributions.resources[action.payload.resource].teams.push(action.payload.team)
        draft.roleAttributions.resources[action.payload.resource].allTeams = false
        break
      case directoryActions.REVOKE_ALL_TEAMS_TO_RESOURCE:
        roleManagementUi.roleAttributions.resources[action.payload.resource].isFetchingAllTeam = true
        break
      case directoryActions.REVOKE_ALL_TEAMS_TO_RESOURCE_SUCCESS:
        draft.roleAttributions.resources[action.payload.resource].teams = []
        draft.roleAttributions.resources[action.payload.resource].allTeams = false
        roleManagementUi.roleAttributions.resources[action.payload.resource].isFetchingAllTeam = false
        break
      case directoryActions.REVOKE_TEAM_TO_RESOURCE:
        roleManagementUi.roleAttributions.resources[action.payload.resource].teams[action.payload.team] = {
          isFetching: true,
        }
        break
      case directoryActions.SET_ROLE_TEAMS_TO_RESOURCE:
        draft.roleAttributions.resources[action.payload.resource].teams = action.payload.teams
        break
      case directoryActions.REVOKE_TEAM_TO_RESOURCE_SUCCESS:
        const roleAttribution = draft.roleAttributions.resources[action.payload.resource]
        draft.roleAttributions.resources[action.payload.resource] = {
          ...roleAttribution,
          teams: roleAttribution.teams.filter(e => e !== action.payload.team),
        }
        draft.roleAttributions.resources[action.payload.resource].allTeams = false
        roleManagementUi.roleAttributions.resources[action.payload.resource].teams[action.payload.team].isFetching =
          false
        break
      case directoryActions.GRANT_ALL_TEAMS_TO_RESOURCE:
        roleManagementUi.roleAttributions.resources[action.payload.resource].isFetchingAllTeam = true
        break
      case directoryActions.GRANT_ALL_TEAMS_TO_RESOURCE_SUCCESS:
        draft.roleAttributions.resources[action.payload.resource] = { teams: [], allTeams: true }
        roleManagementUi.roleAttributions.resources[action.payload.resource].isFetchingAllTeam = false
        break
      case directoryActions.REMOVE_RESOURCE_FROM_ROLE:
        roleManagementUi.roleAttributions.resources[action.payload.resource].isRemoving = true
        break
      case directoryActions.REMOVE_RESOURCE_FROM_ROLE_SUCCESS:
        delete roleManagementUi.roleAttributions.resources[action.payload.resource]
        delete draft.roleAttributions.resources[action.payload.resource]
        break
      case directoryActions.GRANT_ALL_TEAMS_ROLE_ATTRIBUTIONS:
        roleManagementUi.grantResourcesRolesPost.isPosting = true
        roleManagementUi.grantResourcesRolesPost.error =
          initialState.ui.pages.roleManagement.grantResourcesRolesPost.error
        break
      case directoryActions.GRANT_ROLE_ATTRIBUTIONS:
        roleManagementUi.grantResourcesRolesPost.isPosting = true
        roleManagementUi.grantResourcesRolesPost.error =
          initialState.ui.pages.roleManagement.grantResourcesRolesPost.error
        break
      case directoryActions.GRANT_ROLE_ATTRIBUTIONS_SUCCESS:
        roleManagementUi.grantResourcesRolesPost.isPosting = false
        break
      case directoryActions.GRANT_ROLE_ATTRIBUTIONS_FAILURE:
        roleManagementUi.grantResourcesRolesPost.isPosting = false
        roleManagementUi.grantResourcesRolesPost.error.hasError = true
        roleManagementUi.grantResourcesRolesPost.error.message = action.payload.error
        break
      case directoryActions.RESET_ERROR_GRANT_ROLE_ATTRIBUTIONS:
        roleManagementUi.grantResourcesRolesPost.error =
          initialState.ui.pages.roleManagement.grantResourcesRolesPost.error
        break
      case directoryActions.SAVE_RESOURCE_USERNAME:
        accountSettingsUi.changeUsername.isPosting = true
        accountSettingsUi.changeUsername.error = initialState.ui.pages.settings.accountSettings.changeUsername.error
        break
      case directoryActions.SAVE_RESOURCE_USERNAME_SUCCESS:
        accountSettingsUi.changeUsername.isPosting = false
        break
      case directoryActions.SAVE_RESOURCE_USERNAME_FAILURE:
        accountSettingsUi.changeUsername.isPosting = false
        accountSettingsUi.changeUsername.error.hasError = true
        accountSettingsUi.changeUsername.error.message = action.payload.error
        break

      case directoryActions.SAVE_RESOURCE_BALANCE_MODE:
        settingsUi.balanceMode.isPosting = true
        settingsUi.balanceMode.error = initialState.ui.pages.settings.balanceSettings.balanceMode.error
        break
      case directoryActions.SAVE_RESOURCE_BALANCE_MODE_SUCCESS:
        settingsUi.balanceMode.isPosting = false
        settingsUi.balanceMode.error = initialState.ui.pages.settings.balanceSettings.balanceMode.error
        break
      case directoryActions.SAVE_RESOURCE_BALANCE_MODE_FAILURE:
        settingsUi.balanceMode.isPosting = false
        settingsUi.balanceMode.error.hasError = true
        settingsUi.balanceMode.error.message = action.payload.error
        break

      case directoryActions.SAVE_RESOURCE_TIMECHECK_MODE:
        settingsUi.timecheckMode.isPosting = true
        settingsUi.timecheckMode.error = initialState.ui.pages.settings.balanceSettings.timecheckMode.error
        break
      case directoryActions.SAVE_RESOURCE_TIMECHECK_MODE_SUCCESS:
        settingsUi.timecheckMode.isPosting = false
        settingsUi.timecheckMode.error = initialState.ui.pages.settings.balanceSettings.timecheckMode.error
        break
      case directoryActions.SAVE_RESOURCE_TIMECHECK_MODE_FAILURE:
        settingsUi.timecheckMode.isPosting = false
        settingsUi.timecheckMode.error.hasError = true
        settingsUi.timecheckMode.error.message = action.payload.error
        break

      case directoryActions.SAVE_RESOURCE_WORK_DISPLAY_MODE:
        settingsUi.workDisplayMode.isPosting = true
        settingsUi.workDisplayMode.error = initialState.ui.pages.settings.balanceSettings.workDisplayMode.error
        break
      case directoryActions.SAVE_RESOURCE_WORK_DISPLAY_MODE_SUCCESS:
        settingsUi.workDisplayMode.isPosting = false
        settingsUi.workDisplayMode.error = initialState.ui.pages.settings.balanceSettings.workDisplayMode.error
        break
      case directoryActions.SAVE_RESOURCE_WORK_DISPLAY_MODE_FAILURE:
        settingsUi.workDisplayMode.isPosting = false
        settingsUi.workDisplayMode.error.hasError = true
        settingsUi.workDisplayMode.error.message = action.payload.error
        break

      case directoryActions.SAVE_MUST_CHANGE_PASSWORD:
        accountSettingsUi.forceChangePassword.isPosting = true
        accountSettingsUi.forceChangePassword.error =
          initialState.ui.pages.settings.accountSettings.forceChangePassword.error
        break
      case directoryActions.SAVE_MUST_CHANGE_PASSWORD_SUCCESS:
        accountSettingsUi.forceChangePassword.isPosting = false
        break
      case directoryActions.SAVE_MUST_CHANGE_PASSWORD_FAILURE:
        accountSettingsUi.forceChangePassword.isPosting = false
        accountSettingsUi.forceChangePassword.error.hasError = true
        accountSettingsUi.forceChangePassword.error.message = action.payload.error
        break
      case directoryActions.SEND_CREDENTIALS_LINK:
        accountSettingsUi.sendCredentialsLink.isPosting = true
        accountSettingsUi.sendCredentialsLink.error =
          initialState.ui.pages.settings.accountSettings.sendCredentialsLink.error
        break
      case directoryActions.SEND_CREDENTIALS_LINK_SUCCESS:
        accountSettingsUi.sendCredentialsLink.isPosting = false
        break
      case directoryActions.SEND_CREDENTIALS_LINK_FAILURE:
        accountSettingsUi.sendCredentialsLink.isPosting = false
        accountSettingsUi.sendCredentialsLink.error.hasError = true
        accountSettingsUi.sendCredentialsLink.error.message = action.payload.error
        break
      default:
        break
    }
  })
)
