import {
  ActivityState,
  ActivityTypes,
  CLOSE_ACTIVITIES,
  RECEIVE_ACTIVITIES,
  RECEIVE_ACTIVITY_RECENT_TAGS,
  REQUEST_ACTIVITY_RECENT_TAGS,
  REQUEST_OPENED_ACTIVITY,
  START_ACTIVITY,
  STOP_ACTIVITY,
} from 'activity/state/types'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { withCrudReducer } from 'crud/state/withCrudReducer'
import produce from 'immer'
import { compose } from 'redux'

const initialState: ActivityState = {
  activities: {},
  recentTags: [],
  ui: {
    activities: {
      activitiesFetching: {},
      isFetching: false,
    },
    recentTags: {
      isFetching: false,
    },
  },
}

export const activityReducer = compose(withCrudReducer<ActivityState, ActivityTypes>('tags'))(
  (currentState = initialState, action): ActivityState =>
    produce(currentState, draft => {
      switch (action.type) {
        case CLOSE_ACTIVITIES:
          Object.values(draft.activities).forEach(activity => {
            if (activity.period.endDate === null) {
              activity.period.endDate = action.payload.end
            }

            draft.activities[activity.id] = activity
            draft.ui.activities.activitiesFetching[activity.id] = false
          })

          draft.ui.activities.isFetching = false
          break
        case REQUEST_ACTIVITY_RECENT_TAGS:
          draft.ui.recentTags.isFetching = true
          break
        case RECEIVE_ACTIVITY_RECENT_TAGS:
          draft.recentTags = action.payload.tags
          draft.ui.recentTags.isFetching = false
          break
        case REQUEST_OPENED_ACTIVITY:
        case START_ACTIVITY:
        case STOP_ACTIVITY:
          draft.ui.activities.isFetching = true
          break
        case RECEIVE_ACTIVITIES:
          draft.activities = action.payload.activities.reduce((result, activity) => {
            draft.ui.activities.activitiesFetching[activity.id] = false
            return { ...result, [activity.id]: { ...activity, period: DeprecatedPeriod.fromString(activity.period) } }
          }, {})

          draft.ui.activities.isFetching = false
          break
        default:
          break
      }
    })
)
