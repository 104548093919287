import { activitySaga } from 'activity/state'
import { authenticationSaga } from 'authentication/state'
import { communicationSaga } from 'communication/state'
import { taskSaga } from 'communication/task/state'
import { routerMiddleware } from 'connected-react-router'
import { coreSaga } from 'core/state'
import { crudSaga } from 'crud/state'
import { directorySaga } from 'directory/state'
import { documentSaga } from 'document/state'
import { expensesSaga } from 'expenses/state'
import { createBrowserHistory } from 'history'
import { qualitySaga } from 'quality/state'
import { applyMiddleware, createStore } from 'redux'
// @ts-expect-error (nocheck)
import syncBreakpointWithStore from 'redux-breakpoint'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { scheduleSaga } from 'schedule/state'
import { createRootReducer } from 'store/createRootReducer'
import { tagSaga } from 'tag/state'
import { timeclockSaga } from 'timeclock/state'
import { all } from 'typed-redux-saga'

export const buildStore = (history: any, preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware(),
    createdStore = createStore(
      createRootReducer(history),
      preloadedState,
      composeWithDevTools({ trace: true, traceLimit: 25 })(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    )
  const sagas = [
    activitySaga(),
    tagSaga(),
    authenticationSaga(),
    coreSaga(),
    communicationSaga(),
    directorySaga(),
    qualitySaga(),
    scheduleSaga(),
    timeclockSaga(),
    crudSaga(),
    taskSaga(),
    expensesSaga(),
    documentSaga(),
    tagSaga(),
  ]

  sagaMiddleware.run(function* () {
    yield all(sagas)
  })

  syncBreakpointWithStore(createdStore)

  return createdStore
}

export const defaultHistory = createBrowserHistory()

export const store = buildStore(defaultHistory)
