import { CommunicationState, CommunicationTypes, RECEIVE_MESSAGES } from 'communication/state/types'
import produce from 'immer'

const initialState: CommunicationState = {
  messages: [],
  ui: {
    messages: {
      amount_unread: 0,
    },
  },
}

export const communicationReducer = (
  currentState: CommunicationState = initialState,
  action: CommunicationTypes
): CommunicationState =>
  produce(currentState, draft => {
    switch (action.type) {
      case RECEIVE_MESSAGES:
        draft.messages = action.payload.messages
        draft.ui.messages.amount_unread = action.payload.unreads
    }
  })
