import { getDirectoryKinds } from 'core/state/selectors'
import { SnowflakeType } from 'core/types'
import { subState } from 'directory/state/selectors/subState'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { StoreStateType } from 'store/types'
import { TranslatedString } from 'api'

export const isFetchingTeams = (state: StoreStateType) => subState(state).ui.teams.isFetching
export const getError = (state: StoreStateType) => subState(state).ui.pages.roleManagement.roleAttributions.error
export const getRoles = (state: StoreStateType) => subState(state).roles
export const getRole = (state: StoreStateType, roleSnowflake: SnowflakeType) => subState(state).roles[roleSnowflake]
export const getRoleAttributions = (state: StoreStateType) => subState(state).roleAttributions
export const getRoleAttributionUi = (state: StoreStateType, resourceSnowflake: SnowflakeType) =>
  subState(state).ui.pages.roleManagement.roleAttributions.resources[resourceSnowflake]
export const getAllRoleAttributionUi = (state: StoreStateType) =>
  subState(state).ui.pages.roleManagement.roleAttributions.resources
export const isFetchingRoles = (state: StoreStateType) => subState(state).ui.roles.isFetching
export const isFetchingRoleAttributions = (state: StoreStateType) =>
  subState(state).ui.pages.roleManagement.roleAttributions.isFetching
export const isPostingGrantResourcesRoles = (state: StoreStateType) =>
  subState(state).ui.pages.roleManagement.grantResourcesRolesPost.isPosting
export const grantResourcesRolesPostError = (state: StoreStateType) =>
  subState(state).ui.pages.roleManagement.grantResourcesRolesPost.error
export const getResourceRoleAttribution = (state: StoreStateType, resource: SnowflakeType) =>
  subState(state).roleAttributions.resources[resource]

export const getRolesAsOptions = (translate: (trString: TranslatedString) => string) =>
  createCachedSelector(getRoles, roles => {
    return lodash(roles)
      .map(role => ({
        label: translate(role.label),
        value: role.snowflake,
      }))
      .sortBy([role => role.label.toLowerCase(), 'asc'])
      .value()
  })((state: StoreStateType) => `roles_as_options`)

export const getRoleAttribution: (state: StoreStateType, resource: SnowflakeType, team: SnowflakeType) => any =
  createCachedSelector(
    (state: any, resource: SnowflakeType) => getResourceRoleAttribution(state, resource),
    (state, resource: SnowflakeType, team: SnowflakeType) => team,
    (roleAttribution, team) => {
      const teamActive =
        (team === '*' && roleAttribution.allTeams) || roleAttribution.teams.indexOf(team) > -1 ? true : false
      return { allTeams: roleAttribution.allTeams, teamActive }
    }
  )(
    (state, resourceSnowflake, teamSnowflake) =>
      `get_team_${teamSnowflake}_attribution_for_ressource_${resourceSnowflake}`
  )

export const isAnyRoleAttributionTeamLoading = createCachedSelector(
  (state: any, resourceSnowflake: SnowflakeType) => getRoleAttributionUi(state, resourceSnowflake),
  roleAttribution => {
    return !!lodash(roleAttribution.teams).find(team => team.isFetching === true)
  }
)(
  (state: StoreStateType, resourceSnowflake: SnowflakeType) =>
    `is_any_role_attribution_loading_for_resource_${resourceSnowflake}`
)

export const isRoleAttributionTeamLoading: (
  state: StoreStateType,
  resource: SnowflakeType,
  team: SnowflakeType
) => boolean = createCachedSelector(
  (state: any, resource: SnowflakeType) => getRoleAttributionUi(state, resource),
  (state, resource: SnowflakeType, team: SnowflakeType) => team,
  (roleAttribution, team) => {
    if (team === '*') return roleAttribution.isFetchingAllTeam
    return roleAttribution?.teams[team]?.isFetching || false
  }
)(
  (state, resourceSnowflake, teamSnowflake) =>
    `is_role_attribution_team_loading_for_resource_${teamSnowflake}_team_${resourceSnowflake}`
)

export const isRoleAttributionBlockingActionOngoing = createCachedSelector(
  (state: any, resourceSnowflake: SnowflakeType) => getRoleAttributionUi(state, resourceSnowflake),
  roleAttribution => {
    return roleAttribution.isRemoving || roleAttribution.isFetchingAllTeam
  }
)((state: StoreStateType, resourceSnowflake: SnowflakeType) => `is_role_attribution_busy_${resourceSnowflake}`)

export const isRemoveResourceBlocked = createCachedSelector(
  (state: any, resourceSnowflake: SnowflakeType) => getRoleAttributionUi(state, resourceSnowflake),
  isAnyRoleAttributionTeamLoading,
  (roleAttribution, isAnyRoleAttributionTeamLoading) => {
    return roleAttribution.isFetchingAllTeam || isAnyRoleAttributionTeamLoading
  }
)((state: StoreStateType, resourceSnowflake: SnowflakeType) => `is_remove_resource_blocked_${resourceSnowflake}`)

export const getDefaultEmployeeKindRole = createCachedSelector(getRoles, getDirectoryKinds, (roles, kinds) => {
  return lodash(roles).find(role => role.snowflake === kinds.employee)
})(
  (state: StoreStateType, resourceSnowflake: SnowflakeType) =>
    `is_role_attribution_blocking_action_ongoing_resource_${resourceSnowflake}`
)
