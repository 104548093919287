import {
  ADD_SORT_FIELD,
  CHANGE_PER_PAGE,
  CrudActionTypes,
  CrudSetup,
  CrudState,
  GO_TO_PAGE,
  INIT_CRUD,
  PAGE_RECEIVED,
  PERFORM_OPERATION,
  PERFORM_OPERATION_FAILED,
  PERFORM_OPERATION_SUCCESS,
  REFRESH_PAGE,
  UPDATE_FILTER,
} from 'crud/state/types'
import produce from 'immer'
import lodash from 'lodash'

export const emptyState: CrudSetup = {
  currentPage: 1,
  perPage: 25,
  totalPages: 1,
  total: 0,
  sortField: null,
  sortOrder: null,
  isProcessing: false,
  isFetchingEntity: false,
  errors: [],
  pages: {
    1: {
      ids: [],
      isFetching: false,
    },
  },
  filter: '',
}

const initialState = {}

export const crudReducer = (currentState: CrudState = initialState, action: CrudActionTypes): CrudState =>
  produce(currentState, draft => {
    switch (action.type) {
      case INIT_CRUD: {
        const { entityName } = action.payload
        draft[entityName] = emptyState
        break
      }

      case UPDATE_FILTER: {
        const {
          meta: { entityName },
          payload: { filter },
        } = action
        draft[entityName].filter = filter
        break
      }

      case GO_TO_PAGE: {
        const {
          payload: { page },
          meta: { entityName },
        } = action
        const { currentPage, totalPages } = currentState[entityName]

        if (!(page > totalPages || page < 1 || page === currentPage)) {
          draft[entityName].currentPage = page
          draft[entityName].pages[page] = {
            ids: [],
            isFetching: true,
          }
        }
        break
      }

      case REFRESH_PAGE: {
        const {
          meta: { entityName },
        } = action
        const { currentPage } = currentState[entityName]

        draft[entityName].pages[currentPage] = {
          ids: [],
          isFetching: true,
        }
        break
      }

      case CHANGE_PER_PAGE: {
        const {
          meta: { entityName },
          payload: { perPage },
        } = action
        const { currentPage } = currentState[entityName]

        draft[entityName].perPage = perPage
        draft[entityName].pages[currentPage].isFetching = true
        break
      }

      case ADD_SORT_FIELD: {
        const {
          meta: { entityName },
          payload: { field, order },
        } = action

        draft[entityName].currentPage = 1
        draft[entityName].sortField = field
        draft[entityName].sortOrder = order
        draft[entityName].pages[1].isFetching = true

        break
      }

      case PAGE_RECEIVED: {
        const {
          meta: { entityName },
          payload: { entities, total },
        } = action
        const { currentPage, perPage } = currentState[entityName]

        draft[entityName].total = total
        draft[entityName].totalPages = Math.max(1, Math.ceil(total / perPage))
        draft[entityName].pages[currentPage].isFetching = false
        draft[entityName].pages[currentPage].ids = lodash.map(entities, entity => entity.id)

        break
      }

      case PERFORM_OPERATION: {
        const {
          meta: { entityName },
          payload: { operation },
        } = action

        draft[entityName].isProcessing = true
        draft[entityName].errors = []
        if (operation === 'view') {
          draft[entityName].isFetchingEntity = true
        }

        break
      }

      case PERFORM_OPERATION_SUCCESS:
        const {
          meta: { entityName },
          payload: { operation },
        } = action

        draft[entityName].isProcessing = false
        draft[entityName].errors = []
        if (operation === 'view') {
          draft[entityName].isFetchingEntity = false
        }

        break

      case PERFORM_OPERATION_FAILED: {
        const {
          meta: { entityName },
          payload: { errors, operation },
        } = action

        draft[entityName].isProcessing = false
        draft[entityName].errors = errors
        if (operation === 'view') {
          draft[entityName].isFetchingEntity = false
        }
      }
    }
  })
