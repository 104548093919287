import { savePreference } from 'authentication/state/actions'
import { getPreference } from 'authentication/state/selectors'
import { flushLoader } from 'core/state/actions'
import { isMobileLayout, isSidebarOpen } from 'core/state/selectors'
import { THROW_ERROR, TOGGLE_SIDEBAR, ThrowErrorAction } from 'core/state/types'
import { put, select, takeEvery, takeLatest } from 'typed-redux-saga'

function* updateSidebarPreference() {
  const sidebarOpen = yield* select(isSidebarOpen)
  const mobile = yield* select(isMobileLayout)
  const preference = yield* select(getPreference, 'side-menu')
  const newValue = sidebarOpen ? 'site-menubar-unfold' : 'site-menubar-fold'

  if (newValue !== preference && !mobile) {
    yield* put(savePreference('side-menu', newValue))
  }
}

function* handleError({ payload: { error: e } }: ThrowErrorAction) {
  /* istanbul ignore next */
  if (process.env.JEST_WORKER_ID === undefined) {
    // eslint-disable-next-line
    console.error(e)
  }

  // Safari likes to abort XHR requests when it loads a new page
  // What a dumbass...
  /* istanbul ignore else */
  if (!e || e.message !== 'Network Error') {
    yield* put(flushLoader())
  }
}

export const coreSaga = function* coreSaga() {
  yield* takeLatest(THROW_ERROR, handleError)
  yield* takeEvery(TOGGLE_SIDEBAR, updateSidebarPreference)
}
