import { brain } from 'core/services/api'

export const sitesService = {
  getList: async (resource: any, action: any) => {
    const request = await brain.get('/sites/sectors', {
      params: {
        resource,
        action,
        all: !resource && !action,
      },
    })

    return request.data
  },
}
