import { ReactElement, useMemo } from 'react'
import { TagChip, TagChipProps } from 'tag/components/tagChip/TagChip'
import * as S from 'ui/dataDisplay/tagCloud/styled'
import { translations as T } from 'tag/i18n'
import { TagShallow } from 'tag/types'
import { sortAlphabetically } from 'utils/sorting'

type Props = {
  tags: TagShallow[]
  disabledTags?: TagShallow[]
  noTagsMessage?: string
  hideNoTagsMessage?: boolean
  className?: string
  tagComponent?: (props: TagChipProps, tag: TagShallow) => ReactElement<TagChipProps>
}

/**
 * Choose where to put this in our designe system. Beware of overlapping with MUI badge name
 * On  migre ces tags en chips MUI à l'occasion
 */
export const TagCloud = ({
  tags,
  disabledTags = [],
  noTagsMessage = T.tagsEmpty,
  hideNoTagsMessage = false,
  className = '',
  tagComponent = (props: TagChipProps, tag: TagShallow) => <TagChip {...props} key={`tagchip-${tag.snowflake}`} />,
}: Props) => {
  const sortedTags = useMemo(() => sortAlphabetically(tags, 'name'), [tags])
  const sortedDisabledTags = useMemo(() => sortAlphabetically(disabledTags, 'name'), [disabledTags])
  const noTagsToDisplay = useMemo(() => tags.length === 0, [tags])

  if (noTagsToDisplay) {
    return hideNoTagsMessage ? null : (
      <S.NoTagsMessage id={noTagsMessage} className={`tags-cloud-container ${className}`} />
    )
  }

  return (
    <div className={`tags-cloud-container ${className}`}>
      <div className="tags-cloud">
        {sortedTags.map(tag => tagComponent({ label: tag.name, disabled: false }, tag))}
        {sortedDisabledTags.map(tag => tagComponent({ label: tag.name, disabled: true }, tag))}
      </div>
    </div>
  )
}
