import { parseDateTime } from 'chronos'
import { areIntervalsOverlapping, format, isBefore } from 'date-fns'
import { getPersonFactory } from 'directory/state/selectors'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { getTags } from 'tag/state/selectors'
import { TagType } from 'tag/types'
import { getTranslatedStringUtils } from 'ui/inputs/tradfield/context/MultilingualContext'
import { charmap } from 'utils/charmap'

const YYYYMMDD = 'yyyy-MM-dd'

const subState = (state: any) => state.timeclock

export const getPresenceFilter = (state: any) => subState(state).ui.presences.filter
export const getPresences = (state: any) => subState(state).presences
export const getLastTimecheck = (state: any) => {
  const timecheck = subState(state).lastTimecheck
  let timecheckFormatted = {
    isOut: true,
    timecheck: '',
  }
  if (timecheck) {
    timecheckFormatted = {
      ...timecheckFormatted,
      isOut: !!(
        timecheck.time_out ||
        !(timecheck.proposal_in || timecheck.time_in || timecheck.validation_in || timecheck.adjusted_in)
      ),
      // @ts-expect-error (nocheck)
      proposal_in: timecheck.proposal_in,
      adjusted_in: timecheck.adjusted_in,
      adjusted_in_source: timecheck.adjusted_in_source,
      time_in: timecheck.time_in,
      time_out: timecheck.time_out,
      timecheck: timecheck.proposal_in || timecheck.validation_in || timecheck.adjusted_in || timecheck.time_in || '',
      validation_in: timecheck.validation_in,
      snowflake: timecheck.snowflake,
      can_edit_tags: !!timecheck.can_edit_tags,
      remark: timecheck.tags_comment,
      timecheckTags: timecheck.timecheckTags,
    }
  }

  return timecheckFormatted
}
export const getLastTimecheckTags = (state: any) => subState(state).lastTimecheck.timecheckTags

export const getActiveLastTimecheckTagsSnowflake = createCachedSelector(getLastTimecheckTags, lastTimecheckTags => {
  return lastTimecheckTags
    ? lastTimecheckTags.filter((e: any) => e.deleted_at === null && e.deleted_by === null).map((e: any) => e.tag_id)
    : []
})(state => 'active_timecheck_tags_snowflake' + JSON.stringify(subState(state).lastTimecheck.snowflake))

export const getActiveLastTimecheckTags = createCachedSelector(
  getTags,
  getActiveLastTimecheckTagsSnowflake,
  (tags, activeTagsSnowflake) => {
    if (tags && activeTagsSnowflake) {
      const tagsdetail = [] as any[]
      // ugly fix for the case where the tag name is a translateed string
      // becaus of the difficulty to translate the tag name in the backend
      activeTagsSnowflake.forEach((snowflake: any) => {
        if (tags[snowflake])
          tagsdetail.push({
            ...tags[snowflake],
            name:
              typeof tags[snowflake].name === 'string'
                ? tags[snowflake].name
                : getTranslatedStringUtils().translate(tags[snowflake].name),
          })
      })
      return tagsdetail
    }
    return []
  }
)((state, personId) => 'active_LastTimecheck_Tags_' + JSON.stringify(personId))

export const getLastTimecheckTagsAsOption = createCachedSelector(
  getTags,
  getLastTimecheckTags,
  (tags, lastTimecheckTags) => {
    if (tags && lastTimecheckTags) {
      const lastTimecheckTagsSnowflake = lastTimecheckTags.map((e: any) => e.tag_id)
      const tagsFiltered = Object.values(tags)
        .filter(e => lastTimecheckTagsSnowflake.indexOf(e.snowflake) > -1)
        .map((tag: TagType) => {
          if (typeof tag.name === 'string') return tag
          const name = getTranslatedStringUtils().translate(tag.name)
          return { ...tag, name }
        })

      return tagsFiltered
        ? tagsFiltered
            .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
            .reduce((all: any, current: any) => {
              return [
                ...all,
                {
                  label: current.name,
                  value: current.id.toString(),
                  snowflake: current.snowflake,
                },
              ]
            }, [])
        : []
    }
    return []
  }
)((state, personId) => 'last_timecheck_tags_as_option' + JSON.stringify(personId))

export const isActiveTagMustBeValidated = (state: any) =>
  subState(state).ui.lastTimecheck.activeTimecheckTag.requestValidation
export const isFetchingChangeActiveTag = (state: any) => subState(state).ui.changeActiveTimecheckTag.isFetching
export const getListTimecheckForRange = (state: any) => subState(state).listTimecheckForRange
export const getListTimecheckForDate = (state: any) => subState(state).listTimecheckForDate
export const getPresenceList = createSelector(
  getPresenceFilter,
  getPresences,
  getPersonFactory,
  (presenceFilter, presences, getPerson) => {
    const filter = charmap(presenceFilter)
    return Object.values(presences)
      .map((presence: any) => {
        const person = getPerson(presence.person_id)
        return {
          person: {
            id: person.id,
            full_name: person.general.full_name,
            picture: person.general.picture,
          },
          ...presence,
        }
      })
      .filter(presence => presence.person.full_name.match(filter))
      .sort((a, b) => a.person.full_name.localeCompare(b.person.full_name))
  }
)
export const isFetchingLastTimecheck = (state: any) => subState(state).ui.lastTimecheck.isFetching
export const isFetchingListTimecheckForRange = (state: any) => subState(state).ui.listTimecheckForRange.isFetching
export const isForbiddenListTimecheckForRange = (state: any) => subState(state).ui.listTimecheckForRange.isForbidden
export const isFetchingTimecheckRangeValidation = (state: any) =>
  subState(state).ui.listTimecheckForRange.isFetchingValidation
export const isFetchingListTimecheckForDate = (state: any) => subState(state).ui.listTimecheckForDate.isFetching
export const isForbiddenListTimecheckForDate = (state: any) => subState(state).ui.listTimecheckForDate.isForbidden
export const isFetchingPresence = (state: any) => subState(state).ui.presences.isFetching
export const isPostingTimecheckTag = (state: any) => subState(state).ui.timecheckModal.timecheckTags.isPosting

export const getTimechecksForRange = createCachedSelector(
  state => subState(state).timechecks,
  (state: any, start: any, end: any) => start,
  (state: any, start: any, end: any) => end,
  (timechecks, start, end) => {
    const comparator = (period: any) => {
      const date_in = parseDateTime(period.date_in, period.hour_in)
      let date_out = period.date_out ? parseDateTime(period.date_out, period.hour_out) : null

      // If a date out is before date in, date out must be equal to date_in
      if (
        isBefore(
          // @ts-expect-error (nocheck)
          date_out,
          date_in
        )
      ) {
        date_out = date_in
      }

      return !date_out || areIntervalsOverlapping({ start: date_in, end: date_out }, { start, end })
    }
    return lodash.filter(timechecks, comparator)
  }
)((state, start, end) =>
  JSON.stringify({
    start: format(start, YYYYMMDD),
    end: format(end, YYYYMMDD),
  })
)
export const getPreferencesDateRange = (state: any) => {
  return {
    startDate: state.authentication.preferences.timeclock_weekly_startdate,
    endDate: state.authentication.preferences.timeclock_weekly_enddate,
  }
}
export const getActiveDate = (state: any) => state.authentication.preferences.active_date
