import produce from 'immer'
import { QualityState, QualityTypes, RECEIVE_BOOKMARKS, REQUEST_BOOKMARKS } from 'quality/state/types'

const initialState: QualityState = {
  bookmarks: [],
  ui: {
    bookmarks: {
      isFetching: false,
    },
  },
}

export const qualityReducer = (currentState: QualityState = initialState, action: QualityTypes): QualityState =>
  produce(currentState, draft => {
    switch (action.type) {
      case REQUEST_BOOKMARKS:
        draft.ui.bookmarks.isFetching = true
        break
      case RECEIVE_BOOKMARKS:
        draft.bookmarks = action.payload.bookmarks
        draft.ui.bookmarks.isFetching = false
        break
    }
  })
