/* do NOT import getIntl from core/i18n directy, to avoid circular dependency */
import { getIntl } from 'core/i18n/components/I18nProvider'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import frLocale from 'date-fns/locale/fr'
import { TranslationKey } from 'i18nKeys'

export const firstUpper = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getDateFnsLocale = (language: string) => {
  switch (language) {
    case 'fr':
      return frLocale
    case 'de':
      return deLocale
    default:
      return enLocale
  }
}

export const t = (
  translationKey: TranslationKey,
  values?: Record<string, string | number>,
  options?: {
    /** # ⚠️ The usage of this property is subject to change, avoid it if possible ⚠️ */
    capitalize: boolean
  }
) => {
  const opt = { capitalize: true, ...options }
  const intl = getIntl()

  let translated = intl.formatMessage({ id: translationKey }, values, { ignoreTag: true })
  if (opt.capitalize) {
    translated = firstUpper(translated)
  }

  return translated
}

t.html = (...params: Parameters<typeof t>) => <span dangerouslySetInnerHTML={{ __html: t(...params) }} />
