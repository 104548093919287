import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { fetchBlobUrl } from 'core/utils'
import { requestPerson } from 'directory/state/actions'
import { getPerson } from 'directory/state/selectors'
import { PersonType } from 'directory/types'
import { documentService } from 'document/services/document'
import {
  addFilesToImport,
  receiveChildrenDocuments,
  receiveFilesMatch,
  receiveParentsDocuments,
  receivePostFile,
  receiveRootFolder,
  requestChildrenDocuments as requestChildrenDocumentsAction,
  resetErroredFilesToMatched,
  setFileIsDuplicate,
  setFilesAreUploading,
  startFilesMatch as startFilesMatchAction,
  startImport as actionStartImport,
} from 'document/state/actions'
import {
  getImportConfig,
  getImportFilesMatchedStateForRole,
  getImportMatchParameters,
  getRootFolder,
} from 'document/state/selectors'
import {
  ImportFileAction,
  IMPORT_FILE,
  MatchFilesResponse,
  RequestChildrenDocumentsAction,
  RequestFilesMatchAction,
  RequestParentsDocumentsAction,
  RequestRootFolderAction,
  REQUEST_CHILDREN_DOCUMENTS,
  REQUEST_FILES_MATCH,
  REQUEST_PARENTS_DOCUMENTS,
  REQUEST_ROOT_FOLDER,
  RetryFailedImportsAction,
  RETRY_FAILED_IMPORTS,
  StartImportAction,
  START_FILES_MATCH,
  START_IMPORT,
} from 'document/state/types'
import { Document, FILE_STATES } from 'document/types'
import { StoreStateType } from 'store/types'
import { all, put, select, takeEvery, takeLatest } from 'typed-redux-saga'

function* requestChildrenDocuments(action: RequestChildrenDocumentsAction) {
  try {
    const { role, id } = action.payload
    const documents: Document[] = yield* callApi(documentService.getChildrenDocuments, id)
    yield* put(receiveChildrenDocuments(role, id, documents))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestParentsDocuments(action: RequestParentsDocumentsAction) {
  try {
    const { role, id } = action.payload
    const documents: Document[] = yield* callApi(documentService.getParentsDocuments, id)
    const currentDocument = documents.find((document: Document) => document.id === id)
    yield* put(receiveParentsDocuments(role, documents))
    if (currentDocument?.parentFolderId) {
      yield* put(requestChildrenDocumentsAction(role, currentDocument.parentFolderId))
    }
  } catch (e: any) {
    if (e?.response?.status !== 404) {
      yield* put(throwError(e))
    }
  }
}

function* requestRootFolder(action: RequestRootFolderAction) {
  try {
    const { role } = action.payload
    const document: Document = yield* callApi(documentService.getRootFolder, role)
    yield* put(receiveRootFolder(role, document))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestPostFile(action: ImportFileAction) {
  const { role, file, formData } = action.payload
  try {
    const fileObject: File = yield fetchBlobUrl(file.blobUrl, file.name)
    formData.append('file', fileObject)
    yield* callApi(documentService.postFile, formData)
    yield* put(receivePostFile(role, file.id, FILE_STATES.success))
  } catch (e: any) {
    yield* put(receivePostFile(role, file.id, FILE_STATES.error))
  }
}

function* requestFilesMatch(action: RequestFilesMatchAction) {
  try {
    const { role, files } = action.payload
    yield* put(addFilesToImport(role, files))
    yield* put(startFilesMatchAction(role))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* startFilesMatch(action: RequestFilesMatchAction) {
  try {
    const { role } = action.payload
    const postData = yield* select((s: StoreStateType) => getImportMatchParameters(s, role))
    if (!postData) return
    const uniqueFileNames: string[] = []
    const duplicateFileIds: string[] = []
    postData.files = postData.files.filter(file => {
      if (uniqueFileNames.find(item => item === file.name)) {
        duplicateFileIds.push(file.id)
        return false
      } else {
        uniqueFileNames.push(file.name)
        return true
      }
    })
    yield* all(
      duplicateFileIds.map(fileId => {
        return put(setFileIsDuplicate(role, fileId))
      })
    )

    if (postData.files.length > 0) {
      const response: MatchFilesResponse = yield* callApi(documentService.matchFilesForEmployee, postData)
      yield* all(
        response.data.matches.map(match => {
          return put(requestPerson(match.snowflake))
        })
      )
      yield* put(receiveFilesMatch(role, response.data))
    }
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* startImport(action: StartImportAction) {
  try {
    const { role } = action.payload
    const importConfig = yield* select((s: StoreStateType) => getImportConfig(s))
    const rootFolder = yield* select((s: StoreStateType) => getRootFolder(s, role))
    const matchedFiles = yield* select((s: StoreStateType) => getImportFilesMatchedStateForRole(s, role))

    const matchedFilesPersons: PersonType[] = yield* all(
      matchedFiles.map(file => {
        return select((s: StoreStateType) => getPerson(s, file.personSnowflake))
      })
    )

    yield* put(setFilesAreUploading(role, matchedFiles))

    let i = 0
    for (const file of matchedFiles) {
      const formData = new FormData()
      if (importConfig) {
        formData.append('parent', importConfig.folderId.toString())
        formData.append('visible', importConfig.isVisible ? '1' : '0')
        formData.append('send_notification', importConfig.sendNotification ? '1' : '0')
      }
      if (rootFolder) {
        formData.append('scope_id', rootFolder.scopeId.toString())
      }
      formData.append('person_id', matchedFilesPersons[i].id.toString())
      yield* requestPostFile({ type: IMPORT_FILE, payload: { role, file, formData } })
      i++
    }
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* retryFailedImports(action: RetryFailedImportsAction) {
  try {
    const { role } = action.payload
    yield* put(resetErroredFilesToMatched(role))
    yield* put(actionStartImport(role))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export const documentSaga = function* documentSaga() {
  yield* takeEvery(REQUEST_CHILDREN_DOCUMENTS, requestChildrenDocuments)
  yield* takeEvery(REQUEST_PARENTS_DOCUMENTS, requestParentsDocuments)
  yield* takeLatest(REQUEST_ROOT_FOLDER, requestRootFolder)
  yield* takeEvery(IMPORT_FILE, requestPostFile)
  yield* takeEvery(START_FILES_MATCH, startFilesMatch)
  yield* takeEvery(REQUEST_FILES_MATCH, requestFilesMatch)
  yield* takeEvery(RETRY_FAILED_IMPORTS, retryFailedImports)
  yield* takeEvery(START_IMPORT, startImport)
}
