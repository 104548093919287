import { SEPARATOR, URL_SEPARATOR } from 'chronos/interval/parseInterval'

export interface IntervalComponent {
  isBefore(other: this): boolean
  isEqual(other: this): boolean
  isAfter(other: this): boolean
}

export class Interval<T extends IntervalComponent> {
  start: T | null
  end: T | null

  constructor(start: T | null, end: T | null) {
    if (start && end && end.isBefore(start)) {
      throw new Error('Start must be before end')
    }

    this.start = start
    this.end = end
  }

  toURLString() {
    return `${this.start || '-'}${URL_SEPARATOR}${this.end || '-'}`
  }

  toString() {
    return `${this.start || '-'}${SEPARATOR}${this.end || '-'}`
  }

  toJSON() {
    return this.toString()
  }

  hasStart(): this is typeof this & { start: Exclude<T, null> } {
    return this.start !== null
  }

  hasEnd(): this is typeof this & { end: Exclude<T, null> } {
    return this.end !== null
  }

  hasInfiniteStart(): this is typeof this & { start: null } {
    return this.start === null
  }

  hasInfiniteEnd(): this is typeof this & { end: null } {
    return this.end === null
  }

  isFinite(): this is typeof this & { start: Exclude<T, null>; end: Exclude<T, null> } {
    return this.hasStart() && this.hasEnd()
  }

  getFinite() {
    if (!this.isFinite()) throw new Error('Interval is not finite')
    return this
  }

  isInfinite() {
    return !this.isFinite()
  }

  isBoundless(): this is typeof this & { start: null; end: null } {
    return !this.hasStart() && !this.hasEnd()
  }

  hasAtLeastOneBound(): this is
    | (typeof this & { start: Exclude<T, null> })
    | (typeof this & { end: Exclude<T, null> }) {
    return this.hasStart() || this.hasEnd()
  }

  getFiniteEnd() {
    if (!this.end) throw new Error('Interval end is not finite')
    return this.end
  }

  getFiniteStart() {
    if (!this.start) throw new Error('Interval start is not finite')
    return this.start
  }

  forWeek() {
    throw new Error('not implemented')
  }

  expand() {
    throw new Error('not implemented')
  }

  toFullWeeks() {
    throw new Error('not implemented')
  }

  toTimeInterval() {
    throw new Error('not implemented')
  }

  getLengthInDays() {
    throw new Error('not implemented')
  }

  getPediod() {
    throw new Error('not implemented')
  }

  move(amount: any): Interval<T> {
    throw new Error('not implemented')
  }

  iterateDaily() {
    throw new Error('not implemented')
  }

  iterate() {
    throw new Error('not implemented')
  }

  days() {
    throw new Error('not implemented')
  }

  getStart() {
    return this.start
  }

  getEnd() {
    return this.end
  }

  getStartIso() {
    throw new Error('not implemented')
  }

  withStart(start: T | null) {
    return new Interval(start, this.end)
  }

  withEnd(end: T | null) {
    return new Interval(this.start, end)
  }

  isBefore(other: Interval<T>) {
    if (this.end === null) return false

    return this.precedes(other) || this.meets(other)
  }

  isAfter(other: Interval<T>) {
    if (this.start === null) return false

    return this.precededBy(other) || this.metBy(other)
  }

  /**
   * Allen's interval relation "equals".
   */
  equals(other: Interval<T>): boolean {
    return (
      (this.start === null ? other.start === null : other.start !== null && this.start.isEqual(other.start)) &&
      (this.end === null ? other.end === null : other.end !== null && this.end.isEqual(other.end))
    )
  }

  /**
   * Allen's interval relation "precedes".
   */
  precedes(other: Interval<T>) {
    return this.end !== null && other.start !== null && this.end.isBefore(other.start)
  }

  /**
   * Allen's interval relation "precededBy".
   */
  precededBy(other: Interval<T>) {
    return other.precedes(this)
  }

  /**
   * Allen's interval relation "meets".
   */
  meets(other: Interval<T>) {
    return (
      this.end !== null &&
      other.start !== null &&
      this.end.isEqual(other.start) &&
      (this.start === null || this.start.isBefore(other.start))
    )
  }

  /**
   * Allen's interval relation "metBy".
   */
  metBy(other: Interval<T>) {
    return other.meets(this)
  }

  /**
   * Allen's interval relation "finishes".
   */
  finishes(other: Interval<T>) {
    return (
      this.start !== null &&
      (other.start === null || this.start.isAfter(other.start)) &&
      (this.end === null
        ? other.end === null
        : other.end !== null && this.end.isEqual(other.end) && !this.start.isEqual(this.end))
    )
  }

  /**
   * Allen's interval relation "finishedBy".
   */
  finishedBy(other: Interval<T>) {
    return other.finishes(this)
  }

  /**
   * Allen's interval relation "starts".
   */
  starts(other: Interval<T>) {
    return (
      this.end !== null &&
      (other.end === null || this.end.isBefore(other.end)) &&
      (this.start === null ? other.start === null : other.start !== null && this.start.isEqual(other.start))
    )
  }

  /**
   * Allen's interval relation "startedBy".
   */
  startedBy(other: Interval<T>) {
    return other.starts(this)
  }

  /**
   * Allen's interval relation "overlaps".
   *
   * ⚠️ This is rarely what you want, use `intersects` method instead.
   * @param other
   * @returns true if the intervals are overlapping.
   */
  overlaps(other: Interval<T>): boolean {
    return (
      other.start !== null &&
      (this.start === null || this.start.isBefore(other.start)) &&
      this.end !== null &&
      (other.end === null || this.end.isBefore(other.end)) &&
      this.end.isAfter(other.start)
    )
  }

  /**
   * Allen's interval relation "overlappedBy".
   *
   * ⚠️ This is rarely what you want, use `intersects` method instead.
   */
  overlappedBy(other: Interval<T>) {
    return other.overlaps(this)
  }

  /**
   * Allen's interval relation "contains".
   */
  encloses(other: Interval<T>): boolean {
    return (
      other.start !== null &&
      (this.start === null || this.start.isBefore(other.start)) &&
      other.end !== null &&
      (this.end === null || this.end.isAfter(other.end))
    )
  }

  /**
   * Allen's interval relation "during".
   */
  enclosedBy(other: Interval<T>): boolean {
    return other.encloses(this)
  }

  /**
   * Queries whether an interval intersects another interval.
   * An interval intersects if its neither before nor after the other.
   *
   * This method is commutative (A intersects B if and only if B intersects A).
   */
  intersects(other: Interval<T>) {
    return !(this.precedes(other) || this.precededBy(other) || this.meets(other) || this.metBy(other))
  }

  /**
   * Queries whether an interval contains another interval.
   * One interval contains another if it stays within its bounds.
   * An empty interval never contains anything.
   */
  contains(other: Interval<T>) {
    return this.encloses(other) || this.equals(other) || this.startedBy(other) || this.finishedBy(other)
  }

  containsItem(item: T) {
    if (this.start === null && this.end === null) return true
    if (this.start === null && this.end !== null) return this.end.isAfter(item) || this.end.isEqual(item)
    if (this.start !== null && this.end === null) return this.start.isBefore(item) || this.start.isEqual(item)
    if (this.start !== null && this.end !== null)
      return (
        (this.start.isBefore(item) || this.start.isEqual(item)) && (this.end.isAfter(item) || this.end.isEqual(item))
      )
  }

  findIntersection() {
    throw new Error('not implemented')
  }

  abuts() {
    throw new Error('not implemented')
  }
}
