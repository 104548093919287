export type StepKey = 'config' | 'prepare' | 'import'

type ImportStepsRef = {
  [K in StepKey]: StepKey
}

export type ImportStepPaths = {
  [K in StepKey]: string
}

export const IMPORT_STEPS: ImportStepsRef = {
  config: 'config',
  prepare: 'prepare',
  import: 'import',
}
