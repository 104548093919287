import { isAllowed } from 'authentication/state/selectors'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { SnowflakeType } from 'core/types'
import { fetchTeamList } from 'directory/state/sagas'
import { getTeamsSnowflakesAsArray } from 'directory/state/selectors/team'
import { translations as T } from 'expenses/pages/expensesExport/i18n'
import { expenseService } from 'expenses/services/expense'
import { expenseReportService } from 'expenses/services/expenseReport'
import { expenseTypeService } from 'expenses/services/expenseType'
import {
  confirmDeleteExpenseReport,
  receivedExpensesExport,
  receiveExpense,
  receiveExpenseReportDetails,
  receiveExpenseReportSummary,
  receiveExpenseReportToValidateCount,
  receiveExpenses,
  receiveExpenseTypesEnabled,
  receiveRecentExpensesTags,
  requestExpenseReportDetails,
  requestExpenseReportToValidateCount,
  requestExpensesByExpenseReportId,
  saveExpenseSucceeded,
} from 'expenses/state/actions'
import {
  DELETE_EXPENSE,
  DELETE_EXPENSE_REPORT,
  DeleteExpenseAction,
  DeleteExpenseReportAction,
  ExpenseReportResponseType,
  PREVALIDATE_EXPENSE_REPORT,
  PrevalidateExpenseReport,
  REFUSE_EXPENSE_REPORT,
  RefuseExpenseReport,
  RENAME_EXPENSE_REPORT,
  RenameExpenseReportAction,
  REQUEST_EXPENSE,
  REQUEST_EXPENSE_REPORT_DETAILS,
  REQUEST_EXPENSE_REPORT_SUMMARY,
  REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT,
  REQUEST_EXPENSE_TYPES_ENABLED,
  REQUEST_EXPENSES,
  REQUEST_EXPENSES_EXPORT,
  REQUEST_RECENT_EXPENSES_TAGS,
  RequestExpenseAction,
  RequestExpenseReportDetailsAction,
  RequestExpenseReportSummaryAction,
  RequestExpenseReportToValidateCount,
  RequestExpensesAction,
  RequestExpensesExport,
  SAVE_EXPENSE,
  SaveExpenseAction,
  SEND_EXPENSE_REPORT_TO_VALIDATION,
  SendExpenseReportToValidation,
  VALIDATE_EXPENSE_REPORT,
  ValidateExpenseReport,
} from 'expenses/state/types'
import { ExpenseType, ExpenseTypeType } from 'expenses/types'
import { requestTagsList } from 'tag/state/actions'
import { all, put, select, takeEvery, takeLatest } from 'typed-redux-saga'
import { pushToast } from 'utils/pushToast'

function* requestExpenseReportSummaries(action: RequestExpenseReportSummaryAction) {
  try {
    const expenseReports: ExpenseReportResponseType[] = yield* callApi(
      expenseReportService.getExpenseReports,
      action.payload.type,
      action.payload.group,
      action.payload.authorId,
      action.payload.validatorId
    )
    yield* put(receiveExpenseReportSummary(action.payload.type, action.payload.group, expenseReports))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestExpenseTypesEnabled() {
  try {
    const expenseTypes: ExpenseTypeType[] = yield* callApi(expenseTypeService.getExpenseTypes)
    yield* put(receiveExpenseTypesEnabled(expenseTypes))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestExpenseReport(action: RequestExpenseReportDetailsAction) {
  const { id } = action.payload
  try {
    const expenseReport: ExpenseReportResponseType = yield* callApi(expenseReportService.getExpenseReport, id)
    yield* put(receiveExpenseReportDetails(expenseReport))
    yield* put(requestExpensesByExpenseReportId(expenseReport.id))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* renameExpenseReport(action: RenameExpenseReportAction) {
  try {
    const { id, label } = action.payload

    yield* callApi(expenseReportService.renameExpenseReport, id, label)
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestExpenses(action: RequestExpensesAction) {
  try {
    const { expenseReportId } = action.payload

    const expenses: ExpenseType[] = yield* callApi(expenseService.getExpenses, expenseReportId)
    yield* put(receiveExpenses(expenses))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestExpense(action: RequestExpenseAction) {
  try {
    const { expenseId } = action.payload

    const expense: ExpenseType = yield* callApi(expenseService.getExpense, expenseId)
    yield* put(receiveExpense(expense))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* saveExpense(action: SaveExpenseAction) {
  const { attachmentsToAdd, attachmentsToRemove, ...expenseSaved } = action.payload.expense
  try {
    const id = yield* callApi(expenseService.save, action.payload.expense)
    const { expense_type_id, tags, ...expense } = {
      ...expenseSaved,
      id: id,
      value: expenseSaved.amount || null,
      type_id: expenseSaved.expense_type_id || null,
      tag_ids: expenseSaved.tags,
      deletable: true,
      updatable: true,
    }

    yield* all(attachmentsToAdd.map(file => callApi(expenseService.createAttachment, id, file)))
    yield* all(attachmentsToRemove.map(attachment => callApi(expenseService.removeAttachment, id, attachment)))

    yield* put(receiveExpense(expense))
    yield* put(saveExpenseSucceeded(expense.id))
    yield* put(requestExpenseReportDetails(expenseSaved.expense_report_id))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* deleteExpense(action: DeleteExpenseAction) {
  const { expenseId, expenseReportId } = action.payload
  try {
    yield* callApi(expenseService.delete, expenseId)
    yield* put(requestExpenseReportDetails(expenseReportId))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* deleteExpenseReport(action: DeleteExpenseReportAction) {
  const { id } = action.payload
  try {
    yield* callApi(expenseReportService.delete, id)
    yield* put(confirmDeleteExpenseReport(id))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* sendExpenseReportToValidation(action: SendExpenseReportToValidation) {
  try {
    const { expenseReportId, validatorId, remark } = action.payload
    yield* callApi(expenseReportService.sendExpenseReport, expenseReportId, validatorId, remark)
    yield* put(requestExpenseReportDetails(expenseReportId))
    if (yield* select(isAllowed, [{ resource: 'expenses', action: 'manage' }])) {
      yield* put(requestExpenseReportToValidateCount())
    }
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* validateExpenseReport(action: ValidateExpenseReport) {
  try {
    const { expenseReportId, remark } = action.payload
    yield* callApi(expenseReportService.validateExpenseReport, expenseReportId, remark)
    yield* put(requestExpenseReportToValidateCount())
    yield* put(requestExpenseReportDetails(expenseReportId))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* prevalidateExpenseReport(action: PrevalidateExpenseReport) {
  try {
    const { expenseReportId, validatorId, remark } = action.payload
    yield* callApi(expenseReportService.prevalidateExpenseReport, expenseReportId, validatorId, remark)
    yield* put(requestExpenseReportToValidateCount())
    yield* put(requestExpenseReportDetails(expenseReportId))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* refuseExpenseReport(action: RefuseExpenseReport) {
  try {
    const { expenseReportId, remark } = action.payload
    yield* callApi(expenseReportService.refuseExpenseReport, expenseReportId, remark)
    yield* put(requestExpenseReportToValidateCount())
    yield* put(requestExpenseReportDetails(expenseReportId))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* countExpenseReportToValidate(action: RequestExpenseReportToValidateCount) {
  try {
    const count = yield* callApi(expenseReportService.getToValidateCount)
    yield* put(receiveExpenseReportToValidateCount(count))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* requestExpensesExport(action: RequestExpensesExport) {
  const { exportedAt, alreadyExported, status, date } = action.payload
  try {
    yield* callApi(fetchTeamList)
    const teams = yield* select(getTeamsSnowflakesAsArray)

    //get array of expenses to export
    const expenseSnowflakelist: SnowflakeType[] = yield* callApi(
      expenseReportService.getExpenseSnowflakes,
      teams,
      alreadyExported,
      status
    )

    //if expenses to export
    if (expenseSnowflakelist.length > 0) {
      //if user checked mark as exported

      if (exportedAt) {
        yield* callApi(expenseReportService.markExpensesAsExported, expenseSnowflakelist, date)
      }
      //call api to get csv URL
      const filename = 'approvedExpenses.csv'
      const csvData = yield* callApi(expenseReportService.getExpensesExport, expenseSnowflakelist, 'csv', filename)
      const csvURL = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }))

      // download csv
      // FRM-10690 - the old school way, also suitable for safari which doesn't handle well "window.open([csv.url])
      const link = document.createElement('a')
      link.href = csvURL
      link.style.display = 'none'
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)

      pushToast(T.expensesExported, 'success')

      yield* put(receivedExpensesExport())
    } else {
      pushToast(T.noExpensesToExport, 'warning')
      yield* put(receivedExpensesExport())
    }
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* fetchRecentExpensesTags() {
  try {
    const recentTags: SnowflakeType[] = yield* callApi(expenseReportService.getRecentTags)
    yield* put(receiveRecentExpensesTags(recentTags))
    if (recentTags.length > 0) {
      yield* put(requestTagsList(recentTags))
    }
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export const expensesSaga = function* expensesSaga() {
  yield* takeEvery(REQUEST_EXPENSE_REPORT_SUMMARY, requestExpenseReportSummaries)
  yield* takeEvery(REQUEST_EXPENSE_REPORT_DETAILS, requestExpenseReport)
  yield* takeEvery(REQUEST_EXPENSE_TYPES_ENABLED, requestExpenseTypesEnabled)
  yield* takeEvery(DELETE_EXPENSE_REPORT, deleteExpenseReport)
  yield* takeEvery(SAVE_EXPENSE, saveExpense)
  yield* takeEvery(DELETE_EXPENSE, deleteExpense)
  yield* takeEvery(RENAME_EXPENSE_REPORT, renameExpenseReport)
  yield* takeEvery(REQUEST_EXPENSES, requestExpenses)
  yield* takeEvery(REQUEST_EXPENSE, requestExpense)
  yield* takeEvery(SEND_EXPENSE_REPORT_TO_VALIDATION, sendExpenseReportToValidation)
  yield* takeEvery(VALIDATE_EXPENSE_REPORT, validateExpenseReport)
  yield* takeEvery(PREVALIDATE_EXPENSE_REPORT, prevalidateExpenseReport)
  yield* takeEvery(REFUSE_EXPENSE_REPORT, refuseExpenseReport)
  yield* takeLatest(REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT, countExpenseReportToValidate)
  yield* takeEvery(REQUEST_EXPENSES_EXPORT, requestExpensesExport)
  yield* takeLatest(REQUEST_RECENT_EXPENSES_TAGS, fetchRecentExpensesTags)
}
