import { BySnowflake, SnowflakeType } from 'core/types'
import { RequestTagFilter, TagType } from 'tag/types'

export const REQUEST_TAGS = 'REQUEST_TAGS'
export const RECEIVE_TAGS = 'RECEIVE_TAGS'
export const REQUEST_TAG_LIST = 'REQUEST_TAG_LIST'
export const RECEIVE_TAG_LIST = 'RECEIVE_TAG_LIST'

export const REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME = 'REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME'
export const RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME = 'RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME'
export const REQUEST_TAG_SELECT_TAGS = 'REQUEST_TAG_SELECT_TAGS'
export const RECEIVE_TAG_SELECT_TAGS = 'RECEIVE_TAG_SELECT_TAGS'
export const RECEIVE_EXTRA_TAG_SELECT_TAGS = 'RECEIVE_EXTRA_TAG_SELECT_TAGS'
export const REQUEST_TAG_SELECT_HAS_TAGS = 'REQUEST_TAG_SELECT_HAS_TAGS'
export const RECEIVE_TAG_SELECT_HAS_TAGS = 'RECEIVE_TAG_SELECT_HAS_TAGS'

export interface RequestTagsAction {
  type: typeof REQUEST_TAGS
  payload: {
    id: number
    filter: RequestTagFilter
    contextDate?: Date
  }
}

interface ReceiveTagsAction {
  type: typeof RECEIVE_TAGS
  payload: {
    tags: TagType[]
  }
}
export interface RequestTagListAction {
  type: typeof REQUEST_TAG_LIST
  payload: {
    tags: SnowflakeType[]
    id?: number
    filter?: RequestTagFilter
    contextDate?: Date
  }
}

export interface ReceiveTagListAction {
  type: typeof RECEIVE_TAG_LIST
  payload: {
    tags: TagType[]
  }
}

export type RequestSearchTagSelectTagsByNameAction = {
  type: typeof REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME
  payload: {
    id: number
    filter: RequestTagFilter
    contextDate?: Date
    name: string
  }
}

export type ReceiveSearchTagSelectTagsByNameAction = {
  type: typeof RECEIVE_SEARCH_TAG_SELECT_TAGS_BY_NAME
  payload: {
    tags: SnowflakeType[]
  }
}

export interface RequestSelectTagTagsAction {
  type: typeof REQUEST_TAG_SELECT_TAGS
  payload: {
    id: number
    filter: RequestTagFilter
    contextDate?: Date
    forcedTags?: SnowflakeType[]
  }
}

interface ReceiveSelectTagTagsAction {
  type: typeof RECEIVE_TAG_SELECT_TAGS
  payload: {
    tags: SnowflakeType[]
  }
}

interface ReceiveExtraSelectTagTagsAction {
  type: typeof RECEIVE_EXTRA_TAG_SELECT_TAGS
  payload: {
    tags: SnowflakeType[]
  }
}

export type RequestSelectTagHasTagsAction = {
  type: typeof REQUEST_TAG_SELECT_HAS_TAGS
  payload: {
    id: number
    filter: RequestTagFilter
    contextDate?: Date
  }
}

export type ReceiveSelectTagHasTagsAction = {
  type: typeof RECEIVE_TAG_SELECT_HAS_TAGS
  payload: {
    hasTags: boolean
  }
}

export type TagTypes =
  | RequestTagsAction
  | ReceiveTagsAction
  | RequestTagListAction
  | ReceiveTagListAction
  | RequestSearchTagSelectTagsByNameAction
  | ReceiveSearchTagSelectTagsByNameAction
  | RequestSelectTagTagsAction
  | ReceiveSelectTagTagsAction
  | RequestSelectTagHasTagsAction
  | ReceiveSelectTagHasTagsAction
  | ReceiveExtraSelectTagTagsAction

export interface TagState {
  tags: BySnowflake<TagType>
  tagSelect: {
    tags: SnowflakeType[]
    hasTags: boolean
  }
  ui: {
    tags: {
      tagsFetching: BySnowflake<boolean>
      isFetching: boolean
    }
    tagSelect: {
      isFetching: boolean
    }
  }
}
