import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { getFormat } from 'core/state/selectors'
import { format } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { StoreStateType } from 'store/types'
import { TagCloud } from 'ui/dataDisplay/tagCloud/TagCloud'
import { TagType } from 'tag/types'
import { TranslateElement } from 'core/components/i18n'

type ActivityMessageProps = {
  period: DeprecatedPeriod
  tags: TagType[]
}

export const ActivityMessage = ({ period, tags }: ActivityMessageProps) => {
  const hourFormat = useSelector((state: StoreStateType) => getFormat(state, 'hour'))
  const dateToFormat = period.endDate || period.startDate
  const activityHour = dateToFormat ? format(dateToFormat, hourFormat) : ''
  const activityTitle = period.endDate ? 'activity.stop_resume' : 'activity.start_resume'

  return (
    <React.Fragment>
      <TagCloud tags={tags} hideNoTagsMessage />
      <TranslateElement id={activityTitle} values={{ hour: activityHour }} />
    </React.Fragment>
  )
}
