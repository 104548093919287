import {
  AclRecursionType,
  AuthenticationActionTypes,
  Permissions,
  RECEIVE_ACLS,
  RECEIVE_PERMISSIONS,
  RECEIVE_TWO_FACTOR_METHOD,
  REQUEST_ACLS,
  REQUEST_ACTIVE_USER,
  REQUEST_REMOVAL_TWO_FACTOR_METHOD,
  REQUEST_TWO_FACTOR_METHOD,
  SAVE_ACTIVE_LANGUAGE,
  SAVE_PREFERENCE,
  SAVE_SESSION,
  USER_LOGGED_OUT,
} from 'authentication/state/types'
import { TwoFactorType } from 'authentication/types'
import { SnowflakeType } from 'core/types'

export function userLoggedOut(): AuthenticationActionTypes {
  return {
    type: USER_LOGGED_OUT,
  }
}

export function requestActiveUser(): AuthenticationActionTypes {
  return {
    type: REQUEST_ACTIVE_USER,
  }
}

export function requestAcls(force = false): AuthenticationActionTypes {
  return {
    type: REQUEST_ACLS,
    payload: {
      force,
    },
  }
}

export function receiveAcls(acls: AclRecursionType, expiration: Date): AuthenticationActionTypes {
  return {
    type: RECEIVE_ACLS,
    payload: {
      acls,
      expiration,
    },
  }
}

export function savePreference(preference: string, value: any): AuthenticationActionTypes {
  return {
    type: SAVE_PREFERENCE,
    payload: {
      preference,
      value,
    },
  }
}

export function saveActiveLanguage(language: string): AuthenticationActionTypes {
  return {
    type: SAVE_ACTIVE_LANGUAGE,
    payload: {
      language,
    },
  }
}

export function saveSession(key: string, value: string): AuthenticationActionTypes {
  return {
    type: SAVE_SESSION,
    payload: {
      key: key,
      value: value,
    },
  }
}

export function requestTwoFactorMethod(userId: SnowflakeType): AuthenticationActionTypes {
  return {
    type: REQUEST_TWO_FACTOR_METHOD,
    payload: {
      userId,
    },
  }
}

export function receiveTwoFactorMethod(userId: SnowflakeType, data: TwoFactorType[]): AuthenticationActionTypes {
  return {
    type: RECEIVE_TWO_FACTOR_METHOD,
    payload: {
      userId,
      data,
    },
  }
}

export function requestRemovalTwoFactorMethod(
  resourceSnowflake: SnowflakeType,
  methodId: SnowflakeType
): AuthenticationActionTypes {
  return {
    type: REQUEST_REMOVAL_TWO_FACTOR_METHOD,
    payload: {
      resourceSnowflake,
      methodId,
    },
  }
}

export function receivePermissions(snowflake: SnowflakeType, permissions: Permissions): AuthenticationActionTypes {
  return {
    type: RECEIVE_PERMISSIONS,
    payload: {
      snowflake,
      permissions,
    },
  }
}
