import { SnowflakeType } from 'core/types'
import {
  DIRECTORY_ROLES_WITH_DOCUMENTS,
  DIRECTORY_ROLE_CLIENT,
  DIRECTORY_ROLE_EMPLOYEE,
  DIRECTORY_ROLE_EXTERN,
  DIRECTORY_ROLE_RESIDENT,
  DIRECTORY_ROLE_THING,
} from 'directory/types'
import { Identifier, Pattern } from 'document/components/fileNamePattern/types'
import { StepKey } from 'document/pages/documentImportPage/steps/types'
import { Document, DOCUMENT_TYPES, ImportFile, ImportFilesStates } from 'document/types'
import { Step } from 'ui/navigation/stepper/types'

export const REQUEST_CHILDREN_DOCUMENTS = 'REQUEST_CHILDREN_DOCUMENTS'
export const RECEIVE_CHILDREN_DOCUMENTS = 'RECEIVE_CHILDREN_DOCUMENTS'
export const REQUEST_PARENTS_DOCUMENTS = 'REQUEST_PARENTS_DOCUMENTS'
export const RECEIVE_PARENTS_DOCUMENTS = 'RECEIVE_PARENTS_DOCUMENTS'
export const REQUEST_ROOT_FOLDER = 'REQUEST_ROOT_FOLDER'
export const RECEIVE_ROOT_FOLDER = 'RECEIVE_ROOT_FOLDER'
export const SET_FILES_ARE_UPLOADING = 'SET_FILES_ARE_UPLOADING'
export const IMPORT_FILE = 'IMPORT_FILE'
export const RECEIVE_IMPORT_FILE = 'RECEIVE_IMPORT_FILE'
export const ADD_FILES_TO_IMPORT = 'ADD_FILES_TO_IMPORT'
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const SET_STEPS = 'SET_STEPS'
export const START_FILES_MATCH = 'START_FILES_MATCH'
export const REQUEST_FILES_MATCH = 'REQUEST_FILES_MATCH'
export const RECEIVE_FILES_MATCH = 'RECEIVE_FILES_MATCH'
export const REMOVE_IMPORT_FILES_WITH_ERROR = 'REMOVE_IMPORT_FILES_WITH_ERROR'
export const REMOVE_FILE = 'REMOVE_FILE'
export const RETRY_FAILED_IMPORTS = 'RETRY_FAILED_IMPORTS'
export const START_IMPORT = 'START_IMPORT'
export const RESET_ERRORED_FILES_TO_MATCHED = 'RESET_ERRORED_FILES_TO_MATCHED'
export const SET_IMPORT_CONFIG = 'SET_IMPORT_CONFIG'
export const RESET_ALL_FILES = 'RESET_ALL_FILES'
export const SET_FILES_STATE_TO_INITIAL = 'SET_FILES_STATE_TO_INITIAL'
export const SET_FILE_IS_DUPLICATE = 'SET_FILE_IS_DUPLICATE'

type DocumentsState = {
  rootFolderId: number | null
  documents: {
    [id: number]: Document
  }
  import: {
    files: {
      [id: string]: ImportFile
    }
  }
}

type DocumentsUIState = {
  isFetching: boolean
  documentsFetching: {
    [id: number]: boolean
  }
}

export type ImportConfig = {
  fileNamePattern: Pattern
  folderId: number
  isVisible: boolean
  sendNotification: boolean
}
export type ImportStep = Step<StepKey>

export type ImportPageSteps = { [K in StepKey]: ImportStep }

export type PartialImportPageSteps = { [K in StepKey]?: Partial<ImportStep> }

type ImportPageState = {
  activeStep: StepKey
  steps: ImportPageSteps
  config: ImportConfig | null
}

export type DocumentState = {
  documentsByRole: {
    [DIRECTORY_ROLE_EMPLOYEE]: DocumentsState
    [DIRECTORY_ROLE_RESIDENT]: DocumentsState
    [DIRECTORY_ROLE_CLIENT]: DocumentsState
    [DIRECTORY_ROLE_EXTERN]: DocumentsState
    [DIRECTORY_ROLE_THING]: DocumentsState
  }
  pages: {
    import: ImportPageState
  }
  ui: {
    documentsByRole: {
      [DIRECTORY_ROLE_EMPLOYEE]: DocumentsUIState
      [DIRECTORY_ROLE_RESIDENT]: DocumentsUIState
      [DIRECTORY_ROLE_CLIENT]: DocumentsUIState
      [DIRECTORY_ROLE_EXTERN]: DocumentsUIState
      [DIRECTORY_ROLE_THING]: DocumentsUIState
    }
  }
}

export type RequestChildrenDocumentsAction = {
  type: typeof REQUEST_CHILDREN_DOCUMENTS
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    id: number
  }
}

export type ReceiveChildrenDocumentsAction = {
  type: typeof RECEIVE_CHILDREN_DOCUMENTS
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    id: number
    documents: Document[]
  }
}

export type RequestParentsDocumentsAction = {
  type: typeof REQUEST_PARENTS_DOCUMENTS
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    id: number
  }
}

export type ReceiveParentsDocumentsAction = {
  type: typeof RECEIVE_PARENTS_DOCUMENTS
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    documents: Document[]
  }
}

export type RequestRootFolderAction = {
  type: typeof REQUEST_ROOT_FOLDER
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type ImportFileAction = {
  type: typeof IMPORT_FILE
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    file: ImportFile
    formData: FormData
  }
}

export type SetFilesAreUploadingAction = {
  type: typeof SET_FILES_ARE_UPLOADING
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    files: ImportFile[]
  }
}

export type ReceiveImportFileAction = {
  type: typeof RECEIVE_IMPORT_FILE
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    fileId: string
    state: ImportFilesStates
  }
}

type ReceiveRootFolderAction = {
  type: typeof RECEIVE_ROOT_FOLDER
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    document: Document
  }
}

export type AddFilesToImportAction = {
  type: typeof ADD_FILES_TO_IMPORT
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    files: File[]
  }
}

export type StartFilesMatchAction = {
  type: typeof START_FILES_MATCH
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type RequestFilesMatchAction = {
  type: typeof REQUEST_FILES_MATCH
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    files: File[]
  }
}

export type ReceiveFilesMatchAction = {
  type: typeof RECEIVE_FILES_MATCH
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    response: MatchFilesResponseData
  }
}

type SetActiveStepAction = {
  type: typeof SET_ACTIVE_STEP
  payload: {
    stepKey: StepKey
  }
}

type SetStepsAction = {
  type: typeof SET_STEPS
  payload: {
    steps: PartialImportPageSteps
  }
}

type SetImportConfigAction = {
  type: typeof SET_IMPORT_CONFIG
  payload: ImportConfig
}

type RemoveImportFilesWithErrorAction = {
  type: typeof REMOVE_IMPORT_FILES_WITH_ERROR
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type removeImportFileAction = {
  type: typeof REMOVE_FILE
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
    fileId: string
  }
}
export type StartImportAction = {
  type: typeof START_IMPORT
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type RetryFailedImportsAction = {
  type: typeof RETRY_FAILED_IMPORTS
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type ResetErroredFilesToMatchedAction = {
  type: typeof RESET_ERRORED_FILES_TO_MATCHED
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type ResetAllFilesAction = {
  type: typeof RESET_ALL_FILES
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type SetFilesStateToInitialAction = {
  type: typeof SET_FILES_STATE_TO_INITIAL
  payload: {
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type SetFileIsDuplicateAction = {
  type: typeof SET_FILE_IS_DUPLICATE
  payload: {
    fileId: string
    role: DIRECTORY_ROLES_WITH_DOCUMENTS
  }
}

export type MatchFilesParams = {
  pattern: string
  parent_id: number
  id_type: Identifier
  files: {
    id: string
    name: string
    size: number
  }[]
}

export type Violation = {
  type: string
  title: string
  detail: string
  violations: {
    title: string
    propertyPath: string
    type: string
    parameters: { status: string; detail: string; resolutionHint: string }
  }[]
}

export type FileMatch = {
  id: string
  type: DIRECTORY_ROLES_WITH_DOCUMENTS
  snowflake: SnowflakeType
}

export type MatchFilesResponse = {
  data: MatchFilesResponseData
}

export type MatchFilesResponseData = {
  message: string
  violations?: Violation
  matches: FileMatch[]
}

// Old route response type
export type DocumentResponse = {
  blob_uri: string | null
  created_at: string
  created_by: string
  depth: string
  hash: string | null
  has_children_folder: boolean
  full_path: string
  id: string
  lft: string
  name: string
  parent_id: string | null
  person_id: string | null
  rgt: string
  scope_id: string
  type: DOCUMENT_TYPES
  updated_at: string | null
  updated_by: string | null
  visible: string
}

export type DocumentActions =
  | RequestChildrenDocumentsAction
  | ReceiveChildrenDocumentsAction
  | RequestParentsDocumentsAction
  | ReceiveParentsDocumentsAction
  | RequestRootFolderAction
  | ReceiveRootFolderAction
  | ImportFileAction
  | ReceiveImportFileAction
  | ReceiveFilesMatchAction
  | StartFilesMatchAction
  | RequestFilesMatchAction
  | SetActiveStepAction
  | SetStepsAction
  | AddFilesToImportAction
  | StartImportAction
  | RetryFailedImportsAction
  | ResetErroredFilesToMatchedAction
  | RemoveImportFilesWithErrorAction
  | removeImportFileAction
  | SetImportConfigAction
  | ResetAllFilesAction
  | SetFilesStateToInitialAction
  | SetFileIsDuplicateAction
  | SetFilesAreUploadingAction
