import { BySnowflake, SnowflakeType } from 'core/types'
import { getPersonFactory } from 'directory/state/selectors'
import { SectorType } from 'directory/types'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { StoreStateType } from 'store/types'
import { TagOptionType, TagType } from 'tag/types'

const subState = (state: StoreStateType) => state.tag

export const isFetchingTags = (state: StoreStateType) => subState(state).ui.tags.isFetching
export const isFetchingTagSelectTags = (state: StoreStateType) => subState(state).ui.tagSelect.isFetching
export const hasTagSelectTags = (state: StoreStateType) => subState(state).tagSelect.hasTags
export const getTagSelectTags = (state: StoreStateType) => subState(state).tagSelect.tags
export const getTags = (state: StoreStateType) => subState(state).tags
export const getEnabledTags = createSelector(getTags, tags => {
  const enabledTags = Object.values(tags).filter(tag => tag.enabled === true)
  const reducedTags = enabledTags.reduce<BySnowflake<TagType>>(
    (all, current) => ({ ...all, [current.snowflake]: current }),
    {}
  )
  return reducedTags
})

export const getTagsByPersonId = createCachedSelector(
  getEnabledTags,
  (state: StoreStateType, personId: number) => personId,
  getPersonFactory,
  (enabledTags, personId, personFactory) => {
    const person = personFactory(personId)

    /* istanbul ignore else */
    if (!person) {
      return enabledTags
    }

    const {
      work: { sectors },
    } = person

    // TODO: remove any when person selectors are in typescript
    return Object.values(enabledTags)
      .filter(
        tag =>
          tag.sectors.length === 0 ||
          (sectors && !!tag.sectors.some(tagSector => sectors.find((s: SectorType) => s.id === tagSector.id)))
      )
      .reduce<BySnowflake<TagType>>((all, current) => ({ ...all, [current.snowflake]: current }), {})
  }
)((state, personId) => 'tags_' + JSON.stringify(personId))

export const getTagSelectTagsAsOptions: (state: StoreStateType, forcedTags?: SnowflakeType[]) => TagOptionType[] =
  createCachedSelector(
    (state: StoreStateType, forcedTags?: SnowflakeType[]) => forcedTags,
    getEnabledTags,
    getTags,
    getTagSelectTags,
    (forcedTags, enabledTags, allTags, tagSelectTags) => {
      const tagsDetail = { ...enabledTags }
      const availableTags = [...tagSelectTags]
      // Adds forced tags to the list without taking tag.enabled in account
      forcedTags &&
        forcedTags.forEach((snowflake: SnowflakeType) => {
          const tempTag = allTags[snowflake]
          if (tempTag) tagsDetail[snowflake] = tempTag
          if (!availableTags.includes(snowflake)) availableTags.push(snowflake)
        })

      const tagsAsOption =
        tagsDetail &&
        Object.values(tagsDetail)
          .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
          .reduce((all: TagOptionType[], current: TagType) => {
            return availableTags.includes(current.snowflake)
              ? [
                  ...all,
                  {
                    label: current.name,
                    value: current.id.toString(),
                    snowflake: current.snowflake,
                  },
                ]
              : all
          }, [])

      return tagsAsOption
    }
  )(state => 'tag_select_tags_as_options')
