import { BySnowflake } from 'core/types'
import { RoleAttributionTeams } from 'directory/state/types'

export class RoleAttributionsAdapter {
  static fromApi(rolesAttributions: any) {
    return rolesAttributions.reduce((acc: any, item: any) => {
      const resourceId = item['resource']
      const isAllTeams = item['teams'] === '*'
      acc[resourceId] = { allTeams: isAllTeams, teams: isAllTeams ? [] : item['teams'] }
      return acc
    }, {}) as BySnowflake<RoleAttributionTeams>
  }
}
