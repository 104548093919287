import { pal } from 'design/colors'

/** layers */
export const ly = {
  level0: pal.grey100,
  level1: pal.white,
  level2: pal.grey100,
  inset: pal.grey200,
  overlay: pal.white,
}
