import { navigateOutsideReact } from 'core/utils'
import { SagaReturnType } from 'redux-saga/effects'
import { call, SagaGenerator } from 'typed-redux-saga'

/**
 *
 * Crappy |--------------------------| Clever
 *                                  ^
 */
export function* callApi<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
): SagaGenerator<SagaReturnType<Fn>> {
  try {
    return yield* call(fn, ...args)
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      navigateOutsideReact('/auth/login')
    }
    throw e
  }
}
