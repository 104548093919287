import { MessageType } from 'communication/types'

export const REQUEST_MESSAGES = 'REQUEST_MESSAGES'
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES'

interface RequestMessagesAction {
  type: typeof REQUEST_MESSAGES
}

interface ReceiveMessagesAction {
  type: typeof RECEIVE_MESSAGES
  payload: {
    messages: MessageType[]
    unreads: number
  }
}

export type CommunicationTypes = RequestMessagesAction | ReceiveMessagesAction

export interface CommunicationState {
  messages: MessageType[]
  ui: {
    messages: {
      amount_unread: number
    }
  }
}
