import { CommunicationTypes, RECEIVE_MESSAGES, REQUEST_MESSAGES } from 'communication/state/types'
import { MessageType } from 'communication/types'

export function requestMessages(): CommunicationTypes {
  return {
    type: REQUEST_MESSAGES,
  }
}

export function receiveMessages(messages: MessageType[], unreads: number): CommunicationTypes {
  return {
    type: RECEIVE_MESSAGES,
    payload: {
      messages,
      unreads,
    },
  }
}
