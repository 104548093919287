import { brain } from 'core/services/api'
import { SnowflakeType } from 'core/types'
import { format } from 'date-fns'
import { TagAdapter } from 'tag/adapter/TagAdapter'
import { LIMIT_NB_TAGS } from 'tag/constants'
import { RequestTagFilter, TagType } from 'tag/types'

const buildTagsRequestParams = (id?: number, filter?: RequestTagFilter, date?: Date) => {
  let params = {}
  if (date) params = { ...params, date: format(date, 'yyyy-MM-dd') }
  if (id) {
    if (filter) {
      if (filter === RequestTagFilter.ByResource) {
        params = { ...params, person_id: id }
      }
      if (filter === RequestTagFilter.BySector) {
        params = { ...params, sector_ids: [id] }
      }
    }
  }
  return params
}

export const tagService = {
  getTags: async (id: number, filter: RequestTagFilter, date?: Date): Promise<TagType[]> => {
    const tags = await brain.get('/tags', {
      params: {
        limit: LIMIT_NB_TAGS,
        'orderBy[name]': 'asc',
        paginated: true,
        ...buildTagsRequestParams(id, filter, date),
      },
    })
    return TagAdapter.arrayFromApi(tags.data.data)
  },

  getTagList: async (
    tagSnowflakeList: SnowflakeType[],
    id?: number,
    filter?: RequestTagFilter,
    date?: Date
  ): Promise<TagType[]> => {
    const response = await brain.get(`/tags`, {
      params: {
        ids: tagSnowflakeList.join(),
        ...buildTagsRequestParams(id, filter, date),
      },
    })

    return TagAdapter.arrayFromApi(response.data.data)
  },

  /** Gets all tags without pagination ordered by name */
  getAllTags: async (options = { withDisabled: false }): Promise<TagType[]> => {
    const response = await brain.get('/tags', {
      params: {
        'orderBy[name]': 'asc',
        paginated: false,
        with_disabled: options.withDisabled,
      },
    })
    return TagAdapter.arrayFromApi(response.data.data)
  },

  /** Gets all tags without pagination ordered by name, including the disabled ones
   * with: resource=activity and action=view
   */
  getAllActivityTags: async (): Promise<TagType[]> => {
    const response = await brain.get('/tags', {
      params: {
        'orderBy[name]': 'asc',
        paginated: false,
        with_disabled: true,
        resource: 'activity',
        action: 'view',
      },
    })
    return TagAdapter.arrayFromApi(response.data.data)
  },

  hasTags: async (id: number, filter?: RequestTagFilter, date?: Date): Promise<boolean> => {
    const response = await brain.get('/tags', {
      params: {
        limit: 1,
        paginated: true,
        ...buildTagsRequestParams(id, filter, date),
      },
    })

    const { total } = response.data
    return total > 0
  },

  requestSearchTagSelectTagsByName: async (
    name: string,
    id: number,
    filter: RequestTagFilter,
    date?: Date
  ): Promise<TagType[]> => {
    const tags = await brain.post('/tags/search', {
      'orderBy[name]': 'asc',
      query: [
        {
          field: 'name',
          op: 'CONTAINS',
          value: name,
        },
      ],
      ...buildTagsRequestParams(id, filter, date),
    })

    return TagAdapter.arrayFromApi(tags.data.data)
  },
}
