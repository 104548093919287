import { t } from 'core/i18n'
import { withCrudReducer } from 'crud/state/withCrudReducer'
import {
  DIRECTORY_ROLE_CLIENT,
  DIRECTORY_ROLE_EMPLOYEE,
  DIRECTORY_ROLE_EXTERN,
  DIRECTORY_ROLE_RESIDENT,
  DIRECTORY_ROLE_THING,
} from 'directory/types'
import { IMPORT_STEPS } from 'document/pages/documentImportPage/steps/types'
import {
  ADD_FILES_TO_IMPORT,
  DocumentActions,
  DocumentState,
  RECEIVE_CHILDREN_DOCUMENTS,
  RECEIVE_FILES_MATCH,
  RECEIVE_IMPORT_FILE,
  RECEIVE_PARENTS_DOCUMENTS,
  RECEIVE_ROOT_FOLDER,
  REMOVE_FILE,
  REMOVE_IMPORT_FILES_WITH_ERROR,
  REQUEST_CHILDREN_DOCUMENTS,
  REQUEST_ROOT_FOLDER,
  RESET_ALL_FILES,
  RESET_ERRORED_FILES_TO_MATCHED,
  SET_ACTIVE_STEP,
  SET_FILES_ARE_UPLOADING,
  SET_FILES_STATE_TO_INITIAL,
  SET_FILE_IS_DUPLICATE,
  SET_IMPORT_CONFIG,
  SET_STEPS,
} from 'document/state/types'
import { Document, FILE_STATES, ImportFile } from 'document/types'
import produce from 'immer'
import lodash from 'lodash'
import { compose } from 'redux'
import { STEP_STATE_DEFAULT } from 'ui/navigation/stepper/types'

const initialState: DocumentState = {
  pages: {
    import: {
      activeStep: IMPORT_STEPS.config,
      config: null,
      steps: {
        config: {
          id: IMPORT_STEPS.config,
          title: 'document.step.config.title',
          state: STEP_STATE_DEFAULT,
          isClickable: true,
          isDisabled: false,
        },
        prepare: {
          id: IMPORT_STEPS.prepare,
          title: 'document.step.prepare.title',
          state: STEP_STATE_DEFAULT,
          isClickable: true,
          isDisabled: true,
        },
        import: {
          id: IMPORT_STEPS.import,
          title: 'document.step.import.title',
          state: STEP_STATE_DEFAULT,
          isClickable: true,
          isDisabled: true,
        },
      },
    },
  },
  documentsByRole: {
    [DIRECTORY_ROLE_EMPLOYEE]: {
      rootFolderId: null,
      documents: {},
      import: {
        files: {},
      },
    },
    [DIRECTORY_ROLE_RESIDENT]: {
      rootFolderId: null,
      documents: {},
      import: {
        files: {},
      },
    },
    [DIRECTORY_ROLE_CLIENT]: {
      rootFolderId: null,
      documents: {},
      import: {
        files: {},
      },
    },
    [DIRECTORY_ROLE_EXTERN]: {
      rootFolderId: null,
      documents: {},
      import: {
        files: {},
      },
    },
    [DIRECTORY_ROLE_THING]: {
      rootFolderId: null,
      documents: {},
      import: {
        files: {},
      },
    },
  },
  ui: {
    documentsByRole: {
      [DIRECTORY_ROLE_EMPLOYEE]: {
        isFetching: false,
        documentsFetching: {},
      },
      [DIRECTORY_ROLE_RESIDENT]: {
        isFetching: false,
        documentsFetching: {},
      },
      [DIRECTORY_ROLE_CLIENT]: {
        isFetching: false,
        documentsFetching: {},
      },
      [DIRECTORY_ROLE_EXTERN]: {
        isFetching: false,
        documentsFetching: {},
      },
      [DIRECTORY_ROLE_THING]: {
        isFetching: false,
        documentsFetching: {},
      },
    },
  },
}

export const documentReducer = compose(withCrudReducer<DocumentState, DocumentActions>('tags'))(
  (currentState = initialState, action): DocumentState =>
    produce(currentState, draft => {
      switch (action.type) {
        case REQUEST_CHILDREN_DOCUMENTS:
          draft.ui.documentsByRole[action.payload.role].documentsFetching[action.payload.id] = true
          break
        case RECEIVE_CHILDREN_DOCUMENTS:
          action.payload.documents.forEach((document: Document) => {
            draft.documentsByRole[action.payload.role].documents[document.id] = document
          })
          draft.ui.documentsByRole[action.payload.role].documentsFetching[action.payload.id] = false
          break
        case RECEIVE_PARENTS_DOCUMENTS:
          action.payload.documents.forEach((document: Document) => {
            draft.documentsByRole[action.payload.role].documents[document.id] = document
          })
          break
        case REQUEST_ROOT_FOLDER:
          draft.ui.documentsByRole[action.payload.role].isFetching = true
          break
        case RECEIVE_ROOT_FOLDER:
          draft.documentsByRole[action.payload.role].rootFolderId = action.payload.document.id
          draft.documentsByRole[action.payload.role].documents[action.payload.document.id] = action.payload.document
          draft.ui.documentsByRole[action.payload.role].isFetching = false
          break
        case SET_FILES_ARE_UPLOADING:
          action.payload.files.forEach(file => {
            draft.documentsByRole[action.payload.role].import.files[file.id] = {
              ...draft.documentsByRole[action.payload.role].import.files[file.id],
              state: FILE_STATES.uploading,
            }
          })
          break
        case RECEIVE_IMPORT_FILE:
          draft.documentsByRole[action.payload.role].import.files[action.payload.fileId] = {
            ...draft.documentsByRole[action.payload.role].import.files[action.payload.fileId],
            state: action.payload.state,
            ...(action.payload.state === FILE_STATES.error
              ? {
                  error: {},
                }
              : {}),
          }
          break
        case ADD_FILES_TO_IMPORT:
          action.payload.files.forEach(file => {
            const fileId = lodash.uniqueId(`${action.payload.role}_file_to_upload_`)
            const newFile: ImportFile = {
              id: fileId,
              extension: file.name.split('.').pop()?.toLowerCase() || '',
              blobUrl: window.URL.createObjectURL(file),
              size: file.size,
              name: file.name,
              type: file.type,
              state: FILE_STATES.initial,
            }
            const isDuplicate = Object.values(draft.documentsByRole[action.payload.role].import.files).find(
              existingFile => existingFile.name === file.name
            )

            if (isDuplicate) {
              newFile.state = FILE_STATES.error
              newFile.error = {
                title: t('document.import.error.duplicate_file.title'),
                description: t('document.import.error.duplicate_file.detail'),
                tooltip: t('document.import.error.duplicate_file.resolution-hint'),
              }
            }
            draft.documentsByRole[action.payload.role].import.files[fileId] = newFile
          })
          break
        case RECEIVE_FILES_MATCH:
          const { response, role } = action.payload
          response.matches.forEach(match => {
            if (draft.documentsByRole[role].import.files[match.id]) {
              draft.documentsByRole[role].import.files[match.id] = {
                ...draft.documentsByRole[role].import.files[match.id],
                state: FILE_STATES.matched,
                personSnowflake: match.snowflake,
              }
            }
          })
          response?.violations?.violations.forEach(violation => {
            if (draft.documentsByRole[role].import.files[violation.propertyPath]) {
              draft.documentsByRole[role].import.files[violation.propertyPath] = {
                ...draft.documentsByRole[role].import.files[violation.propertyPath],
                state: FILE_STATES.error,
                error: {
                  title: violation.title,
                  description: violation.parameters.detail,
                  tooltip: violation.parameters.resolutionHint,
                },
              }
            }
          })
          break
        case SET_ACTIVE_STEP:
          draft.pages.import.activeStep = action.payload.stepKey
          break
        case SET_FILE_IS_DUPLICATE: {
          const { fileId, role } = action.payload
          if (draft.documentsByRole[role].import.files[fileId]) {
            draft.documentsByRole[role].import.files[fileId] = {
              ...draft.documentsByRole[role].import.files[fileId],
              state: FILE_STATES.error,
              error: {
                title: t('document.import.error.duplicate_file.title'),
                description: t('document.import.error.duplicate_file.detail'),
                tooltip: t('document.import.error.duplicate_file.resolution-hint'),
              },
            }
          }
          break
        }
        case SET_STEPS:
          draft.pages.import.steps = {
            config: {
              ...draft.pages.import.steps.config,
              ...action.payload.steps?.config,
            },
            prepare: {
              ...draft.pages.import.steps.prepare,
              ...action.payload.steps?.prepare,
            },
            import: {
              ...draft.pages.import.steps.import,
              ...action.payload.steps?.import,
            },
          }
          break
        case REMOVE_IMPORT_FILES_WITH_ERROR:
          Object.values(draft.documentsByRole[action.payload.role].import.files).forEach(file => {
            if (file.state === FILE_STATES.error) {
              delete draft.documentsByRole[action.payload.role].import.files[file.id]
            }
          })
          break
        case REMOVE_FILE:
          if (draft.documentsByRole[action.payload.role].import.files[action.payload.fileId]) {
            delete draft.documentsByRole[action.payload.role].import.files[action.payload.fileId]
          }
          break
        case RESET_ERRORED_FILES_TO_MATCHED:
          Object.values(draft.documentsByRole[action.payload.role].import.files).forEach(file => {
            if (file.state === FILE_STATES.error && file.personSnowflake) {
              file.state = FILE_STATES.matched
            }
          })
          break
        case SET_FILES_STATE_TO_INITIAL:
          Object.values(draft.documentsByRole[action.payload.role].import.files).forEach((file, index) => {
            file.state = FILE_STATES.initial
            delete draft.documentsByRole[action.payload.role].import.files[file.id].error
            delete draft.documentsByRole[action.payload.role].import.files[file.id].personSnowflake
          })
          break
        case SET_IMPORT_CONFIG:
          draft.pages.import.config = action.payload
          break
        case RESET_ALL_FILES:
          draft.documentsByRole[action.payload.role].import.files = {}
          break
        default:
          break
      }
    })
)
