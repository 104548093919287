import { Interval, IntervalComponent } from 'chronos/interval/Interval'

export const SEPARATOR = '/'
export const URL_SEPARATOR = '~'

export const parseInterval = <T extends IntervalComponent, I extends Interval<T>>(
  base: { parse(value: string): T },
  interval: { new (start: T | null, end: T | null): I },
  value: string,
  options: { urlSafe?: boolean } = {}
) => {
  const sep = options.urlSafe ? URL_SEPARATOR : SEPARATOR

  if (!value.includes(sep)) throw new Error(`Invalid interval format. Must be in the form "start${sep}end"`)
  const [start, end] = value.split(sep)

  const intervalStart = start === '-' ? null : base.parse(start)

  const intervalEnd = end === '-' ? null : base.parse(end)

  return new interval(intervalStart, intervalEnd)
}
