import { brain } from 'core/services/api'

export const scheduleTemplateTypesService = {
  getAll: async () => {
    const { data: scheduleTemplateTypes } = await brain.get('/plannings/schedule-template-types', {
      params: {
        paginated: false,
      },
    })

    return scheduleTemplateTypes.data
  },
}
