import { ActivityType } from 'activity/types'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { ById, SnowflakeType } from 'core/types'
import { PersonType } from 'directory/types'
import { TagType } from 'tag/types'

export const CLOSE_ACTIVITIES = 'CLOSE_ACTIVITIES'
export const REQUEST_OPENED_ACTIVITY = 'REQUEST_OPENED_ACTIVITY'
export const REQUEST_TAGS_BY_SECTOR = 'REQUEST_TAGS_BY_SECTOR'
export const START_ACTIVITY = 'START_ACTIVITY'
export const STOP_ACTIVITY = 'STOP_ACTIVITY'
export const RECEIVE_ACTIVITIES = 'RECEIVE_ACTIVITIES'
export const REQUEST_ACTIVITY_RECENT_TAGS = 'REQUEST_ACTIVITY_RECENT_TAGS'
export const RECEIVE_ACTIVITY_RECENT_TAGS = 'RECEIVE_ACTIVITY_RECENT_TAGS'

type ActivityResponseType = {
  id: number
  person: PersonType
  period: string
  tags: TagType[]
}

export interface RequestActivityRecentTagsAction {
  type: typeof REQUEST_ACTIVITY_RECENT_TAGS
  payload: {
    personId: number | null
    contextDate?: Date
    forcedTags?: SnowflakeType[]
  }
}

export interface ReceiveActivityRecentTagsAction {
  type: typeof RECEIVE_ACTIVITY_RECENT_TAGS
  payload: {
    tags: SnowflakeType[]
  }
}

interface CloseActivitiesAction {
  type: typeof CLOSE_ACTIVITIES
  payload: {
    end: Date
  }
}

export interface RequestOpenedActivityAction {
  type: typeof REQUEST_OPENED_ACTIVITY
  payload: {
    personId: number
  }
}

export interface StartActivityAction {
  type: typeof START_ACTIVITY
  payload: {
    tags: TagType[]
    period: DeprecatedPeriod
    remark?: string
  }
}

export interface StopActivityAction {
  type: typeof STOP_ACTIVITY
  payload: {
    id: number
    tags: TagType[]
    period: DeprecatedPeriod
    remark?: string
  }
}

interface ReceiveActivitiesAction {
  type: typeof RECEIVE_ACTIVITIES
  payload: {
    activities: ActivityResponseType[]
  }
}

export type ActivityTypes =
  | CloseActivitiesAction
  | RequestOpenedActivityAction
  | RequestActivityRecentTagsAction
  | ReceiveActivityRecentTagsAction
  | StartActivityAction
  | StopActivityAction
  | ReceiveActivitiesAction

export interface ActivityState {
  activities: ById<ActivityType>
  recentTags: SnowflakeType[]
  ui: {
    activities: {
      activitiesFetching: ById<boolean>
      isFetching: boolean
    }
    recentTags: {
      isFetching: boolean
    }
  }
}
