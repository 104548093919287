/**
 * Truncate a long text in the middle
 * @param label Text to truncate
 * @param maxLength Max length of the text before truncating
 * @param charToDisplay Number of characters to display at the beginning and at the end of the text
 * @example
 * truncateMiddle('Welcome to the jungle', 20, 6) // Welcom...jungle
 **/
export const truncateMiddle = (label: string, maxLength = 20, charToDisplay = 6) => {
  label = label.trim() // we don't want to consider spacing when counting characters
  if (label.length > maxLength) return label.slice(0, charToDisplay) + '...' + label.slice(-charToDisplay)
  return label
}

// ES6 normalize, see https://stackoverflow.com/a/37511463
export const removeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

/** Returns a normalized lowercase string (replacing accentuated characters by non accentuated characters) */
export const normalizeString = (str: string) => removeAccents(str).toLowerCase()

/**
 * Adds (or removes) the `-` (minus) sign to the beginning of the string.
 * If called twice, it will always return the original string.
 *
 * @example
 * ```js
 * neg('foo') // '-foo'
 * neg('-foo') // 'foo'
 *
 * neg('12px') // '-12px'
 * neg('-12px') // '12px'
 *
 * neg('') // '-'
 * neg('-') // ''
 *
 * neg(neg('foo')) // 'foo'
 * ```
 */
export const neg = (str: string) => (str[0] === '-' ? str.slice(1) : '-' + str)

/**
 * Converts a length in pixels to a number using `parseInt`
 *
 * @see [specification here](https://262.ecma-international.org/14.0/#sec-parseint-string-radix)
 *
 * @example
 * ```js
 * nb('12px') // 12
 * nb('-12px') // -12
 * ```
 */
export const nb = (n: string) => parseInt(n, 10)

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
