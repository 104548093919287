import { BookmarkType } from 'quality/types'

export const REQUEST_BOOKMARKS = 'REQUEST_BOOKMARKS'
export const RECEIVE_BOOKMARKS = 'RECEIVE_BOOKMARKS'

interface RequestBookmarksAction {
  type: typeof REQUEST_BOOKMARKS
}

interface ReceiveBookmarksAction {
  type: typeof RECEIVE_BOOKMARKS
  payload: {
    bookmarks: BookmarkType[]
  }
}

export type QualityTypes = ReceiveBookmarksAction | RequestBookmarksAction

export interface QualityState {
  bookmarks: BookmarkType[]
  ui: {
    bookmarks: {
      isFetching: boolean
    }
  }
}
