export const REQUEST_BALANCES = 'REQUEST_BALANCES'
export function requestBalances(start: any, end: any) {
  return {
    type: REQUEST_BALANCES,
    payload: {
      start,
      end,
    },
  }
}

export const RECEIVE_BALANCES = 'RECEIVE_BALANCES'
export function receiveBalances(holidays: any, work: any) {
  return {
    type: RECEIVE_BALANCES,
    payload: {
      holidays,
      work,
    },
  }
}

export const REQUEST_SCHEDULE = 'REQUEST_SCHEDULE'
export function requestSchedule(scheduleSnowflake: any) {
  return {
    type: REQUEST_SCHEDULE,
    payload: {
      scheduleSnowflake,
    },
  }
}

export const FETCHING_SCHEDULE = 'FETCHING_SCHEDULE'
export function fetchingSchedule(scheduleSnowflake: any) {
  return {
    type: FETCHING_SCHEDULE,
    payload: {
      scheduleSnowflake,
    },
  }
}

export const FETCHING_SCHEDULE_DONE = 'FETCHING_SCHEDULE_DONE'
export function fetchingScheduleDone(scheduleSnowflake: any) {
  return {
    type: FETCHING_SCHEDULE_DONE,
    payload: {
      scheduleSnowflake,
    },
  }
}

export const RECEIVE_SCHEDULE = 'RECEIVE_SCHEDULE'
export function receiveSchedule(schedule: any) {
  return {
    type: RECEIVE_SCHEDULE,
    payload: {
      schedule,
    },
  }
}

export const REQUEST_SCHEDULE_TEMPLATE = 'REQUEST_SCHEDULE_TEMPLATE'
export function requestScheduleTemplate(scheduleTemplateId: any) {
  return {
    type: REQUEST_SCHEDULE_TEMPLATE,
    payload: {
      scheduleTemplateId,
    },
  }
}

export const RECEIVE_SCHEDULE_TEMPLATE = 'RECEIVE_SCHEDULE_TEMPLATE'
export function receiveScheduleTemplate(scheduleTemplate: any) {
  return {
    type: RECEIVE_SCHEDULE_TEMPLATE,
    payload: {
      scheduleTemplate,
    },
  }
}

export const RECEIVE_PERIODS = 'RECEIVE_PERIODS'
export function receivePeriods(schedules: any, absences: any, partialAbsences: any, alldayAbsence: any) {
  return {
    type: RECEIVE_PERIODS,
    payload: {
      schedules,
      absences,
      partialAbsences,
      alldayAbsence,
    },
  }
}

export const REQUEST_SCHEDULE_TEMPLATE_TYPES = 'REQUEST_SCHEDULE_TEMPLATE_TYPES'
export function requestScheduleTemplateTypes() {
  return {
    type: REQUEST_SCHEDULE_TEMPLATE_TYPES,
  }
}

export const RECEIVE_SCHEDULE_TEMPLATE_TYPES = 'RECEIVE_SCHEDULE_TEMPLATE_TYPES'
export function receiveScheduleTemplateTypes(scheduleTemplateTypes: any) {
  return {
    type: RECEIVE_SCHEDULE_TEMPLATE_TYPES,
    payload: {
      scheduleTemplateTypes,
    },
  }
}

export const REQUEST_ABSENCE_REQUEST_TO_VALIDATE_COUNT = 'REQUEST_ABSENCE_REQUEST_TO_VALIDATE_COUNT'
export function requestAbsenceRequestToValidateCount() {
  return {
    type: REQUEST_ABSENCE_REQUEST_TO_VALIDATE_COUNT,
  }
}

export const RECEIVE_ABSENCE_REQUEST_TO_VALIDATE_COUNT = 'RECEIVE_ABSENCE_REQUEST_TO_VALIDATE_COUNT'
export function receiveAbsenceRequestToValidateCount(count: any) {
  return {
    type: RECEIVE_ABSENCE_REQUEST_TO_VALIDATE_COUNT,
    payload: {
      count,
    },
  }
}

export const REQUEST_ADJUSTMENT_BALANCES = 'REQUEST_ADJUSTMENT_BALANCES'
export function requestAdjustmentBalances(personId: any, date: any) {
  return {
    type: REQUEST_ADJUSTMENT_BALANCES,
    payload: {
      personId,
      date,
    },
  }
}

export const RECEIVE_ADJUSTMENT_BALANCES = 'RECEIVE_ADJUSTMENT_BALANCES'
export function receiveAdjustmentBalances(personId: any, adjustmentBalances: any) {
  return {
    type: RECEIVE_ADJUSTMENT_BALANCES,
    payload: {
      adjustmentBalances,
      personId,
    },
  }
}

export const SAVE_ADJUSTMENT_BALANCES = 'SAVE_ADJUSTMENT_BALANCES'
export function saveAdjustmentBalances(personId: any, balances: any) {
  return {
    type: SAVE_ADJUSTMENT_BALANCES,
    payload: {
      personId,
      balances,
    },
  }
}

export const ADJUSTMENT_BALANCES_SAVED = 'ADJUSTMENT_BALANCES_SAVED'
export function adjustmentBalancesSaved(personId: any) {
  return {
    type: ADJUSTMENT_BALANCES_SAVED,
    payload: {
      personId,
    },
  }
}

export const REQUEST_WEEKLY_HOURS = 'REQUEST_WEEKLY_HOURS'
export function requestWeeklyHours(payload: any) {
  return {
    type: REQUEST_WEEKLY_HOURS,
    payload,
  }
}

export const RECEIVE_WEEKLY_HOURS = 'RECEIVE_WEEKLY_HOURS'
export function receiveWeeklyHours(payload: any) {
  return {
    type: RECEIVE_WEEKLY_HOURS,
    payload,
  }
}

export const REQUEST_EMPLOYEE_BALANCES = 'REQUEST_EMPLOYEE_BALANCES'
export const requestEmployeeBalances = (date: any, personId: any) => {
  return {
    type: REQUEST_EMPLOYEE_BALANCES,
    payload: {
      date,
      personId,
    },
  }
}
/*
export const REQUEST_EMPLOYEE_DAILY_SCHEDULES = 'REQUEST_EMPLOYEE_DAILY_SCHEDULES'
export const requestEmployeeDailySchedules = (date, personId) => {
  return {
    type: REQUEST_EMPLOYEE_DAILY_SCHEDULES,
    payload: {
      date,
      personId
    }
  }
}*/

export const REQUEST_EXTRA_HOURS = 'REQUEST_EXTRA_HOURS'
export const requestExtraHours = (teamId: any, teamType: any, startDate: any, endDate: any) => {
  return {
    type: REQUEST_EXTRA_HOURS,
    payload: {
      teamId,
      teamType,
      startDate,
      endDate,
    },
  }
}

export const RECEIVE_EXTRA_HOURS = 'RECEIVE_EXTRA_HOURS'
export const receiveExtraHours = (extraHours: any, error: any) => {
  return {
    type: RECEIVE_EXTRA_HOURS,
    payload: {
      extraHours,
      error,
    },
  }
}

export const RECEIVE_TEAM_RESOURCES = 'RECEIVE_TEAM_RESOURCES'
export const receiveTeamResources = (teamId: any, startDate: any, endDate: any, snowflakes: any) => {
  return {
    type: RECEIVE_TEAM_RESOURCES,
    payload: {
      teamId,
      startDate,
      endDate,
      snowflakes,
    },
  }
}
