import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { goToPage, pageReceived, performOperationFailed, performOperationSuccess } from 'crud/state/actions'
import { getCurrentPage, getFilter, getPerPage, getSortField } from 'crud/state/selectors'
import {
  ADD_SORT_FIELD,
  CHANGE_PER_PAGE,
  CrudFetchableActionTypes,
  GO_TO_PAGE,
  PERFORM_OPERATION,
  PERFORM_OPERATION_SUCCESS,
  PerformOperationAction,
  REFRESH_PAGE,
  UPDATE_FILTER,
} from 'crud/state/types'
import { all, put, select, takeEvery } from 'typed-redux-saga'

function* fetchPage(action: CrudFetchableActionTypes) {
  const {
    meta: { entityName, service },
  } = action
  const currentPage = yield* select(getCurrentPage, entityName)
  const sortFields = yield* select(getSortField, entityName)
  const perPage = yield* select(getPerPage, entityName)
  const filterValue = yield* select(getFilter, entityName)

  try {
    // FIXME: https://jira.gammadia.ch/browse/FRM-4748
    const results =
      filterValue !== ''
        ? yield* callApi(service.operations.filter, filterValue, currentPage, perPage, sortFields)
        : yield* callApi(service.operations.list, currentPage, perPage, sortFields)
    const { total, data } = results.data

    yield* put(pageReceived(entityName, { total, entities: data }, service))
  } catch (e: any) {
    if (e.response && e.response.status === 400) {
      yield* put(goToPage(entityName, { page: currentPage - 1 }, service))
      return
    }

    yield* put(throwError(e))
  }
}

function* operateOnEntity(action: PerformOperationAction) {
  const {
    meta: { entityName, service },
    payload: { operation, args },
  } = action
  try {
    const result = yield* callApi(service.operations[operation], ...args)
    yield* put(performOperationSuccess(entityName, { operation, args, result }, service))
  } catch (e: any) {
    if (e.response && e.response.status === 400) {
      yield* put(
        performOperationFailed(
          entityName,
          {
            operation,
            args,
            errors: e.response.data.errors,
          },
          service
        )
      )
      return
    }

    yield* put(throwError(e))
  }
}

export function* crudSaga() {
  all([
    yield* takeEvery(
      [REFRESH_PAGE, GO_TO_PAGE, CHANGE_PER_PAGE, ADD_SORT_FIELD, PERFORM_OPERATION_SUCCESS, UPDATE_FILTER],
      fetchPage
    ),
    yield* takeEvery(PERFORM_OPERATION, operateOnEntity),
  ])
}
