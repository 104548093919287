import { receiveBootstrap } from 'core/state/actions'

export const RECEIVE_BOOTSTRAP = 'RECEIVE_BOOTSTRAP'
export const THROW_ERROR = 'THROW_ERROR'
export const TOGGLE_TOPBAR_VISIBILITY = 'TOGGLE_TOPBAR_VISIBILITY'
export const TOPBAR_HEIGHT_CHANGED = 'TOPBAR_HEIGHT_CHANGED'
export const TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR'
export const OPEN_LOADER = 'OPEN_LOADER'
export const CLOSE_LOADER = 'CLOSE_LOADER'
export const FLUSH_LOADER = 'FLUSH_LOADER'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SELECT_SIDEBAR_ITEM = 'SELECT_SIDEBAR_ITEM'
export const SELECT_SIDEBAR_SUBSECTION = 'SELECT_SIDEBAR_SUBSECTION'
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU'
export const NEW_UPDATE_AVAILABLE = 'NEW_UPDATE_AVAILABLE'
export const DISPLAY_FLASH_MESSAGES = 'DISPLAY_FLASH_MESSAGES'

export type ConfigType = {
  [x: string]: string | null | boolean | Array<any> | ConfigType | any
}

export type FlashMessages = {
  success: string[]
  error: string[]
} | null

type ModuleType = {
  enabled: 'checked' | ''
  external_link: string | null
  id: string
  machine_name: string
  menu_link: string
  name: string
  parent_id: string
  teaser: string | null
}

export interface ThrowErrorAction {
  type: typeof THROW_ERROR
  payload: {
    error?: any
  }
}

interface ToggleTopbarVisibilityAction {
  type: typeof TOGGLE_TOPBAR_VISIBILITY
  payload: {
    visible: boolean
  }
}

interface TopbarHeightChangedAction {
  type: typeof TOPBAR_HEIGHT_CHANGED
  payload: {
    height: number
  }
}

interface ToggleSearchBarAction {
  type: typeof TOGGLE_SEARCHBAR
}

interface OpenLoaderAction {
  type: typeof OPEN_LOADER
  payload: {
    message: string
    timeout: number
  }
}

interface CloseLoaderAction {
  type: typeof CLOSE_LOADER
}

interface FlushLoaderAction {
  type: typeof FLUSH_LOADER
}

interface ToggleSidebarAction {
  type: typeof TOGGLE_SIDEBAR
}

interface SelectSidebarItemAction {
  type: typeof SELECT_SIDEBAR_ITEM
  payload: {
    name: string
    selected: boolean
  }
}

interface SelectSidebarSubSectionAction {
  type: typeof SELECT_SIDEBAR_SUBSECTION
  payload: {
    name: string
    selected: boolean
  }
}

interface ToggleMobileMenuAction {
  type: typeof TOGGLE_MOBILE_MENU
}

interface NewUpdateAvailableAction {
  type: typeof NEW_UPDATE_AVAILABLE
}

interface RequestFlashMessagesAction {
  type: typeof DISPLAY_FLASH_MESSAGES
}

export type CoreActionTypes =
  | ThrowErrorAction
  | ToggleTopbarVisibilityAction
  | TopbarHeightChangedAction
  | ToggleSearchBarAction
  | OpenLoaderAction
  | CloseLoaderAction
  | FlushLoaderAction
  | ToggleSidebarAction
  | SelectSidebarItemAction
  | SelectSidebarSubSectionAction
  | ToggleMobileMenuAction
  | NewUpdateAvailableAction
  | RequestFlashMessagesAction
  | ReturnType<typeof receiveBootstrap>

export interface CoreState {
  modules: {
    [x: string]: ModuleType
  }
  ui: {
    newUpdateAvailable: boolean
    formats: {
      date: string
      shortTextDate: string
      textDate: string
      longTextDate: string
      hour: string
    }
    config: ConfigType
    hasUnrecoverableError: boolean
    loader: {
      count: number
      message: string
      timeout: number
    }
    mobilemenu: {
      open: boolean
    }
    searchbar: {
      visible: boolean
    }
    sidebar: {
      open: boolean
      width: number
      currentItem: string | null
      currentSubsection: string | null
    }
    topbar: {
      visible: boolean
      height: number
    }
  }
}
