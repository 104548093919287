import { receivePermissions } from 'authentication/state/actions'
import { isAllowed } from 'authentication/state/selectors'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { getDirectoryKinds } from 'core/state/selectors'
import { requestResourcesSummaries, requestTeam as requestTeamAction } from 'directory/state/actions'
import { findTeam, requestTeam } from 'directory/state/sagas'
import lodash from 'lodash'
import { PeriodAdapter } from 'schedule/adapter/PeriodAdapter'
import { absenceRequestService } from 'schedule/services/absenceRequest/absenceRequest'
import { adjustmentService } from 'schedule/services/adjustments/adjustment'
import { scheduleService } from 'schedule/services/schedule'
import { scheduleTemplateTypesService } from 'schedule/services/scheduleTemplateTypes'
import {
  RECEIVE_TEAM_RESOURCES,
  REQUEST_ABSENCE_REQUEST_TO_VALIDATE_COUNT,
  REQUEST_ADJUSTMENT_BALANCES,
  REQUEST_BALANCES,
  REQUEST_EMPLOYEE_BALANCES,
  REQUEST_EXTRA_HOURS,
  REQUEST_SCHEDULE,
  REQUEST_SCHEDULE_TEMPLATE,
  REQUEST_SCHEDULE_TEMPLATE_TYPES,
  REQUEST_WEEKLY_HOURS,
  SAVE_ADJUSTMENT_BALANCES,
  adjustmentBalancesSaved,
  fetchingSchedule,
  fetchingScheduleDone,
  receiveAbsenceRequestToValidateCount,
  receiveAdjustmentBalances,
  receiveBalances,
  receiveExtraHours,
  receivePeriods,
  receiveSchedule,
  receiveScheduleTemplate,
  receiveScheduleTemplateTypes,
  receiveTeamResources as receiveTeamResourcesAction,
  receiveWeeklyHours,
} from 'schedule/state/actions'
import { getScheduleBySnowflake } from 'schedule/state/selectors'
import { receiveTimechecks } from 'timeclock/state/actions'
import { all, put, select, takeEvery, takeLatest } from 'typed-redux-saga'

function* requestBalances(action: any) {
  const {
    payload: { start, end },
  } = action
  try {
    const { holidays, hours } = yield* callApi(scheduleService.getBalances, start, end)

    yield* put(receiveBalances(holidays, hours))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* requestScheduleTemplate(action: any) {
  try {
    const scheduleTemplate = yield* callApi(scheduleService.getScheduleTemplate, action.payload.scheduleTemplateId)
    yield* put(receiveScheduleTemplate(scheduleTemplate))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* requestSchedule(action: any) {
  try {
    const existingSchedule =
      // @ts-expect-error (nocheck)
      yield select(e => getScheduleBySnowflake(e, action.payload.scheduleSnowflake))
    if (existingSchedule) return
    yield* put(fetchingSchedule(action.payload.scheduleSnowflake))
    const schedule = yield* callApi(scheduleService.getSchedule, action.payload.scheduleSnowflake)
    const scheduleTemplate = yield* callApi(scheduleService.getScheduleTemplate, schedule.timetable.id)
    yield* put(receiveSchedule(schedule))
    yield* put(receiveScheduleTemplate(scheduleTemplate))
  } catch (e) {
    yield* put(fetchingScheduleDone(action.payload.scheduleSnowflake))
    yield* put(throwError(e))
  }
}

function* requestScheduleTemplateTypes() {
  try {
    const scheduleTemplateTypes = yield* callApi(scheduleTemplateTypesService.getAll)
    yield* put(receiveScheduleTemplateTypes(scheduleTemplateTypes))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* requestAdjustmentBalances(action: any) {
  try {
    const adjustmentBalances = yield* callApi(
      adjustmentService.getBalances,
      action.payload.personId,
      action.payload.date
    )
    yield* put(receiveAdjustmentBalances(action.payload.personId, adjustmentBalances))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* registerAdjustment(action: any) {
  try {
    yield* callApi(adjustmentService.adjustmentPersonsBatch, action.payload.balances.work)
    yield* callApi(adjustmentService.adjustmentPersonsBatch, action.payload.balances.holiday)
    yield* put(adjustmentBalancesSaved(action.payload.personId))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* requestAbsenceRequestToValidateCount() {
  try {
    const count = yield* callApi(absenceRequestService.getToValidateCount)
    yield* put(receiveAbsenceRequestToValidateCount(count))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* fetchWeeklyHours(action: any) {
  try {
    const weeklyHours = yield* callApi(scheduleService.getWeeklyHours, action.payload)
    yield* put(receiveWeeklyHours({ weeklyHours, personId: action.payload.personId }))
  } catch (e: any) {
    if (!e.response || e.response.status !== 403) yield* put(throwError(e))
  }
}

function* requestEmployeeBalances({ payload: { date, personId } }: any) {
  try {
    let periods
    const schedules = [] as any[]
    const absences = [] as any[]
    const partialAbsences = [] as any[]
    let alldayAbsence = {}

    const isAllowedTimeclockModule = yield* select(isAllowed, [{ resource: 'timeclock', action: 'module' }])
    if (isAllowedTimeclockModule) {
      const employeeBalances = yield* callApi(scheduleService.getEmployeeBalances, date, personId)

      const { timechecks, partial_absence, allday_absence } = employeeBalances
      alldayAbsence = allday_absence || {}
      periods = employeeBalances.periods

      if (partial_absence) {
        lodash.forEach(partial_absence, absence => {
          partialAbsences.push(absence)
        })
      }

      yield* put(
        receivePermissions(employeeBalances.person.snowflake, {
          can_access_timeclock: employeeBalances.can_access_timeclock,
          can_add_proposal_timechecks: employeeBalances.can_add_proposal_timechecks,
          can_add_validation_timechecks: employeeBalances.can_add_validation_timechecks,
        })
      )

      yield* put(receiveTimechecks(timechecks))
    } else {
      const employeeScheduleBalances = yield* callApi(scheduleService.getEmployeeDailySchedules, date, personId)
      periods = employeeScheduleBalances.periods
    }

    lodash.forEach(PeriodAdapter.adapt(periods), period => {
      period.hasOwnProperty('planif_id') ? schedules.push(period) : absences.push(period)
    })

    yield* put(receivePeriods(schedules, absences, partialAbsences, alldayAbsence))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* requestExtraHours(action: any) {
  const { teamId, teamType, startDate, endDate } = action.payload
  try {
    // get team
    yield* callApi(requestTeam, requestTeamAction(teamId, teamType))

    // call api to retrieve snowflake
    const team = yield* callApi(findTeam, teamId, teamType)

    if (!team) {
      const error = 'tipee.missing_team'
      yield* put(receiveExtraHours(false, error))
      return
    }

    const kindsList = yield* select(getDirectoryKinds)

    // call api to get resource list
    const resourcesForTeam =
      // @ts-expect-error (nocheck)
      yield callApi(scheduleService.getTeamResources, team.snowflake, startDate, endDate, [kindsList.employee])

    // dispatch action to load summary
    yield* put(receiveTeamResourcesAction(teamId, startDate, endDate, resourcesForTeam))

    // get extra hours
    if (resourcesForTeam.length > 0) {
      const extraHours = yield* callApi(scheduleService.getExtraHours, resourcesForTeam, startDate, endDate)
      yield* put(
        // @ts-expect-error (nocheck)
        receiveExtraHours(extraHours)
      )
    } else {
      yield* put(
        // @ts-expect-error (nocheck)
        receiveExtraHours({})
      )
    }
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* requestSummaryForResources(action: any) {
  const { snowflakes } = action.payload
  yield* put(requestResourcesSummaries(snowflakes))
}

export const scheduleSaga = function* scheduleSaga() {
  all([
    yield* takeLatest(REQUEST_ABSENCE_REQUEST_TO_VALIDATE_COUNT, requestAbsenceRequestToValidateCount),
    yield* takeEvery(REQUEST_BALANCES, requestBalances),
    yield* takeEvery(REQUEST_SCHEDULE_TEMPLATE_TYPES, requestScheduleTemplateTypes),
    yield* takeEvery(REQUEST_ADJUSTMENT_BALANCES, requestAdjustmentBalances),
    yield* takeEvery(SAVE_ADJUSTMENT_BALANCES, registerAdjustment),
    yield* takeEvery(REQUEST_SCHEDULE_TEMPLATE, requestScheduleTemplate),
    yield* takeEvery(REQUEST_SCHEDULE, requestSchedule),
    yield* takeEvery(RECEIVE_TEAM_RESOURCES, requestSummaryForResources),
    yield* takeLatest(REQUEST_WEEKLY_HOURS, fetchWeeklyHours),
    yield* takeLatest(REQUEST_EMPLOYEE_BALANCES, requestEmployeeBalances),
    yield* takeLatest(REQUEST_EXTRA_HOURS, requestExtraHours),
  ])
}
