export const font = {
  display: {
    m: 'var(--font-display-m)',
  },
  title: {
    l: 'var(--font-title-l)',
    m: 'var(--font-title-m)',
  },
  textBold: {
    l: 'var(--font-textBold-l)',
    m: 'var(--font-textBold-m)',
    s: 'var(--font-textBold-s)',
  },
  text: {
    l: 'var(--font-text-l)',
    m: 'var(--font-text-m)',
    s: 'var(--font-text-s)',
  },
  icon: {
    xl: 'var(--font-icon-xl)',
    l: 'var(--font-icon-l)',
    m: 'var(--font-icon-m)',
    s: 'var(--font-icon-s)',
  },
} as const
