import { I18nContext } from 'core/i18n/components/I18nContext'
import { PrimitiveType } from 'intl-messageformat/src/formatters'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { IntlShape, MessageDescriptor } from 'react-intl'

export const usePrevious = <T>(prop: T) => {
  const previousProp = useRef(prop)

  useEffect(() => {
    previousProp.current = prop
  })

  return previousProp.current
}

export const useAutoFocusRef = () => {
  const input: any = useRef(null)

  useEffect(() => {
    input.current && input.current.focus()
  }, [])

  return input
}

export const useAutoSelectRef = () => {
  const input: any = useRef(null)

  useEffect(() => {
    input.current && input.current.select()
  }, [])

  return input
}

/**
 * @deprecated please use `t` function from `core/i18n` instead
 */
export const useIntl = () => {
  const intl = useContext(I18nContext)

  const formatMessage = useCallback(
    (messageDefinition: MessageDescriptor, values?: Record<string, PrimitiveType>, capitalize = true) => {
      const translated = (intl as IntlShape).formatMessage(messageDefinition, values, { ignoreTag: true })

      return capitalize ? translated.charAt(0).toUpperCase() + translated.slice(1) : translated
    },
    [intl]
  )

  return [formatMessage, { ...intl, formatMessage }]
}

// Simulates componentDidMount, by running the given callback only once
export const useOnMount = (callback: any) => {
  const mounted = useRef(false)

  /* istanbul ignore else */
  if (!mounted.current) {
    callback()
    mounted.current = true
  }
}

export const useEffectOnce = (effect: any) => {
  // eslint-disable-next-line
  useEffect(effect, [])
}

export const useCallbackOnce = (effect: any) => {
  // eslint-disable-next-line
  return useCallback(effect, [])
}

export const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies)

  const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    // eslint-disable-next-line
    console.log('[use-effect-debugger] ', changedDeps)
  }

  // eslint-disable-next-line
  useEffect(effectHook, dependencies)
}

export const useClickOutside = <I extends 'mouse' | 'touch' = 'mouse'>(
  ref: React.RefObject<HTMLElement> | HTMLElement | (React.RefObject<HTMLElement> | HTMLElement)[],
  callback: (e: I extends 'mouse' ? MouseEvent : TouchEvent) => void,
  inputType?: I
) => {
  // here we use `mousedown` instead of click to avoid race conditions
  // given that practically all components use `onClick` to close themselves and not `onMousedown`
  // see: https://javascript.info/mouse-events-basics#events-order
  const eventType = inputType === 'mouse' ? 'mousedown' : inputType === 'touch' ? 'touchstart' : 'mousedown'

  useEffect(() => {
    const handleClickOutside = (event: I extends 'mouse' ? MouseEvent : TouchEvent) => {
      let els = [ref].flat().map(r => (r instanceof HTMLElement ? r : r.current))
      // exclude elements that have the `useClickOutside-notOutside` class
      els = [...els, ...document.querySelectorAll<HTMLElement>('.' + useClickOutside.notOutsideClass)]

      if (els.every(el => el && !el.contains(event.target as Node))) callback(event)
    }

    document.addEventListener(eventType, handleClickOutside as any)
    return () => document.removeEventListener(eventType, handleClickOutside as any)
  }, [ref, callback])
}

// use this class to tell the hook to ignore an element when it's not possible to use a ref
useClickOutside.notOutsideClass = 'useClickOutside-notOutside'

/**
 * Returns a debounced value of the given value.
 *
 * see: https://github.com/uidotdev/usehooks/blob/v2.4.1/index.js#L239
 *
 * @param value the value to debounce
 * @param delay the delay in milliseconds (default: 250ms)
 */
export const useDebouncedValue = <T>(value: T, delay = 250) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useShowDebounce = <T extends HTMLElement>(ref: React.RefObject<T>, delay = 250) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const mouseOver = debounce(() => {
      setShow(true)
    }, delay)

    const mouseExit = () => {
      mouseOver.cancel()
      setShow(false)
    }

    if (ref.current) {
      ref.current.addEventListener('mouseover', mouseOver)
      ref.current.addEventListener('mouseout', mouseExit)

      return () => {
        ref.current && ref.current.removeEventListener('mouseover', mouseOver)
        ref.current && ref.current.removeEventListener('mouseleave', mouseExit)
      }
    }
  }, [delay])

  return show
}
