import { SnowflakeType, Team, TeamFull, TeamType } from 'core/types'

export class SectorAdapter {
  static fromApi(team: any) {
    return {
      snowflake: team.snowflake,
      id: parseInt(team.id),
      type: TeamType.Sector,
    } as Team
  }
}

export const SectorsBySiteAdapter = {
  fromApi: (
    sites: {
      id: number
      snowflake: SnowflakeType
      name: string
      sectors: { id: number; snowflake: SnowflakeType; name: string }[]
    }[]
  ) => {
    const teams: TeamFull[] = []

    sites.forEach(site => {
      teams.push({
        snowflake: site.snowflake,
        legacyId: site.id,
        parentSnowflake: null,
        name: site.name,
      })

      // push all sectors
      site.sectors.forEach(sector => {
        teams.push({
          snowflake: sector.snowflake,
          legacyId: sector.id,
          parentSnowflake: site.snowflake,
          name: sector.name,
        })
      })
    })

    return teams
  },
}
