import lodash from 'lodash'

const map = {
  a: 'a|á|à|ã|â|ä|À|Á|Ã|Â|Ä',
  e: 'e|é|è|ê|ë|É|È|Ê|Ë',
  i: 'i|í|ì|î|Ì|Î',
  o: 'o|ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
  u: 'u|ú|ù|û|ü|Ú|Ù|Û|Ü',
  c: 'c|ç|Ç',
  n: 'n|ñ|Ñ',
}

export const charmap = (value: any, flags = 'ig') => {
  let newValue = value
  lodash.forIn(map, regex => {
    newValue = newValue.replace(new RegExp(regex, 'ig'), '[' + regex + ']')
  })

  return new RegExp(newValue, flags)
}
