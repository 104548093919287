import { queryClient } from 'queryClient'
import { lazy, Suspense } from 'react'
// import 'react-app-polyfill/ie9'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from 'store'

import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(
  '74124e481150194f11cb3e27137fe880Tz02OTk2NCxFPTE3MjAwMDMxNDc1MzgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

// lazy imports not to inject all the react css when we just want the portal css
const App = lazy(() => import('App').then(m => ({ default: m.App })))
const Bridge = lazy(() => import('bridge').then(m => ({ default: m.Bridge })))

// @ts-expect-error (nocheck)
window.tipee = {
  startApp: () => {
    createRoot(document.getElementById('root')!).render(
      <Suspense fallback={<div></div>}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </Suspense>
    )
  },
  startBridge: (node, frontendBootstrap) => {
    createRoot(node).render(
      <Suspense fallback={<div></div>}>
        <Bridge frontendBootstrap={frontendBootstrap} />
      </Suspense>
    )
  },
  ready: true,
  utilsReady: false,
}

// used in index.html to know when the window.tipee object is ready
window.dispatchEvent(new Event('tipee:ready'))
