import { parseDateTime } from 'chronos'
import { DocumentResponse } from 'document/state/types'
import { Document, FILE_TYPES } from 'document/types'
import { TreeNode } from 'ui/dataDisplay/treeView/types'
import { latinize } from 'utils/latinize'

export const formatDocument = (document: DocumentResponse) => ({
  blobURI: document.blob_uri,
  createdAt: parseDateTime(document.created_at),
  createdBy: parseInt(document.created_by),
  depth: parseInt(document.depth),
  fullPath: document.full_path,
  hash: document.hash,
  hasChildrenFolder: document.has_children_folder,
  id: parseInt(document.id),
  lft: parseInt(document.lft),
  name: document.name,
  parentFolderId: document.parent_id ? parseInt(document.parent_id) : null,
  personId: document.person_id ? parseInt(document.person_id) : null,
  rgt: parseInt(document.rgt),
  scopeId: parseInt(document.scope_id),
  type: document.type,
  updatedAt: document.updated_at ? parseDateTime(document.updated_at) : null,
  updatedBy: document.updated_by ? parseInt(document.updated_by) : null,
  visible: !!parseInt(document.visible),
})

const sortByName = <T extends { name: string }>(object: T[]) =>
  object.sort((a, b) => latinize(a.name).toLowerCase().localeCompare(latinize(b.name).toLowerCase()))

export const getFoldersByParent = (folders: Document[], parentId: number) =>
  folders.filter((folder: Document) => folder.parentFolderId === parentId)

const formatToFoldersTreeViewRecursive = (folder: Document, folders: Document[]) => {
  const children: TreeNode[] = sortByName(
    getFoldersByParent(folders, folder.id).map((f: Document) => formatToFoldersTreeViewRecursive(f, folders))
  )

  return {
    id: folder.id,
    name: folder.name,
    hasChildren: folder.hasChildrenFolder,
    children,
  }
}

export const formatToFoldersTreeView = (initialFolders: Document[], folders: Document[]) =>
  sortByName(initialFolders.map((folder: Document) => formatToFoldersTreeViewRecursive(folder, folders)))

export const getFileType = (extension: string) => {
  const fileType = Object.values(FILE_TYPES).find(type => {
    return type.extensions.find(ext => ext === extension)
  })
  return fileType || FILE_TYPES.default
}
