export const muiFont = {
  size: {
    xs: '10px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
  },
  weight: {
    regular: 400,
    medium: 500,
    semibold: 600,
  },
} as const
