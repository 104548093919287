import { ResourceModes } from 'balance/types'

export class ResourceModesAdapter {
  static fromApi(resourceModes: any) {
    return {
      balanceMode: resourceModes.balance_mode,
      workDisplayMode: resourceModes.work_display_mode,
      timecheckMode: resourceModes.timecheck_mode,
      defaultBalanceMode: resourceModes.default_balance_mode,
      defaultWorkDisplayMode: resourceModes.default_work_display_mode,
      defaultTimecheckMode: resourceModes.default_timecheck_mode,
      canViewBalanceMode: resourceModes.can_view_balance_mode,
      canViewTimecheckMode: resourceModes.can_view_timecheck_mode,
      canViewWorkDisplayMode: resourceModes.can_view_work_display_mode,
      canChangeWorkDisplayMode: resourceModes.can_change_work_display_mode,
      canChangeBalanceMode: resourceModes.can_change_balance_mode,
      canChangeTimecheckMode: resourceModes.can_change_timecheck_mode,
      canDefineVariable: resourceModes.can_define_variables,
    } as ResourceModes
  }
}
