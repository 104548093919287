import { CoreState } from 'core/state/types'
import lodash from 'lodash'
import { createSelector } from 'reselect'
import { StoreStateType } from 'store/types'

const subState = (state: StoreStateType) => state.core

export const activeLanguage = (state: StoreStateType) => getConfigKey(state, 'active_language')
export const getFormat = (state: StoreStateType, format: keyof CoreState['ui']['formats']) =>
  subState(state).ui.formats[format]
export const getConfigKey = (state: StoreStateType, key: string) => lodash.get(getConfig(state), key)
export const getConfigKeys = (state: StoreStateType, keys: string[]) =>
  lodash.map(Array.isArray(keys) ? keys : [keys], key => getConfigKey(state, key))
export const getCurrentSidebarItem = (state: StoreStateType) => subState(state).ui.sidebar.currentItem
export const getCurrentSidebarSubsection = (state: StoreStateType) => subState(state).ui.sidebar.currentSubsection
export const getConfig = (state: StoreStateType) => subState(state).ui.config
export const getModule = (state: StoreStateType, module: string) => subState(state).modules[module] || {}
export const getSidebarWidth = (state: StoreStateType) => subState(state).ui.sidebar.width

export const getDirectoryKinds = (state: StoreStateType) => {
  return subState(state).ui.config.directory.kinds
}

export const isAppReady = (state: StoreStateType) =>
  Object.keys(subState(state).ui.config).length >= 5 &&
  Object.values(state.authentication.ui.boot).every(flag => flag === true)

export const isDesktop = (state: StoreStateType) => state.breakpoint.isLarge || state.breakpoint.isExtraLarge
export const isLoaderOpen = (state: StoreStateType) => subState(state).ui.loader.count > 0
export const getLoaderMessage = (state: StoreStateType) => subState(state).ui.loader.message
export const getLoaderTimeout = (state: StoreStateType) => subState(state).ui.loader.timeout
export const getLoaderCount = (state: StoreStateType) => subState(state).ui.loader.count
export const isMobileLayout = createSelector(
  (state: StoreStateType) => state.breakpoint,
  breakpoint => breakpoint.isSmall || breakpoint.isExtraSmall
)
export const isMobileMenuOpen = (state: StoreStateType) => subState(state).ui.mobilemenu.open
export const isModuleActive = (state: StoreStateType, module: string) => {
  const modules = subState(state).modules
  return modules[module] ? modules[module].enabled === 'checked' : false
}
export const isSearchbarVisible = (state: StoreStateType) => subState(state).ui.searchbar.visible
export const isSidebarOpen = (state: StoreStateType) => subState(state).ui.sidebar.open
export const isTablet = (state: StoreStateType) => state.breakpoint.isMedium || state.breakpoint.isLarge

// TODO refactor these two selectors (if truly needed) inside a topbar context
//      temporarly left there to avoid breaking the app
//      possible solution: use css class on body (?) to move up the slidepanel if the topbars is hidden
export const isTopbarVisible = (state: StoreStateType) => subState(state).ui.topbar.visible
export const getTopbarHeight = (state: StoreStateType) => 66

export const hasUnrecoverableError = (state: StoreStateType) => subState(state).ui.hasUnrecoverableError
export const hasNewUpdateAvailable = (state: StoreStateType) => subState(state).ui.newUpdateAvailable
