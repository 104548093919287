import produce from 'immer'
import * as timeclockActions from 'timeclock/state/actions'

export const initialState = {
  presences: {},
  timechecks: {},
  lastTimecheck: {},
  listTimecheckForRange: {},
  listTimecheckForDate: [],
  ui: {
    presences: {
      isFetching: false,
      filter: '',
    },
    lastTimecheck: {
      activeTimecheckTag: {
        requestValidation: false,
      },
      isFetching: false,
    },
    listTimecheckForRange: {
      isFetching: false,
      isForbidden: false,
      isFetchingValidation: false,
    },
    listTimecheckForDate: {
      isFetching: false,
      isForbidden: false,
      isFetchingValidation: false,
    },
    changeActiveTimecheckTag: {
      isFetching: false,
    },
    timecheckModal: {
      timecheckTags: {
        isPosting: false,
      },
    },
  },
}

export const timeclockReducer = (currentState = initialState, action: any) =>
  produce(currentState, (draft: any) => {
    switch (action.type) {
      case timeclockActions.REQUEST_PRESENCE:
        draft.ui.presences.isFetching = true
        break
      case timeclockActions.RECEIVE_PRESENCE:
        const { presences } = action.payload

        presences.forEach((presence: any) => (draft.presences[presence.person_id] = presence))
        draft.ui.presences.isFetching = false
        break
      case timeclockActions.REQUEST_LAST_TIMECHECK:
        draft.ui.lastTimecheck.isFetching = true
        break
      case timeclockActions.REQUEST_LIST_TIMECHECK_FOR_RANGE:
        draft.ui.listTimecheckForRange.isFetching = true
        draft.ui.listTimecheckForRange.isForbidden = false
        break
      case timeclockActions.REQUEST_LIST_TIMECHECK_FOR_DATE:
        draft.ui.listTimecheckForDate.isFetching = true
        draft.ui.listTimecheckForDate.isForbidden = false
        break
      case timeclockActions.REQUEST_PUT_TIMECHECK_RANGE_VALIDATION:
        draft.ui.listTimecheckForRange.isFetchingValidation = true
        break
      case timeclockActions.RECEIVE_LAST_TIMECHECK: {
        draft.ui.lastTimecheck.activeTimecheckTag.requestValidation = false
        draft.ui.lastTimecheck.isFetching = false
        draft.lastTimecheck = action.payload.lastTimecheck
        break
      }
      case timeclockActions.RECEIVE_LIST_TIMECHECK_FOR_RANGE: {
        const { listTimecheckForRange, personId } = action.payload
        draft.ui.listTimecheckForRange.isFetching = false
        draft.listTimecheckForRange = {
          ...draft.listTimecheckForRange,
          [personId]: {
            days: {
              ...draft.listTimecheckForRange[personId]?.days,
              ...listTimecheckForRange[0]?.days,
            },
          },
        }
        break
      }
      case timeclockActions.RECEIVE_LIST_TIMECHECK_FOR_RANGE_FORBIDDEN: {
        draft.ui.listTimecheckForRange.isFetching = false
        draft.ui.listTimecheckForRange.isForbidden = true
        break
      }
      case timeclockActions.RECEIVE_LIST_TIMECHECK_FOR_DATE_FORBIDDEN: {
        draft.ui.listTimecheckForDate.isFetching = false
        draft.ui.listTimecheckForDate.isForbidden = true
        break
      }
      case timeclockActions.RECEIVE_LIST_TIMECHECK_FOR_DATE: {
        const { timechecks } = action.payload
        draft.ui.listTimecheckForDate.isFetching = false

        draft.listTimecheckForDate =
          timechecks &&
          timechecks.map((timecheck: any) => ({
            ...timecheck,
            activities: Object.values(timecheck.activities || {}),
          }))
        break
      }
      case timeclockActions.FILTER_PRESENCE:
        draft.ui.presences.filter = action.payload.text
        break
      case timeclockActions.RECEIVE_TIMECHECKS:
        action.payload.timechecks.forEach((timecheck: any) => (draft.timechecks[timecheck.id] = timecheck))
        break
      case timeclockActions.RECEIVE_PUT_TIMECHECK_VALIDATION: {
        const { personId, day, validatedTimechecks } = action.payload
        const { listTimecheckForRange, listTimecheckForDate } = draft

        // update collaboratorValidation store
        if (listTimecheckForRange[personId]?.days[day]) {
          validatedTimechecks.forEach((validatedTimecheck: any) => {
            // timecheck by timecheck validation
            listTimecheckForRange[personId].days[day] = {
              ...listTimecheckForRange[personId].days[day],
              timechecks: listTimecheckForRange[personId].days[day].timechecks.reduce((result: any, timecheck: any) => {
                if (timecheck.id === validatedTimecheck) {
                  return [...result, { ...timecheck, validation_type: 'manual' }]
                }
                return [...result, { ...timecheck }]
              }, []),
            }

            // global day validation
            listTimecheckForRange[personId].days[day].need_validation =
              listTimecheckForRange[personId].days[day].timechecks.filter(
                (timecheck: any) => timecheck.validation_type !== 'manual'
              ).length > 0
          })
        }

        // update dayValidation store
        const dayIndex = Object.keys(listTimecheckForDate).find(index => {
          return listTimecheckForDate[index].person.id === personId
        })

        if (dayIndex) {
          validatedTimechecks.forEach((validatedTimecheck: any) => {
            // timecheck by timecheck validation
            listTimecheckForDate[dayIndex] = {
              ...listTimecheckForDate[dayIndex],
              timechecks: listTimecheckForDate[dayIndex].timechecks.reduce((result: any, timecheck: any) => {
                if (timecheck.id === validatedTimecheck) {
                  return [...result, { ...timecheck, validation_type: 'manual' }]
                }
                return [...result, { ...timecheck }]
              }, []),
            }

            // global day validation
            listTimecheckForDate[dayIndex].need_validation =
              listTimecheckForDate[dayIndex].timechecks.filter((timecheck: any) => {
                return timecheck.validation_type !== 'manual'
              }).length > 0
          })
        }
        break
      }
      case timeclockActions.RECEIVE_PUT_TIMECHECK_RANGE_VALIDATION: {
        const { personId, validatedDays, validatedTimechecks } = action.payload
        const { listTimecheckForRange } = draft

        // fully validated days
        draft.ui.listTimecheckForRange.isFetchingValidation = false
        draft.listTimecheckForRange = {
          ...listTimecheckForRange,
          [personId]: {
            ...listTimecheckForRange[personId],
            days: {
              ...listTimecheckForRange[personId].days,
              ...validatedDays.reduce(
                (result: any, day: any) => ({
                  ...result,
                  [day]: {
                    ...listTimecheckForRange[personId].days[day],
                    need_validation:
                      draft.listTimecheckForRange[personId].days[day].need_validation &&
                      !listTimecheckForRange[personId].days[day].timechecks.some((e: any) => e.can_validate),

                    timechecks: listTimecheckForRange[personId].days[day].timechecks.reduce(
                      (result: any, timecheck: any) => {
                        const canEdit =
                          draft.listTimecheckForRange[personId].days[day].need_validation && timecheck.can_validate
                        if (canEdit) {
                          return [...result, { ...timecheck, validation_type: 'manual' }]
                        }
                        return [...result, { ...timecheck }]
                      },
                      []
                    ),
                  },
                }),
                {}
              ),
            },
          },
        }

        // partially validated days : validate timechecks one by one
        Object.keys(validatedTimechecks).forEach(day => {
          draft.listTimecheckForRange[personId].days[day] = {
            ...listTimecheckForRange[personId].days[day],
            timechecks: listTimecheckForRange[personId].days[day].timechecks.reduce((result: any, timecheck: any) => {
              if (validatedTimechecks[day].find((id: any) => timecheck.id === id)) {
                return [...result, { ...timecheck, validation_type: 'manual' }]
              }
              return [...result, { ...timecheck }]
            }, []),
          }
        })
        break
      }
      case timeclockActions.RECEIVE_PUT_TIMECHECK_DATE_VALIDATION: {
        const { validatedTimechecks } = action.payload
        const { listTimecheckForDate } = draft
        draft.ui.listTimecheckForDate.isFetchingValidation = false

        validatedTimechecks.forEach((validatedTimecheck: any) => {
          const personId = parseInt(validatedTimecheck.person_id)
          const dayIndex = Object.keys(listTimecheckForDate).find(index => {
            return listTimecheckForDate[index].person.id === personId
          })

          if (dayIndex) {
            listTimecheckForDate[dayIndex] = {
              ...listTimecheckForDate[dayIndex],
              timechecks: listTimecheckForDate[dayIndex].timechecks.reduce((result: any, timecheck: any) => {
                if (timecheck.id === validatedTimecheck.id) {
                  return [...result, { ...timecheck, validation_type: 'manual' }]
                }
                return [...result, { ...timecheck }]
              }, []),
            }

            listTimecheckForDate[dayIndex].need_validation =
              listTimecheckForDate[dayIndex].timechecks.filter(
                (timecheck: any) => timecheck.validation_type !== 'manual'
              ).length > 0
          }
        })

        break
      }
      case timeclockActions.REQUEST_CHANGE_ACTIVE_TIMECHECK_TAG: {
        draft.ui.changeActiveTimecheckTag.isFetching = true
        break
      }
      case timeclockActions.RECEIVE_CHANGE_ACTIVE_TIMECHECK_TAG: {
        draft.ui.changeActiveTimecheckTag.isFetching = false
        break
      }
      case timeclockActions.REQUEST_TIMECHECK_TAG_VALIDATION: {
        draft.ui.lastTimecheck.activeTimecheckTag.requestValidation = true
        break
      }
      case timeclockActions.RECEIVE_TIMECHECK_TAG_VALIDATION: {
        draft.ui.lastTimecheck.activeTimecheckTag.requestValidation = false
        break
      }
      case timeclockActions.RECEIVE_ASSIGN_LAST_TIMECHECK_TAG:
        {
          const { tags } = action.payload
          const newTags = tags.map((e: any) => ({
            timecheck_id: draft.lastTimecheck.snowflake,
            tag_id: e,
            datetime: '',
            author: '',
            deleted_at: null,
            deleted_by: null,
          }))
          draft.lastTimecheck.timecheckTags = newTags
          draft.ui.lastTimecheck.activeTimecheckTag.requestValidation = false
        }
        break
      case timeclockActions.REQUEST_ASSIGN_TAG_TO_TIMECHECK: {
        draft.ui.timecheckModal.timecheckTags.isPosting = true
        break
      }
      case timeclockActions.RECEIVE_ASSIGN_TAG_TO_TIMECHECK: {
        draft.ui.timecheckModal.timecheckTags.isPosting = false
        break
      }
      default:
        break
    }
  })
