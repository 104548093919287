import { SnowflakeType } from 'core/types'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { StoreStateType } from 'store/types'
import { getEnabledTags, getTags, getTagSelectTags, isFetchingTags, isFetchingTagSelectTags } from 'tag/state/selectors'
import { TagOptionType, TagType } from 'tag/types'

const subState = (state: StoreStateType) => state.activity

export const getActivities = (state: StoreStateType) => subState(state).activities
export const isFetchingActivityRecentTags = (state: StoreStateType) => subState(state).ui.recentTags.isFetching
export const getOpenActivity = createSelector(getActivities, activities =>
  Object.values(activities).find(activity => activity.period.endDate === null)
)

export const getRecentActivityTags: (state: StoreStateType, forcedTags?: SnowflakeType[]) => TagType[] =
  createCachedSelector(
    (state: StoreStateType) => subState(state).recentTags,
    (state: StoreStateType, forcedTags?: SnowflakeType[]) => forcedTags,
    getTags,
    getTagSelectTags,
    (recentTagsList, forcedTags, allTags, tagSelectTags) => {
      const allRecentActiveTag: TagType[] = []
      const availableTags = [...tagSelectTags]
      const tagsList = [...recentTagsList]

      // add forced tags to available tags (to bypass tag.enabled)
      forcedTags &&
        forcedTags.forEach(snowflake => {
          if (!availableTags.includes(snowflake)) availableTags.push(snowflake)
          if (!tagsList.includes(snowflake)) tagsList.push(snowflake)
        })

      tagsList.forEach((snowflake: SnowflakeType) => {
        let tagDetails

        // check if tag exist in tagSelect to be sure we have access to the tag
        if (availableTags.find(tagSelectSnowflake => tagSelectSnowflake === snowflake)) {
          const tempTag = Object.values(allTags).find(tag => tag.snowflake === snowflake)

          // filter disabled tags
          if (tempTag && (tempTag.enabled || forcedTags?.includes(tempTag.snowflake))) {
            tagDetails = tempTag
          }
        }

        tagDetails && allRecentActiveTag.push(tagDetails)
      })

      return Object.values(allRecentActiveTag).slice(0, 10)
    }
  )(state => 'recent_activity_tags')

export const getRecentActivityTagsAsOptions: (state: StoreStateType, forcedTags?: SnowflakeType[]) => TagOptionType[] =
  createCachedSelector(
    (state: StoreStateType, forcedTags?: SnowflakeType[]) => getRecentActivityTags(state, forcedTags),
    (state: StoreStateType, forcedTags?: SnowflakeType[]) => forcedTags,
    (recentTags, forcedTags) => {
      return recentTags.map((current: TagType) => ({
        label: current.name,
        value: current.id.toString(),
        snowflake: current.snowflake,
      }))
    }
  )((state, personId: number) => 'recent_activity_tags_as_options_' + JSON.stringify(personId))

export const getTagsWithSnowflake = createCachedSelector(
  getEnabledTags,
  (state: StoreStateType, tagsSnowflake: any) => tagsSnowflake,
  (allTags, tagsSnowflake) => {
    const tagsDetail: TagType[] = []
    if (tagsSnowflake && allTags) {
      tagsSnowflake.forEach((snowflake: SnowflakeType) => allTags[snowflake] && tagsDetail.push(allTags[snowflake]))
    }
    return tagsDetail
  }
)((state, tagsSnowflake: any) => `tags_data_for_${tagsSnowflake?.join('-')}`)

export const isFetchingActivityTagSelect = createCachedSelector(
  isFetchingTags,
  isFetchingTagSelectTags,
  isFetchingActivityRecentTags,
  (isFetching, isFetchingTagSelect, isFetchingRecent) => {
    return isFetching || isFetchingTagSelect || isFetchingRecent
  }
)(state => 'is_fetching_activity_tag_select')

export const hasOpenActivity = (state: StoreStateType) => !!getOpenActivity(state)
export const isFetchingActivities = (state: StoreStateType) => subState(state).ui.activities.isFetching
