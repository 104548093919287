/* eslint-disable no-restricted-imports */
import { LocalDate, Period } from '@js-joda/core'
/* eslint-enable no-restricted-imports */
import { DateRange } from '@mui/x-date-pickers-pro'
import { Interval } from 'chronos/interval/Interval'
import { parseInterval } from 'chronos/interval/parseInterval'
import { FormatPattern, format, toJsDate, toLocalDate } from 'chronos/utils'
import { t } from 'core/i18n'

export class LocalDateInterval extends Interval<LocalDate> {
  static parse(value: string) {
    return parseInterval(LocalDate, LocalDateInterval, value)
  }

  /**
   * The same as `parse` but using a URL safe separator `~`
   */
  static parseURL(value: string) {
    return parseInterval(LocalDate, LocalDateInterval, value, { urlSafe: true })
  }

  static of(dateRange: DateRange<Date>): LocalDateInterval
  static of(start: Date | null, end: Date | null): LocalDateInterval
  static of(start: LocalDate | null, end: LocalDate | null): LocalDateInterval
  static of(
    firstArg: LocalDate | DateRange<Date> | Date | null,
    secondArg?: LocalDate | Date | null
  ): LocalDateInterval {
    // MUI DateRange
    if (Array.isArray(firstArg) && secondArg === undefined) {
      return new LocalDateInterval(
        firstArg[0] ? toLocalDate(firstArg[0]) : null,
        firstArg[1] ? toLocalDate(firstArg[1]) : null
      )
    }

    // Two args as JsDate
    if (
      (firstArg instanceof Date || firstArg === null) &&
      (secondArg instanceof Date || secondArg === null || secondArg === undefined)
    ) {
      return new LocalDateInterval(firstArg ? toLocalDate(firstArg) : null, secondArg ? toLocalDate(secondArg) : null)
    }

    // Two args as LocalDate
    if (
      (firstArg instanceof LocalDate || firstArg === null) &&
      (secondArg instanceof LocalDate || secondArg === null)
    ) {
      return new LocalDateInterval(firstArg, secondArg)
    }

    throw new Error(`LocalDateInterval factory: Cannot create LocalDateInterval with "${firstArg}" and "${secondArg}"`)
  }

  static since(start: LocalDate) {
    return new LocalDateInterval(start, null)
  }

  static until(end: LocalDate) {
    return new LocalDateInterval(null, end)
  }

  static ofToday() {
    return LocalDateInterval.ofDay(LocalDate.now())
  }

  static ofDay(day: LocalDate) {
    return LocalDateInterval.of(day, day)
  }

  static ofThisWeek() {
    return LocalDateInterval.ofWeekContaining(LocalDate.now())
  }

  static ofWeekContaining(innerDay: LocalDate) {
    const currentDayOfWeek = innerDay.dayOfWeek().ordinal()
    return LocalDateInterval.of(innerDay.minusDays(currentDayOfWeek), innerDay.plusDays(6 - currentDayOfWeek))
  }

  static ofThisMonth() {
    return LocalDateInterval.ofMonthContaining(LocalDate.now())
  }

  static ofMonthContaining(innerDay: LocalDate) {
    return LocalDateInterval.of(innerDay.withDayOfMonth(1), innerDay.withDayOfMonth(1).plusMonths(1).minusDays(1))
  }

  static ofThisYear() {
    return LocalDateInterval.ofYearContaining(LocalDate.now())
  }

  static ofYearContaining(innerDay: LocalDate) {
    return LocalDateInterval.of(innerDay.withDayOfYear(1), innerDay.withDayOfYear(1).plusYears(1).minusDays(1))
  }

  static containerOf(items: LocalDate[]) {
    if (items.length === 0) return new LocalDateInterval(null, null)
    if (items.length === 1) return new LocalDateInterval(items[0], items[0])

    const min = items.reduce<LocalDate>((acc, item) => (item.isBefore(acc) ? item : acc), items[0])
    const max = items.reduce<LocalDate>((acc, item) => (item.isAfter(acc) ? item : acc), items[0])

    return new LocalDateInterval(min, max)
  }

  toMuiDateRange(): DateRange<Date> {
    return [this.start ? toJsDate(this.start) : null, this.end ? toJsDate(this.end) : null]
  }

  *iterate() {
    if (!this.isFinite()) throw new Error('Iterate is not supported for infinite interval.')

    for (let i = this.start; i.isBefore(this.end.plusDays(1)); i = i.plusDays(1)) {
      yield i
    }
  }

  days() {
    return Array.from(this.iterate())
  }

  countDays() {
    if (!this.start || !this.end) return Infinity

    return this.end.toEpochDay() - this.start.toEpochDay() + 1
  }

  format(formatPattern?: FormatPattern, options?: { verboseFiniteRange?: boolean; capitalize?: boolean }) {
    const pattern = formatPattern || 'default'
    if (this.isFinite()) {
      return options?.verboseFiniteRange
        ? t(
            'tipee.from_date_to_date',
            {
              startDate: format.local(this.start, pattern),
              endDate: format.local(this.end, pattern),
            },
            { capitalize: options?.capitalize ?? true }
          )
        : `${format.local(this.start, pattern)} - ${format.local(this.end, pattern)}`
    }
    if (this.hasStart()) {
      return t(
        'tipee.since_date',
        { date: format.local(this.start, pattern) },
        { capitalize: options?.capitalize ?? true }
      )
    }
    if (this.hasEnd()) {
      return t(
        'planning.until.date',
        { date: format.local(this.end, pattern) },
        { capitalize: options?.capitalize ?? true }
      )
    }
  }

  formatWeek() {
    if (this.hasStart()) {
      return t('tipee.date.week_format', { date: format.local(this.start, 'weekNumber') })
    }
    if (this.hasEnd()) {
      return t('tipee.date.week_format', { date: format.local(this.end, 'weekNumber') })
    }

    throw new Error('Cannot format week with no start or end')
  }

  move(p: Period) {
    return LocalDateInterval.of(this.start && this.start.plus(p), this.end && this.end.plus(p))
  }

  equal(other: LocalDateInterval) {
    return this.start?.equals(other.start) && this.end?.equals(other.end)
  }

  //inlcudes the end and start date
  containsDate(date: LocalDate) {
    const interval = LocalDateInterval.of(date, date)
    return this.intersects(interval)
  }

  isInSameMonth() {
    return this.start?.monthValue() === this.end?.monthValue()
  }

  isInSameYear() {
    return this.start?.year() === this.end?.year()
  }

  isFullMonth() {
    return (
      this.isInSameMonth() &&
      this.isInSameYear() &&
      this.start?.dayOfMonth() === 1 &&
      this.end?.dayOfMonth() === this.end?.lengthOfMonth()
    )
  }

  isFullYear() {
    return this.isInSameYear() && this.start?.dayOfYear() === 1 && this.end?.dayOfYear() === this.end?.lengthOfYear()
  }

  prevFullMonth() {
    if (!this.start || !this.end) return this

    const month = this.start.monthValue()
    const year = this.start.year()
    const prevMonth = month === 1 ? 12 : month - 1
    const prevYear = month === 1 ? year - 1 : year
    const prevMonthDate = LocalDate.of(prevYear, prevMonth, 1)

    return LocalDateInterval.of(prevMonthDate, LocalDate.of(prevYear, prevMonth, prevMonthDate.lengthOfMonth()))
  }

  nextFullMonth() {
    if (!this.start || !this.end) return this

    const month = this.start.monthValue()
    const year = this.start.year()
    const nextMonth = month === 12 ? 1 : month + 1
    const nextYear = month === 12 ? year + 1 : year
    const nextMonthDate = LocalDate.of(nextYear, nextMonth, 1)

    return LocalDateInterval.of(nextMonthDate, LocalDate.of(nextYear, nextMonth, nextMonthDate.lengthOfMonth()))
  }

  prevFullYear() {
    if (!this.start || !this.end) return this

    const year = this.start.year()
    const prevYearDate = LocalDate.of(year - 1, 1, 1)
    const lastPrevYearMonthDate = LocalDate.of(year - 1, 12, 1)

    return LocalDateInterval.of(prevYearDate, LocalDate.of(year - 1, 12, lastPrevYearMonthDate.lengthOfMonth()))
  }

  nextFullYear() {
    if (!this.start || !this.end) return this

    const year = this.start.year()
    const nextYearDate = LocalDate.of(year + 1, 1, 1)
    const lastNextYearMonthDate = LocalDate.of(year + 1, 12, 1)

    return LocalDateInterval.of(nextYearDate, LocalDate.of(year + 1, 12, lastNextYearMonthDate.lengthOfMonth()))
  }

  prevRangeInterval() {
    if (this === undefined || this.start === null || this.end === null) return

    let newDate
    if (this?.isFullMonth()) newDate = this.prevFullMonth()
    else if (this?.isFullYear()) newDate = this.prevFullYear()
    // range with fixed number of days, handles case like 10.02.xxx-9.03.xxx => 10.01.xxx-9.02.xxx
    else {
      const range = this.countDays()
      const newRange = range === this.start.lengthOfMonth() ? this.start.minusMonths(1).lengthOfMonth() : range
      newDate = LocalDateInterval.of(this.start.minusDays(newRange), this.start.minusDays(1))
    }

    return newDate
  }

  nextRangeInterval() {
    if (this === undefined || this.start === null || this.end === null) return

    let newDate
    if (this?.isFullMonth()) newDate = this.nextFullMonth()
    else if (this?.isFullYear()) newDate = this.nextFullYear()
    // range with fixed number of days, handles case like 10.02.xxx-9.03.xxx => 10.03.xxx-9.04.xxx
    else {
      const range = this.countDays()
      const newRange = range === this.start.lengthOfMonth() ? this.end.lengthOfMonth() : range
      newDate = LocalDateInterval.of(this.end.plusDays(1), this.end.plusDays(newRange))
    }

    return newDate
  }
}
