import { getActiveUser } from 'authentication/state/selectors'
import { communicationService, GetMessagesResponse } from 'communication/services/communication'
import { receiveMessages } from 'communication/state/actions'
import { REQUEST_MESSAGES } from 'communication/state/types'
import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { isModuleActive } from 'core/state/selectors'
import { put, select, takeLatest } from 'typed-redux-saga'

function* fetchActiveUserMessages() {
  const isCommunicationActive: boolean = yield* select(isModuleActive, 'communication')
  const activeUser = yield* select(getActiveUser)

  if (!isCommunicationActive) {
    return
  }

  try {
    const messagesResponse: GetMessagesResponse = yield* callApi(communicationService.getMessages, activeUser.id)
    yield* put(receiveMessages(messagesResponse.messages || [], messagesResponse.nb_of_messages || 0))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export const communicationSaga = function* communicationSaga() {
  yield* takeLatest(REQUEST_MESSAGES, fetchActiveUserMessages)
}
