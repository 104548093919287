/* eslint-disable @typescript-eslint/no-var-requires */
const detect = require('detect.js')
const MobileDetect = require('mobile-detect')

export const isMobileDevice = () => detect.parse(navigator.userAgent).device.type === 'Mobile'

export const getDeviceModel = () => {
  const ua = detect.parse(navigator.userAgent)
  const md = new MobileDetect(navigator.userAgent)
  if (md.mobile()) {
    let mobileDevice = (md.phone() || md.tablet()).replace('Unknown', 'Generic')
    if ((mobileDevice.includes('Phone') || mobileDevice.includes('Tablet')) && !md.is('iPhone')) {
      mobileDevice = mobileDevice.replace('Phone', '')
      mobileDevice = mobileDevice.replace('Tablet', '')
    }
    const isDeviceTypeDetermined = md.is('iPad') || md.is('iPhone')
    return `${mobileDevice}${!isDeviceTypeDetermined ? ` ${md.phone() ? 'Phone' : md.tablet() ? 'Tablet' : ''}` : ''}`
  } else {
    return ua.os.family
  }
}
