import { useMemo } from 'react'
import * as S from 'tag/components/tagChip/styled'

export type TagChipProps = {
  label: string
  className?: string
  disabled?: boolean
}

export const TagChip = ({ label, className = '', disabled = false }: TagChipProps) => {
  const disabledClassName = useMemo(() => (disabled ? `disabled` : ``), [disabled])

  return (
    <S.Badge className={`${className} ${disabledClassName}`} data-cy="tag-chip">
      {label}
    </S.Badge>
  )
}
