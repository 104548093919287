/* eslint-disable gammadia/no-new-date */
const toNumbers = (value: string) => parseInt(value, 10)

/**
 *  @deprecated - use js-joda instead of native js Dates
 */
export const parseDateTime = (date?: string, time?: string): Date => {
  if (date && typeof date === 'string') {
    if (date.search(/[Z+]/) !== -1) {
      return new Date(date)
    }
    let separator
    if (date.search(/[/]/) !== -1) {
      separator = '/'
    } else if (date.search(/[.]/) !== -1) {
      separator = '.'
    } else {
      separator = '-'
    }
    let timeInfos
    const dateInfos = date
      .split(separator)
      .map(toNumbers)
      .map((value, index) => (index === 1 ? value - 1 : value)) // new Date() expects 2nd argument to be a numeric month between 0-11, and not 1-12
    if (date.split(/[\sT]/)[1]) {
      timeInfos = date.split(/[\sT]/)[1].split(':').map(toNumbers)
    } else if (time && typeof time === 'string') {
      timeInfos = time.split(':').map(toNumbers)
    } else {
      return new Date(dateInfos[0], dateInfos[1], dateInfos[2])
    }
    return new Date(dateInfos[0], dateInfos[1], dateInfos[2], ...timeInfos)
  }
  return new Date(Date.now())
}
