import { pal } from 'design/colors'

export const bg = {
  white: {
    default: pal.white,
    hover: pal.grey50,
  },
  grey: {
    light: {
      default: pal.grey100,
      hover: pal.grey200,
    },
    strong: {
      default: pal.grey600,
      hover: pal.grey600,
    },
  },
  blue: {
    light: {
      default: pal.blue100,
      hover: pal.blue200,
    },
    medium: {
      default: pal.blue400,
      hover: pal.blue500,
    },
    strong: {
      default: pal.blue600,
      hover: pal.blue700,
    },
  },
  pink: {
    light: {
      default: pal.pink100,
      hover: pal.pink200,
    },
    strong: {
      default: pal.pink700,
      hover: pal.pink800,
    },
  },
  success: {
    light: {
      default: pal.green100,
      hover: pal.green200,
    },
    strong: {
      default: pal.green600,
      hover: pal.green700,
    },
  },
  warning: {
    light: {
      default: pal.orange100,
      hover: pal.orange200,
    },
    strong: {
      default: pal.orange600,
      hover: pal.orange700,
    },
  },
  error: {
    light: {
      default: pal.red100,
      hover: pal.red200,
    },
    strong: {
      default: pal.red600,
      hover: pal.red700,
    },
  },
} as const
