import { brain } from 'core/services/api'
import { BookmarkType } from 'quality/types'

export const bookmarksService = {
  getBookmarks: async (): Promise<BookmarkType[]> => {
    try {
      const config = await brain.get<BookmarkType[]>('/quality/bookmarks')
      return config.data || []
    } catch (e: any) {
      throw e
    }
  },
}
