export const FONT_WEIGHT_LIGHT = 'light'
export const FONT_WEIGHT_REGULAR = 'regular'
export const FONT_WEIGHT_MEDIUM = 'medium'
export const FONT_WEIGHT_SEMIBOLD = 'semibold'

export type FontWeight =
  | typeof FONT_WEIGHT_LIGHT
  | typeof FONT_WEIGHT_REGULAR
  | typeof FONT_WEIGHT_MEDIUM
  | typeof FONT_WEIGHT_SEMIBOLD
