import {
  AclRecursionType,
  AuthenticationActionTypes,
  AuthenticationState,
  RECEIVE_ACLS,
  RECEIVE_PERMISSIONS,
  RECEIVE_TWO_FACTOR_METHOD,
  REQUEST_REMOVAL_TWO_FACTOR_METHOD,
  REQUEST_TWO_FACTOR_METHOD,
  SAVE_PREFERENCE,
  USER_LOGGED_OUT,
} from 'authentication/state/types'
import { ActiveUserType, PreferencesType } from 'authentication/types'
import { RECEIVE_BOOTSTRAP } from 'core/state/types'
import produce from 'immer'

const initialState: AuthenticationState = {
  ui: {
    boot: {
      acl: false,
      activeUser: false,
      preferences: false,
    },
    twoFactor: {
      isFetching: false,
      isPosting: false,
      isDeleting: false,
    },
  },
  activeUser: null as unknown as ActiveUserType,
  preferences: {},
  session: {},
  twoFactor: {},
  permissions: {},
  acls: {
    values: {},
    expiration: null,
  },
}

export const authenticationReducer = (
  currentState: AuthenticationState = initialState,
  action: AuthenticationActionTypes
) =>
  produce(currentState, draft => {
    switch (action.type) {
      case USER_LOGGED_OUT:
        draft.activeUser = null as unknown as ActiveUserType
        break
      case RECEIVE_BOOTSTRAP:
        // preferences
        draft.preferences = action.payload.boot.preferences as PreferencesType
        draft.ui.boot.preferences = true

        // acls
        draft.ui.boot.acl = true
        draft.acls = {
          values: action.payload.boot.acl as AclRecursionType,
          expiration: action.payload.aclExpiration,
        }

        // active user
        draft.activeUser = action.payload.boot.user as ActiveUserType
        draft.ui.boot.activeUser = true

        // session
        draft.session = action.payload.boot.session as any
        break
      case RECEIVE_ACLS:
        draft.ui.boot.acl = true
        draft.acls = {
          values: action.payload.acls,
          expiration: action.payload.expiration,
        }
        break
      case SAVE_PREFERENCE:
        // We stringify and parse the payload value to make sure we store only JSON-compatibles values in preferences.
        draft.preferences[action.payload.preference] = JSON.parse(JSON.stringify(action.payload.value))
        break
      case RECEIVE_PERMISSIONS:
        const { snowflake, permissions } = action.payload
        draft.permissions[snowflake] = {
          ...draft.permissions[snowflake],
          ...permissions,
        }
        break
      case REQUEST_TWO_FACTOR_METHOD:
        draft.ui.twoFactor.isFetching = true
        break
      case RECEIVE_TWO_FACTOR_METHOD:
        draft.twoFactor[action.payload.userId] = action.payload.data
        draft.ui.twoFactor.isFetching = false
        draft.ui.twoFactor.isDeleting = false
        draft.ui.twoFactor.isPosting = false
        break
      case REQUEST_REMOVAL_TWO_FACTOR_METHOD:
        draft.ui.twoFactor.isDeleting = true
        break
      default:
        break
    }
  })
