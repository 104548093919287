import { SnowflakeType } from 'core/types'
import {
  AttachmentType,
  ExpenseReportStatusType,
  ExpenseReportTransitionType,
  ExpenseReportType,
  ExpenseType,
  ExpenseTypeType,
  EXPENSE_REPORT_GROUPS,
  EXPENSE_REPORT_STATUS_VALIDATED,
  EXPENSE_REPORT_TYPES,
} from 'expenses/types'

export const REQUEST_EXPENSE_REPORT_SUMMARY = 'REQUEST_EXPENSE_REPORT_SUMMARY'
export const RECEIVE_EXPENSE_REPORT_SUMMARY = 'RECEIVE_EXPENSE_REPORT_SUMMARY'
export const REQUEST_EXPENSE_REPORT_DETAILS = 'REQUEST_EXPENSE_REPORT_DETAILS'
export const RECEIVE_EXPENSE_REPORT_DETAILS = 'RECEIVE_EXPENSE_REPORT_DETAILS'
export const REQUEST_EXPENSE_TYPES_ENABLED = 'REQUEST_EXPENSE_TYPES_ENABLED'
export const RECEIVE_EXPENSE_TYPES_ENABLED = 'RECEIVE_EXPENSE_TYPES_ENABLED'
export const RENAME_EXPENSE_REPORT = 'RENAME_EXPENSE_REPORT'
export const DELETE_EXPENSE_REPORT = 'DELETE_EXPENSE_REPORT'
export const CONFIRM_DELETE_EXPENSE_REPORT = 'CONFIRM_DELETE_EXPENSE_REPORT'
export const REQUEST_EXPENSES = 'REQUEST_EXPENSES'
export const RECEIVE_EXPENSES = 'RECEIVE_EXPENSES'
export const REQUEST_EXPENSE = 'REQUEST_EXPENSE'
export const RECEIVE_EXPENSE = 'RECEIVE_EXPENSE'
export const SAVE_EXPENSE = 'SAVE_EXPENSE'
export const SAVE_EXPENSE_SUCCEEDED = 'SAVE_EXPENSE_SUCCEEDED'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'
export const RESET_EXPENSE_MODAL = 'RESET_EXPENSE_MODAL'
export const SEND_EXPENSE_REPORT_TO_VALIDATION = 'SEND_EXPENSE_REPORT_TO_VALIDATION'
export const VALIDATE_EXPENSE_REPORT = 'VALIDATE_EXPENSE_REPORT'
export const PREVALIDATE_EXPENSE_REPORT = 'PREVALIDATE_EXPENSE_REPORT'
export const REFUSE_EXPENSE_REPORT = 'REFUSE_EXPENSE_REPORT'
export const REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT = 'REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT'
export const RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT = 'RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT'
export const SAVE_ATTACHMENT = 'SAVE_ATTACHMENT'
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'
export const REQUEST_EXPENSES_EXPORT = 'REQUEST_EXPENSES_EXPORT'
export const RECEIVED_EXPENSES_EXPORT = 'RECEIVED_EXPENSES_EXPORT'
export const REQUEST_RECENT_EXPENSES_TAGS = 'REQUEST_RECENT_EXPENSES_TAGS'
export const RECEIVE_RECENT_EXPENSES_TAGS = 'RECEIVE_RECENT_EXPENSES_TAGS'

export type AttachmentToAdd = {
  file: Blob
  fileName: string
}

export type ExpenseReportResponseType = {
  expense_ids: SnowflakeType[]
  id: SnowflakeType
  person_id: SnowflakeType
  label: string
  transitions: ExpenseReportTransitionType[]
  status: ExpenseReportStatusType
  total: number
}

export type RequestExpenseResponseType = {
  id: SnowflakeType
  expense_report_id: SnowflakeType
  summary: string
  date: Date
  amount: number | null
  attachments: any[] //@todo change type
  type: string
  unit: string
  deletable: boolean
  updatable: boolean
  tag_ids: SnowflakeType[]
}

export type RequestValidatorsResponseType = {
  id: SnowflakeType
  last_name: string
  first_name: string
}

export type SaveExpenseType = {
  id?: SnowflakeType
  summary: string
  date: Date
  amount?: number
  expense_report_id: SnowflakeType
  expense_type_id?: SnowflakeType
  tags: SnowflakeType[]
  attachments: AttachmentType[]
  attachmentsToAdd: AttachmentToAdd[]
  attachmentsToRemove: AttachmentType[]
}

type ExpenseReportPaginationRequestType = {
  lastSnowflake: SnowflakeType
  date: Date
}

type ExpenseReportMetadataType = {
  type: EXPENSE_REPORT_TYPES
  group: EXPENSE_REPORT_GROUPS
  authorId?: SnowflakeType
  validatorId?: SnowflakeType
}

export interface RequestExpenseReportSummaryAction {
  type: typeof REQUEST_EXPENSE_REPORT_SUMMARY
  payload: ExpenseReportMetadataType & {
    pagination?: ExpenseReportPaginationRequestType
  }
}

interface ReceiveExpenseReportSummaryAction {
  type: typeof RECEIVE_EXPENSE_REPORT_SUMMARY
  payload: ExpenseReportMetadataType & {
    expenseReports: ExpenseReportResponseType[]
  }
}

export interface RequestExpenseReportDetailsAction {
  type: typeof REQUEST_EXPENSE_REPORT_DETAILS
  payload: {
    id: SnowflakeType
  }
}

interface ReceiveExpenseReportDetailsAction {
  type: typeof RECEIVE_EXPENSE_REPORT_DETAILS
  payload: {
    details: ExpenseReportResponseType
  }
}

export interface RenameExpenseReportAction {
  type: typeof RENAME_EXPENSE_REPORT
  payload: {
    id: SnowflakeType
    label: string
  }
}

interface RequestExpenseTypesEnabledAction {
  type: typeof REQUEST_EXPENSE_TYPES_ENABLED
}

interface ReceiveExpenseTypesEnabledAction {
  type: typeof RECEIVE_EXPENSE_TYPES_ENABLED
  payload: {
    expenseTypes: Array<ExpenseTypeType>
  }
}

export interface RequestExpensesAction {
  type: typeof REQUEST_EXPENSES
  payload: {
    expenseReportId: SnowflakeType
  }
}

interface ReceiveExpensesAction {
  type: typeof RECEIVE_EXPENSES
  payload: {
    expenses: ExpenseType[]
  }
}

export interface RequestExpenseAction {
  type: typeof REQUEST_EXPENSE
  payload: {
    expenseId: SnowflakeType
  }
}

export interface ReceiveExpenseAction {
  type: typeof RECEIVE_EXPENSE
  payload: {
    expense: ExpenseType
  }
}

export interface SaveExpenseAction {
  type: typeof SAVE_EXPENSE
  payload: {
    expense: SaveExpenseType
  }
}

export interface SaveExpenseSucceededAction {
  type: typeof SAVE_EXPENSE_SUCCEEDED
  payload: {
    id: SnowflakeType
  }
}

export interface DeleteExpenseReportAction {
  type: typeof DELETE_EXPENSE_REPORT
  payload: {
    id: SnowflakeType
  }
}

interface ConfirmDeleteExpenseReportAction {
  type: typeof CONFIRM_DELETE_EXPENSE_REPORT
  payload: {
    id: SnowflakeType
  }
}

export interface DeleteExpenseAction {
  type: typeof DELETE_EXPENSE
  payload: {
    expenseId: SnowflakeType
    expenseReportId: SnowflakeType
  }
}

export interface ResetExpenseModal {
  type: typeof RESET_EXPENSE_MODAL
}

export interface SendExpenseReportToValidation {
  type: typeof SEND_EXPENSE_REPORT_TO_VALIDATION
  payload: {
    expenseReportId: SnowflakeType
    validatorId: SnowflakeType
    remark: string | null
  }
}

export interface ValidateExpenseReport {
  type: typeof VALIDATE_EXPENSE_REPORT
  payload: {
    expenseReportId: SnowflakeType
    remark: string | null
  }
}

export interface PrevalidateExpenseReport {
  type: typeof PREVALIDATE_EXPENSE_REPORT
  payload: {
    expenseReportId: SnowflakeType
    validatorId: SnowflakeType
    remark: string | null
  }
}

export interface RefuseExpenseReport {
  type: typeof REFUSE_EXPENSE_REPORT
  payload: {
    expenseReportId: SnowflakeType
    remark: string | null
  }
}

interface ReceiveExpenseReportToValidateCount {
  type: typeof RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT
  payload: {
    count: number
  }
}

export interface RequestExpenseReportToValidateCount {
  type: typeof REQUEST_EXPENSE_REPORT_TO_VALIDATE_COUNT
}

export interface SaveAttachmentAction {
  type: typeof SAVE_ATTACHMENT
  payload: {
    data: string
    fileName: string
  }
}

export interface DeleteAttachmentAction {
  type: typeof DELETE_ATTACHMENT
}

export interface RequestExpensesExport {
  type: typeof REQUEST_EXPENSES_EXPORT
  payload: {
    exportedAt: boolean
    alreadyExported: boolean
    status: typeof EXPENSE_REPORT_STATUS_VALIDATED
    date: Date
  }
}

export interface ReceivedExpensesExport {
  type: typeof RECEIVED_EXPENSES_EXPORT
}

export interface RequestRecentExpensesTagsAction {
  type: typeof REQUEST_RECENT_EXPENSES_TAGS
}

export interface ReceiveRecentExpensesTagsAction {
  type: typeof RECEIVE_RECENT_EXPENSES_TAGS
  payload: {
    tags: SnowflakeType[]
  }
}

export type ExpensesTypes =
  | RequestExpenseReportSummaryAction
  | ReceiveExpenseReportSummaryAction
  | RequestExpenseReportDetailsAction
  | ReceiveExpenseReportDetailsAction
  | RenameExpenseReportAction
  | RequestExpenseTypesEnabledAction
  | ReceiveExpenseTypesEnabledAction
  | RequestExpensesAction
  | ReceiveExpensesAction
  | RequestExpenseAction
  | ReceiveExpenseAction
  | SaveExpenseAction
  | SaveExpenseSucceededAction
  | DeleteExpenseAction
  | DeleteExpenseReportAction
  | ConfirmDeleteExpenseReportAction
  | ResetExpenseModal
  | SendExpenseReportToValidation
  | ValidateExpenseReport
  | PrevalidateExpenseReport
  | RefuseExpenseReport
  | ReceiveExpenseReportToValidateCount
  | SaveAttachmentAction
  | DeleteAttachmentAction
  | RequestExpensesExport
  | ReceivedExpensesExport
  | RequestRecentExpensesTagsAction
  | ReceiveRecentExpensesTagsAction

export interface ExpensesState {
  ui: {
    attachment: {
      data: string
      fileName: string
    }
    expenseReports: {
      isLoading: {
        current: boolean
        archived: boolean
      }
      expensesList: {
        isLoading: boolean
      }
      expenseModal: {
        isSaving: boolean
        hasBeenSaved: boolean
      }
      expenses: {
        [snowflake: string]: {
          isFetching: boolean
        }
      }
      toValidate: {
        count: number
      }
    }
    expenseTypes: {
      isLoading: boolean
    }
    expensesExport: {
      isFetching: boolean
    }
    recentTags: {
      isFetching: boolean
    }
  }
  expenseReports: {
    [snowflake: string]: ExpenseReportType
  }
  expenseTypes: {
    [snowflake: string]: ExpenseTypeType
  }
  expenses: {
    [snowflake: string]: ExpenseType
  }
  recentTags: SnowflakeType[]
}
