import { brain } from 'core/services/api'

export const taskService = {
  getTasks: async (personId: any) => {
    const tasks = await brain.get('/tasks', {
      params: {
        user_id: personId,
      },
    })
    return tasks.data
  },
  close: async (taskAssigneeId: any) => {
    const response = await brain.put('/tasks/taskassignees/' + taskAssigneeId.toString() + '/close')

    return response.data
  },
  save: async (task: any) => {
    const response = task.id ? await brain.put('/tasks/' + task.id, task) : await brain.post('/tasks', task)

    return response.data
  },
}
