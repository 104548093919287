/* eslint-disable no-restricted-imports */
import { Duration } from '@js-joda/core'
import { t } from 'core/i18n'
/* eslint-enable no-restricted-imports */
import { formatFloat, roundFloat } from 'core/utils/numberUtils'

const decimalRegex = () => /(?<unit>^|^[0-9]+)(?<delimiter>\.|,)(?<decimal>[0-9]+$|$)/g
const hoursMinutesRegex = () => /(?<hours>^|^[0-9]+)(?<delimiter>:|h)(?<minutes>[0-9]+$|$)/gi
const delimiterRegex = () => /:|h|\.|,/gi

const hoursMinutesDelimiters = [':', 'h', 'H']
const decimalDelimiters = ['.', ',']
const delimiters = [...hoursMinutesDelimiters, ...decimalDelimiters]
const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const negativeSign = ['-']
const allowedChars = [...delimiters, ...numbers, ...negativeSign]

/**
 * Behaviours are to be kept in sync with back-end equivalent Helper/DurationHelper.php
 */
export const DurationHelper = {
  /**
   * Multiples regex queries to help identify part of a valid duration string
   */
  regex: {
    decimal: decimalRegex,
    hoursMinutes: hoursMinutesRegex,
    delimiter: delimiterRegex,
  },
  /**
   * Differently from `Duration::multipliedBy`, this method accepts floats
   */
  multiplyBy: (duration: Duration, rate: number) => {
    if (rate === 1) return duration
    return Duration.ofMillis(roundFloat(duration.toMillis() * rate, 0))
  },
  divideBy: (duration: Duration, rate: number) => DurationHelper.multiplyBy(duration, 1 / rate),

  toFloatHours: (duration: Duration) => duration.toMillis() / (60 * 60 * 1000),
  toFloatDays: (duration: Duration, dayLength: Duration) => duration.toMillis() / dayLength.toMillis(),

  toDecimalHours: (duration: Duration) => {
    return formatFloat(DurationHelper.toFloatHours(duration), {
      round: true,
      precision: 2,
      cute: true,
      alwaysNumber: true,
    })
  },

  toDecimalDays: (duration: Duration, dayLength: Duration) => {
    return formatFloat(DurationHelper.toFloatDays(duration, dayLength), {
      round: true,
      precision: 2,
      cute: true,
      alwaysNumber: true,
    })
  },

  toLabel: (duration: Duration) => {
    const days = Math.floor(duration.toDays())
    const hours = Math.floor(duration.toHours())
    const minutes = Math.floor(duration.toMinutes())

    if (days > 0)
      return days === 1 ? t('tipee.yesterday') : days.toString() + t('tipee.day_short', {}, { capitalize: false })
    if (hours > 0) return hours.toString() + t('tipee.hour_short', {}, { capitalize: false })
    if (minutes > 0) return minutes.toString() + ' ' + t('planning.minute_short', {}, { capitalize: false })

    return t('tipee.just_now')
  },

  /** validates that the given string is a valid duration string (Decimal or HH:MM) **/
  validateString: (value: string) => {
    // check for unauthorized characters
    const hasUnauthorizedCharacters = value.split('').some(char => !allowedChars.includes(char))
    if (hasUnauthorizedCharacters) return false
    if (value.startsWith('-')) {
      value = value.substring(1)
    }
    // whole number
    const delimiterMatch = delimiterRegex().exec(value)
    if (!delimiterMatch) {
      return true
    }

    // decimal input
    const decimalMatch = decimalRegex().exec(value)
    if (decimalMatch) {
      const hours = decimalMatch?.groups?.unit ? parseInt(decimalMatch.groups.unit) : 0
      const decimalPart = parseFloat(`0.${decimalMatch?.groups?.decimal || 0}`)
      const minutes = Math.floor(60 * decimalPart)
      return !isNaN(hours) && !isNaN(minutes)
    }

    // hours:minutes input
    const hourMinutesMatch = hoursMinutesRegex().exec(value)
    if (hourMinutesMatch) {
      const hours = parseInt(hourMinutesMatch?.groups?.hours || '0')
      const minutes = parseInt(hourMinutesMatch?.groups?.minutes || '0')
      return !isNaN(hours) && !isNaN(minutes)
    }

    return false
  },
  /**
   * Converts a user-inputted duration string to a Duration object, rounding down to the nearest minute
   * @throws Error if the given string is not a valid duration string or if it overflows an int
   */
  parseDuration: (value: string) => {
    if (!DurationHelper.validateString(value)) throw new Error(`Invalid duration string: ${value}`)

    const isNegative = value.startsWith('-')
    if (isNegative) {
      value = value.substring(1)
    }
    const inputValue = value
    const decimalMatch = DurationHelper.regex.decimal().exec(inputValue)
    const hourMinutesMatch = DurationHelper.regex.hoursMinutes().exec(inputValue)
    const delimiterMatch = DurationHelper.regex.delimiter().exec(inputValue)

    // hours:minutes input
    if (hourMinutesMatch) {
      const hours = parseInt(hourMinutesMatch?.groups?.hours || '0')
      const minutes = parseInt(hourMinutesMatch?.groups?.minutes || '0')

      const duration = Duration.ofHours(hours).plusMinutes(minutes)
      return isNegative ? duration.multipliedBy(-1) : duration
    }

    if (Number(value) >= Number.MAX_SAFE_INTEGER || Number(value) <= Number.MIN_SAFE_INTEGER)
      throw new Error(`Calculation overflows an int: ${value}`)

    // whole number
    if (!delimiterMatch) {
      const duration = Duration.ofHours(parseInt(value))
      try {
        duration.toMillis()
      } catch (e: any) {
        throw new Error(`Cannot interpret duration value as millis since it overflows: ${value}`)
      }
      return isNegative ? duration.multipliedBy(-1) : duration
    }

    // decimal input
    if (decimalMatch) {
      const hours = decimalMatch?.groups?.unit ? parseInt(decimalMatch.groups.unit) : 0

      const decimalPart = parseFloat(`0.${decimalMatch?.groups?.decimal || 0}`)
      const seconds = Math.floor(3600 * decimalPart)

      const duration = Duration.ofHours(hours).plusSeconds(seconds)
      try {
        duration.toMillis()
      } catch (e: any) {
        throw new Error(`Cannot interpret duration value as millis since it overflows: ${value}`)
      }

      return isNegative ? duration.multipliedBy(-1) : duration
    }

    throw new Error(`Could not process duration string: ${value})`)
  },
}
