import lodash from 'lodash'

const adaptShortPerson = (person: any) => {
  person.id = parseInt(person.id, 10)

  return person
}

const adaptSector = (sector: any) => {
  sector.id = parseInt(sector.id, 10)

  return sector
}

export class PersonAdapter {
  static adapt = (person: any) => {
    return {
      id: parseInt(person.id, 10),
      snowflake: person.snowflake,
      tag_id: person.tag_id,
      external_id: person.external_id,
      is_confidential: person.confidential,
      is_archived: person.archive,
      is_hidden: person.hidden,
      is_company: person.is_company,
      username: person.username,
      change_password: person.change_password,
      mainSector: parseInt(person.mainsector),
      general: {
        gender: person.gender,
        civility: person.civility,
        first_name: person.first_name,
        last_name: person.last_name,
        full_name: person.full_name,
        picture: person.picture,
        job: person.job,
        avs: person.avs,
        nationality: person.nationality,
        description: person.description,
        website: person.website,
        birthdate: person.birthdate,
      },
      phones: {
        mobile: person.mobile_phone,
        private: person.private_phone,
        work: person.work_phone,
        work_mobile: person.work_mobile_phone,
        fax: person.fax,
      },
      mails: {
        private: person.private_email,
        work: person.work_email,
      },
      custom_fields: {
        custom1: person.custom1,
        custom2: person.custom2,
      },
      referees: {
        home_referee: lodash.map(person.home_referee, adaptShortPerson),
        home_coreferee: lodash.map(person.home_coreferee, adaptShortPerson),
        workshop_referee: lodash.map(person.workshop_referee, adaptShortPerson),
        doctor_referee: lodash.map(person.doctor_referee, adaptShortPerson),
        manager_referee: lodash.map(person.manager_referee, adaptShortPerson),
      },
      referee_for: {
        home_referee: lodash.map(person.home_referee_for, adaptShortPerson),
        home_coreferee: lodash.map(person.home_coreferee_for, adaptShortPerson),
        workshop_referee: lodash.map(person.workshop_referee_for, adaptShortPerson),
        doctor_referee: lodash.map(person.doctor_referee_for, adaptShortPerson),
      },
      contact: {
        address: person.address,
        city: person.city,
        zip: person.zip,
        state: person.state,
        country: person.country,
        department: person.department,
      },
      work: {
        sectors: person.sectors && lodash.map(person.sectors, adaptSector),
        role: person.role,
        comment: person.comment,
        regrouping: parseInt(person.regrouping, 10),
        contract_type: person.contract_type,
        balance_mode: person.balance_mode,
        timecheck_mode: person.time_check_mode,
        start_date: person.start_date,
      },
      activity_rate_contract: person.activity_rate_contract,
      permissions: person.permissions,
    }
  }
}
