import { brain } from 'core/services/api'
import { DIRECTORY_ROLES_WITH_DOCUMENTS } from 'directory/types'
import { DocumentResponse, MatchFilesParams } from 'document/state/types'
import { formatDocument } from 'document/utils'

export const documentService = {
  getChildrenDocuments: async (id: number) => {
    try {
      const documentsResponse = await brain.get(`documents/${id}/childrens?withTreeView=1&onlyFolders=1`)

      return documentsResponse.data.map((document: DocumentResponse) => formatDocument(document))
    } catch (e: any) {
      throw e
    }
  },

  getParentsDocuments: async (id: number) => {
    try {
      const documentsResponse = await brain.get(`documents/${id}/parents?withTreeView=1`)

      return documentsResponse.data.map((document: DocumentResponse) => formatDocument(document))
    } catch (e: any) {
      throw e
    }
  },

  getRootFolder: async (role: DIRECTORY_ROLES_WITH_DOCUMENTS) => {
    try {
      const documentResponse = await brain.get('documents/types/' + role)
      return formatDocument(documentResponse.data)
    } catch (e: any) {
      throw e
    }
  },

  postFile: async (formData: FormData) => {
    try {
      const documentResponse = await brain.post('documents/files', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return documentResponse.data
    } catch (e: any) {
      throw e
    }
  },

  matchFilesForEmployee: async (data: MatchFilesParams) => {
    try {
      return await brain.post('/documents/employee/import.preview', data)
    } catch (e: any) {
      throw e
    }
  },
}
