import { TranslatedStringUtils, useTranslatedString } from 'core/hooks/useTranslatedString'
import { Locale } from 'core/i18n/types'
import { createContext, useContext, useState } from 'react'

let translatedStringUtils: TranslatedStringUtils | undefined = undefined

export const getTranslatedStringUtils = () => {
  if (!translatedStringUtils) {
    throw new Error('TranslatedStringUtils not initialized')
  }
  return translatedStringUtils
}

export type MultilingualContextType = {
  locale: Locale
  setLocale: (locale: Locale) => void
}

const MultilingualContext = createContext<MultilingualContextType>({} as MultilingualContextType)

export function MultilingualProvider({ children }: { children: React.ReactNode }) {
  const utils = useTranslatedString()

  // leak utils out of react context
  translatedStringUtils = utils

  const baseLocale = utils.priorityList[0]
  const [locale, setLocale] = useState<Locale>(baseLocale)

  return <MultilingualContext.Provider value={{ locale, setLocale }}>{children}</MultilingualContext.Provider>
}

export const useMultilingual = () => useContext(MultilingualContext)
