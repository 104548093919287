/* eslint-disable no-restricted-imports */
import { DayOfWeek, Duration, LocalDate, LocalDateTime, LocalTime, convert } from '@js-joda/core'
import { DurationHelper } from 'chronos/utils/durationHelper'
/* eslint-enable no-restricted-imports */
import { setMomentLocale } from 'chronos/deprecated/date'
import { LocalDateInterval } from 'chronos/localDateInterval/LocalDateInterval'
import { LocalDateTimeInterval } from 'chronos/localDateTimeInterval/LocalDateTimeInterval'
import { LocalTimeInterval } from 'chronos/localTimeInterval/LocalTimeInterval'
import { parseDateTime } from 'chronos/utils/parseDateTime'
import { formatFloat } from 'core/utils/numberUtils'
import { format as dateFnsFormat } from 'date-fns'
import de from 'date-fns/locale/de'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import _ from 'lodash'

/**
 *  ----- global locale  -----
 */
let dateFnsLocale = en
type Locale = 'fr' | 'en' | 'de'
export let locale: Locale = 'en'
const setLocale = (newLocale: string) => {
  switch (newLocale) {
    case 'en':
      dateFnsLocale = en
      locale = 'en'
      setMomentLocale('en')
      break
    case 'de':
      dateFnsLocale = de
      locale = 'de'
      setMomentLocale('de')
      break
    case 'fr':
      dateFnsLocale = fr
      locale = 'fr'
      setMomentLocale('fr')
      break
  }
  return locale
}

/**
 *  ----- Convertion -----
 */
const toJsDate = (dateTime: LocalDateTime | LocalDate | LocalTime) => {
  if (dateTime instanceof LocalTime) {
    // eslint-disable-next-line gammadia/no-new-date
    return new Date(1970, 0, 1, dateTime.hour(), dateTime.minute(), dateTime.second())
  } else {
    return convert(dateTime).toDate()
  }
}

/**
 *  ----- Formatting -----
 */
export const patterns = {
  default: 'dd.MM.yyyy',
  'like 1999-12-31': 'yyyy-MM-dd',
  'like 12': 'MM',
  'like 31': 'dd',
  'like 31 (no zero)': 'd',
  'like 31.12': 'dd.MM',
  'like 31.12.99': 'dd.MM.yy',
  'like 31.12.1999': 'dd.MM.yyyy',
  'like 12.1999': 'MM.yyyy',
  'like 31 Dec': 'dd MMM',
  'like 31 Dec (no zero)': 'd MMM',
  'like 31 Dec 1999': 'dd MMM yyyy',
  'like 31 December 1999': 'dd MMMM yyyy',
  'like Dec': 'MMM',
  'like December': 'MMMM',
  'like December 1999': 'MMMM yyyy',
  'like 1999': 'yyyy',
  'like Mo': 'EEEEEE',
  'like Mon': 'EE',
  'like Monday': 'EEEE',
  'like Mon 31': 'EE dd',
  'like Mon 31 (no zero)': 'EE d',
  'like Mon 31.12': 'EE dd.MM',
  'like Mon 31 Dec': 'EE dd MMM',
  'like Mon 31 Dec HH:mm': 'EE dd MMM HH:mm',
  'like Mon 31 Dec 1999': 'EE dd MMM yyyy',
  'like Mon 31 Dec 1999 (no zero)': 'EE d MMM yyyy',
  'like Monday 31 December 1999': 'EEEE dd MMMM yyyy',
  'like Monday 31 December 1999 (no zero)': 'EEEE d MMMM yyyy',
  'like Mo 31 December 1999': 'EEEEEE dd MMMM yyyy',
  'like Mo. 31.12.1999': 'EEEEEE. dd.MM.yyyy',
  'like HH:mm': 'HH:mm',
  'like H:mm': 'H:mm',

  weekNumber: 'ww',
  ymd: 'yyyy-MM-dd',
  ym: 'yyyy-MM',
} as const

export type FormatPattern = keyof typeof patterns

// LocalDate or LocalDateTime to localized string
const formatLocalItem = (temporal: LocalDate | LocalDateTime, pattern?: FormatPattern) => {
  return dateFnsFormat(toJsDate(temporal), pattern ? patterns[pattern] : patterns.default, {
    locale: dateFnsLocale,
  })
}

const formatYMD = (localDateOrDateTime: LocalDate | LocalDateTime) => {
  return formatLocalItem(localDateOrDateTime, 'ymd')
}

const formatJsDateOrString = (unknownDate: string | Date, pattern?: FormatPattern) => {
  const date = typeof unknownDate === 'string' ? parseDateTime(unknownDate) : unknownDate
  return dateFnsFormat(date, pattern ? patterns[pattern] : patterns.default, {
    locale: dateFnsLocale,
  })
}

const toLocalDate = (date: Date) => {
  return LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate())
}

const formatTime = (time: LocalTime, pattern: 'like HH:mm' | 'like H:mm' = 'like HH:mm') =>
  formatLocalItem(LocalDateTime.of(LocalDate.now(), time), pattern)

/**
 * This method formats Duration to a string like **1h30**, **-01h06**, **01:45**, **1.75**, **-1.6h** or **1Std.**.
 *
 * **N.B.** If the seconds are > 30, the minutes are rounded up.
 *
 * @param mode default **like 1h06**, the format of the output
 *
 * @param opt.trailingZeros if true, decimal trailing zeros are kept. E.g. **1.00** instead of **1**.
 * Only applies for mode **like 1.66** or **like 1.66h / 1.66Std.**
 *
 * @throws `ArithmeticException` if the duration is too large to be represented as a int (_`Duration.toMillis`_)
 */
function formatDuration(
  duration: Duration,
  mode: 'like 1h06' | 'like 01h06' | 'like 1:06' | 'like 01:06' | 'like 1.66' | 'like 1.66h / 1.66Std.' = 'like 1h06',
  opt: { trailingZeros?: boolean } = {}
): string {
  switch (mode) {
    case 'like 1h06':
    case 'like 1:06':
    case 'like 01h06':
    case 'like 01:06': {
      const isNegative = duration.isNegative()
      const leadingZero = mode === 'like 01h06' || mode === 'like 01:06'
      // The german part of Switzerland also use **h** as separator for hours-minutes format.
      // Thus, it's ok to hardcode it for our actual locales.
      const separator = mode === 'like 1h06' || mode === 'like 01h06' ? 'h' : ':'
      let roundedDuration = duration

      // round up minutes if seconds are > 30
      if (Math.abs(duration.toMillis()) % (60 * 1000) > 30 * 1000)
        roundedDuration = isNegative ? duration.minusMinutes(1) : duration.plusMinutes(1)

      let hours = Math.abs(roundedDuration.toHours()).toString()
      let minutes = Math.abs(roundedDuration.toMinutes() % 60).toString()

      if (leadingZero) hours = hours.padStart(2, '0')
      minutes = minutes.padStart(2, '0')

      if (opt.trailingZeros === false && minutes === '00') minutes = ''

      return (isNegative ? '-' : '') + hours + separator + minutes
    }
    case 'like 1.66':
    case 'like 1.66h / 1.66Std.': {
      let output = formatFloat(DurationHelper.toFloatHours(duration), {
        round: true,
        precision: 2,
        cute: !opt.trailingZeros,
        alwaysNumber: true,
      })
      if (mode === 'like 1.66h / 1.66Std.') output += locale === 'de' ? 'Std.' : 'h'
      return output
    }
  }
}

/** add leading zero to two-digit hours and minutes */
const padHourMinutes = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`
}

const weekDay = (
  dayOfWeek: DayOfWeek,
  pattern?: keyof Pick<typeof patterns, 'like Mo' | 'like Mon' | 'like Monday'>
) => {
  return formatLocalItem(LocalDate.now().with(dayOfWeek), pattern ?? 'like Monday')
}

/**
 * Format an interval of LocalTime or LocalDate or LocalDateTime. Normally used as such:
 * ```ts
 * format.interval(dateRange, 'like Mon 31').join(' - ')
 * ```
 */
function formatInterval(interval: LocalDateTimeInterval, pattern: FormatPattern): [string, string]
function formatInterval(interval: LocalDateInterval, pattern: FormatPattern): [string, string]
function formatInterval(interval: LocalTimeInterval, pattern: FormatPattern): [string, string]
function formatInterval(start: LocalDateTime, end: LocalDateTime, pattern: FormatPattern): [string, string]
function formatInterval(start: LocalTime, end: LocalTime, pattern: FormatPattern): [string, string]
function formatInterval(start: LocalDate, end: LocalDate, pattern: FormatPattern): [string, string]
function formatInterval(
  start: LocalDateTimeInterval | LocalTimeInterval | LocalDateInterval | LocalDateTime | LocalTime | LocalDate,
  end: LocalDateTime | LocalTime | LocalDate | FormatPattern,
  pattern?: FormatPattern
): [string, string] {
  if (start instanceof LocalDateTimeInterval && typeof end === 'string' && !pattern)
    return formatInterval(start.getFiniteStart(), start.getFiniteEnd(), end)

  if (start instanceof LocalDateInterval && typeof end === 'string' && !pattern)
    return formatInterval(start.getFiniteStart(), start.getFiniteEnd(), end)

  if (start instanceof LocalTimeInterval && typeof end === 'string' && !pattern)
    return formatInterval(start.startTime(), start.endTime(), end)

  if (start instanceof LocalTime && end instanceof LocalTime && pattern)
    return [
      formatLocalItem(LocalDateTime.of(LocalDate.EPOCH_0, start), pattern),
      formatLocalItem(LocalDateTime.of(LocalDate.EPOCH_0, end), pattern),
    ]

  if (
    (start instanceof LocalDateTime || start instanceof LocalDate) &&
    (end instanceof LocalDateTime || end instanceof LocalDate) &&
    pattern
  )
    return [formatLocalItem(start, pattern), formatLocalItem(end, pattern)]

  throw new Error(
    `Can't format interval of type ${_.get(start, 'constructor.name')}, ${_.get(end, 'constructor.name')}`
  )
}

const format = {
  local: formatLocalItem,
  ymd: formatYMD,
  time: formatTime,
  duration: formatDuration,
  date: formatJsDateOrString,
  padNumber: padHourMinutes,
  interval: formatInterval,
  weekDay,
} as const

const isWeekend = (date: LocalDate | LocalDateTime) =>
  date.dayOfWeek().equals(DayOfWeek.SATURDAY) || date.dayOfWeek().equals(DayOfWeek.SUNDAY)

export { format, setLocale, toJsDate, toLocalDate, isWeekend }
