import { SnowflakeType } from 'core/types'

export const ACL_STRATEGY_ALLOF = 'ACL_STRATEGY_ALLOF'
export const ACL_STRATEGY_ONEOF = 'ACL_STRATEGY_ONEOF'
export const ACL_STRATEGIES = [ACL_STRATEGY_ALLOF, ACL_STRATEGY_ONEOF] as const

export type ActiveUserType = {
  id: number
  username: string
  full_name: string
  picture: string
  first_name: string
  last_name: string
  admin: boolean
  snowflake: SnowflakeType
  mainsectorname: string
  head_sector_id: number
}

export type AclType = {
  resource: string
  action: string
  sectorId?: number | null
}

export type PreferencesType = {
  [x: string]: string
}

export type SessionType = {
  [x: string]: string
}

export enum Methods {
  Totp = 'totp',
  Sms = 'sms',
}

export type TwoFactorType = {
  active: boolean
  canDelete: boolean
  snowflake: SnowflakeType
  data: { phoneNumber: string } | null
  since: Date
  type: Methods
}
