import { SnowflakeType, Team, TeamType, TeamWithParent } from 'core/types'
import { sortTeams } from 'directory/utils'

export class TeamAdapter {
  static fromApi(team: any) {
    return {
      snowflake: team.id,
      name: team.name,
      type: team.parent_id ? TeamType.Sector : TeamType.Site,
    } as Team
  }

  static fromApiWithParent(data: { id: string; parent_id: string | null; name: string }[]) {
    const unsorted = data.map(team => ({
      snowflake: team.id as SnowflakeType,
      parentSnowflake: team.parent_id,
      name: team.name,
    })) as TeamWithParent[]
    return sortTeams(unsorted)
  }

  static fromStore(team: Team) {}
}
