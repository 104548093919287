import { FONT_WEIGHT_LIGHT, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR, FONT_WEIGHT_SEMIBOLD } from 'theme/types'

export const fonts = {
  size: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    mdn: '16px',
    lg: '20px',
    xl: '24px',
  },
  weight: {
    [FONT_WEIGHT_LIGHT]: 300,
    [FONT_WEIGHT_REGULAR]: 400,
    [FONT_WEIGHT_MEDIUM]: 500,
    [FONT_WEIGHT_SEMIBOLD]: 600,
  },
} as const
