import { TranslationGroup } from 'core/types'

export const translations: TranslationGroup = {
  pageTitle: 'expenses.expense_export.page_title',
  alreadyExported: 'expenses.expense_export.already_exported',
  export: 'tipee.export',
  exportedAt: 'expenses.expense_export.exported_on',
  noExpensesToExport: 'expenses.expense_export.no_expenses_to_export',
  expensesExported: 'expenses.expense_export.expenses_exported',
}
