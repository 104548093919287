export const REQUEST_BIRTHDAYS = 'REQUEST_BIRTHDAYS'
export function requestBirthdays() {
  return {
    type: REQUEST_BIRTHDAYS,
  }
}

export const RECEIVE_BIRTHDAYS = 'RECEIVE_BIRTHDAYS'
export function receiveBirthdays(birthdays: any) {
  return {
    type: RECEIVE_BIRTHDAYS,
    payload: {
      birthdays,
    },
  }
}

export const REQUEST_PERSON = 'REQUEST_PERSON'
export function requestPerson(id: any) {
  return {
    type: REQUEST_PERSON,
    payload: {
      id,
    },
  }
}

export const REQUEST_RESOURCES_SUMMARIES = 'REQUEST_RESOURCES_SUMMARIES'
export function requestResourcesSummaries(snowflakes: any) {
  return {
    type: REQUEST_RESOURCES_SUMMARIES,
    payload: {
      snowflakes,
    },
  }
}

export const REQUEST_RESOURCE_SUMMARY = 'REQUEST_RESOURCE_SUMMARY'
export function requestResourceSummary(snowflake: any) {
  return {
    type: REQUEST_RESOURCE_SUMMARY,
    payload: {
      snowflake,
    },
  }
}

export const FETCHING_RESOURCE_SUMMARY = 'FETCHING_RESOURCE_SUMMARY'
export function fetchingResourceSummary(snowflake: any) {
  return {
    type: FETCHING_RESOURCE_SUMMARY,
    payload: {
      snowflake,
    },
  }
}

export const REQUEST_RESOURCE_ACCOUNT = 'REQUEST_RESOURCE_ACCOUNT'
export function requestResourceAccount(snowflake: any, forceRefetch = false) {
  return {
    type: REQUEST_RESOURCE_ACCOUNT,
    payload: {
      snowflake,
      forceRefetch,
    },
  }
}

export const FETCHING_RESOURCE_ACCOUNT = 'FETCHING_RESOURCE_ACCOUNT'
export function fetchingResourceAccount(snowflake: any) {
  return {
    type: FETCHING_RESOURCE_ACCOUNT,
    payload: {
      snowflake,
    },
  }
}

export const RECEIVE_RESOURCE_ACCOUNT = 'RECEIVE_RESOURCE_ACCOUNT'
export function receiveResourceAccount(snowflake: any, account: any, expiration: any) {
  return {
    type: RECEIVE_RESOURCE_ACCOUNT,
    payload: {
      snowflake,
      account,
      expiration,
    },
  }
}

export const SAVE_RESOURCE_USERNAME = 'SAVE_RESOURCE_USERNAME'
export function saveResourceUsername(resource: any, username: any) {
  return {
    type: SAVE_RESOURCE_USERNAME,
    payload: {
      resource,
      username,
    },
  }
}

export const SAVE_RESOURCE_USERNAME_SUCCESS = 'SAVE_RESOURCE_USERNAME_SUCCESS'
export const saveResourceUsernameSuccess = () => {
  return {
    type: SAVE_RESOURCE_USERNAME_SUCCESS,
  }
}

export const SAVE_RESOURCE_USERNAME_FAILURE = 'SAVE_RESOURCE_USERNAME_FAILURE'
export const saveResourceUsernameFailure = ({ error }: any) => {
  return {
    type: SAVE_RESOURCE_USERNAME_FAILURE,
    payload: {
      error,
    },
  }
}

export const SAVE_RESOURCE_BALANCE_MODE = 'SAVE_RESOURCE_BALANCE_MODE'
export function saveResourceBalanceMode(resourceSnowflake: any, mode: any) {
  return {
    type: SAVE_RESOURCE_BALANCE_MODE,
    payload: {
      resourceSnowflake,
      mode,
    },
  }
}

export const SAVE_RESOURCE_BALANCE_MODE_SUCCESS = 'SAVE_RESOURCE_BALANCE_MODE_SUCCESS'
export const saveResourceBalanceModeSuccess = () => {
  return {
    type: SAVE_RESOURCE_BALANCE_MODE_SUCCESS,
  }
}

export const SAVE_RESOURCE_BALANCE_MODE_FAILURE = 'SAVE_RESOURCE_BALANCE_MODE_FAILURE'
export const saveResourceBalanceModeFailure = ({ error }: any) => {
  return {
    type: SAVE_RESOURCE_BALANCE_MODE_FAILURE,
    payload: {
      error,
    },
  }
}

export const SAVE_RESOURCE_WORK_DISPLAY_MODE = 'SAVE_RESOURCE_WORK_DISPLAY_MODE'
export function saveResourceWorkDisplayMode(resourceSnowflake: any, mode: any) {
  return {
    type: SAVE_RESOURCE_WORK_DISPLAY_MODE,
    payload: {
      resourceSnowflake,
      mode,
    },
  }
}

export const SAVE_RESOURCE_WORK_DISPLAY_MODE_SUCCESS = 'SAVE_RESOURCE_WORK_DISPLAY_MODE_SUCCESS'
export const saveResourceWorkDisplayModeSuccess = () => {
  return {
    type: SAVE_RESOURCE_WORK_DISPLAY_MODE_SUCCESS,
  }
}

export const SAVE_RESOURCE_WORK_DISPLAY_MODE_FAILURE = 'SAVE_RESOURCE_WORK_DISPLAY_MODE_FAILURE'
export const saveResourceWorkDisplayModeFailure = ({ error }: any) => {
  return {
    type: SAVE_RESOURCE_WORK_DISPLAY_MODE_FAILURE,
    payload: {
      error,
    },
  }
}

export const SAVE_RESOURCE_TIMECHECK_MODE = 'SAVE_RESOURCE_TIMECHECK_MODE'
export function saveResourceTimecheckMode(resourceSnowflake: any, mode: any) {
  return {
    type: SAVE_RESOURCE_TIMECHECK_MODE,
    payload: {
      resourceSnowflake,
      mode,
    },
  }
}

export const SAVE_RESOURCE_TIMECHECK_MODE_SUCCESS = 'SAVE_RESOURCE_TIMECHECK_MODE_SUCCESS'
export const saveResourceTimecheckModeSuccess = () => {
  return {
    type: SAVE_RESOURCE_TIMECHECK_MODE_SUCCESS,
  }
}

export const SAVE_RESOURCE_TIMECHECK_MODE_FAILURE = 'SAVE_RESOURCE_TIMECHECK_MODE_FAILURE'
export const saveResourceTimecheckModeFailure = ({ error }: any) => {
  return {
    type: SAVE_RESOURCE_TIMECHECK_MODE_FAILURE,
    payload: {
      error,
    },
  }
}

export const SAVE_MUST_CHANGE_PASSWORD = 'SAVE_MUST_CHANGE_PASSWORD'
export function saveMustChangePassword(resource: any, mustChangePassword: any) {
  return {
    type: SAVE_MUST_CHANGE_PASSWORD,
    payload: {
      resource,
      mustChangePassword,
    },
  }
}

export const SAVE_MUST_CHANGE_PASSWORD_SUCCESS = 'SAVE_MUST_CHANGE_PASSWORD_SUCCESS'
export const saveMustChangePasswordSuccess = () => {
  return {
    type: SAVE_MUST_CHANGE_PASSWORD_SUCCESS,
  }
}

export const SAVE_MUST_CHANGE_PASSWORD_FAILURE = 'SAVE_MUST_CHANGE_PASSWORD_FAILURE'
export const saveMustChangePasswordFailure = ({ error }: any) => {
  return {
    type: SAVE_MUST_CHANGE_PASSWORD_FAILURE,
    payload: {
      error,
    },
  }
}

export const SEND_CREDENTIALS_LINK = 'SEND_CREDENTIALS_LINK'
export function sendCrendetialsLink(resource: any) {
  return {
    type: SEND_CREDENTIALS_LINK,
    payload: {
      resource,
    },
  }
}

export const SEND_CREDENTIALS_LINK_SUCCESS = 'SEND_CREDENTIALS_LINK_SUCCESS'
export const sendCredentialsLinkSuccess = () => {
  return {
    type: SEND_CREDENTIALS_LINK_SUCCESS,
  }
}

export const SEND_CREDENTIALS_LINK_FAILURE = 'SEND_CREDENTIALS_LINK_FAILURE'
export const sendCredentialsLinkFailure = ({ error }: any) => {
  return {
    type: SEND_CREDENTIALS_LINK_FAILURE,
    payload: {
      error,
    },
  }
}

export const FETCHING_RESOURCES_SUMMARIES = 'FETCHING_RESOURCES_SUMMARIES'
export function fetchingResourcesSummaries(snowflakes: any) {
  return {
    type: FETCHING_RESOURCES_SUMMARIES,
    payload: {
      snowflakes,
    },
  }
}

export const RECEIVE_RESOURCE_SUMMARY = 'RECEIVE_RESOURCE_SUMMARY'
export function receiveResourceSummary(summary: any, expiration: any) {
  return {
    type: RECEIVE_RESOURCE_SUMMARY,
    payload: {
      summary,
      expiration,
    },
  }
}

export const RECEIVE_RESOURCES_SUMMARIES = 'RECEIVE_RESOURCES_SUMMARIES'
export function receiveResourcesSummaries(summaries: any, expiration: any) {
  return {
    type: RECEIVE_RESOURCES_SUMMARIES,
    payload: {
      summaries,
      expiration,
    },
  }
}

export const REQUEST_ROLES_LIST = 'REQUEST_ROLES_LIST'
export function requestRolesList() {
  return {
    type: REQUEST_ROLES_LIST,
  }
}

export const RECEIVE_ROLES_LIST = 'RECEIVE_ROLES_LIST'
export function receiveRolesList(roles: any) {
  return {
    type: RECEIVE_ROLES_LIST,
    payload: {
      roles,
    },
  }
}

export const FETCHING_PERSON = 'FETCHING_PERSON'
export function fetchingPerson(id: any) {
  return {
    type: FETCHING_PERSON,
    payload: {
      id,
    },
  }
}

export const RECEIVE_PERSON = 'RECEIVE_PERSON'
export function receivePerson(person: any, expiration: any) {
  return {
    type: RECEIVE_PERSON,
    payload: {
      person,
      expiration,
    },
  }
}

export const SEARCH_PERSONS = 'SEARCH_PERSONS'
export function searchPersons(text: any, resourceInfos: any) {
  return {
    type: SEARCH_PERSONS,
    payload: {
      text,
      resourceInfos,
    },
  }
}

export const RECEIVE_PERSONS = 'RECEIVE_PERSONS'
export function receivePersons(persons: any) {
  return {
    type: RECEIVE_PERSONS,
    payload: {
      persons,
    },
  }
}

export const REQUEST_SITES_LIST = 'REQUEST_SITES_LIST'
export function requestSitesList(resource: any, action: any) {
  return {
    type: REQUEST_SITES_LIST,
    payload: {
      resource,
      action,
    },
  }
}

export const RECEIVE_SITES_LIST = 'RECEIVE_SITES_LIST'
export function receiveSitesList(sites: any, resource: any, action: any) {
  return {
    type: RECEIVE_SITES_LIST,
    payload: {
      sites,
      resource,
      action,
    },
  }
}

export const REQUEST_SUPPORT_PERSON_LIST = 'REQUEST_SUPPORT_PERSON_LIST'
export function requestSupportPersonList() {
  return {
    type: REQUEST_SUPPORT_PERSON_LIST,
  }
}

export const RECEIVE_SUPPORT_PERSON_LIST = 'RECEIVE_SUPPORT_PERSON_LIST'
export function receiveSupportPersonList(persons: any) {
  return {
    type: RECEIVE_SUPPORT_PERSON_LIST,
    payload: {
      persons,
    },
  }
}

export const REQUEST_TEAM = 'REQUEST_TEAM'
export function requestTeam(id: any, type: any) {
  return {
    type: REQUEST_TEAM,
    payload: {
      id,
      type,
    },
  }
}

export const RECEIVE_TEAM = 'RECEIVE_TEAM'
export function receiveTeam(team: any, teamType: any) {
  return {
    type: RECEIVE_TEAM,
    payload: {
      team,
      teamType,
    },
  }
}

export const REQUEST_TEAM_LIST = 'REQUEST_TEAM_LIST'
export function requestTeamList() {
  return {
    type: REQUEST_TEAM_LIST,
    payload: {},
  }
}

export const RECEIVE_TEAM_LIST = 'RECEIVE_TEAM_LIST'
export function receiveTeamList(teamList: any) {
  return {
    type: RECEIVE_TEAM_LIST,
    payload: {
      teamList,
    },
  }
}

export const REQUEST_ROLE_ATTRIBUTIONS = 'REQUEST_ROLE_ATTRIBUTIONS'
export const requestRoleAttributions = (roleSnowflake: any) => {
  return {
    type: REQUEST_ROLE_ATTRIBUTIONS,
    payload: {
      roleSnowflake,
    },
  }
}

export const RECEIVE_ROLE_ATTRIBUTIONS = 'RECEIVE_ROLE_ATTRIBUTIONS'
export const receiveRoleAttributions = (roleAttributions: any, error: any) => {
  return {
    type: RECEIVE_ROLE_ATTRIBUTIONS,
    payload: {
      roleAttributions,
      error,
    },
  }
}

export const GRANT_TEAM_TO_RESOURCE = 'GRANT_TEAM_TO_RESOURCE'
export const grantTeamToResource = (resource: any, role: any, team: any) => {
  return {
    type: GRANT_TEAM_TO_RESOURCE,
    payload: {
      resource,
      team,
      role,
    },
  }
}

export const GRANT_TEAM_TO_RESOURCE_SUCCESS = 'GRANT_TEAM_TO_RESOURCE_SUCCESS'
export const grantTeamToResourceSuccess = (resource: any, team: any) => {
  return {
    type: GRANT_TEAM_TO_RESOURCE_SUCCESS,
    payload: {
      resource,
      team,
    },
  }
}

export const GRANT_ALL_TEAMS_TO_RESOURCE = 'GRANT_ALL_TEAMS_TO_RESOURCE'
export const grantAllTeamsToResource = (resource: any, role: any) => {
  return {
    type: GRANT_ALL_TEAMS_TO_RESOURCE,
    payload: {
      resource,
      role,
    },
  }
}

export const GRANT_ALL_TEAMS_TO_RESOURCE_SUCCESS = 'GRANT_ALL_TEAMS_TO_RESOURCE_SUCCESS'
export const grantAllTeamsToResourceSuccess = (resource: any) => {
  return {
    type: GRANT_ALL_TEAMS_TO_RESOURCE_SUCCESS,
    payload: {
      resource,
    },
  }
}

export const REVOKE_ALL_TEAMS_TO_RESOURCE = 'REVOKE_ALL_TEAMS_TO_RESOURCE'
export const revokeAllTeamsToResource = (resource: any, role: any) => {
  return {
    type: REVOKE_ALL_TEAMS_TO_RESOURCE,
    payload: {
      resource,
      role,
    },
  }
}

export const REVOKE_ALL_TEAMS_TO_RESOURCE_SUCCESS = 'REVOKE_ALL_TEAMS_TO_RESOURCE_SUCCESS'
export const revokeAllTeamsToResourceSuccess = (resource: any) => {
  return {
    type: REVOKE_ALL_TEAMS_TO_RESOURCE_SUCCESS,
    payload: {
      resource,
    },
  }
}

export const REVOKE_TEAM_TO_RESOURCE = 'REVOKE_TEAM_TO_RESOURCE'
export const revokeTeamToResource = (resource: any, role: any, team: any) => {
  return {
    type: REVOKE_TEAM_TO_RESOURCE,
    payload: {
      resource,
      team,
      role,
    },
  }
}

export const REVOKE_TEAM_TO_RESOURCE_SUCCESS = 'REVOKE_TEAM_TO_RESOURCE_SUCCESS'
export const revokeTeamToResourceSuccess = (resource: any, team: any) => {
  return {
    type: REVOKE_TEAM_TO_RESOURCE_SUCCESS,
    payload: {
      resource,
      team,
    },
  }
}

export const SET_ROLE_TEAMS_TO_RESOURCE = 'SET_ROLE_TEAMS_TO_RESOURCE'
export const setRoleTeamsToResource = (resource: any, teams: any) => {
  return {
    type: SET_ROLE_TEAMS_TO_RESOURCE,
    payload: {
      resource,
      teams,
    },
  }
}

export const REMOVE_RESOURCE_FROM_ROLE = 'REMOVE_RESOURCE_FROM_ROLE'
export const removeResourceFromRole = (resource: any, role: any) => {
  return {
    type: REMOVE_RESOURCE_FROM_ROLE,
    payload: {
      resource,
      role,
    },
  }
}

export const REMOVE_RESOURCE_FROM_ROLE_SUCCESS = 'REMOVE_RESOURCE_FROM_ROLE_SUCCESS'
export const removeResourceFromRoleSuccess = (resource: any) => {
  return {
    type: REMOVE_RESOURCE_FROM_ROLE_SUCCESS,
    payload: {
      resource,
    },
  }
}

export const GRANT_ROLE_ATTRIBUTIONS = 'GRANT_ROLE_ATTRIBUTIONS'
export const grantRoleAttributions = (resources: any, role: any, teams: any) => {
  return {
    type: GRANT_ROLE_ATTRIBUTIONS,
    payload: {
      resources,
      role,
      teams,
    },
  }
}

export const GRANT_ALL_TEAMS_ROLE_ATTRIBUTIONS = 'GRANT_ALL_TEAMS_ROLE_ATTRIBUTIONS'
export const grantAllTeamsRoleAttributions = (resources: any, role: any) => {
  return {
    type: GRANT_ALL_TEAMS_ROLE_ATTRIBUTIONS,
    payload: {
      resources,
      role,
    },
  }
}

export const GRANT_ROLE_ATTRIBUTIONS_SUCCESS = 'GRANT_ROLE_ATTRIBUTIONS_SUCCESS'
export const grantRoleAttributionsSuccess = () => {
  return {
    type: GRANT_ROLE_ATTRIBUTIONS_SUCCESS,
  }
}

export const GRANT_ROLE_ATTRIBUTIONS_FAILURE = 'GRANT_ROLE_ATTRIBUTIONS_FAILURE'
export const grantRoleAttributionsFailure = ({ error }: any) => {
  return {
    type: GRANT_ROLE_ATTRIBUTIONS_FAILURE,
    payload: {
      error,
    },
  }
}

export const RESET_ERROR_GRANT_ROLE_ATTRIBUTIONS = 'RESET_ERROR_GRANT_ROLE_ATTRIBUTIONS'
export const resetErrorGrantRoleAttributions = () => {
  return {
    type: RESET_ERROR_GRANT_ROLE_ATTRIBUTIONS,
  }
}
