/* do NOT import from `chronos` directy, to avoid circular dependency */
import { BootstrapData } from 'api'
import axios from 'axios'
import { locale, setLocale } from 'chronos/utils'
import { I18nContext } from 'core/i18n/components/I18nContext'
import { activeLanguage, getConfigKey, isAppReady } from 'core/state/selectors'
import PropTypes from 'prop-types'
import { PropsWithChildren, useEffect, useState } from 'react'
import { IntlProvider, IntlShape, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { LocaleObject, setLocale as setYupLocale } from 'yup-old'

let INTL: IntlShape
let yupLocale: LocaleObject

export const getIntl = () => {
  return (
    INTL || {
      formatMessage: ({ id, translateKey }: any) => id || translateKey || 'no.translation.found',
    }
  )
}

export const IntlContextProvider = injectIntl(({ intl, children }: { intl: IntlShape; children: React.ReactNode }) => {
  // This component is rendered only once (or at best when switching locales)
  // So there is no problem setting yup messages here, it won't have a huge
  // impact on the app's performance

  const currentLocale = useSelector(activeLanguage)
  if (locale !== currentLocale) {
    setLocale(locale)
  }

  const replaceSlash = /\\/g

  if (!yupLocale) {
    yupLocale = {
      mixed: {
        required: intl.formatMessage({ id: 'validator.field_required' }),
        notType: /*istanbul ignore next*/ () => intl.formatMessage({ id: 'validator.field_required' }),
      },
      number: {
        min: intl.formatMessage({ id: 'validator.number.min' }).replace(replaceSlash, ''),
        max: intl.formatMessage({ id: 'validator.number.max' }).replace(replaceSlash, ''),
        positive: intl.formatMessage({ id: 'validator.number.positive' }).replace(replaceSlash, ''),
      },
      array: {
        min: intl.formatMessage({ id: 'validator.array.min' }).replace(replaceSlash, ''),
      },
      string: {
        max: intl.formatMessage({ id: 'validator.string.max' }).replace(replaceSlash, ''),
      },
    }
    setYupLocale(yupLocale)
  }

  // This leaks the intl object, allowing us to use it out of context.
  INTL = intl
  return <I18nContext.Provider value={intl}>{children}</I18nContext.Provider>
})

type I18nProviderProps = PropsWithChildren & { boot: BootstrapData }

export const I18nProvider = ({ children, boot }: I18nProviderProps) => {
  const currentLocale = useSelector(activeLanguage)
  const isPrefLoaded = useSelector(isAppReady)
  const [isLoading, setLoading] = useState(true)
  const [messages, setMessages] = useState(null)
  const revision = useSelector((state: any) => getConfigKey(state, 'revision')) as string | undefined
  const customTranslations = boot.config.custom_translations as
    | {
        [key: string]: {
          [key: string]: string
        }
      }
    | undefined

  useEffect(() => {
    const fallbackLanguage = 'fr'
    const fetchLocales = async () => {
      setLoading(true)
      const currentLocaleCustomTranslations = customTranslations ? customTranslations[currentLocale] || {} : {}
      const fallbacktLocale = await axios.get(`/locale/${fallbackLanguage}/LC_MESSAGES/global.json?v=${revision}`)
      setMessages({ ...fallbacktLocale.data, ...currentLocaleCustomTranslations })

      if (currentLocale !== 'fr') {
        const locale = await axios.get(`/locale/${currentLocale}/LC_MESSAGES/global.json?v=${revision}`)
        Object.keys(locale.data).forEach(
          translationKey => locale.data[translationKey] === '' && delete locale.data[translationKey]
        )

        setMessages({ ...fallbacktLocale.data, ...locale.data, ...currentLocaleCustomTranslations })
        setLoading(false)
      } else {
        setLoading(false)
      }
    }

    // avoid flicker on dev, because of ever-changing revision
    if (process.env.NODE_ENV === 'development' && !!messages) return

    isPrefLoaded && fetchLocales()
  }, [currentLocale, revision, isPrefLoaded, setMessages, setLoading])

  return isLoading ? null : (
    <IntlProvider locale={currentLocale} messages={messages as any}>
      <IntlContextProvider>{children}</IntlContextProvider>
    </IntlProvider>
  )
}

I18nProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}
