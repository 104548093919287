import { throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { tagService } from 'tag/services/tag'
import {
  receiveSearchTagSelectTagsByName,
  receiveTagList,
  receiveTags,
  receiveTagSelectHasTags,
  receiveTagSelectTags,
} from 'tag/state/actions'
import {
  RequestSearchTagSelectTagsByNameAction,
  RequestSelectTagHasTagsAction,
  RequestTagListAction,
  RequestTagsAction,
  REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME,
  REQUEST_TAGS,
  REQUEST_TAG_LIST,
  REQUEST_TAG_SELECT_HAS_TAGS,
  REQUEST_TAG_SELECT_TAGS,
} from 'tag/state/types'
import { all, put, takeEvery, takeLatest, takeLeading } from 'typed-redux-saga'

function* fetchTags(action: RequestTagsAction) {
  const {
    payload: { id, filter, contextDate },
  } = action

  try {
    const tags = yield* callApi(tagService.getTags, id, filter, contextDate)
    yield* put(receiveTags(tags))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

export function* fetchTagList(action: RequestTagListAction) {
  const { tags, id, filter, contextDate } = action.payload

  try {
    const tagList = yield* callApi(tagService.getTagList, tags, id, filter, contextDate)
    yield* put(receiveTagList(tagList))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* fetchSelectTagTags(action: RequestTagsAction) {
  const {
    payload: { id, filter, contextDate },
  } = action

  try {
    const tags = yield* callApi(tagService.getTags, id, filter, contextDate)
    const tagsList = tags.map(tag => tag.snowflake)
    yield* put(receiveTags(tags))
    yield* put(receiveTagSelectTags(tagsList))
  } catch (e: any) {
    yield* put(throwError(e))
  }
}

function* hasSelectTagTags(action: RequestSelectTagHasTagsAction) {
  try {
    const { id, filter, contextDate } = action.payload
    const hasTags = yield* callApi(tagService.hasTags, id, filter, contextDate)

    yield* put(receiveTagSelectHasTags(hasTags))
  } catch (exception) {
    yield* put(throwError(exception))
  }
}

function* requestSearchTagSelectTagsByName(action: RequestSearchTagSelectTagsByNameAction) {
  const { name, id, filter, contextDate } = action.payload

  try {
    const tags = yield* callApi(tagService.requestSearchTagSelectTagsByName, name, id, filter, contextDate)
    const tagsList = tags.map(tag => tag.snowflake)
    yield* put(receiveTags(tags))
    yield* put(receiveSearchTagSelectTagsByName(tagsList))
  } catch (exception) {
    yield* put(throwError(exception))
  }
}

export const tagSaga = function* tagSaga() {
  all([
    yield* takeLeading(REQUEST_TAGS, fetchTags),
    yield* takeEvery(REQUEST_TAG_LIST, fetchTagList),
    yield* takeLatest(REQUEST_SEARCH_TAG_SELECT_TAGS_BY_NAME, requestSearchTagSelectTagsByName),
    yield* takeLatest(REQUEST_TAG_SELECT_HAS_TAGS, hasSelectTagTags),
    yield* takeLatest(REQUEST_TAG_SELECT_TAGS, fetchSelectTagTags),
  ])
}
