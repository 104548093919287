import { getPersonsBySnowflake } from 'directory/state/selectors/person'
import { DIRECTORY_ROLES_WITH_DOCUMENTS, DIRECTORY_ROLE_EMPLOYEE } from 'directory/types'
import { IDENTIFIER, SEPARATOR_LIST } from 'document/components/fileNamePattern/types'
import {
  Document,
  DOCUMENT_TYPE_FOLDER,
  FILE_STATES,
  ImportFile,
  ImportFileWithError,
  ImportFileWithMatch,
} from 'document/types'
import createCachedSelector from 're-reselect'
import { StoreStateType } from 'store/types'

const subState = (state: StoreStateType) => state.document

const getRootFolderId = (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) =>
  subState(s).documentsByRole[role].rootFolderId

export const getPattern = (s: StoreStateType) => subState(s).pages.import.config?.fileNamePattern

export const getPatternRegex = (s: StoreStateType) => {
  const pattern = subState(s).pages.import.config?.fileNamePattern
  if (!pattern) return ''
  const patternDef = SEPARATOR_LIST.find(separator => separator.value === pattern.separator)
  const separator = patternDef?.char
  if (!separator) return ''
  const countBeforeId = pattern.position - 1
  const countAfterId = pattern.elementsNumber - pattern.position
  const avsIdentifier = '([0-9]{3}.[0-9]{4}.[0-9]{4}.[0-9]{2})'
  const externalIdentifier = `([^${separator}]+)`
  const identifier = pattern.identifier === IDENTIFIER.avs ? avsIdentifier : externalIdentifier
  // eslint-disable-next-line
  const regex = `^(?:[^${separator}]+${separator}){${countBeforeId}}${identifier}(?:${separator}[^${separator}]+){${countAfterId}}(\\.[^${separator}]+$)`
  return regex
}

export const isFetchingAllDocuments = (state: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) =>
  subState(state).ui.documentsByRole[role].isFetching

export const isFetchingDocuments = (state: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) =>
  subState(state).ui.documentsByRole[role].documentsFetching

export const getImportPageActiveStep = (state: StoreStateType) => subState(state).pages.import.activeStep
export const getImportPageSteps = (state: StoreStateType) => subState(state).pages.import.steps
export const getImportConfig = (s: StoreStateType) => subState(s).pages.import.config

export const getImportPageStepsWithActive = createCachedSelector(
  getImportPageActiveStep,
  getImportPageSteps,
  (activeStep, steps) => ({
    ...steps,
    config: {
      ...steps.config,
      isActive: activeStep === steps.config.id,
    },
    prepare: {
      ...steps.prepare,
      isActive: activeStep === steps.prepare.id,
    },
    import: {
      ...steps.import,
      isActive: activeStep === steps.import.id,
    },
  })
)(_ => `import_page_steps`)

export const getRootFolder = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => subState(s).documentsByRole[role].documents,
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getRootFolderId(s, role),
  (documents, rootFolderId) => (rootFolderId ? documents[rootFolderId] : null)
)((_, role) => `root_folder_${role}`)

export const getFolders = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => subState(s).documentsByRole[role].documents,
  documents => Object.values(documents).filter((document: Document) => document.type === DOCUMENT_TYPE_FOLDER)
)((_, role) => `folders_${role}`)

export const getFolder = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => subState(s).documentsByRole[role].documents,
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS, folderId: number) => folderId,
  (documents, folderId) =>
    Object.values(documents).find(
      (document: Document) => document.type === DOCUMENT_TYPE_FOLDER && document.id === folderId
    )
)((_, role, id) => `get_folder_${role}_${id}`)

export const getImportFolder = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => subState(s).documentsByRole[role].documents,
  getImportConfig,
  (documents, config) => {
    const folderId = config?.folderId
    return folderId ? documents[folderId] : undefined
  }
)((_, role) => `get_import_folder_${role}`)

export const getImportFolderPath = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFolder(s, role),
  document => (document ? document.fullPath : undefined)
)((_, role) => `get_import_folder_fullpath_${role}`)

export const getDocumentStateForRole = (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) =>
  subState(s).documentsByRole[role]

export const getDocumentsForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getDocumentStateForRole(s, role),
  documentState => documentState.documents
)((_, role) => `${role}_documents`)

export const getImportStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getDocumentStateForRole(s, role),
  documentState => documentState.import
)((_, role) => `${role}_import_state`)

export const getImportFilesForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportStateForRole(s, role),
  (s: StoreStateType) => s, // Warning -> fix pour forcer les selecteurs à se mettre à jour dans les tests: limitation de redux-mock-store
  importState => importState.files
)((_, role) => `${role}_import_files`)

export const getImportFilesAsArrayForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesForRole(s, role),
  files => Object.values(files)
)((_, role) => `${role}_import_files_as_array`)

export const getImportFilesErrorStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files => files.filter((file: ImportFile) => file.error && file.state === FILE_STATES.error) as ImportFileWithError[]
)((_, role) => `${role}_import_files_error_state`)

export const getImportFilesWithoutErrorStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files => files.filter((file: ImportFile) => file.state !== FILE_STATES.error)
)((_, role) => `${role}_import_files_without_error_state`)

export const getImportFilesSuccessStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files => files.filter((file: ImportFile) => file.state === FILE_STATES.success)
)((_, role) => `${role}_import_files_success_state`)

export const getImportFilesUploadingStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files => files.filter((file: ImportFile) => file.state === FILE_STATES.uploading)
)((_, role) => `${role}_import_files_uploading_state`)

export const getImportFilesMatchingStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files => files.filter((file: ImportFile) => file.state === FILE_STATES.matching)
)((_, role) => `${role}_import_files_matching_state`)

export const getImportFilesMatchedStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files =>
    files.filter(
      (file: ImportFile) => file.personSnowflake && file.state === FILE_STATES.matched
    ) as ImportFileWithMatch[]
)((_, role) => `${role}_import_files_matched_state`)

export const getImportFilesInitialStateForRole = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesAsArrayForRole(s, role),
  files => files.filter((file: ImportFile) => file.state === FILE_STATES.initial)
)((_, role) => `${role}_import_files_initial_state`)

export const hasEmployeePrepareStepRequiredData = createCachedSelector(
  getPatternRegex,
  (s: StoreStateType) => getImportFolderPath(s, DIRECTORY_ROLE_EMPLOYEE),
  (pattern, folderPath) => {
    const hasSelectedFolder = folderPath ? folderPath.length > 0 : false
    const hasFileNamePattern = pattern.length > 0
    return hasSelectedFolder && hasFileNamePattern
  }
)(_ => `has_employee_prepare_step_required_data`)

export const hasEmployeeImportStepRequiredData = createCachedSelector(
  getPersonsBySnowflake,
  (s: StoreStateType) => getImportFilesMatchedStateForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (s: StoreStateType) => getImportFilesUploadingStateForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (s: StoreStateType) => getImportFilesSuccessStateForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (s: StoreStateType) => getImportFilesErrorStateForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (s: StoreStateType) => getImportFilesAsArrayForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (personsBySnowflake, matchedFiles, uploadingFiles, successFiles, errorFiles, allFiles) => {
    const hasFiles = allFiles.length > 0
    const allFilesAreMatchedOrUploadingOrSuccessOrError =
      allFiles.length ===
      matchedFiles.filter(file => !!personsBySnowflake[file.personSnowflake]).length +
        uploadingFiles.length +
        successFiles.length +
        errorFiles.filter(({ error }) => !error.title).length
    return hasFiles && allFilesAreMatchedOrUploadingOrSuccessOrError
  }
)(_ => `has_employee_import_step_required_data`)

export const hasEmployeeImportAllFilesSuccessState = createCachedSelector(
  (s: StoreStateType) => getImportFilesAsArrayForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (s: StoreStateType) => getImportFilesSuccessStateForRole(s, DIRECTORY_ROLE_EMPLOYEE),
  (importfileAsArray, importfileSuccessState) => importfileAsArray.length === importfileSuccessState.length
)(_ => `has_employee_import_all_files_success_state`)

export const getImportMatchParameters = createCachedSelector(
  getPattern,
  getPatternRegex,
  getImportConfig,
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesForRole(s, role),
  (pattern, regex, config, files) => {
    if (!pattern || !config) return null
    const matchParams = {
      id_type: pattern?.identifier,
      pattern: regex,
      parent_id: config?.folderId,
      files: Object.values(files)
        .filter(file => file.state === FILE_STATES.initial)
        .map(file => ({ id: file.id, size: file.size, name: file.name })),
    }
    return matchParams
  }
)((_, role) => `${role}_import_page_match_parameters`)

export const getUploadingProgress = createCachedSelector(
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesUploadingStateForRole(s, role).length,
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesSuccessStateForRole(s, role).length,
  (s: StoreStateType, role: DIRECTORY_ROLES_WITH_DOCUMENTS) => getImportFilesErrorStateForRole(s, role).length,
  (uploadingFilesLength, successFilesLength, errorFilesLength) => {
    const finishedFiles = successFilesLength + errorFilesLength
    const totalFiles = finishedFiles + uploadingFilesLength
    return finishedFiles === 0 && uploadingFilesLength === 0
      ? undefined
      : parseInt(((finishedFiles / totalFiles) * 100).toFixed(0))
  }
)((_, role) => `${role}_uploading_progress`)
