export const Radius = {
  v0: '0px',
  v4: '4px',
  v8: '8px',
  v16: '16px',
  v24: '24px',
  FULL: '99999px',
} as const

export type PickRadius<Keys extends keyof typeof Radius> = Keys

export type RadiusKey = keyof typeof Radius
