import { parseDateTime } from 'chronos'
import {
  CONFIRM_DELETE_EXPENSE_REPORT,
  DELETE_ATTACHMENT,
  DELETE_EXPENSE,
  ExpensesState,
  ExpensesTypes,
  PREVALIDATE_EXPENSE_REPORT,
  RECEIVED_EXPENSES_EXPORT,
  RECEIVE_EXPENSE,
  RECEIVE_EXPENSES,
  RECEIVE_EXPENSE_REPORT_DETAILS,
  RECEIVE_EXPENSE_REPORT_SUMMARY,
  RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT,
  RECEIVE_EXPENSE_TYPES_ENABLED,
  RECEIVE_RECENT_EXPENSES_TAGS,
  REFUSE_EXPENSE_REPORT,
  RENAME_EXPENSE_REPORT,
  REQUEST_EXPENSES,
  REQUEST_EXPENSES_EXPORT,
  REQUEST_EXPENSE_REPORT_SUMMARY,
  REQUEST_EXPENSE_TYPES_ENABLED,
  REQUEST_RECENT_EXPENSES_TAGS,
  RESET_EXPENSE_MODAL,
  SAVE_ATTACHMENT,
  SAVE_EXPENSE,
  SAVE_EXPENSE_SUCCEEDED,
  SEND_EXPENSE_REPORT_TO_VALIDATION,
  VALIDATE_EXPENSE_REPORT,
} from 'expenses/state/types'
import {
  EXPENSE_REPORT_STATUS_PENDING_APPROVAL,
  EXPENSE_REPORT_STATUS_REFUSED,
  EXPENSE_REPORT_STATUS_VALIDATED,
} from 'expenses/types'
import produce from 'immer'

const initialState: ExpensesState = {
  ui: {
    attachment: {
      data: '',
      fileName: '',
    },
    expenseReports: {
      isLoading: {
        current: false,
        archived: false,
      },
      expensesList: {
        isLoading: false,
      },
      expenseModal: {
        isSaving: false,
        hasBeenSaved: false,
      },
      expenses: {},
      toValidate: {
        count: 0,
      },
    },
    expenseTypes: {
      isLoading: false,
    },
    expensesExport: {
      isFetching: false,
    },
    recentTags: {
      isFetching: false,
    },
  },
  expenseReports: {},
  expenseTypes: {},
  expenses: {},
  recentTags: [],
}

export const expensesReducer = (currentState: ExpensesState = initialState, action: ExpensesTypes): ExpensesState =>
  produce(currentState, draft => {
    switch (action.type) {
      case REQUEST_EXPENSE_REPORT_SUMMARY:
        draft.ui.expenseReports.isLoading[action.payload.group] = true
        break
      case RECEIVE_EXPENSE_REPORT_SUMMARY:
        draft.ui.expenseReports.isLoading[action.payload.group] = false
        action.payload.expenseReports.forEach(expenseReport => {
          draft.expenseReports[expenseReport.id] = {
            ...(draft.expenseReports[expenseReport.id] || {}),
            ...expenseReport,
          }
        })
        break
      case RECEIVE_EXPENSE_REPORT_DETAILS:
        const report = action.payload.details
        draft.expenseReports[report.id] = {
          ...draft.expenseReports[report.id],
          ...report,
        }
        break

      case REQUEST_EXPENSE_TYPES_ENABLED:
        draft.ui.expenseTypes.isLoading = true
        break

      case RECEIVE_EXPENSE_TYPES_ENABLED:
        const expenseTypes = action.payload.expenseTypes
        draft.ui.expenseTypes.isLoading = false
        draft.expenseTypes = {}
        expenseTypes.forEach(expenseType => {
          draft.expenseTypes[expenseType.id] = expenseType
        })
        break
      case RENAME_EXPENSE_REPORT:
        draft.expenseReports[action.payload.id].label = action.payload.label
        break

      case CONFIRM_DELETE_EXPENSE_REPORT:
        delete draft.expenseReports[action.payload.id]
        break

      case DELETE_EXPENSE:
        const { expenseId, expenseReportId } = action.payload

        draft.expenses[expenseId] && delete draft.expenses[expenseId]

        if (draft.expenseReports[expenseReportId]) {
          const index = draft.expenseReports[expenseReportId].expense_ids.findIndex(id => expenseId === id)

          if (index >= 0) {
            draft.expenseReports[expenseReportId].expense_ids.splice(index, 1)
          }
        }
        break

      case REQUEST_EXPENSES:
        draft.ui.expenseReports.expensesList.isLoading = true
        break

      case RECEIVE_EXPENSES:
        const expenses = action.payload.expenses
        draft.ui.expenseReports.expensesList.isLoading = false
        expenses.forEach(expense => {
          draft.ui.expenseReports.expenses[expense.id] = { isFetching: false }
          draft.expenses[expense.id] = expense
        })
        break

      case RECEIVE_EXPENSE:
        const expense = action.payload.expense
        draft.expenses[expense.id] = expense
        draft.ui.expenseReports.expenses[expense.id] = { isFetching: false }
        break

      case SAVE_EXPENSE:
        draft.ui.expenseReports.expenseModal.isSaving = true
        draft.ui.expenseReports.expenseModal.hasBeenSaved = false
        break

      case SAVE_EXPENSE_SUCCEEDED: {
        const expenseId = action.payload.id
        draft.ui.expenseReports.expenseModal.isSaving = false
        draft.ui.expenseReports.expenseModal.hasBeenSaved = true
        draft.ui.expenseReports.expenses[expenseId].isFetching = true
        break
      }

      case RESET_EXPENSE_MODAL:
        draft.ui.expenseReports.expenseModal.isSaving = false
        draft.ui.expenseReports.expenseModal.hasBeenSaved = false
        break

      case SEND_EXPENSE_REPORT_TO_VALIDATION:
        draft.expenseReports[action.payload.expenseReportId].status = {
          current: EXPENSE_REPORT_STATUS_PENDING_APPROVAL,
          since: parseDateTime(),
        }
        break

      case VALIDATE_EXPENSE_REPORT:
        draft.expenseReports[action.payload.expenseReportId].status = {
          current: EXPENSE_REPORT_STATUS_VALIDATED,
          since: parseDateTime(),
        }
        break

      case PREVALIDATE_EXPENSE_REPORT:
        draft.expenseReports[action.payload.expenseReportId].status = {
          current: EXPENSE_REPORT_STATUS_PENDING_APPROVAL,
          since: parseDateTime(),
        }
        draft.expenseReports[action.payload.expenseReportId].current_transition = null
        break

      case REFUSE_EXPENSE_REPORT:
        draft.expenseReports[action.payload.expenseReportId].status = {
          current: EXPENSE_REPORT_STATUS_REFUSED,
          since: parseDateTime(),
        }
        break

      case RECEIVE_EXPENSE_REPORT_TO_VALIDATE_COUNT:
        draft.ui.expenseReports.toValidate.count = action.payload.count
        break
      case SAVE_ATTACHMENT:
        draft.ui.attachment = action.payload
        break
      case DELETE_ATTACHMENT:
        draft.ui.attachment = initialState.ui.attachment
        break
      case REQUEST_EXPENSES_EXPORT:
        draft.ui.expensesExport.isFetching = true
        break
      case RECEIVED_EXPENSES_EXPORT:
        draft.ui.expensesExport.isFetching = false
        break
      case REQUEST_RECENT_EXPENSES_TAGS:
        draft.ui.recentTags.isFetching = true
        break
      case RECEIVE_RECENT_EXPENSES_TAGS:
        draft.recentTags = action.payload.tags
        draft.ui.recentTags.isFetching = false
        break
    }
  })
