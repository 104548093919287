import { Snowflake } from 'api'
import { DateRangeToISO, formatYMD, parseISODateRange } from 'chronos'
import { brain, pluginsForumComponent, publicApi } from 'core/services/api'
import { addHashToColor } from 'schedule/services/utils'
import { colors } from 'theme'

export const scheduleService = {
  getDaySchedules: async (person: any, date: any) => {
    try {
      const daySchedules = [] as any[]
      const day = await publicApi.get('/plannings/day', {
        params: {
          person,
          date: formatYMD(date),
        },
      })

      if (day.data[0]) {
        day.data[0].periods.forEach((timetable: any) => {
          if (timetable.planif_id && !daySchedules.find(v => v.id === parseInt(timetable.planif_id))) {
            // color hash
            let hashedColor = colors.white as string
            if (timetable.Timetable?.couleur) {
              hashedColor = addHashToColor(timetable.Timetable?.couleur)
            }
            // textColor hash
            let hashedTextColor = colors.darkGrey as string
            if (timetable.coul_text) {
              hashedTextColor = addHashToColor(timetable.coul_text)
            }
            daySchedules.push({
              id: parseInt(timetable.planif_id),
              name: timetable.Timetable?.nom,
              color: hashedColor,
              description: timetable.Timetable?.detail,
              modified: timetable.modified,
              remark: timetable.remarque,
              textColor: hashedTextColor,
              date: date,
              adjustmentText: timetable.adjustment_text,
            })
          }
        })
      }

      return daySchedules
    } catch (e) {
      throw e
    }
  },
  getSchedule: async (scheduleId: any) => {
    try {
      const schedule = await brain.get(`/plannings/${scheduleId}`)
      return schedule.data
    } catch (e: any) {
      throw e.response
    }
  },
  getScheduleTemplate: async (timetableId: any) => {
    try {
      const schedule = await publicApi.get(`/timetable/${timetableId}`)
      return schedule.data
    } catch (e: any) {
      throw e.response
    }
  },
  addMatchingSchedule: async (scheduleId: any, timecheckId: any) => {
    try {
      await brain.post('/plannings/day/timecheck', {
        planningday_id: scheduleId,
        timecheck_id: timecheckId,
      })
    } catch (e: any) {
      throw e.response
    }
  },
  deleteMatchingSchedule: async (scheduleId: any, timecheckId: any) => {
    try {
      await brain.delete(`/plannings/day/${scheduleId}/timecheck/${timecheckId}`)
    } catch (e: any) {
      throw e.response
    }
  },
  deleteAdjustment: async (planningDayId: any) => {
    try {
      await brain.delete(`/plannings/day/${planningDayId}/adjustment`)
    } catch (e: any) {
      throw e.response
    }
  },
  deleteAllPlanningForPeriod: async (
    idPerson: any,
    sector: any,
    dateInterval: any,
    daysOfWeek: any, // array of dayOfWeek ordinals
    dropPlanifs: any,
    dropAbsences: any,
    dropPiquets: any,
    dropAdjustments: any,
    dryrun = false
  ) => {
    try {
      const daysParams = daysOfWeek.map((day: any) => ['daysOfWeek[]', day])
      const params = [
        ['cmd', 'deleteAllPeriod'],
        ['person', idPerson],
        ['sector', sector],
        ['start', dateInterval.start?.toString() ?? null],
        ['end', dateInterval.end?.toString() ?? null],
        ['end_display', dateInterval.end?.toString() ?? null],
        ...daysParams,
        ['drop_planifs', dropPlanifs],
        ['drop_absences', dropAbsences],
        ['drop_piquets', dropPiquets],
        ['drop_adjustments', dropAdjustments],
        ['dry_run', dryrun ? 1 : 0],
      ]
      const formattedParams = new URLSearchParams(params)
      const result = await pluginsForumComponent.post(
        '/Planning/Day/Form/ajax/controller.php',
        // @ts-expect-error (nocheck)
        decodeURIComponent(formattedParams)
      )
      return result.data
    } catch (e: any) {
      throw e.response
    }
  },
  getBalances: async (startDate: any, endDate: any) => {
    const balances = await brain.get('/plannings/soldes', {
      params: {
        day_start: startDate ? formatYMD(startDate) : undefined,
        day_end: endDate ? formatYMD(endDate) : undefined,
      },
    })

    return balances.data
  },
  getWeeklyHours: async ({ personId, startDate, endDate }: any) => {
    try {
      const weeklyHours = await brain.post('/plannings/person-weekly-hours', {
        person: { id: personId },
        period: `${startDate}/${endDate}`,
      })
      return weeklyHours.data || {}
    } catch (e) {
      throw e
    }
  },
  getEmployeeBalances: async (date: any, personId: any) => {
    try {
      const employeeBalances = await publicApi.get(`employees/${personId}/workday?date=${date}`)
      return employeeBalances.data
    } catch (e) {
      throw e
    }
  },
  getEmployeeDailySchedules: async (date: any, personId: any) => {
    try {
      const employeeDailySchedules = await publicApi.get(`plannings/day?person=${personId}&date=${date}`)
      return employeeDailySchedules.data
    } catch (e) {
      throw e
    }
  },
  getSectorIndemnities: async (
    indemnitiesSnowflakes: Snowflake[],
    teamsSnowflakes: any,
    startDate: any,
    endDate: any
  ) => {
    try {
      const response = await publicApi.post(`sectors/indemnities/preview`, {
        indemnities: indemnitiesSnowflakes,
        ids: teamsSnowflakes,
        start: startDate,
        end: endDate,
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getSectorIndemnitiesHeaders: async (
    indemnitiesSnowflakes: Snowflake[],
    teamsSnowflakes: any,
    startDate: any,
    endDate: any
  ) => {
    try {
      const indemnitiesHeaders = await publicApi.post(`sectors/indemnities/types`, {
        indemnities: indemnitiesSnowflakes,
        ids: teamsSnowflakes,
        start: startDate,
        end: endDate,
        displayInReport: 1,
      })
      return indemnitiesHeaders.data
    } catch (e) {
      throw e
    }
  },
  getExtraHours: async (resourcesIds: any, startDate: any, endDate: any) => {
    try {
      const request = `/balances/reports.show-extra-hours?resource_ids=${resourcesIds.join(',')}&date_range=${formatYMD(
        startDate
      )}/${formatYMD(endDate)}`
      const { data } = await publicApi.get(request)
      data.columns = data.columns.map((col: any) => ({
        ...col,
        date_range: parseISODateRange(col.date_range),
      }))

      return data
    } catch (e) {
      throw e
    }
  },
  getTeamResources: async (teamSnowflake: any, startDate: any, endDate: any, kindSnowflakeList: any) => {
    try {
      const response = await publicApi.get(
        `/directory/resources.enumerate?teams=${teamSnowflake}&dateRange=${DateRangeToISO(
          startDate,
          endDate
        )}&kinds=${kindSnowflakeList}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  },
  getTeamResourcesPost: async (teamSnowflake: any, startDate: any, endDate: any, kindSnowflakeList: any) => {
    try {
      const response = await publicApi.post(`/directory/resources.enumerate`, {
        teams: teamSnowflake,
        dateRange: DateRangeToISO(startDate, endDate),
        kinds: [kindSnowflakeList],
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getResources: async (dateToSend: any, access: any, kinds: any) => {
    try {
      const response = await publicApi.get(
        `/directory/resources.enumerate?dateRange=${dateToSend.dateStart}/${dateToSend.dateEnd}`,
        {
          params: {
            access,
            kinds,
          },
        }
      )
      return response.data
    } catch (e) {
      throw e
    }
  },
}
