import { activityReducer } from 'activity/state'
import { authenticationReducer } from 'authentication/state'
import { communicationReducer } from 'communication/state'
import { taskReducer } from 'communication/task/state'
import { connectRouter } from 'connected-react-router'
import { coreReducer } from 'core/state'
import { crudReducer } from 'crud/state'
import { directoryReducer } from 'directory/state'
import { documentReducer } from 'document/state'
import { expensesReducer } from 'expenses/state'
import { qualityReducer } from 'quality/state'
import { combineReducers } from 'redux'
// @ts-expect-error (nocheck)
import { breakpointReducer } from 'redux-breakpoint'
import { scheduleReducer } from 'schedule/state'
import { tagReducer } from 'tag/state'
import { timeclockReducer } from 'timeclock/state'

export const createRootReducer = (history: any) => {
  const reducers = {
    breakpoint: breakpointReducer,
    activity: activityReducer,
    authentication: authenticationReducer,
    core: coreReducer,
    communication: communicationReducer,
    directory: directoryReducer,
    expenses: expensesReducer,
    quality: qualityReducer,
    tag: tagReducer,
    timeclock: timeclockReducer,
    schedule: scheduleReducer,
    crud: crudReducer,
    router: connectRouter(history),
    task: taskReducer,
    document: documentReducer,
  }

  return combineReducers(reducers)
}
