import { brain, publicApi } from 'core/services/api'
import { format } from 'date-fns'
import { PersonAdapter } from 'directory/adapter/PersonAdapter'
import { ResourceAccountAdapter } from 'directory/adapter/ResourceAdapter'
import { RoleAttributionsAdapter } from 'directory/adapter/RoleAttributionsAdapter'
import { SectorAdapter, SectorsBySiteAdapter } from 'directory/adapter/SectorAdapter'
import { TeamAdapter } from 'directory/adapter/TeamAdapter'
import lodash from 'lodash'

export const directoryService = {
  getBirthdays: async () => {
    try {
      const [employeeBirthdays, residentsBirthdays] = await Promise.all([
        brain.get('/persons/employee/birthday'),
        brain.get('/persons/resident/birthday'),
      ])
      return {
        employees: employeeBirthdays.data || [],
        residents: residentsBirthdays.data || [],
      }
    } catch (e) {
      throw e
    }
  },
  getPerson: async (id: any) => {
    try {
      const person = await brain.get('/persons/' + id)
      return PersonAdapter.adapt(person.data)
    } catch (e) {
      throw e
    }
  },
  getResourcesSummaries: async (snowflakes: any, options = { adapted: false }) => {
    try {
      const resourcesSummaries = await publicApi.post('/directory/resources.show-summary', {
        ids: snowflakes,
      })

      if (options.adapted) return resourcesSummaries.data.map(ResourceAccountAdapter.fromApiSummary)
      return resourcesSummaries.data
    } catch (e) {
      throw e
    }
  },
  getResourcePermissions: async (snowflake: any) => {
    try {
      const resourcePermissions = await publicApi.get('/directory/resources.show-permissions?resource=' + snowflake)
      return resourcePermissions.data
    } catch (e) {
      throw e
    }
  },
  getResourceAccount: async (snowflake: any) => {
    try {
      const resourceAccount = await publicApi.get('/security/credentials.show-summary?resource=' + snowflake)
      return ResourceAccountAdapter.fromApi(resourceAccount.data)
    } catch (e) {
      throw e
    }
  },
  getList: async (
    search: any,
    role = 'employee',
    resource = 'employee',
    action = 'view',
    mapping = JSON.stringify([
      'id',
      'snowflake',
      'first_name',
      'last_name',
      'full_name',
      'picture',
      'company',
      'is_company',
      'confidential',
      'hidden',
      'archive',
      'sectors',
      'role',
    ])
  ) => {
    try {
      const personsResponse = await brain.get('/persons', {
        params: {
          search,
          role,
          resource,
          action,
          mapping,
        },
      })

      return lodash.map(personsResponse.data || [], person => PersonAdapter.adapt(person))
    } catch (e) {
      throw e
    }
  },
  enumerateActiveResourcesWithTeams: async (resources: any, { dateStart = '', dateEnd = '' } = {}, access?: any) => {
    try {
      const dateToSend = (dateStart || '-') + '/' + (dateEnd || '-')
      const response = await publicApi.post('/directory/resource-teams.enumerate', {
        resources,
        date_range: dateToSend,
        access,
      })
      // the object returned from the api is {id: string, teams: string[]}[]
      // mini adapter here
      return response.data.map((entry: any) => ({ snowflake: entry.id, teams: entry.teams }))
    } catch (e) {
      throw e
    }
  },
  getSupportPersonList: async () => {
    try {
      const supportPersonList = await brain.get('/config/supports')
      let personsList = [] as any[]
      const sectorsList = lodash.map(supportPersonList.data || [], sector => {
        const persons = lodash.map(sector.supports, person => {
          person.full_name = person.last_name + ' ' + person.first_name
          person.confidential = false
          person.archive = false
          person.hidden = false
          person.is_company = false
          person.sectors = []
          person.role = ''
          return PersonAdapter.adapt(person)
        })
        personsList.push(persons)
        return {
          id: sector.id,
          name: sector.name,
          persons: lodash.map(persons, person => person.id),
        }
      })

      personsList = lodash.flatten(personsList)
      return { persons: personsList, sectors: sectorsList }
    } catch (e) {
      throw e
    }
  },
  getRoles: async () => {
    try {
      const rolesList = await publicApi.get('/directory/roles.list')

      return rolesList.data.data.map((role: any) => ({
        label: role.label,
        snowflake: role.id,
        description: role.description,
      }))
    } catch (e) {
      throw e
    }
  },
  getSector: async (sectorId: any) => {
    try {
      const sector = await brain.get(`/sectors/${sectorId}`)
      return sector.data
    } catch (e) {
      return []
    }
  },
  getSite: async (siteId: any) => {
    try {
      const site = await brain.get(`/sites/${siteId}`)
      return site.data
    } catch (e) {
      return []
    }
  },
  getActivResources: async (kindSnowflakeList: any) => {
    try {
      const response = await publicApi.get(
        `/directory/resources.enumerate?dateRange=${format(
          Date.now(),
          'yyyy-MM-dd'
        )}/3000-01-01&kinds=${kindSnowflakeList}`
      )
      return response.data
    } catch (exception) {
      throw exception
    }
  },
  getRoleAttributions: async (roleSnowflake: any, activResources: any) => {
    try {
      const rolesAttributions = await publicApi.post('/directory/resource-roles.list', {
        roles: [roleSnowflake],
        resources: activResources,
      })

      const adaptedRoleAttributions = RoleAttributionsAdapter.fromApi(rolesAttributions.data)

      const result = {
        role: roleSnowflake,
        resources: adaptedRoleAttributions,
      }
      return result
    } catch (e) {
      throw e
    }
  },
  grantTeamToResource: async (resource: any, role: any, teams: any) => {
    try {
      await publicApi.post('/directory/resource-roles.grant', { ids: [resource], roles: [role], teams: teams })
    } catch (e) {
      return e
    }
  },
  grantAllTeamsToResource: async (resource: any, role: any) => {
    try {
      await publicApi.post('/directory/resource-roles.grant', { ids: [resource], roles: [role], teams: '*' })
    } catch (e) {
      return e
    }
  },
  revokeAllTeamsToResource: async (resource: any, role: any) => {
    try {
      await publicApi.post('/directory/resource-roles.revoke', { ids: [resource], roles: [role], teams: '*' })
    } catch (e) {
      return e
    }
  },
  revokeTeamToResource: async (resource: any, role: any, team: any) => {
    try {
      await publicApi.post('/directory/resource-roles.revoke', { ids: [resource], roles: [role], teams: [team] })
    } catch (e) {
      return e
    }
  },
  removeResourceFromRole: async (resource: any, role: any) => {
    try {
      await publicApi.post('/directory/resource-roles.revoke', { ids: [resource], roles: [role] })
    } catch (e) {
      return e
    }
  },
  getSectorsAndSitesList: async (args?: { key: string; value: string }[]) => {
    let url = '/sites/sectors?'

    if (!args) url += 'all=true'
    else
      args.forEach((arg, index) => {
        url += `${arg.key}=${arg.value}` + (index < args.length - 1 ? '&' : '')
      })

    const sites = await brain.get(url)
    return SectorsBySiteAdapter.fromApi(sites.data)
  },
  getSectorList: async () => {
    const sectors = await brain.get('/sectors')
    return sectors.data.map(SectorAdapter.fromApi)
  },
  getTeamList: async () => {
    const teams = await publicApi.get('/directory/teams.list')
    return teams.data.map(TeamAdapter.fromApi)
  },
  getTeamListFiltered: async (requestBody: any) => {
    const response = await publicApi.post('/directory/teams.list', requestBody)
    return TeamAdapter.fromApiWithParent(response.data)
  },
  grantRoleAttributions: async (resources: any, role: any, teams: any) => {
    try {
      const params = { ids: resources, roles: [role] }
      if (teams.length) {
        // @ts-expect-error (nocheck)
        params.teams = teams
      }

      await publicApi.post('/directory/resource-roles.grant', params)
    } catch (e) {
      throw e
    }
  },
  grantAllTeamsRoleAttributions: async (resources: any, role: any) => {
    try {
      await publicApi.post('/directory/resource-roles.grant', { ids: resources, roles: [role], teams: '*' })
    } catch (e) {
      throw e
    }
  },
  saveResourceUsername: async (resource: any, username: any) => {
    try {
      await publicApi.post('/security/credentials.change-username', { resource: resource, username: username || null })
    } catch (e) {
      // @ts-expect-error (nocheck)
      throw e.response
    }
  },
  saveMustChangePassword: async (resource: any, mustChangePassword: any) => {
    try {
      await publicApi.post('/security/credentials.set-must-change-password', {
        resource,
        must_change_password: mustChangePassword,
      })
    } catch (e) {
      throw e
    }
  },
  sendCredentialsLink: async (resource: any) => {
    try {
      await publicApi.post('/security/login-link.send-by-email', {
        resource,
      })
    } catch (e) {
      throw e
    }
  },
}
