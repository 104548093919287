import { AclType, ACL_STRATEGIES, ACL_STRATEGY_ALLOF, TwoFactorType } from 'authentication/types'
import { parseDateTime } from 'chronos'
import { isAppReady } from 'core/state/selectors'
import { SnowflakeType } from 'core/types'
import { compareAsc } from 'date-fns'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { StoreStateType } from 'store/types'

const subState = (state: StoreStateType) => state.authentication
export const getActiveUser = (state: StoreStateType) => subState(state).activeUser
export const getSession = (state: StoreStateType, sessionKey: string) => subState(state).session[sessionKey]
export const getPreference = (state: StoreStateType, preference: string) => subState(state).preferences[preference]
export const getPermissions = (state: StoreStateType, snowflake: SnowflakeType) =>
  subState(state).permissions[snowflake]
export const areAclsExpired = createSelector(subState, state => {
  const { expiration } = state.acls
  return expiration === null || compareAsc(expiration, parseDateTime()) <= 0
})

export const isUserAuthenticated = createSelector(
  isAppReady,
  getActiveUser,
  (isAppReady, activeUser) => activeUser !== null && isAppReady
)

const checkAcl = (
  state: StoreStateType,
  acls: AclType[],
  strategy: (typeof ACL_STRATEGIES)[number] = ACL_STRATEGY_ALLOF
) => {
  const permissions = subState(state).acls,
    checker = ({ resource, action, sectorId = null }: AclType) => {
      if (!permissions.values[resource] || !permissions.values[resource][action]) {
        return false
      }

      const { allowed, sectors = null } = permissions.values[resource][action]

      if (sectorId && sectors) {
        return sectors === '*' || sectors.includes(sectorId)
      }

      return allowed
    }

  return strategy === ACL_STRATEGY_ALLOF ? acls.every(checker) : acls.some(checker)
}

export const isAllowed = createCachedSelector(
  areAclsExpired,
  checkAcl,
  (expired, checkAcl) => !expired && checkAcl
)((_, acls, strategy = ACL_STRATEGY_ALLOF) => JSON.stringify(acls) + '_' + strategy)

const allowedSectors = createCachedSelector(
  (state: StoreStateType) => subState(state).acls.values,
  (_0: StoreStateType, resource: string, _1: string) => resource,
  (_0: StoreStateType, _1: string, action: string) => action,
  (acls, resource, action) => {
    return acls[resource] && acls[resource][action] ? acls[resource][action].sectors || [] : []
  }
)((_, resource, action) => `allowed_sectors_${resource}_${action}`)

export const getAllowedSectors = createCachedSelector(areAclsExpired, allowedSectors, (expired, allowedSectors) =>
  expired ? [] : allowedSectors
)((_, resource, action) => resource + '_' + action)

export const getTwoFactorMethods = (state: StoreStateType, userSnowflake: SnowflakeType) =>
  subState(state).twoFactor[userSnowflake] || []

export const isFetchingTwoFactorMethod = (state: StoreStateType) => subState(state).ui.twoFactor.isFetching

export const isTwoFactorActionOngoing = (state: StoreStateType) =>
  subState(state).ui.twoFactor.isDeleting ||
  subState(state).ui.twoFactor.isPosting ||
  subState(state).ui.twoFactor.isFetching

export const getActiveTwoFactorMethods = createCachedSelector(
  (state: StoreStateType) => subState(state).twoFactor,
  (state: StoreStateType, resourceSnowflake: SnowflakeType) => resourceSnowflake,
  (methods, resourceSnowflake) => {
    const resourceMethods = methods[resourceSnowflake]
    if (resourceMethods) {
      return resourceMethods.filter((method: TwoFactorType) => method.active === true)
    }

    return []
  }
)((state, resourceSnowflake) => `active_two_factor_methods_for_${resourceSnowflake}`)
