/* eslint-disable no-restricted-imports */
import { Duration, LocalTime, LocalDate } from '@js-joda/core'
/* eslint-enable no-restricted-imports */
import { LocalDateTimeInterval } from 'chronos'

const arbitraryDate = LocalDate.parse('2025-03-20')

export class LocalTimeInterval {
  static SEP = '/'

  timepoint: LocalTime
  duration: Duration

  constructor(timepoint: LocalTime, duration: Duration) {
    this.timepoint = timepoint
    this.duration = duration
  }

  static parse(value: string) {
    const [timepointString, durationString] = value.split(LocalTimeInterval.SEP)
    if (!timepointString || !durationString) throw new Error('Invalid call to LocalTimeInteraval.parse(): ' + value)

    return new LocalTimeInterval(LocalTime.parse(timepointString), Duration.parse(durationString))
  }

  static of(timepoint: LocalTime, duration: Duration) {
    return new LocalTimeInterval(timepoint, duration)
  }

  static between(start: LocalTime, end: LocalTime) {
    const duration = Duration.between(start, end)
    return new LocalTimeInterval(start, duration)
  }

  toString() {
    return `${this.timepoint || '-'}/${this.duration || '-'}`
  }

  toJSON() {
    return this.toString()
  }

  startTime() {
    return this.timepoint
  }

  endTime() {
    if (this.timepoint.equals(LocalTime.MIN) && this.duration.equals(Duration.ofHours(24))) {
      // we want to display 24:00 instead of 00:00 (achieved by rounding up LocalTime.MAX with our chronos formatters)
      return LocalTime.MAX
    }
    return this.timepoint.plus(this.duration)
  }

  atDate(date: LocalDate) {
    const start = this.timepoint.atDate(date)
    return LocalDateTimeInterval.of(start, start.plus(this.duration))
  }

  /**
   * Queries whether an interval intersects another interval.
   * An interval intersects if its neither before nor after the other.
   *
   * This method is commutative (A intersects B if and only if B intersects A).
   */
  intersects(other: LocalTimeInterval) {
    return !(this.precedes(other) || this.precededBy(other) || this.meets(other) || this.metBy(other))
  }

  /**
   * Allen's interval relation "precedes".
   */
  precedes(other: LocalTimeInterval) {
    return this.atDate(arbitraryDate).precedes(other.atDate(arbitraryDate))
  }

  /**
   * Allen's interval relation "precededBy".
   */
  precededBy(other: LocalTimeInterval) {
    return other.precedes(this)
  }

  /**
   * Allen's interval relation "meets".
   */
  meets(other: LocalTimeInterval): boolean {
    return this.atDate(arbitraryDate).meets(other.atDate(arbitraryDate))
  }

  /**
   * Allen's interval relation "metBy".
   */
  metBy(other: LocalTimeInterval): boolean {
    return other.meets(this)
  }

  /**
   * Allen's interval relation "contains".
   */
  encloses(other: LocalTimeInterval): boolean {
    return this.atDate(arbitraryDate).encloses(other.atDate(arbitraryDate))
  }

  /**
   * Queries whether an interval contains another interval.
   * One interval contains another if it stays within its bounds.
   * An empty interval never contains anything.
   */
  contains(other: LocalTimeInterval): boolean {
    return this.atDate(arbitraryDate).contains(other.atDate(arbitraryDate))
  }

  /**
   * Check if the given time is within the interval (end non-inclusive).
   */
  includes(time: LocalTime) {
    return !time.isBefore(this.startTime()) && time.isBefore(this.endTime())
  }
}
