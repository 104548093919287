import { BySnowflake, Team, TeamType } from 'core/types'
import { subState } from 'directory/state/selectors/subState'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { StoreStateType } from 'store/types'

export const getTeams = (state: StoreStateType) => subState(state).teams

export const getSectors = createCachedSelector(getTeams, (teams: BySnowflake<Team>) => {
  return Object.values(teams)
    .filter(team => team.type === TeamType.Sector)
    .sort((a, b) => a.name.localeCompare(b.name))
})((state: StoreStateType) => `sectors`)

export const getTeamsByType = createCachedSelector(getTeams, teams => {
  const teamsArray = lodash.map(teams, team => team)
  const teamsByType = lodash(teamsArray).groupBy('type').value()
  return teamsByType
})((state: StoreStateType) => `teams_by_type`)

export const getTeamsSnowflakesAsArray = createCachedSelector(getTeams, teams => {
  return teams.map((e: Team) => e.snowflake)
})((state: StoreStateType) => `teams_snowflake_as_array`)
