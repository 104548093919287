import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count: number, error: any) => {
        const status = error?.response?.status || 0
        return status >= 502 && status !== 504 && status !== 507 && count < 2
      },
      refetchOnWindowFocus: false,
    },
  },
})
