import { colors, fonts, styled } from 'theme'

export const Badge = styled.span`
  display: inline-block;
  text-align: center;
  font-size: ${fonts.size.sm};
  line-height: 1;
  font-weight: ${fonts.weight.regular};
  color: ${colors.darkGrey};
  background: ${colors.white};
  border: 1px solid ${colors.darkGrey};
  border-radius: 4px;
  margin: 0 3px 3px 0;
  padding: 3px 10px 3px 10px;
  white-space: normal;

  &.disabled {
    color: ${colors.greyHover};
    border: 1px solid ${colors.greyHover};
  }
`
Badge.displayName = 'sc_Badge'
