export enum Spacing {
  v0 = '0px',
  v4 = '4px',
  v8 = '8px',
  v12 = '12px',
  v16 = '16px',
  v24 = '24px',
  v32 = '32px',
  v40 = '40px',
}

export type PickSpacing<Keys extends keyof typeof Spacing> = Keys

export type SpacingKey = keyof typeof Spacing
