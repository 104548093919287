import { colors } from 'design'

// Material-ui theme colors
export const muiColors = {
  textPrimary: colors.grey600,
  defaultLight: colors.grey400,
  default: colors.grey500,
  defaultDark: colors.grey600,
  primaryLight: colors.blue400,
  primary: colors.blue600,
  primaryDark: colors.blue800,
  secondaryLight: colors.pink600,
  secondary: colors.pink700,
  secondaryDark: colors.pink900,
  errorLight: colors.red100,
  error: colors.red300,
  errorDark: colors.red500,
} as const
