import { publicApi } from 'core/services/api'
import { SnowflakeType } from 'core/types'
import { format } from 'date-fns'
import { ExpenseReportResponseType, RequestValidatorsResponseType } from 'expenses/state/types'
import {
  ExpenseReportStatusTypes,
  EXPENSE_REPORT_GROUPS,
  EXPENSE_REPORT_GROUP_ARCHIVED,
  EXPENSE_REPORT_GROUP_CURRENT,
  EXPENSE_REPORT_STATUS_ARCHIVED,
  EXPENSE_REPORT_STATUS_DRAFT,
  EXPENSE_REPORT_STATUS_PENDING_APPROVAL,
  EXPENSE_REPORT_STATUS_READY,
  EXPENSE_REPORT_STATUS_REFUSED,
  EXPENSE_REPORT_STATUS_VALIDATED,
  EXPENSE_REPORT_TYPES,
  EXPENSE_REPORT_TYPE_MINE,
  EXPENSE_REPORT_TYPE_TO_VALIDATE,
} from 'expenses/types'

const statusesByGroupAndType = (
  type: EXPENSE_REPORT_TYPES,
  group: EXPENSE_REPORT_GROUPS
): (typeof ExpenseReportStatusTypes)[number][] => {
  switch (true) {
    case type === EXPENSE_REPORT_TYPE_MINE && group === EXPENSE_REPORT_GROUP_CURRENT:
      return [EXPENSE_REPORT_STATUS_DRAFT, EXPENSE_REPORT_STATUS_READY, EXPENSE_REPORT_STATUS_PENDING_APPROVAL]
    case type === EXPENSE_REPORT_TYPE_MINE && group === EXPENSE_REPORT_GROUP_ARCHIVED:
      return [EXPENSE_REPORT_STATUS_VALIDATED, EXPENSE_REPORT_STATUS_REFUSED, EXPENSE_REPORT_STATUS_ARCHIVED]
    case type === EXPENSE_REPORT_TYPE_TO_VALIDATE && group === EXPENSE_REPORT_GROUP_CURRENT:
      return [EXPENSE_REPORT_STATUS_PENDING_APPROVAL]
    case type === EXPENSE_REPORT_TYPE_TO_VALIDATE && group === EXPENSE_REPORT_GROUP_ARCHIVED:
      return [EXPENSE_REPORT_STATUS_VALIDATED, EXPENSE_REPORT_STATUS_REFUSED, EXPENSE_REPORT_STATUS_ARCHIVED]
  }

  /* istanbul ignore next */
  throw new Error('Case not implemented')
}

export const expenseReportService = {
  getExpenseReports: async (
    type: EXPENSE_REPORT_TYPES,
    group: EXPENSE_REPORT_GROUPS,
    authorId?: SnowflakeType,
    validatorId?: SnowflakeType
  ): Promise<ExpenseReportResponseType[]> => {
    const filters = {
      status_in: statusesByGroupAndType(type, group),
      ...(authorId ? { author_id: authorId } : {}),
      ...(validatorId ? { validator_id: validatorId } : {}),
    }

    const expenseReportSummaryResponse = await publicApi.get<ExpenseReportResponseType[]>('/expenses/reports.list', {
      params: filters,
    })

    return expenseReportSummaryResponse.data
  },
  getExpenseReport: async (snowflake: SnowflakeType): Promise<ExpenseReportResponseType> => {
    const expenseReport = await publicApi.get<ExpenseReportResponseType>('/expenses/reports.show', {
      params: {
        id: snowflake,
      },
    })
    return expenseReport.data
  },
  getToValidateCount: async (): Promise<number> => {
    const expenseReport = await publicApi.get<number>('/expenses/reports-to-validate.count')

    return expenseReport.data
  },
  delete: async (id: SnowflakeType): Promise<void> => {
    await publicApi.post('/expenses/reports.delete', { id })
  },
  createExpenseReport: /* istanbul ignore next */ async (snowflake: SnowflakeType, label: string): Promise<void> => {
    await publicApi.post('/expenses/reports.create', {
      id: snowflake,
      label,
    })
  },
  renameExpenseReport: async (id: SnowflakeType, label: string): Promise<void> => {
    await publicApi.post('/expenses/reports.change-label', {
      id,
      label,
    })
  },
  getValidators: async (expenseReportId: SnowflakeType): Promise<RequestValidatorsResponseType[]> => {
    const validatorsList = await publicApi.get('/expenses/reports.validators.list', {
      params: {
        expense_report_id: expenseReportId,
      },
    })
    return validatorsList.data
  },
  sendExpenseReport: async (id: SnowflakeType, validatorId: SnowflakeType, remark: string | null): Promise<void> => {
    await publicApi.post('/expenses/reports.send', { id, validatorId, remark })
  },
  validateExpenseReport: async (id: SnowflakeType, remark: string | null): Promise<void> => {
    await publicApi.post('/expenses/reports.approve', { id, remark })
  },
  prevalidateExpenseReport: async (
    id: SnowflakeType,
    validatorId: SnowflakeType,
    remark: string | null
  ): Promise<void> => {
    await publicApi.post('/expenses/reports.forward', {
      id,
      validatorId,
      remark,
    })
  },
  refuseExpenseReport: async (id: SnowflakeType, remark: string | null): Promise<void> => {
    await publicApi.post('/expenses/reports.refuse', { id, remark })
  },

  getExpenseSnowflakes: async (
    teams: SnowflakeType,
    alreadyExported?: boolean,
    status?: typeof EXPENSE_REPORT_STATUS_VALIDATED
  ) => {
    try {
      const expenseSnowflakeList = await publicApi.get('expenses/reports.export-enumerate', {
        params: { teams, status, alreadyExported: alreadyExported ? undefined : false },
      })
      return expenseSnowflakeList.data
    } catch (e) {
      throw e
    }
  },

  markExpensesAsExported: async (ids: SnowflakeType[], date: Date) => {
    try {
      await publicApi.post('expenses/reports.mark-as-exported', {
        date: format(date, 'yyyy-MM-dd'),
        ids,
      })
    } catch (e) {
      throw e
    }
  },

  getExpensesExport: async (id: SnowflakeType[], format: string, filename: string) => {
    try {
      const receivedFile = await publicApi.post('expenses/reports.export', { id, format, filename })
      return receivedFile.data
    } catch (e) {
      throw e
    }
  },

  getRecentTags: async (): Promise<SnowflakeType[]> => {
    const tags = await publicApi.get('/expenses/recent-tags.enumerate', {
      params: {
        limit: 30,
      },
    })
    return tags.data
  },
}
