import { closeActivities, startActivity, stopActivity } from 'activity/state/actions'
import { getOpenActivity } from 'activity/state/selectors'
import { getActiveUser } from 'authentication/state/selectors'
import { parseDateTime } from 'chronos'
import { DeprecatedPeriod } from 'chronos/deprecated/Period'
import { TimecheckNotification } from 'core/components/topbar/TimecheckNotification'
import { closeLoader, openLoader, throwError } from 'core/state/actions'
import { callApi } from 'core/state/effects'
import { getConfig } from 'core/state/selectors'
import { format } from 'date-fns'
import { PersonAdapter } from 'directory/adapter/PersonAdapter'
import { receivePersons } from 'directory/state/actions'
import lodash from 'lodash'
import { toast } from 'react-toastify'
import { requestEmployeeBalances } from 'schedule/state/actions'
import { receiveTags } from 'tag/state/actions'
import { timeclockService } from 'timeclock/services/timeclock'
import * as actions from 'timeclock/state/actions'
import { getActiveLastTimecheckTagsSnowflake, getLastTimecheck } from 'timeclock/state/selectors'
import { all, put, putResolve, select, take, takeEvery, takeLatest } from 'typed-redux-saga'
import { getDeviceModel } from 'utils/deviceUtils'
import { pushToast } from 'utils/pushToast'

// triggers the update of live work times values in WorkStatsLive.tsx
export const eventClockInOutType = 'topbar.clockInOut.stamp'
const eventClockInOut = new Event(eventClockInOutType)

/** ============================= */
/** ===== General functions ===== */
/** ============================= */

// General clockin. Chooses the correct clockin based on configuration
export function* saveClockIn({ payload: { tags, period, remark } }: any) {
  try {
    const config = yield* select(getConfig)
    const qualification = config.timeclock.qualification
    if (qualification) {
      yield* callApi(qualificationClockIn)
    } else {
      yield* callApi(clockIn, tags, period, remark)
    }

    window.dispatchEvent(eventClockInOut)
    // legacy my day refresh
    window.jQuery && window.jQuery('body').trigger('updatecomplete.planif')
  } catch (e) {
    yield put(throwError(e))
  }
}

// General clockout. Chooses the correct clockout based on configuration
export function* saveClockOut({ payload: { tags, period, activityId, remark } }: any) {
  try {
    const config = yield* select(getConfig)
    const qualification = config.timeclock.qualification
    if (qualification) {
      yield* callApi(registerTimecheck)
    } else {
      yield* callApi(clockOut, tags, period, activityId, remark)
    }

    window.dispatchEvent(eventClockInOut)
    // legacy my day refresh
    window.jQuery && window.jQuery('body').trigger('updatecomplete.planif')
  } catch (e) {
    yield* put(throwError(e))
  }
}

/** ============================= */
/** == Qualification activated == */
/** ============================= */

export function* qualificationClockIn() {
  try {
    // we call savelastTimecheck and save the returned formated value
    const timecheck = yield* callApi(registerTimecheck)
    // if the returned tags' array from the timecheck is empty we request a validation from him
    if (timecheck.timecheckTags.length === 0) {
      yield* putResolve(actions.requestTimecheckTagValidation())
    } else {
      // dispatch tags
      yield* put(receiveTags(timecheck.tags))
    }
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* changeActiveTimecheckTag() {
  try {
    const previousTag = yield* select(getActiveLastTimecheckTagsSnowflake)

    // close the current timecheck
    yield* callApi(clockOut, previousTag, undefined, undefined, undefined)

    // Open a new Timecheck
    const lastTimecheck = yield* callApi(registerTimecheck)
    const newActiveTag = lastTimecheck.timecheckTags.map((e: any) => e.tag_id)

    // if user must tag and the previous timechecktag is equal to the new one or if there isn't new tag we request a validation
    const requestTimecheckTagValdiation = lodash.isEqual(previousTag, newActiveTag) || newActiveTag.length === 0

    if (requestTimecheckTagValdiation) {
      yield* put(actions.requestTimecheckTagValidation())
    }

    yield* put(actions.receiveChangeActiveTimecheckTag())
  } catch (e) {
    yield* put(throwError(e))
  }
}

/** ============================= */
/** ===== legacy activities ===== */
/** ============================= */

// Old clockIn with activities (if module is activated)
export function* clockIn(tags: any, period: any, remark: any) {
  const isStamping = !period
  try {
    let adjustment
    if (!period) {
      const lastTimecheck = yield* callApi(registerTimecheck)
      const lastTimecheckAction = actions.receiveLastTimecheck({ ...lastTimecheck })

      if (lastTimecheckAction.payload.lastTimecheck.adjusted_in) {
        adjustment = {
          in: parseDateTime(lastTimecheckAction.payload.lastTimecheck.adjusted_in),
          sourceIn: lastTimecheckAction.payload.lastTimecheck.adjusted_in_source,
        }
      }
      const timecheck = lastTimecheckAction.payload.lastTimecheck.time_in
      period = new DeprecatedPeriod(parseDateTime(timecheck))
    }
    if (tags?.length) {
      yield* put(startActivity(tags, period, remark))
    }
    toast.success(<TimecheckNotification isStamping={isStamping} period={period} adjustment={adjustment} tags={tags} />)
  } catch (e) {
    // TODO: Ajouter un toast si on est dans le bridge?
    yield put(throwError(e))
  }
}

// Old clockOut with activities (if module is activated)
export function* clockOut(tags: any, period: any, activityId: any, remark: any) {
  const isStamping = !(activityId && period)
  const config = yield* select(getConfig)
  const qualification = config.timeclock.qualification
  let adjustment

  if (activityId && period) {
    yield* put(stopActivity(activityId, tags, period, remark))
  } else {
    const openActivity = yield* select(getOpenActivity)
    yield* putResolve(actions.saveTimecheck())
    const lastTimecheckAction = yield* take(actions.RECEIVE_LAST_TIMECHECK)
    // @ts-expect-error (nocheck)
    const end = lastTimecheckAction.payload.lastTimecheck.time_out
      ? // @ts-expect-error (nocheck)
        parseDateTime(lastTimecheckAction.payload.lastTimecheck.time_out)
      : null
    // @ts-expect-error (nocheck)
    if (lastTimecheckAction.payload.lastTimecheck.adjusted_out) {
      adjustment = {
        // @ts-expect-error (nocheck)
        out: parseDateTime(lastTimecheckAction.payload.lastTimecheck.adjusted_out),
        // @ts-expect-error (nocheck)
        sourceOut: lastTimecheckAction.payload.lastTimecheck.adjusted_out_source,
      }
    }

    if (openActivity && Object.keys(openActivity).length) {
      period = openActivity.period
      tags = openActivity.tags
      yield* put(
        closeActivities(
          // @ts-expect-error (nocheck)
          end
        )
      )
    } else {
      // @ts-expect-error (nocheck)
      period = new DeprecatedPeriod(parseDateTime(lastTimecheckAction.payload.lastTimecheck.time_in), end)
    }
  }
  if (!qualification)
    toast.success(<TimecheckNotification isStamping={isStamping} period={period} adjustment={adjustment} tags={tags} />)
}

/** ============================= */
/** =========== Other =========== */
/** ============================= */

export function* assignLastTimecheckTag({ payload: { tags, remark } }: any) {
  try {
    const lastTimecheck = yield* select(getLastTimecheck)
    yield* put(
      actions.requestAssignTagToTimecheck(
        // @ts-expect-error (nocheck)
        lastTimecheck.snowflake,
        tags,
        remark
      )
    )
    yield* put(actions.receiveAssignLastTimecheckTag(tags))
  } catch (e) {
    yield* put(throwError(e))
  }
}

export function* assignTagToTimecheck({ payload: { timecheck, tags, remark } }: any) {
  yield* callApi(timeclockService.assignTagToTimecheck, timecheck, tags, remark)
  yield* put(actions.receiveAssignTagToTimecheck())
}

function* fetchPresences() {
  try {
    const rawPresences = yield* callApi(timeclockService.getPresences),
      persons = [] as any[],
      presences = [] as any[]

    lodash.forEach(rawPresences, rawPresence => {
      //TODO: remove once we are sure we can add the role to the API's output
      rawPresence.role = 'employee'

      const person = PersonAdapter.adapt(rawPresence)
      presences.push({
        person_id: person.id,
        proposal: rawPresence.openproposal,
        timecheck: rawPresence.opentimecheck,
      })
      persons.push(person)
    })

    yield* put(receivePersons(persons))
    yield* put(actions.receivePresence(presences))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* fetchLastTimecheck() {
  try {
    const lastTimecheck = yield* callApi(timeclockService.getLastTimecheck)
    if (lastTimecheck.timecheckTags?.length > 0) {
      const tags = lastTimecheck.timecheckTags.map((e: any) => e.tag)
      yield* put(receiveTags(tags))
    }
    yield* put(actions.receiveLastTimecheck(lastTimecheck))
  } catch (e) {
    yield* put(throwError(e))
  }
}

function* fetchListTimecheckForRange(action: any) {
  try {
    const listTimecheckForRange = yield* callApi(timeclockService.getListTimecheckForRange, action.payload)
    yield* put(actions.receiveListTimecheckForRange({ listTimecheckForRange, personId: action.payload.personId }))
  } catch (e: any) {
    if (e.response && e.response.status === 403) yield* put(actions.receiveListTimecheckForRangeForbidden())
    else yield* put(throwError(e))
  }
}

function* fetchListTimecheckForDate(action: any) {
  try {
    const listTimecheckForDate = yield* callApi(timeclockService.getTimecheckListForDate, action.payload)
    yield* put(
      actions.receiveListTimecheckForDate({
        timechecks: listTimecheckForDate,
      })
    )
  } catch (e: any) {
    if (e.response && e.response.status === 403) yield* put(actions.receiveListTimecheckForDateForbidden())
    else yield* put(throwError(e))
  }
}

export function* registerTimecheck() {
  try {
    yield* put(openLoader())

    const activeUser = yield* select(getActiveUser)
    const config = yield* select(getConfig)

    const lastTimecheck = yield* select(getLastTimecheck)
    const deviceModel = yield* select(getDeviceModel)

    const data: any = {
      person: activeUser.id,
      timeclock: deviceModel,
    }
    let geoloc: any = []
    if (config.timeclock.geolocation) {
      geoloc = yield* callApi(timeclockService.getCurrentLocation)

      /* istanbul ignore else */
      if (geoloc.coords) {
        if (lastTimecheck.isOut) {
          data.geolocation_in_longitude = geoloc.coords.longitude
          data.geolocation_in_latitude = geoloc.coords.latitude
          data.geolocation_in_accuracy = geoloc.coords.accuracy
        } else {
          data.geolocation_out_longitude = geoloc.coords.longitude
          data.geolocation_out_latitude = geoloc.coords.latitude
          data.geolocation_out_accuracy = geoloc.coords.accuracy
        }
      }
    }
    const newLastTimecheck = yield* callApi(timeclockService.saveTimecheck, data)
    yield* putResolve(actions.receiveLastTimecheck(newLastTimecheck))
    yield* put(requestEmployeeBalances(format(parseDateTime(), 'yyyy-MM-dd'), activeUser.id))
    return newLastTimecheck
  } catch (e) {
    yield* put(throwError(e))
  } finally {
    yield* put(closeLoader())
  }
}

function* putTimecheckValidation(action: any) {
  try {
    const {
      validated_timechecks: validatedTimechecks,
      incomplete_validation: incompleteValidation,
      message,
    } = yield* callApi(timeclockService.putTimecheckValidation, action.payload)
    yield* put(actions.receivePutTimecheckValidation({ ...action.payload, validatedTimechecks: validatedTimechecks }))
    if (incompleteValidation) {
      pushToast(message, 'error')
    } else {
      pushToast('timeclock.timecheck.daily_timecheck_validated', 'success')
    }
  } catch (response: any) {
    pushToast(['tipee.server_error', response.data.message], 'error')
  }
}

function* putTimecheckRangeValidation(action: any) {
  try {
    const {
      validated_timechecks: validatedTimechecks,
      validated_days: validatedDays,
      incomplete_validation: incompleteValidation,
    } = yield* callApi(timeclockService.putTimecheckRangeValidation, action.payload)
    yield* put(
      actions.receivePutTimecheckRangeValidation({
        personId: action.payload.personId,
        validatedDays,
        validatedTimechecks,
      })
    )
    if (incompleteValidation) {
      pushToast('timeclock.timecheck.timecheck_range_incomplete', 'error')
    } else {
      pushToast('timeclock.timecheck.timecheck_range_validated', 'success')
    }
  } catch (response: any) {
    pushToast(['tipee.server_error', response.data.message], 'error')
  }
}

function* putTimecheckDateValidation(action: any) {
  try {
    const {
      validated_timechecks: validatedTimechecks,
      incomplete_validation: incompleteValidation,
      message,
    } = yield* callApi(timeclockService.putTimecheckDateValidation, action.payload)
    yield* put(
      actions.receivePutTimecheckDateValidation({
        validatedTimechecks: validatedTimechecks,
      })
    )
    if (incompleteValidation) {
      pushToast(message, 'error')
    } else {
      pushToast('timeclock.timecheck.daily_timecheck_validated_for_all_sector', 'success')
    }
  } catch (response: any) {
    pushToast(['tipee.server_error', response.data.message], 'error')
  }
}

export const timeclockSaga = function* timeclockSaga() {
  all([
    yield* takeLatest(actions.CLOCK_IN, saveClockIn),
    yield* takeLatest(actions.CLOCK_OUT, saveClockOut),
    yield* takeLatest(actions.REQUEST_PRESENCE, fetchPresences),
    yield* takeLatest(actions.REQUEST_LAST_TIMECHECK, fetchLastTimecheck),
    yield* takeLatest(actions.REQUEST_LIST_TIMECHECK_FOR_RANGE, fetchListTimecheckForRange),
    yield* takeLatest(actions.REQUEST_LIST_TIMECHECK_FOR_DATE, fetchListTimecheckForDate),
    yield* takeLatest(actions.SAVE_TIMECHECK, registerTimecheck),
    yield* takeEvery(actions.REQUEST_PUT_TIMECHECK_VALIDATION, putTimecheckValidation),
    yield* takeLatest(actions.REQUEST_PUT_TIMECHECK_RANGE_VALIDATION, putTimecheckRangeValidation),
    yield* takeLatest(actions.REQUEST_PUT_TIMECHECK_DATE_VALIDATION, putTimecheckDateValidation),
    yield* takeLatest(actions.REQUEST_CHANGE_ACTIVE_TIMECHECK_TAG, changeActiveTimecheckTag),
    yield* takeLatest(actions.REQUEST_ASSIGN_LAST_TIMECHECK_TAG, assignLastTimecheckTag),
    yield* takeLatest(actions.REQUEST_ASSIGN_TAG_TO_TIMECHECK, assignTagToTimecheck),
  ])
}
