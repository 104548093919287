import {
  CLOSE_LOADER,
  CoreActionTypes,
  CoreState,
  FLUSH_LOADER,
  NEW_UPDATE_AVAILABLE,
  OPEN_LOADER,
  RECEIVE_BOOTSTRAP,
  SELECT_SIDEBAR_ITEM,
  SELECT_SIDEBAR_SUBSECTION,
  THROW_ERROR,
  TOGGLE_MOBILE_MENU,
  TOGGLE_SEARCHBAR,
  TOGGLE_SIDEBAR,
  TOGGLE_TOPBAR_VISIBILITY,
  TOPBAR_HEIGHT_CHANGED,
} from 'core/state/types'
import { formatLunchbreakConfig } from 'core/utils'
import produce from 'immer'

export const initialState: CoreState = {
  modules: {},
  ui: {
    newUpdateAvailable: false,
    formats: {
      date: 'dd.MM.yyyy',
      shortTextDate: 'dd MMM',
      textDate: 'dd MMMM yyyy',
      longTextDate: 'EEEE dd MMMM yyyy',
      hour: 'HH:mm',
    },
    config: {
      active_language: 'fr',
      available_languages: null,
      show_birthdates: false,
      schedule: {},
    },
    hasUnrecoverableError: false,
    loader: {
      count: 0,
      message: '',
      timeout: 0,
    },
    mobilemenu: {
      open: false,
    },
    searchbar: {
      visible: false,
    },
    sidebar: {
      open: false,
      width: 90,
      currentItem: null,
      currentSubsection: null,
    },
    topbar: {
      visible: true,
      height: 0,
    },
  },
}

export const coreReducer = (currentState: CoreState = initialState, action: CoreActionTypes): CoreState =>
  produce(currentState, draft => {
    switch (action.type) {
      case RECEIVE_BOOTSTRAP:
        /* ----- config ----- */
        /*eslint-disable-next-line */
        let { modules, ...config } = action.payload.boot.config
        config = formatLunchbreakConfig(config)

        draft.modules = modules as any
        draft.ui.config = config
        break
      case TOGGLE_TOPBAR_VISIBILITY:
        draft.ui.topbar.visible = action.payload.visible
        break
      case TOPBAR_HEIGHT_CHANGED:
        draft.ui.topbar.height = action.payload.height
        break
      case TOGGLE_SEARCHBAR:
        draft.ui.searchbar.visible = !draft.ui.searchbar.visible
        break
      case OPEN_LOADER:
        const { message, timeout } = action.payload
        draft.ui.loader = {
          count: draft.ui.loader.count + 1,
          message,
          timeout,
        }
        break
      case CLOSE_LOADER:
        draft.ui.loader.count = Math.max(draft.ui.loader.count - 1, 0)
        break
      case FLUSH_LOADER:
        draft.ui.loader.count = 0
        break
      case TOGGLE_SIDEBAR:
        const newOpenState = !draft.ui.sidebar.open
        const mobileMenuOpen = newOpenState ? false : draft.ui.mobilemenu.open

        draft.ui.sidebar.open = newOpenState
        draft.ui.sidebar.width = newOpenState ? 260 : 90
        draft.ui.mobilemenu.open = mobileMenuOpen
        break
      case SELECT_SIDEBAR_ITEM:
        const { name, selected } = action.payload
        const current = draft.ui.sidebar.currentItem

        const newValue = selected ? name : current === name ? null : current
        draft.ui.sidebar.currentItem = newValue
        break
      case SELECT_SIDEBAR_SUBSECTION: {
        const { name, selected } = action.payload
        const current = currentState.ui.sidebar.currentSubsection

        const newValue = selected ? name : current === name ? null : current
        draft.ui.sidebar.currentSubsection = newValue
        break
      }
      case TOGGLE_MOBILE_MENU: {
        const mobileMenuOpen = !draft.ui.mobilemenu.open
        const sidebarOpen = mobileMenuOpen ? false : currentState.ui.sidebar.open

        draft.ui.mobilemenu.open = mobileMenuOpen
        draft.ui.sidebar.open = sidebarOpen
        break
      }
      case THROW_ERROR:
        draft.ui.hasUnrecoverableError = true
        break
      case NEW_UPDATE_AVAILABLE:
        draft.ui.newUpdateAvailable = true
        break
    }
  })
