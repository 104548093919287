import { parseDateTime } from 'chronos'
import { format, isBefore, isWithinInterval } from 'date-fns'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { PeriodAdapter } from 'schedule/adapter/PeriodAdapter'

const subState = (state: any) => state.schedule

export const isFetchingBalances = (state: any) => subState(state).ui.balances.isFetching
export const isFetchingSchedule = (state: any) => subState(state).ui.schedules.isFetching
export const isFetchingWorkdayBalances = (state: any) => subState(state).ui.workdayBalances.isFetching
export const getHolidayBalances = (state: any) => subState(state).ui.balances.holidays

export const getPersonAdjustment = createCachedSelector(
  state => subState(state).ui.personsAdjustmentBalances,
  (state: any, id: any) => id,
  (personsAdjustmentBalances, id) => (personsAdjustmentBalances[id] ? personsAdjustmentBalances[id].balances : null)
)((state, id) => id)

export const getPeriodsForRange = createCachedSelector(
  state => subState(state).absences,
  state => subState(state).schedules,
  (state: any, start: any, end: any) => start,
  (state: any, start: any, end: any) => end,
  (absences, schedules, start, end) => {
    const comparator = (period: any) => {
      const date = parseDateTime(period.date)

      return (period.thisday_start || isWithinInterval(date, { start, end })) && isBefore(date, end)
    }

    return PeriodAdapter.revert([...lodash.filter(absences, comparator), ...lodash.filter(schedules, comparator)])
  }
)((state, start, end) =>
  JSON.stringify({
    start: format(start || parseDateTime(), 'yyyy-MM-dd'),
    end: format(end || parseDateTime(), 'yyyy-MM-dd'),
  })
)

export const getAllDayAbsence = createCachedSelector(
  state => subState(state).alldayAbsences,
  (state: any, day: any) => day,
  (alldayAbsences, day) => lodash.find(alldayAbsences, absence => absence.date === format(day, 'yyyy-MM-dd'))
)((state, start, end) =>
  JSON.stringify({
    start: format(start || parseDateTime(), 'yyyy-MM-dd'),
    end: format(end || parseDateTime(), 'yyyy-MM-dd'),
  })
)
export const getPartialAbsences = createCachedSelector(
  state => subState(state).partialAbsences,
  (state: any, start: any, end: any) => start,
  (state: any, start: any, end: any) => end,
  (partialAbsences, start, end) => {
    const comparator = (period: any) => {
      const date = parseDateTime(period.date)

      return isWithinInterval(date, { start, end }) && isBefore(date, end)
    }

    return PeriodAdapter.revert(lodash.filter(partialAbsences, comparator))
  }
)((state, start, end) =>
  JSON.stringify({
    start: format(start || parseDateTime(), 'yyyy-MM-dd'),
    end: format(end || parseDateTime(), 'yyyy-MM-dd'),
  })
)
export const getSchedulesBySnowflake = (state: any) => subState(state).schedulesBySnowflake
export const getScheduleById = createCachedSelector(
  getSchedulesBySnowflake,
  (state: any, id: any) => id,
  (schedules, id) => lodash(schedules).find(schedule => schedule.id === id)
)((state, snowflake) => `schedule_by_id_${snowflake}`)

export const getScheduleBySnowflake = createCachedSelector(
  getSchedulesBySnowflake,
  (state: any, snowflake: any) => snowflake,
  (schedules, snowflake) => {
    return schedules[snowflake]
  }
)((state, snowflake) => `schedule_by_snowflake_${snowflake}`)

export const isAdjustmentBalancesSaved = (state: any) => subState(state).ui.personsAdjustmentBalances.success

export const getAbsenceRequestToValidateCount = (state: any) => subState(state).absenceRequest.count

export const getScheduleTemplates = (state: any) => subState(state).scheduleTemplates

export const getScheduleTemplate = createCachedSelector(
  getScheduleTemplates,
  (state: any, snowflake: any) => snowflake,
  (scheduleTemplates, snowflake) => scheduleTemplates[snowflake]
)((state, snowflake) => `schedule_template_${snowflake}`)

export const isFetchingWeeklyHours = (state: any) => subState(state).ui.weeklyHours.isFetching

export const getWeeklyHours = (state: any) => subState(state).weeklyHours

export const getIndemnities = (state: any) => subState(state).indemnities

export const getExtraHours = (state: any) => subState(state).extraHours

export const isFetchingExtraHours = (state: any) => subState(state).ui.extraHours.isFetching

export const getAbsenceTypes = (state: any) => subState(state).absenceTypes
