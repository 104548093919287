/**
 * @deprecated - use directly js-joda to format WeekDayNames
 */
export const daysOfWeek = [
  { label: 'tipee.date.monday', value: 1 },
  { label: 'tipee.date.tuesday', value: 2 },
  { label: 'tipee.date.wednesday', value: 3 },
  { label: 'tipee.date.thursday', value: 4 },
  { label: 'tipee.date.friday', value: 5 },
  { label: 'tipee.date.saturday', value: 6 },
  { label: 'tipee.date.sunday', value: 0 },
]
